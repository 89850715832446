import React, { Component, Fragment } from 'react';
import { Icon, Table } from 'semantic-ui-react';

class Printout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  
  _onDownload = () =>{
    const { match,typeFile,receipt } = this.props

    if(typeFile===19){
      let url = 'https://wspro.eef.or.th/PMSServicesAPI/PageLoad.aspx?docNo='+typeFile+'&id='+receipt._id+'&db=product'
      window.open(url)
    }else{
      let url = 'https://wspro.eef.or.th/PMSServicesAPI/PageLoad.aspx?docNo='+typeFile+'&id='+match.params.id+'&db=product'
      // let url = 'http://wspro.eef.or.th/PMSServicesAPI/PageLoad.aspx?docNo='+typeFile+'&id='+match.params.id+'&db=product'
      window.open(url)
    }
  }


  render() {
    const { name } = this.props

    let content = <Fragment></Fragment>
    content = (
      <Table.Row >
        <Table.Cell content={name} />
        {/* {checkDownload &&  */}
        <Table.Cell textAlign='center' content={<Icon name='download' link onClick={this._onDownload} />} />
      {/* } */}
      </Table.Row>
    )

    return (
      <Fragment>{content}</Fragment>
    )
  }
}

export default Printout;
