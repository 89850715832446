import React, { Component } from 'react';
import {
  Tab,
  Segment,
  Menu
} from 'semantic-ui-react';
import WithdrawForm from './WithdrawForm'
import { actions as projectActions } from 'Redux/reducers/projects'
import { connect } from 'react-redux'
import { HeaderContent } from 'Components/Header'
import { AttachedComponent } from 'Utils'
import { PrintoutComponent } from 'Utils'

class TabWithdrawForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      modalAttachment: false
    };
  }


  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.props.getProposal(match.params.id);
    }
    window.scrollTo(0, 0);
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true })
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false })
  }

  handleUploaded = ({ uploaded }) => {
    let { currentProposal } = this.props
    if (!currentProposal[uploaded.name]) currentProposal[uploaded.name] = [];
    currentProposal[uploaded.name].push({ 'fileName': uploaded.typeOfAttach + '_' + uploaded.file.name, 'typeOfAttach': uploaded.typeOfAttach });
    this.props.updateProposal(currentProposal, currentProposal._id);
  }

  handleAttachedFileRemove = (name) => {
    let { currentProposal } = this.props;
    currentProposal['attachWithdrawForm'].splice(name.index, 1);
    this.props.updateProposal(currentProposal, currentProposal._id);
  }

  handleCloseModal = () => {
    this.setState({ modalSendEmail: false, modalAttachment: false, modalAddGroupFinance: false, modalPrintout: false })
  }

  render() {
    let { data, modalAttachment, modalPrintout } = this.state
    const { currentProposal, match } = this.props


    data = { ...currentProposal, ...data };
    if (!currentProposal) return (<div><HeaderContent title={data.nameTh} subtitle='แบบเบิกเงินรายงวด' onUpload={this._onUpload} /></div>);


    let index = 0
    if (currentProposal) {
      if (currentProposal.withDrawTime) index = currentProposal.withDrawTime.length
    }
    let WithdrawFormOptions = []
    let panes = [];
    for (let i = 0; i < index; i++) {
      if(i === 1 && currentProposal.special === 'yes'){
          for (let j = 0; j < 3; j++) {
            panes.push({
              menuItem: <Menu.Item key={index + j} style={{ fontFamily: 'supermarket' }}>งวดที่ {(i + 1) + '.' + (j + 1)}</Menu.Item>, render: () => <Tab.Pane><WithdrawForm
                {...this.props} index={j} history={this.props.history} special={true} /></Tab.Pane>
            })
            WithdrawFormOptions.push(
              { text: 'ขอเบิกจากผู้รับทุนงวด' + (i + 1) + '.' + (j + 1), value: 'รายงานความก้าวหน้ารายงวด' + (i + 1) + '.' + (j + 1) },
              { text: 'เล่มรายงานงวด' + (i + 1) + '.' + (j + 1), value: 'เล่มรายงานงวด' + (i + 1) + '.' + (j + 1) })
          }
      } else {
        panes.push({
          menuItem: <Menu.Item key={i} style={{ fontFamily: 'supermarket' }}>งวดที่ {(i + 1)}</Menu.Item>, render: () => <Tab.Pane><WithdrawForm
            {...this.props} index={i} history={this.props.history} special={false} /></Tab.Pane>
        })
        WithdrawFormOptions.push(
          { text: 'ขอเบิกจากผู้รับทุนงวด' + (i + 1), value: 'ขอเบิกจากผู้รับทุนงวด' + (i + 1) },
          { text: 'เล่มรายงานงวด' + (i + 1), value: 'เล่มรายงานงวด' + (i + 1) })
      }    
    }

    function compare(a, b) {
      if (a.text < b.text)
        return -1;
      if (a.text > b.text)
        return 1;
      return 0;
    }

    WithdrawFormOptions.sort(compare)

    return (
      <div>
        <HeaderContent
          title={data.nameTh}
          subtitle='แบบเบิกเงินรายงวด' />
        <AttachedComponent
          currentProposal={currentProposal}
          namePage='attachWithdrawForm'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={WithdrawFormOptions}
          onClose={this.handleCloseModal}
          checkStatus={currentProposal && currentProposal.status === 'ประเมินความก้าวหน้าโครงการ'} />
        <PrintoutComponent
          modalPrintout={modalPrintout}
          numberOfFile={['แบบเบิกเงินโครงการ']}
          typeFile={[9]}
          match={match}
          onClose={this.handleCloseModal} />
        {currentProposal && currentProposal.withDrawTime &&
          < Tab panes={panes} />
        }
        {(!currentProposal.withDrawTime || (currentProposal && currentProposal.withDrawTime.length === 0)) &&
          <Segment className="ui placeholder segment" >
            <div className="ui icon header">
              <i className="dont icon"></i>
              <h2 style={{ fontFamily: 'supermarket' }}>
                ยังไม่มีข้อมูลงวดเงินจากการทำสัญญา
            </h2>
            </div>
          </Segment>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  form: state.proposalForm,
  currentState: state.projects
})

const mapDispatchToProps = dispatch => ({
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: (id) => dispatch(projectActions.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TabWithdrawForm)
