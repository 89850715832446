import React, { Component, Fragment } from 'react';
import { Table, Button, Message, Confirm, Segment, Menu, Form, Input, Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux'
import { actions as torsActions } from 'Redux/reducers/alltors'
import { generate } from 'Redux/reducers/counting'

import { GetServerTime as GetTime } from 'Utils'

let gettime, thisYear = ''
let noTOR = undefined

const filterOptions = [
  { key: '1', text: 'ทั้งหมด', value: 'ทั้งหมด' },
  { key: '2', text: 'อนุมัติแล้ว', value: 'อนุมัติแล้ว' },
  { key: '3', text: 'ยังไม่อนุมัติ', value: 'ยังไม่อนุมัติ' },
]

class CloneTOR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      openConfirmCopy: false,
      query: {},
      search: {},
      nameSearch: 'name',
      dataSaveing: false
    };
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }


  componentDidMount = () => {
    const { auth, counter } = this.props

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });
    noTOR = undefined
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let query = {
      sort: { nameTh: 1 },
      limit: 10,
      skip: 0,
      query: {
        academic: user._id
      }
    }

    if (Object.getOwnPropertyNames(counter.counter).length > 0) {
      Object.keys(counter.counter).forEach((key) => {
        delete counter.counter[key]
      })
    }

    this.props.fetchTors(query)
    this.setState({ query: query })
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
      }
    })
  }

  componentWillReceiveProps = (nextProps) => {
    let { data, dataSaveing } = this.state
    let { counter, isSaving } = nextProps
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
          // thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
          thisYear = new Date((new Date(value)).getFullYear() + 543).getTime()
        // }
      }
    });

    // if(!gettime && gettime === '' && !thisYear && thisYear === ''&& gettime === undefined && thisYear === undefined) this.getTimeFuction()

    if (data.nameTh) {
      if (counter.counter['tor' + thisYear]) {
        let code = counter.counter['tor' + thisYear].toString().padStart(5, '0')
        noTOR = thisYear + '-T-' + code;
        delete counter.counter['tor' + thisYear]
        this.copyProject()
      }
    }

    if (isSaving) {
      this.setState({ dataSaveing: true })
    } else {
      if (dataSaveing) {
        this.setState({ dataSaveing: false })
        this.saveDataSuccess()
      }
    }


  }

  saveDataSuccess = () => {
    this.props.onCloseModalProject()
  }

  copyProject = () => {
    let { data } = this.state
    delete data._id
    delete data.noBookTor
    delete data.torCode

    data.status = 'ยังไม่อนุมัติ'
    data.recDate = gettime
    data.timestamp = gettime
    if (noTOR) {
      data.torCode = noTOR
      noTOR = undefined
      this.props.addTor(data)
      this.setState({ openConfirmCopy: false })
      // this.props.onCloseModalProject()
    } else {
      this.props.genId('tor' + thisYear)
    }

  }

  handleOpenConfirmCopy = (data) => {
    this.setState({ data: data, openConfirmCopy: true })
  }

  paging = (skip) => {
    let { query } = this.state
    query.skip = query.skip + skip
    this.props.fetchTors(query)
    this.setState({ query })
  }

  close = () => {
    this.setState({ openConfirmCopy: false })
  }

  onTextChange = (e, { name, value }) => {
    let { query } = this.state

    if (name === 'name') {
      let search = query.query
      if (value.match(/^[0-9]{3}/) !== null) {
        search['torCode'] = {}
        search['torCode']['$regex'] = value
        delete search['nameTh']
      } else {
        search['nameTh'] = {}
        search['nameTh']['$regex'] = value
        delete search['torCode']

      }
      if (value.length >= 3) {
        this.props.searchTors(search)
        this.setState({ search: search })
      } else if (value.length === 0) {
        search = query.query
        this.props.searchTors(search)
      }
    } else {
      let search = query.query
      search['academicName'] = {}
      search['academicName']['$regex'] = value
      if (value.length >= 3) {
        this.props.searchTors(search)
        this.setState({ search: search })
      } else if (value.length === 0) {
        search = query.query
        this.props.searchTors(search)
      }
    }
  }


  handleFilter = (e, { value }) => {
    const { query } = this.state

    let { search } = this.state
    search = query.query

    if (value === 'ทั้งหมด') {
      if (search.status) {
        delete search.status
      }
    } else {
      search['status'] = value
    }
    this.props.searchTors(search)
    this.setState({ status: value, })
  }

  // handleChangeSearch =()=>{

  //   if(this.state.nameSearch === 'name'){
  //     this.setState({nameSearch:'academicName'})
  //   }else{
  //     this.setState({nameSearch:'name'})
  //   }
  // }


  render() {
    const { tors } = this.props
    let { nameSearch, dataSaveing } = this.state

    return (
      <Fragment>
        <Dimmer active={dataSaveing}>
          <Loader size='large'>กำลังทำการคัดลอกข้อกำหนด</Loader>
        </Dimmer>
        <Message color='yellow'>
          <Message.Header style={{ fontFamily: 'supermarket' }}>หมายเหตุ*</Message.Header>
          <p>
            * ผู้ใช้งานสามารถ คัดลอกข้อมูลข้อกำหนดที่เคยทำไปแล้ว
            </p>
          <p>
            * เลข Running Number ต่างๆ ในข้อมูลข้อกำหนด จะเป็นเลขตัวใหม่
            </p>
          <p>
            * ผู้ใช้งานสามารถ แก้ไขข้อมูลข้อกำหนดที่คัดลอกได้ตามขั้นตอนต่างๆ เช่นเดียวกับการสร้างข้อกำหนดปกติ
            </p>
        </Message>
        <Segment>
          <Menu secondary >
            <Menu.Item fitted >
              <Input style={{ fontFamily: 'supermarket' }} name={nameSearch} onChange={this.onTextChange} icon='search' placeholder={nameSearch === 'name' ? 'ชื่อหรือเลขที่ข้อกำหนด..' : 'ผู้ประสานงาน..'} />
            </Menu.Item>
            {/* <Menu.Item fitted>
                <Button style={{fontFamily:'supermarket'}} content={nameSearch==='name'?'ชื่อหรือเลขที่ข้อกำหนด':'ผู้ประสานงาน'} fluid color='grey' icon='retweet' onClick={this.handleChangeSearch} />
              </Menu.Item> */}
            <Menu.Item fitted>
              <Form>
                <Dropdown
                  placeholder='กรองข้อมูล'
                  icon='filter'
                  labeled
                  button
                  className='icon search-dropdown'
                  onChange={this.handleFilter}
                  options={filterOptions} />
              </Form>
            </Menu.Item>
          </Menu>
        </Segment>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>เลขที่ข้อกำหนด</Table.HeaderCell>
              <Table.HeaderCell>ชื่อข้อกำหนด</Table.HeaderCell>
              {/* <Table.HeaderCell>ชื่อผู้ประสานงาน</Table.HeaderCell> */}
              <Table.HeaderCell>สถานะ</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>คัดลอก</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tors && tors.data.map((item, idx) =>
              <Table.Row key={idx}>
                <Table.Cell>{item.torCode}</Table.Cell>
                <Table.Cell>
                  {item.nameTh}
                </Table.Cell>
                {/* <Table.Cell>{item.academicName}</Table.Cell> */}
                <Table.Cell>{item.status}</Table.Cell>
                <Table.Cell textAlign='center'>
                  <Button icon='copy' color='blue' onClick={this.handleOpenConfirmCopy.bind(this, item)} />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          {tors.data.length !== 0 &&
            <Table.Footer fullWidth >
              <Table.Row>
                <Table.HeaderCell colSpan='5' textAlign='right'>
                  {tors.params && tors.params.skip !== 0 &&
                    <Button style={{ fontFamily: 'supermarket' }} content='ย้อนกลับ' onClick={this.paging.bind(null, -10)} />
                  }
                  {tors && tors.data.length >= 10 && tors.data.length !== 0 &&
                    <Button style={{ fontFamily: 'supermarket' }} content='ถัดไป' onClick={this.paging.bind(null, +10)} />
                  }
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          }
        </Table>
        <Confirm open={this.state.openConfirmCopy}
          content='ต้องการคัดลอกข้อกำหนดใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton="ยืนยัน"
          onCancel={this.close}
          onConfirm={this.copyProject} />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  selectedTor: state.tors.currentData,
  tors: state.alltors,
  isSaving: state.alltors.isSaving,
  role: state.role,
  counter: state.counter,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  fetchTors: (query) => dispatch(torsActions.fetchList({ ...query })),
  searchTors: (query) => dispatch(torsActions.fetchList({
    query: query,
    sort: { nameTh: 1 },
    limit: 10,
    skip: 0,
  })),
  addTor: (data) => dispatch(torsActions.save(data)),
  genId: (name) => dispatch(generate(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CloneTOR);
