import React, { Component, Fragment } from 'react'
import validatejs from 'validate.js'
import passwordHash from 'password-hash'
import {
  Form,
  Button,
  Header,
  Input,
  Select,
  Message,
  Segment,
  Transition,
  Icon
} from 'semantic-ui-react'
 
import { localTh } from 'Utils'
import { connect } from 'react-redux'
import { actions as userActions } from 'Redux/reducers/user'
import { constraints as ChangePwdConstraints } from './ChangePwdConstraints'


class ChangePwdUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      validate: { validateError: {}, attrs: {}, error: false },
      onSaveSuccess: false
    };
  }

  componentDidMount = () => {
    const { userID } = this.props
    this.props.getUser(userID)
  }

  _renderFormField = (content) => {
    const { validate } = this.state;
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state

    validate.attrs = {};
    validate.error = false

    data[name] = value
    this.setState({ data })

  }

  handleSubmit = () => {
    let { data, validate } = this.state
    let { user, userID } = this.props

    if(user){
      data = { ...user, ...data }
    }

    validate['validateError'] = validatejs(data, ChangePwdConstraints.ChangePwdConstraints)

    if(user.password){
      let checkOldPwd = passwordHash.verify(data.oldPwd,user.password)
      if(checkOldPwd){
        validate.validateError={}
        validate.attrs={}
        validate.error=false
      }else{
        validate.error = true
        validate['validateError']['pwd'] = []
        validate['validateError']['pwd'].push('รหัสผ่านไม่ตรงกับรหัสผ่านเก่า')
      }
    }

    if(data.Pwd !== data.confirmPwd){
      validate.error = true
      validate['validateError']['confirmPwd'] = []
      validate['validateError']['confirmPwd'].push('ยืนยันรหัสผ่านไม่ตรงกับรหัสผ่านใหม่')
    }

    if (Object.keys(validate['validateError']).length!==0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      data.password =  passwordHash.generate(data.Pwd)
      this.props.updateUser(data, userID)
      this.setState({ onSaveSuccess: true });
      setTimeout(() => {
        this.setState({ onSaveSuccess: false });
        this.props.onCloseModal()
      }, 3000)
      validate['error'] = false
      this.setState({ validate });
    }
  }

  render() {
    let { data, validate, onSaveSuccess } = this.state

    let content = {
      'oldPwd': { control: Input, type: 'password' },
      'Pwd': { control: Input, type: 'password' },
      'confirmPwd': { control: Input, type: 'password' },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('changePwd', key);
      content[key].name = key;
      content[key].value = data[key] || '';
      content[key].onChange = this.handleChange;
    });

    return (
      <Fragment>
        <Segment>
          <Header style={{fontFamily:'supermarket'}}  content='เปลี่ยนรหัสผ่าน' icon='settings' />
          <Form error={validate.error}>
            <Form.Group widths='equal'>
              {this._renderFormField(content.oldPwd)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.Pwd)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.confirmPwd)}
            </Form.Group>
            <Button style={{fontFamily:'supermarket'}} color='blue' content='เปลี่ยนรหัสผ่าน' onClick={this.handleSubmit}/>
            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
              <Message.List>
                {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}> {localTh('changePwd', key)} - {item} </Message.Item>
                    ))}
                  </Fragment>
                ))}
              </Message.List>
            </Message>
          </Form>
        </Segment>

        <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>เปลี่ยนรหัสผ่านสำเร็จ</Message.Header>
          </Message>
        </Transition>
        {/* </div> */}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.currentData,
});

const mapDispatchToProps = dispatch => ({
  updateUser: (data, id) => dispatch(userActions.save(data, id, 'POST')),
  getUser: (id) => dispatch(userActions.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePwdUser)