import React, { Component } from 'react'
import './Sidebar.css'
import { Menu, Icon, Button, Popup } from 'semantic-ui-react'
import SidebarP from 'react-sidebar'
import { sidebarState } from 'Redux/actions'
import { connect } from 'react-redux'

const mql = window.matchMedia(`(min-width: 800px)`)

class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: true
    }
  }

  componentDidMount () {
    const { sidebarOpen, sidebarDocked } = this.state
    this.props.sidebarState(sidebarOpen && sidebarDocked)

    // if (this.props.project && this.props.project._id !== undefined) {
    //   sessionStorage.setItem('projectID', this.props.project._id)
    // }
  }

  componentWillMount () {
    mql.addListener(this.mediaQueryChanged)
  }

  componentWillUnmount () {
    mql.removeListener(this.mediaQueryChanged)
  }

  onSetSidebarOpen = open => {
    this.setState({ sidebarOpen: open }, this.onChange)
  }

  mediaQueryChanged = () => {
    this.setState(
      { sidebarDocked: mql.matches, sidebarOpen: false },
      this.onChange
    )
  }

  onChange = () => {
    const { sidebarOpen, sidebarDocked } = this.state
    this.props.sidebarState(sidebarOpen && sidebarDocked)
  }

  onHideSidebar = () => {
    this.setState({ sidebarDocked: false, sidebarOpen: false }, this.onChange)
  }

  onShowSidebar = () => {
    this.setState({ sidebarDocked: true, sidebarOpen: true }, this.onChange)
  }

  getList = sub => {
    const { match } = this.props
    let menu = [
      { text: 'หน้าหลัก', icon: 'home', link: '/' },
      { text: 'ข้อมูลโครงการทั้งหมด', icon: 'search', link: '/search' }
    ]
    if (
      match.url === '/graph/search' ||
      match.url === '/graph/conclude' ||
      match.url === '/graph/progress' ||
      match.url === '/graph/borrow' ||
      match.url === '/graph/progress_split'
    ) {
      menu = [
        { text: 'หน้าหลัก', icon: 'home', link: '/' },
        { text: 'ข้อมูลโครงการทั้งหมด', icon: 'pie graph', link: '/search' },
        {
          text: 'ดูข้อมูลโครงการทั้งหมด',
          icon: 'file outline',
          link: '/conclude'
        },
        { text: 'ดูข้อมูลการเงินโครงการ', icon: 'money', link: '/progress' },
        { text: 'ดูข้อมูลเบิกเงินโครงการ(รายงวด)', icon: 'money', link: '/progress_split' },
        // { text: 'ดูข้อมูลยืมเงินทดรองจ่ายทั้งหมด', icon:'', link: '/borrow'}
      ]
      return menu
    }
    if (sub.toLowerCase() === 'graphpersonoffice') {
      menu.push({
        text: 'รายชื่อบุคลากร',
        icon: 'file outline',
        link: '/graphpersonoffice'
      })
      return menu
    }
    if (sub.toLowerCase() === 'search') return menu
    if (sub.toLowerCase() === 'searchburnproject') return menu
    if (sub.toLowerCase() === 'listorganization') {
      menu.push({
        text: 'รายชื่อองค์กร',
        icon: 'file outline',
        link: '/listorganization'
      })
      menu.splice(2, 1)
      return menu
    }
    if (sub.toLowerCase() === 'listindividuals') {
      menu.push({
        text: 'รายชื่อบุคลากร',
        icon: 'file outline',
        link: '/listindividuals'
      })
      menu.splice(2, 1)
      return menu
    }
    if (sub.toLowerCase() === 'torinfo') {
      menu.push({
        text: 'รายละเอียดข้อกำหนด',
        icon: 'file outline',
        link: '/torinfo'
      })
      menu.splice(2, 1)
      return menu
    }
    if (sub.toLowerCase() === 'torform') {
      menu.push({
        text: 'รายละเอียดข้อกำหนด',
        icon: 'file outline',
        link: '/torform'
      })
      menu.splice(2, 1)
      return menu
    } else {
      menu.push({
        text: 'รายละเอียดโครงการ',
        icon: 'clone outline',
        link: '/menu'
      })
      if (sub.toLowerCase() === 'menu') {
        menu.splice(2, 1)
        return menu
      } else {
        if (
          [
            'torflow',
            'information',
            'pagepromise',
            'tabprogressproject',
            'resultprogress'
          ].indexOf(sub.toLowerCase()) > -1
        ) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/information'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagepromise'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/tabprogressproject'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/resultprogress'
          })
        } else if (['objective'].indexOf(sub.toLowerCase()) > -1) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/objective'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagepromise'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/tabprogressproject'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/resultprogress'
          })
        } else if (['area'].indexOf(sub.toLowerCase()) > -1) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/area'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagepromise'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/tabprogressproject'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/resultprogress'
          })
        } else if (['withdrawtime'].indexOf(sub.toLowerCase()) > -1) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/withdrawtime'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagepromise'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/tabprogressproject'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/resultprogress'
          })
        } else if (['pagescreen'].indexOf(sub.toLowerCase()) > -1) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/pagescreen'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagepromise'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/tabprogressproject'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/resultprogress'
          })
        } else if (['pagesigner'].indexOf(sub.toLowerCase()) > -1) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/information'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagesigner'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/tabprogressproject'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/resultprogress'
          })
        } else if (['tabwithdrawform'].indexOf(sub.toLowerCase()) > -1) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/information'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagepromise'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/tabwithdrawform'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/resultprogress'
          })
        } else if (['extendtime'].indexOf(sub.toLowerCase()) > -1) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/information'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagepromise'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/extendtime'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/resultprogress'
          })
        } else if (['pagequitproject'].indexOf(sub.toLowerCase()) > -1) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/information'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagepromise'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/tabprogressproject'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/pagequitproject'
          })
        } else if (['endform'].indexOf(sub.toLowerCase()) > -1) {
          menu.splice(2, 1)
          menu.splice(2, 1)
          menu.push({
            text: 'รายละเอียดโครงการ',
            icon: 'clone outline',
            link: '/menu'
          })
          menu.push({
            text: 'รายละเอียดข้อกำหนด',
            icon: 'file outline',
            link: '/torflow'
          })
          menu.push({
            text: 'ส่งข้อเสนอ',
            icon: 'envelope outline',
            link: '/information'
          })
          menu.push({
            text: 'ทำสัญญา',
            icon: 'envelope outline',
            link: '/pagepromise'
          })
          menu.push({
            text: 'ความก้าวหน้า',
            icon: 'envelope outline',
            link: '/tabprogressproject'
          })
          menu.push({
            text: 'ปิด/ยุติ',
            icon: 'envelope outline',
            link: '/endform'
          })
        }
      }
    }
    return menu
  }

  onMenuClick = m => {
    // console.log(m, this.props.project._id)
    if (m === '/') return this.props.history.push(m)
    const path = '/project' + m
    if (m === '/search') {
      if (
        this.props.match.params.sub === 'graphPersonOffice' ||
        this.props.match.params.sub === 'managedb' ||
        this.props.match.params.sub === 'conclude' ||
        this.props.match.params.sub === 'progress' ||
        this.props.match.params.sub === 'borrow'
      ) {
        return this.props.history.push('/graph' + m)
      } else {
        return (
          this.props.match.params.sub !== 'search' &&
          this.props.history.push(path)
        )
      }
    }
    if (m === '/conclude' || m === '/progress' || m === '/borrow' || m === '/progress_split') {
      return this.props.history.push('/graph' + m)
    }
    if (m === '/searchburnproject') {
      this.props.history.push('/burnproject' + m)
    }
    if (m === '/torinfo' || m === '/torflow') {
      return (
        this.props.match.params.sub !== 'torinfo' &&
        this.props.history.push(
          path + '/' + (this.props.tor._id || localStorage.getItem('tor_id'))
        )
      )
    }
    if (m === '/menu') {
      return (
        this.props.match.params.sub !== 'menu' &&
        this.props.history.push(path + '/' + (this.props.project._id || localStorage.getItem('project_id')))
      )
    }
    if (
      m === '/information' ||
      m === '/pagepromise' ||
      m === '/tabprogressproject' ||
      m === '/resultprogress'
    ) {      
      if (!this.props.project || this.props.project._id === undefined) {
        this.props.history.push(
          '/project' + m + '/' + localStorage.getItem('project_id')
        )
      } else {
        this.props.history.push('/project' + m + '/' + this.props.project._id)
      }
    }
  }

  render () {
    const { sidebarOpen, sidebarDocked } = this.state
    const { match } = this.props
    const list = this.getList(match.params.sub)
    return (
      <div className='sidebar-container1'>
        <SidebarP
          sidebar={
            <div className='project-sidebar'>
              <Button
                floated='right'
                icon='angle double left'
                onClick={this.onHideSidebar}
              />
              <br />
              <br />
              {list.map((m, i) => (
                <Menu vertical fluid key={i}>
                  <Menu.Item
                    color='green'
                    active={
                      match.params.sub.toLowerCase() === m.link.replace('/', '')
                    }
                    onClick={this.onMenuClick.bind(this, m.link)}
                  >
                    {m.text}
                    <Icon name={m.icon} />
                  </Menu.Item>
                </Menu>
              ))}
            </div>
          }
          open={sidebarOpen}
          docked={sidebarDocked}
          styles={{
            sidebar: { marginTop: 60, width: 250 },
            content: { marginTop: 60 }
          }}
          onSetOpen={this.onSetSidebarOpen}
        >
          {!sidebarOpen && !sidebarDocked && (
            <div className='sidebar-button'>
              <Button icon='angle double right' onClick={this.onShowSidebar} />
              {list.map((m, i) => (
                <Popup
                  key={i}
                  content={m.text}
                  trigger={
                    <Button
                      basic
                      icon={m.icon}
                      onClick={this.onMenuClick.bind(this, m.link)}
                    />
                  }
                />
              ))}
            </div>
          )}
          <div className='sidebar-content'>{this.props.children}</div>
        </SidebarP>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tor: state.tors.currentData,
  project: state.projects.currentData,
  sidebar: state.sidebar
})

const mapDispatchToProps = dispatch => ({
  sidebarState: isOpen => dispatch(sidebarState(isOpen))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)
