function formatcomma(input) {

  if (input) {
    input = input.toString()
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      };
    } else {
      return input.substring(0, input.length - 1);
    }
  }
}

module.exports = formatcomma