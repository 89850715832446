import React, { Component } from 'react'
import './SearchResult.css'
import jwt from 'jsonwebtoken'
import { Button, Icon, Divider, Container, Grid, Modal, Confirm } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as shareuserActions } from 'Redux/reducers/shareuser'
import { ShareUsers, GetServerTime as GetTime } from 'Utils'

let gettime = ''

class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.torIds = []
    this.projects = []
    this.state = {
      openShare: false,
      projectShare: null
    }
  }

  componentDidMount() {
    const { auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });
  }


  proposalClick = (project) => {
    localStorage.setItem("tor_id", project.tor_id);
    localStorage.setItem("project_id", project._id);
    this.props.getProposal(project._id)
    this.props.getTor(project.tor_id)
    this.props.history.push("/project/menu/" + project._id + '/search')
  }

  openShareClick = (project) => {
    let query = {
      projectShareId: project._id
    }
    this.props.getlistShare(query)
    this.setState({ openShare: true, projectShare: project })
  }

  closeShareModal = () => {
    this.setState({ openShare: false })

  }

  renderProposal = () => {
    const { projects, project, auth } = this.props
    let { openShare, projectShare } = this.state

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let checkUserShare = project.findIndex(x => x.academic_id === user._id)

    return <div className="search-result-container">
      {projects && projects.data.map((p, i) => <div key={i} className="search-result-card">
        <div className="text">โครงการ : <span>{p.nameTh || ''}</span></div>
        <div className="text">เลขที่โครงการ : <span>{p.codeProject || ''}</span></div>
        <div className="text">ผู้รับผิดชอบ : <span>{p.managerName || ''}</span></div>
        <Divider />
        <div className="text">STATUS : {p.status || ''}</div>
        <Divider />
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={11}>
              <Button className="button" fluid onClick={this.proposalClick.bind(this, p)}>รายละเอียด</Button>
            </Grid.Column>
            <Grid.Column width={5}>
              <Button disabled={checkUserShare === -1} circular icon='share square' onClick={this.openShareClick.bind(this, p)} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={openShare}
          closeIcon
          onClose={this.closeShareModal}>
          <Modal.Header><Icon name='users' />Users Sharing</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <ShareUsers projectShare={projectShare} />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.closeShareModal}>
              <p style={{ fontFamily: 'supermarket' }}>ปิด</p>
            </Button>
          </Modal.Actions>
        </Modal>
      </div>)}
    </div>
  }

  getProject = (_torIds, _projects, role) => {
    const { auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (this.torIds.length === 0) {
      this.torIds = _torIds
      let query = {
        sort: { nameTh: 1 },
        limit: 100,
        skip: 0,
        query: {
          delete: { $ne: true },
          $or: this.torIds.map(t => { return { 'tor_id': t, } })
        }
      }

      setTimeout(() => {
        this.props.listProject(query)
      }, 300)
    } else {
      if (JSON.stringify(_torIds) !== JSON.stringify(this.torIds)) {
        this.torIds = []
        this.getProject(_torIds, _projects)
      }
    }
  }

  paging = (skip) => {
    const { search, tors, projects } = this.props
    let tmp = search.type === 'proposal' ? projects.params : tors.params
    tmp.query.delete = { $ne: true }
    tmp.skip = tmp.skip + skip
    search.type === 'proposal' ? this.props.listProject(tmp) : this.props.listTor(tmp)
  }

  onDeleteTOR = (id) => {
    this.props.getTor(id)
    let query = {
      query: {
        tor_id: id
      }
    }
    this.props.listProject(query)
    this.setState({ modalDelteTOR: true })
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  handlrConfirmDeleteTOR = () => {
    let { search, tors, projects, auth } = this.props

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });
    tors.currentData.userDelete = user._id
    tors.currentData.timeDelete = gettime
    tors.currentData.delete = true
    this.props.updateTor(tors.currentData, tors.currentData._id)

    projects.data.forEach(item => {
      item.userDelete = user._id
      item.timeDelete = gettime
      item.delete = true
      this.props.updateProject(item, item._id)
    })

    let tmp = search.type === 'proposal' ? projects.params : tors.params
    tmp.query.delete = { $ne: true }
    search.type === 'proposal' ? this.props.listProject(tmp) : this.props.listTor(tmp)
    this.setState({ modalDelteTOR: false })
  }

  handleCloseModalTOR = () => {
    this.setState({ modalDelteTOR: false })
  }

  renderTor = () => {
    const { tors, projects, role } = this.props

    let torIds = []
    if (tors && tors.data) {
      for (let t of tors.data) torIds.push(t._id)
    }

    if (torIds.length > 0) this.getProject(torIds, projects, role)
    return <div>
      <div className="search-result-container">
        {tors && tors.data.map((t, i) => {
          let count = '0'
          if (projects && !projects.listLoading) {
            count = projects.data.filter(x => x.tor_id === t._id).length
          }
          return <div key={i} className="search-result-card">
            <div className="text">ข้อกำหนด : <span>{t.nameTh}</span></div>
            <div className="text">เลขที่ข้อกำหนด : <span>{t.torCode}</span></div>
            <div className="text">ผู้ประสานงาน : <span>{t.academicName}</span></div>
            <Divider />
            <div className="text">STATUS : <Icon
              name={t.status === 'อนุมัติแล้ว' ? 'check' : 'close'}
              circular
              inverted
              color={t.status === 'อนุมัติแล้ว' ? 'green' : 'red'} /><span>{t.status}</span></div>
            <Divider />
            <div className="text">โครงการทั้งหมด {count}</div>
            <ul className="list">
              {projects && projects.data.map((p, i) => {
                if (p.tor_id === t._id) return <li key={i}>{p.nameTh}</li>
                return null
              })}
            </ul>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={11}>
                  <Button loading={projects.listLoading} className="button" fluid as={Link} to={"/project/torinfo/" + t._id}>รายละเอียด</Button>
                </Grid.Column>
                {role !== 'Contract' && <Grid.Column width={5}>
                  <Button circular icon='close' onClick={this.onDeleteTOR.bind(this, t._id)} />
                </Grid.Column>}
              </Grid.Row>
            </Grid>
            <Confirm open={this.state.modalDelteTOR}
              content={'ต้องการลบข้อกำหนด ' + (tors.currentData && tors.currentData.nameTh) + ' ใช่หรือไม่'}
              cancelButton='ยกเลิก'
              confirmButton="ยืนยัน"
              onCancel={this.handleCloseModalTOR}
              onConfirm={this.handlrConfirmDeleteTOR} />
          </div>
        })}
      </div>
    </div>
  }

  render() {
    const { search, projects, tors } = this.props

    let isShowPrevButton = false
    let isShowNextButton = false
    if (search.type === 'proposal') {
      isShowPrevButton = projects && projects.params && projects.params.skip !== 0
      isShowNextButton = projects && projects.data.length >= 5
    } else {
      isShowPrevButton = tors && tors.params && tors.params.skip !== 0
      isShowNextButton = tors && tors.data.length >= 5
    }
    return <div>
      {search.type === 'proposal' ? this.renderProposal() : this.renderTor()}
      <div className="search-result-container">
        {search.type === 'proposal' && projects && projects.data.length === 0 &&
          <Container textAlign='center'>
            <div className="ui icon header">
              <i className="dont icon"></i>
              <h2 style={{ fontFamily: 'supermarket' }}>
                ไม่มีข้อมูลโครงการ
                  </h2>
            </div>
          </Container>
        }
        {!tors.listLoading && search.type === 'tor' && tors && tors.data.length === 0 &&
          <Container textAlign='center'>
            <div className="ui icon header">
              <i className="dont icon"></i>
              <h2 style={{ fontFamily: 'supermarket' }}>
                ไม่มีข้อมูลข้อกำหนด
                  </h2>
            </div>
          </Container>
        }
      </div>
      <div className="search-result-footer">
        {isShowNextButton &&
          <Button
            content='ถัดไป'
            floated='right'
            icon='right arrow'
            labelPosition='right'
            onClick={this.paging.bind(null, +5)} />
        }
        {isShowPrevButton && <Button
          content='ก่อนหน้า'
          floated='left'
          icon='left arrow'
          labelPosition='left'
          onClick={this.paging.bind(null, -5)} />}
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  search: state.search,
  projects: state.projects,
  project: state.projects.data,
  tors: state.tors,
  auth: state.auth,
  role: state.role,
  shareuser: state.shareuser.data
})

const mapDispatchToProps = dispatch => ({
  getTor: (id) => dispatch(torActions.fetchOne(id)),
  getProposal: (id) => dispatch(projectActions.fetchOne(id)),
  listProject: (query) => dispatch(projectActions.fetchList({ ...query })),
  listTor: (query) => dispatch(torActions.fetchList({ ...query })),
  getlistShare: (query) => dispatch(shareuserActions.fetchList({ query })),
  updateTor: (data, id) => dispatch(torActions.save(data, id, 'POST')),
  updateProject: (data, id) => dispatch(projectActions.save(data, id, 'POST'))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult)