import React, { Component, Fragment } from 'react';
import { Table,Header,Modal,Button, Checkbox } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as professional_burnActions } from 'Redux/reducers/professional_burn'
import { actions as professional_bActions } from 'Redux/reducers/professional_b'
// import { ListProfesBurn } from './ListProfesBurn'

class YearProfesBurn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'year',
      data:{}
    };
  }

  componentDidMount() {
    this.props.listproburnPeople()
    this.props.listproburnYear()
  }

  handleSelectRow = (selected) => {
    this.props.onSelected(selected)
  }

  handleChange = (e,{name,id,checked}) =>{
    const { proburnYear } = this.props
    let { data } = this.state
    let proburnYearFilter =  proburnYear.filter(x=> x._id === id)
    let proburnYearFilterOther =  proburnYear.filter(x=> x._id !== id)

    if(proburnYear.length!==0 && proburnYearFilter[0]){
      data = { ...proburnYearFilter[0], ...data}
    }

    data['choose'] = checked

    if(data._id) this.props.updateProburn(data,data._id)


    if(data.choose){
      proburnYearFilterOther.forEach(d => {
        let tmp = { ...d }
        if(tmp.choose){
          tmp.choose = false
          this.props.updateProburn(tmp,tmp._id)
        }
      });
    }
  }

  mapArrayBudget = (data) => {
    let result = []
    if (data.length > 0) {
      result[0] = data[0]
      for (let i = 1; i < data.length; i++) {
        let check = false
        for (let j = 0; j < result.length; j++) {
          if (data[i].name === result[j].name) check = true
        }
        if (!check) result.push(data[i])
      }
    }
    return result
  }

  handleAddRow = () => {
    const { proburnYear } = this.props
    const liseYear = this.mapArrayBudget(proburnYear.map((item, idx) => ({
      name: parseInt(item.year),
      value: item
    })));
    const nextYear = (liseYear[liseYear.length - 1].name + 1).toString()
    this.setState({ modal: true, closeOnDimmerClick: false, nextYear })
  }

  handleConfirmModalAdd = () => {
    const { nextYear } = this.state
    let tmp = {}
    tmp['year'] = nextYear
    tmp['version'] = '1'
    tmp['list'] = []
    this.props.addproburn(tmp)
    this.setState({ modal: false, closeOnDimmerClick: true })
  }

  handleCloseModalAdd = () => {
    this.setState({ modal: false, closeOnDimmerClick: true })
  }

  

  render() {
    let { nextYear } = this.state
    const { proburnYear } = this.props

    const liseYear = this.mapArrayBudget(proburnYear.map((item, idx) => ({
      name: item.year,
      id: item._id,
      value: item,
      choose:item.choose
    })));

    return (
      <Fragment >
        <Header style={{fontFamily:'supermarket'}} content='ปีของผู้ทรงคุณวุฒิของโครงการเผาหัว' />
        <Table >
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell content='เปิด/ปิด' textAlign='center'/>
              <Table.HeaderCell content='ปีของผู้ทรงคุณวุฒิ' textAlign='center' />
              <Table.HeaderCell content='จำนวน' textAlign='center' />
              <Table.HeaderCell content='' textAlign='center' />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {liseYear && liseYear.map((row, idx) => (
              <Fragment key={idx}>
                <Table.Row  >
                <Table.Cell collapsing>
                <Checkbox name={row.value.year} id={row.id} slider onChange={this.handleChange} checked={row.choose}/>
                </Table.Cell>
                  <Table.Cell textAlign='center' content={row.value.year} />
                  <Table.Cell textAlign='center' content={row.value.list.length + ' คน'} />
                  <Table.Cell textAlign='center'>
                    <Button color='blue' icon='step forward' content='รายชื่อผู้ทรงคุณวุฒิ' onClick={this.handleSelectRow.bind(this, row)}/>
                  </Table.Cell>
                </Table.Row>
              </Fragment>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='4'>
                <Button content='เพิ่มปีของผู้ทรงคุณวุฒิ' color='blue' onClick={this.handleAddRow} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        {/* <Button onClick={this.handleAddPro}>add</Button> */}
        <Modal
          open={this.state.modal}
          onClose={this.handleCloseModalAdd}
          closeOnDimmerClick={this.state.closeOnDimmerClick}
          size='tiny'
          closeIcon>
          <Modal.Header style={{fontFamily:'supermarket'}} content={'เพิ่มปีของผู้ทรงคุณวุฒิ : ' + nextYear} as='h3' />
          <Modal.Actions>
            <Button style={{fontFamily:'supermarket'}} content='ยินยัน' color='blue' icon='check' onClick={this.handleConfirmModalAdd} />
            <Button style={{fontFamily:'supermarket'}} content='ยกเลิก' color='red' icon='close' onClick={this.handleCloseModalAdd} />
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  individuals: state.individuals,
  proburnYear: state.professional_burn.data,
  proburns: state.professional_b.data,
  organization: state.organizations,
  role: state.role,
});

const mapDispatchToProps = dispatch => ({
  listIndividuals: (query) => dispatch(individualsActions.fetchList({ ...query })),
  addproburn: (data) => dispatch(professional_burnActions.save(data)),
  updateProburn: (data,id) => dispatch(professional_burnActions.save(data,id,'POST')),
  listproburnYear: () => dispatch(professional_burnActions.fetchList({ sort: { year: 1 } })),
  listproburnPeople: () => dispatch(professional_bActions.fetchList()),
  getIndividuals: (id) => dispatch(individualsActions.fetchOne(id)),
  delIndividuals: (id) => dispatch(individualsActions.delete(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(YearProfesBurn);