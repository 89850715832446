import React, { Component, Fragment } from 'react';
import { Icon, Button, Progress, Form, Select, Modal } from 'semantic-ui-react';



class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      progress: 0,
      isUploading: false,
      isUploaded: false,
      typeOfAttach: undefined,
      modalWraning: false,
      nameOfAttach: ''
    };
  }


  handleClick = () => {
    const { typeOfAttach } = this.state
    if (typeOfAttach) {
      this.refs.inputOpenFileRef.click()
    }
  }

  handleChange = (e, { name, value }) => {
    const { options } = this.props
    let index = options.findIndex(x => x.value === value)
    this.setState({ typeOfAttach: value, nameOfAttach: options[index].text })

  }

  handleUpload = (e) => {
    const file = e.target.files[0]
    const formData = new FormData();
    formData.append('file', file);
    this.setState({ file: e.target.files[0] })
    if (file.name.length > 60) {
      this.setState({ modalWraning: true })
    } else {
      fetch('/minio/presignedUrl/' + this.props.bucket + '?name=' + this.state.typeOfAttach + '_' + file.name, {
        headers: {
          'Authorization': 'JWT ' + localStorage.getItem('id_token')
        }
      })
        .then(res => res.text())
        .then(url => {
          var xhr = new XMLHttpRequest()
          xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
              var progress = (event.loaded / event.total * 100).toFixed(2);
              this.setState({ progress });
            }
          });
          xhr.open('PUT', url, true)
          xhr.send(file)
          this.setState({ isUploading: true });
          xhr.onload = () => {
            if (xhr.status === 200) {
              this.setState({ isUploading: false });
              let uploaded = { ...this.props, ...this.state }

              if (this.props.onUploaded) this.props.onUploaded({ uploaded });
            }
          }
        });
    }
  }

  close = () => this.setState({ modalWraning: false })

  render() {
    const { options } = this.props
    let control = (
      <Fragment>
        <Form >
          <Form.Group inline>
            <Button style={{ fontFamily: 'supermarket' }} type='button' onClick={this.handleClick} icon labelPosition='left' >
              <Icon name='attach' />
              แนบไฟล์
          </Button>
            <Form.Field
              name='typeOfAttach'
              onChange={this.handleChange}
              control={Select}
              options={options}
              search
              placeholder='เลือกประเภทไฟล์' />
            <input ref='inputOpenFileRef' type="file" style={{ display: "none" }} value=""
              onChange={this.handleUpload} />
          </Form.Group>
        </Form>
      </Fragment>
    )
    let content = (<div></div>);
    if (this.state.isUploading) {
      content = (
        <Progress percent={this.state.progress} progress indicating>
          {this.state.file.name}
        </Progress>
      );
    }
    return (
      <Fragment>
        {content}
        {control}
        <Modal size={'tiny'} open={this.state.modalWraning} onClose={this.close}>
          <Modal.Header>คำเตือน !</Modal.Header>
          <Modal.Content>
            <p>กรุณาตั้งชื่อไฟล์แนบไม่เกิน 60 ตัวอักษร</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' content='ปิด' onClick={this.close} />
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

export default FileUploader;
