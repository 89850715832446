import React, { Component } from 'react'
import './SearchResult.css'
import { Button, Divider, Grid, Confirm } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions as scholarActions } from 'Redux/reducers/scholar'
// import { formatcomma } from 'Utils'
import { ThaiDate } from 'Utils'

class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBorrowDelete: {
        show: false,
        data: {}
      }
    }
  }

  paging = (skip) => {
    const { scholar } = this.props
    let tmp = scholar.params
    tmp.skip = tmp.skip + skip
    this.props.listBorrow(tmp)
  }

  format(input) {
    if (!input) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1);
    }
  }

  render() {
    const { borrow, scholar } = this.props
    let isShowPrevButton = false
    let isShowNextButton = false
    isShowPrevButton = scholar && scholar.params && scholar.params.skip !== 0
    isShowNextButton = scholar && scholar.data && scholar.data.length >= 5
    return (
      <div className="search-result-container">
        {borrow && borrow.map((a, i) => <div key={i} className="search-result-card">

          <div className="text">เลขที่ทดรองจ่าย: <span>{a.codeBorrow}</span></div>
          <div className="text">จุดประสงค์ : <span>{a.objective}</span></div>
          <div className="text">ปีงบประมาณ : <span>ปี {a.yearBudget}</span></div>
          <div className="text">จำนวนเงินที่ยืม : <span>{a.sumBorrowMoney ? this.format(a.sumBorrowMoney) + ' บาท' : 'ยังไม่มีข้อมูล'}</span></div>
          <div className="text">วันที่เริ่มใช้ : <span>{a.startDate ? ThaiDate('month', a.startDate) : 'ยังไม่มีข้อมูล'}</span></div>
          <div className="text">ชื่อผู้ขอยืมเงิน : <span>{a.academic}</span></div>
          <div className="text">ชื่อผู้สร้างหัวข้อขืมเงิน : <span>{a.otherAcademic || a.academic}</span></div>

          {/* <Divider /> */}
          {/* <div className="text">STATUS : <Icon name='clipboard outline' circular inverted color="grey" /><span>{a.status}</span></div> */}
          <Divider />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={11}>
                <Button loading={scholar.listLoading} fluid as={Link} to={"/borrow/tapBorrow/" + a._id}>รายละเอียด</Button>
              </Grid.Column>
              <Grid.Column width={5}>
                {/* <Button circular icon='close' onClick={this.onDeleteTOR.bind(this, t._id)} /> */}
                <Button circular icon='close' onClick={() => {
                  this.setState({ listBorrowDelete: { show: true, data: a } })
                }} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>)}
        <div className="search-result-footer">
          {isShowNextButton && <Button
            content='ถัดไป'
            floated='right'
            icon='right arrow'
            labelPosition='right'
            onClick={this.paging.bind(null, +5)} />
          }
          {isShowPrevButton && <Button
            content='ก่อนหน้า'
            floated='left'
            icon='left arrow'
            labelPosition='left'
            onClick={this.paging.bind(null, -5)} />}
        </div>
        <Confirm open={this.state.listBorrowDelete.show}
          content={'ต้องการลบทดรองจ่ายเลขที่ ' + (this.state.listBorrowDelete.data.codeBorrow) + ' ใช่หรือไม่'}
          cancelButton='ยกเลิก'
          confirmButton="ยืนยัน"
          onCancel={() => this.setState({ listBorrowDelete: { show: false, data: {} } })}
          onConfirm={() => {
            let borrow = this.state.listBorrowDelete.data
            borrow.delete = true
            this.props.updateBorrow(borrow, borrow._id)
            this.props.listBorrow(scholar.params)
            this.setState({ listBorrowDelete: { show: false, data: {} } })
          }} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  borrow: state.scholar.data
});

const mapDispatchToProps = dispatch => ({
  addBorrow: (data) => dispatch(scholarActions.save(data)),
  updateBorrow: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
  listBorrow: (query) => dispatch(scholarActions.fetchList({ ...query }))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);