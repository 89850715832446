import React, { Component, Fragment } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Header,
  Message,
  Segment,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css';
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react';
import jwt from 'jsonwebtoken'
import moment from 'moment'
import 'moment-precise-range-plugin'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { constraints as InfoConstraints } from './BurnProjectConstraints'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { actions as userActions } from 'Redux/reducers/user'
import { generate } from 'Redux/reducers/counting'
import { localTh } from 'Utils'
import { FundingFields } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'
import { actions as typeProject } from 'Redux/reducers/typeproject'

let gettime = '', noProject, thisYear = ''

let typeOfficeOption = []

class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      checkOffice: '',
      dataSaveing: false,
      checkYear: ''
    };
  }

  componentDidMount() {
    let { counter } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

    this.props.listUser()
    this.props.getListOffice()

    noProject = undefined

    if (Object.getOwnPropertyNames(counter.counter).length > 0) {
      Object.keys(counter.counter).forEach((key) => {
        delete counter.counter[key]
      })
    }

  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        if (value > checkYear) {
          thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        } else {
          thisYear = new Date((new Date(value)).getFullYear() + 543).getTime()
        }
      }
    })
  }

  componentWillReceiveProps = (nextProps) => {
    let { counter, isSaving, typeProject } = nextProps
    const { data, dataSaveing } = this.state

    if (typeProject.length > 0) {
      typeOfficeOption = []
      for (let i = 0; i < typeProject[0].typeOffice.length; i++) {
        typeOfficeOption.push({ key: i, text: typeProject[0].typeOffice[i].name, value: typeProject[0].typeOffice[i].value })
      }
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        if (value > checkYear) {
          thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        } else {
          thisYear = new Date((new Date(value)).getFullYear() + 543).getTime()
        }
      }
    });

    // if (!gettime && gettime === '' && !thisYear && thisYear === '' && gettime === undefined && thisYear === undefined) this.getTimeFuction()

    if (data.nameTh) {
      if (counter.counter['projectBurn-' + thisYear]) {
        noProject = counter.counter['projectBurn-' + thisYear] + '/' + thisYear
        delete counter.counter['projectBurn-' + thisYear]
        this.handleSubmit()
      }
    }

    if (isSaving) {
      this.setState({ dataSaveing: true })
    } else {
      if (dataSaveing) {
        this.setState({ dataSaveing: false })
        this.saveDataSuccess()
      }
    }
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state
    const { users } = this.props
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    let usersName = users.data.map((item, idx) => ({
      id: item._id,
      name: item.title + ' ' + item.firstname + ' ' + item.lastname
    }))

    if (name === 'funding') {
      // console.log(value.value)
      data['yearBudget'] = value.value.year
      data['groupsPlan'] = value.value.group
      data['plan'] = value.value.plan
      data['activity'] = value.value.activity
      data['valueGroup'] = value.value.valueGroup
      data['valuePlan'] = value.value.valuePlan
      data['valueActivity'] = value.value.valueActivity
      if (data['valueActivity']) {
        data.codeBudget = (parseInt(data.yearBudget) - 2500).toString() + data.valueActivity
      }
    } else if (name === 'typeOffice') {
      typeOfficeOption.map(r => {
        if (value === r.value) {
          data[name] = value;
          data['typeOfficeName'] = r.text
        }
        return r
      })

    } else if (name === 'academicID') {
      usersName.map(r => {
        if (value === r.id) {
          data[name] = value;
          data['nameAcademic'] = r.name
        }
        return r
      })
    } else {
      data[name] = value
    }

    this.setState({ data, validate });
  }

  handleSubmit = () => {
    let { data, validate } = this.state;
    const { auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (data.budget === '0') delete data.budget
    if (!data.objective) data.objective = []
    if (!data.targetGroup) data.targetGroup = []
    if (!data.operationPlan) data.operationPlan = []
    if (!data.result) data.result = []

    validate.attrs = {};
    validate['validateError'] = validatejs(data, InfoConstraints.InfoConstraints)
    if (validate['validateError']) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });

    } else {
      if (noProject !== undefined && noProject !== '') {
        validate['error'] = false
        if (data)
          data['recDate'] = gettime
        data['timestamp'] = gettime
        data['status'] = 'ข้อมูลพื้นฐานโครงการ'
        if (user) {
          data.academicCreateID = (user._id || '')
          data.nameAcademicCreate = (user.title || '') + (user.firstname || '') + ' ' + (user.lastname || '')
        }
        data['codeProject'] = noProject
        data['burnHaveAttach'] = false
        this.props.addBurnProject(data)
      } else {
        this.props.genId('projectBurn-' + thisYear)
      }
    }
  }

  saveDataSuccess = () => {
    this.props.onRefreshCard()
    if (this.props.onCloseModalProject) this.props.onCloseModalProject();
  }


  getEndTime = (start, endTime) => {
    var day = ((new Date(start)).getDate());
    var month = ((new Date(start)).getMonth() + endTime);
    var year = ((new Date(start)).getFullYear());
    var date = new Date(year, month, day).getTime();
    return date
  }

  _renderFormField = (content) => {
    const { validate } = this.state;
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else return <Form.Field {...content} error={validate.attrs[content.name]} />
  }


  handleComponentPerson = () => {
    this.setState({ openModalPerson: true })
  }

  handleComponentOrganization = () => {
    this.setState({ openModalOrganization: true })
  }

  close = () => {
    this.setState({ openModalPerson: false, openModalOrganization: false })
  }

  // onMoneyChange = (e) => {
  //   let { data, validate } = this.state

  //   data[e.target.name] = e.target.rawValue
  //   validate['validateError'] = validatejs(data, InfoConstraints.InfoConstraints)
  //   validate.attrs = {};
  //   if (!validate.validateError) {
  //     validate.error = false;
  //   } else {
  //     validate.error = true;
  //     Object.keys(validate.validateError).forEach((item) => {
  //       validate.attrs[item] = true;
  //     })
  //   }
  //   this.setState({ data, validate })
  // }

  onBorrowNumberChange = (e) => {
    let { data, validate } = this.state
    // let money = Math.abs(parseFloat(e.target.rawValue))
    let money = e.target.rawValue.replace('-', '')
    data[e.target.name] = money ? money.toString() : '0'
    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false
    // data[e.target.name] = parseInt(e.target.rawValue, 10)
    this.setState({ data, validate })
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state
    data[name] = date._d.getTime()
    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false

    if (data.startDate && data.endDate) {

      let sd = (moment(data.startDate).format('YYYY-MM-DD'))
      let ed = (moment(data.endDate).format('YYYY-MM-DD'))

      // var diff = moment.preciseDiff(sd, ed);
      var diff = moment.preciseDiff(sd + " 00:00:00", ed + " 24:00:00")

      let diffyth
      let diffmth
      let diffdth


      let cy = diff.indexOf('years')

      if (cy !== -1) {
        diffyth = diff.replace("years", "ปี");
      } else {
        diffyth = diff.replace("year", "ปี");
      }

      let cl = diff.indexOf('months')

      if (cl !== -1) {
        diffmth = diffyth.replace("months", "เดือน");
      } else {
        diffmth = diffyth.replace("month", "เดือน");
      }

      let cd = diff.indexOf('days')

      if (cd !== -1) {
        diffdth = diffmth.replace("days", "วัน");
      } else {
        diffdth = diffmth.replace("day", "วัน");
      }

      data['operationTime'] = diffdth
    }

    this.setState({ data, validate })
  }

  render() {
    const { validate, data, dataSaveing } = this.state
    const { auth, users } = this.props

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let usersOptions = users.data.map((item, idx) => ({
      text: item.title + ' ' + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    let content = {
      'nameTh': { control: Input },
      'typeOffice': { control: Select, options: typeOfficeOption, width: 8 },
      'funding': { control: FundingFields, width: 16 },
      'academicID': { control: Select, width: 8, options: usersOptions },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('burnInfo', key);
      content[key].name = key;
      content[key].onChange = this.handleChange;
      if (content[key].control === Input && content[key].name === 'nameTh') {
        content[key].value = data[key] || ''
      } else if (content[key].control === FundingFields) {
        content[key].value = {
          year: data['yearBudget'],
          group: data['groupsPlan'],
          plan: data['plan'],
          activity: data['activity'],
          valueGroup: data['valueGroup'],
          valuePlan: data['valuePlan'],
          valueActivity: data['valueActivity']
        };
      } else {
        content[key].value = data[key] || ''
      }
    });

    return (
      <Fragment>
        <Dimmer active={dataSaveing}>
          <Loader size='large'>กำลังทำการบันทึกข้อมูล</Loader>
        </Dimmer>
        <HeaderContent
          title={data.nameTh}
          subtitle='ข้อมูลพื้นฐานโครงการ'
          name='burn' />
        <Form error={validate.error} >
          <Segment>
            <Header style={{ fontFamily: 'supermarket' }} icon='hotjar' content='โครงการประเภท "เผาหัว"' as='h4' />
            {this._renderFormField(content.nameTh)}
            <Form.Group>
              {this._renderFormField(content.typeOffice)}
              <Form.Field width={8} error={validate.attrs['budget']}>
                <label>{'งบประมาณ'}</label>
                <Cleave name='budget'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onBorrowNumberChange.bind(this)}
                  value={data.budget || ''} />
              </Form.Field>
            </Form.Group>
            <Header style={{ fontFamily: 'supermarket' }} icon='clock' content='ระยะเวลาดำเนินการ' as='h4' />
            <Form.Group>
              <Form.Field width={4} >
                <label>{'วันที่เริ่มต้น'}</label>
                <DayPicker ser={'startDate'} name={'startDate'}
                  max={data.endDate || null}
                  onDayChange={this.handleDayChange}
                  data={data.startDate || ''} />
              </Form.Field>
              <Form.Field width={4} >
                <label>{'วันที่สิ้นสุด'}</label>
                <DayPicker ser={'endDate'} name={'endDate'}
                  min={data.startDate || null}
                  onDayChange={this.handleDayChange}
                  data={data.endDate || ''} />
              </Form.Field>
              <Header style={{ fontFamily: 'supermarket', marginLeft: 20 }}>ระยะเวลาดำเนินการ : {data['operationTime'] ? data['operationTime'] : 'ยังไม่ได้ระบุเวลาเริ่มต้น/สิ้นสุด'}</Header>
            </Form.Group>
          </Segment>
          <Segment>
            <Header style={{ fontFamily: 'supermarket' }} content='หมวดงบประมาณโครงการ' as='h4' icon='money' />
            {this._renderFormField(content.funding)}
            <Header style={{ fontFamily: 'supermarket' }} as='h4' content={'รหัสงบประมาณ : ' + (data.codeBudget || '')} />
          </Segment>
          <Form.Group>
            {this._renderFormField(content.academicID)}
          </Form.Group>

          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('burnInfo', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
          {
            <Button
              style={{ fontFamily: 'supermarket' }}
              color='blue'
              content='บันทึก'
              icon='save'
              // disabled={validate.error} loading={currentState.isSaving}
              onClick={this.handleSubmit} />
          }
        </Form>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  users: state.user,
  counter: state.counter,
  auth: state.auth,
  isSaving: state.burnprojects.isSaving,
  typeProject: state.typeproject.data
});

const mapDispatchToProps = dispatch => ({
  addBurnProject: (data) => dispatch(burnProjectAction.save(data)),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  listUser: () => dispatch(userActions.fetchList()),
  genId: (name) => dispatch(generate(name)),
  getListOffice: () => dispatch(typeProject.fetchList({ query: { name: 'สังกัดสำนัก' } }))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);
