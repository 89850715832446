import React, { Component, Fragment } from 'react';
import {
  Header,
  Button,
  Form,
  Table,
  Message,
  Select,
  Label,
  Input,
  TextArea,
  Confirm,
  Transition,
  Icon,
  Modal
} from 'semantic-ui-react'
import Cleave from 'cleave.js/react';
import validatejs from 'validate.js'
import { GetServerTime as GetTime } from 'Utils'
import { connect } from 'react-redux'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { constraints as TorConstraints } from './TORConstraints'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { actions as userActions } from 'Redux/reducers/user'
import { HeaderContent } from 'Components/Header'
import { DatePicker as DayPicker } from 'Utils'
import { localTh } from 'Utils'
import { AttachedComponent } from 'Utils'
import { PrintoutComponent } from 'Utils'
import { FundingFields } from 'Utils'
import { ValidateArray as validateArray } from 'Utils'
import { constraints as TorArrayConstraints } from './TORConstraints'
import { constraints as TorArrayConstraintsP } from './TORConstraints'
// import { constraints as TorArrayConstraintsR } from './TORConstraints'
import { constraints as TorArrayConstraintsO } from './TORConstraints'
import { constraints as TorArrayConstraintsS } from './TORConstraints'
import { formatcomma } from 'Utils'
import jwt from 'jsonwebtoken'

let gettime = ''
let idAcademic = ''
let nameAcademic = ''

let time = []
for (let i = 0; i < 60; i++) {
  time.push({ key: i.toString(), value: i + 1, text: (i + 1).toString() + ' เดือน' })
}

class TORForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      modalAttachment: false,
      modalPrintout: false,
      confirm: false,
      indexDelete: null,
      keyDelete: '',
      onSaveSuccess: false,
      alert: false,
      nameArray: ''
    };
  }


  componentDidMount = () => {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

    if (match.params.id) {
      this.props.getTor(match.params.id)
      this.props.litTor(match.params.id)
      this.props.listProposal({ tor_id: match.params.id })
    } else {
      this.props.getTor(localStorage.getItem("tor_id"))
      this.props.litTor(localStorage.getItem("tor_id"))
      this.props.listProposal({ tor_id: localStorage.getItem("tor_id") })
    }

    this.props.listBudget()
    this.props.listUser()
    this.props.listProposal({ tor_id: localStorage.getItem("tor_id") })
  }

  componentWillReceiveProps(nextProps) {
    let { data } = this.state
    let { selectedProject, projects } = nextProps

    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.props.getTor(nextProps.match.params.id)
      this.props.litTor(nextProps.match.params.id)
      
    } else {
      if (selectedProject) {
        if (selectedProject._id !== localStorage.getItem("tor_id")) {
          this.props.litTor(localStorage.getItem("tor_id"))
          this.props.getTor(localStorage.getItem("tor_id"))
        }
        if (this.props.match.params.id && projects.data) {
          data = { ...selectedProject, ...data };        
        }
        idAcademic = selectedProject.academic
        nameAcademic = selectedProject.academicName
      }
      // this.props.getTor(localStorage.getItem("tor_id"))
      // this.props.litTor(localStorage.getItem("tor_id"))
      // data = { ...selectedProject, ...data };
    }

    this.setState({ data })
  }

  handleChangeDate = (date) => {
    this.setState({ Date: date })
  }

  format(input) {
    if (!input) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      input = input.substring(0, input.length - 1);
      return input
    }
  }


  getEndTime = (start, endTime) => {
    var day = ((new Date(start)).getDate());
    var month = ((new Date(start)).getMonth() + endTime);
    var year = ((new Date(start)).getFullYear());
    var date = new Date(year, month, day).getTime();
    return date
  }


  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state;
    let { selectedProject, users } = this.props
    if (this.props.selectedProject) data = { ...selectedProject, ...data }

    if (name === 'budgetLimit') {
      let money = this.format(value)
      data[name] = money
    } else if (name === 'funding') {
      data['yearBudget'] = value.value.year
      data['groupsPlan'] = value.value.group
      data['plan'] = value.value.plan
      data['activity'] = value.value.activity
      data['valueGroup'] = value.value.valueGroup
      data['valuePlan'] = value.value.valuePlan
      data['valueActivity'] = value.value.valueActivity
    } else if (name === 'academic') {
      data[name] = value;      
      idAcademic = value
      users.data.map(r => {
        if (value === r._id){
          data['academicName'] = r.title + r.firstname + ' ' + r.lastname
          nameAcademic = r.title + r.firstname + ' ' + r.lastname
        } 
        return r
      })
    } else {
      data[name] = value;
    }
    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false

    this.setState({ data, validate });
  }

  handleAddRow = (e, { name }) => {
    let { data, validate, nameArray } = this.state;
    let { selectedProject } = this.props
    let indexT, indexP, indexR, indexO, indexS = null

    // if (this.props.selectedProject) data = { ...selectedProject, ...data }
    let tmp = {}
    let _targets = []
    let _product = []
    let _result = []
    let _objective = []
    let _scope = []


    if (name === 'targets') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _targets = selectedProject.targets.slice()
        } else {
          if (!data.targets) data.targets = selectedProject.targets.slice()
          _targets = data.targets.slice()
        }
      }
      _targets.push({ targetGroup: '', detail: '', countPerson: 0 });
      indexT = _targets.length - 1
      tmp = { ...data, targets: _targets }
    } else if (name === 'product') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _product = selectedProject.product.slice()
        } else {
          if (!data.product) data.product = selectedProject.product.slice()
          _product = data.product.slice()
        }
      }
      _product.push({ product: '' });
      indexP = _product.length - 1
      tmp = { ...data, product: _product }
    } else if (name === 'result') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _result = selectedProject.result.slice()
        } else {
          if (!data.result) data.result = selectedProject.result.slice()
          _result = data.result.slice()
        }
      }
      _result.push({ result: '' });
      tmp = { ...data, result: _result }
      indexR = _result.length - 1
    } else if (name === 'objective') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _objective = selectedProject.objective.slice()
        } else {
          if (!data.objective) data.objective = selectedProject.objective.slice()
          _objective = data.objective.slice()
        }
      }
      _objective.push({ objective: '' });
      tmp = { ...data, objective: _objective }
      indexO = _objective.length - 1
    } else if (name === 'scope') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _scope = selectedProject.scope.slice()
        } else {
          if (!data.scope) data.scope = selectedProject.scope.slice()
          _scope = data.scope.slice()
        }
      }

      _scope.push({ scope: '' });
      tmp = { ...data, scope: _scope }
      indexS = _scope.length - 1
    }

    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false


    this.setState({
      data: tmp,
      selectedTargetRow: indexT,
      selectedProductRow: indexP,
      selectedResultRow: indexR,
      selectedObjectiveRow: indexO,
      selectedScopeRow: indexS,
      validate,
      nameArray
    })
  }

  handleSelectRow = (selectedRow, name) => {
    let { selectedTargetRow,
      selectedProductRow,
      selectedResultRow,
      selectedObjectiveRow,
      selectedScopeRow } = this.state

    if (name === 'target') {
      if (selectedRow === this.state.selectedTargetRow) selectedTargetRow = null;
      else selectedTargetRow = selectedRow
      selectedProductRow = null;
      selectedResultRow = null;
      selectedObjectiveRow = null;
    }
    if (name === 'product') {
      if (selectedRow === this.state.selectedProductRow) selectedProductRow = null;
      else selectedProductRow = selectedRow
      selectedTargetRow = null;
      selectedResultRow = null;
      selectedObjectiveRow = null;
      selectedScopeRow = null;
    }
    if (name === 'result') {
      if (selectedRow === this.state.selectedResultRow) selectedResultRow = null;
      else selectedResultRow = selectedRow
      selectedTargetRow = null;
      selectedProductRow = null;
      selectedObjectiveRow = null;
      selectedScopeRow = null;
    }
    if (name === 'objective') {
      if (selectedRow === this.state.selectedObjectiveRow) selectedObjectiveRow = null;
      else selectedObjectiveRow = selectedRow
      selectedTargetRow = null;
      selectedProductRow = null;
      selectedResultRow = null;
      selectedScopeRow = null;
    }
    if (name === 'scope') {
      if (selectedRow === this.state.selectedScopeRow) selectedScopeRow = null;
      else selectedScopeRow = selectedRow
      selectedTargetRow = null;
      selectedProductRow = null;
      selectedResultRow = null;
      selectedObjectiveRow = null;
    }

    this.setState({ selectedTargetRow, modalAttachment: false, selectedProductRow, selectedResultRow, selectedObjectiveRow, selectedScopeRow })
  }

  handleEditRow = (idx, field, e, { name, value }) => {
    let { data, validate, nameArray } = this.state;
    let { selectedProject } = this.props

    // if (this.props.selectedProject) data = { ...selectedProject, ...data }
    let tmp = {}
    let _targets = []
    let _product = []
    let _result = []
    let _objective = []
    let _scope = []


    if (field === 'targets') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          selectedProject.targets.forEach(item => {
            var tmp = { ...item }
            _targets.push(tmp)
          })
        } else {
          if (!data.targets) {
            data.targets = []
            selectedProject.targets.forEach(item => {
              var tmp = { ...item }
              data.targets.push(tmp)
            })
          }
          data.targets.forEach(item => {
            var tmp = { ...item }
            _targets.push(tmp)
          })
        }
      }

      if (name === 'typeTarget') {
        if (value !== 'อื่นๆ') _targets[idx]['targetGroup'] = value
        else _targets[idx]['targetGroup'] = ''
      }
      _targets[idx][name] = value;
      tmp = { ...data, targets: _targets }

    } else if (field === 'product') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          selectedProject.product.forEach(item => {
            var tmp = { ...item }
            _product.push(tmp)
          })
        } else {
          if (!data.product) {
            data.product = []
            selectedProject.product.forEach(item => {
              var tmp = { ...item }
              data.product.push(tmp)
            })
          }
          data.product.forEach(item => {
            var tmp = { ...item }
            _product.push(tmp)
          })
        }
      }

      _product[idx][name] = value;
      tmp = { ...data, product: _product }
    } else if (field === 'result') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          selectedProject.result.forEach(item => {
            var tmp = { ...item }
            _result.push(tmp)
          })
        } else {
          if (!data.result) {
            data.result = []
            selectedProject.result.forEach(item => {
              var tmp = { ...item }
              data.result.push(tmp)
            })
          }
          data.result.forEach(item => {
            var tmp = { ...item }
            _result.push(tmp)
          })
        }
      }

      _result[idx][name] = value;
      tmp = { ...data, result: _result }
    } else if (field === 'objective') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          selectedProject.objective.forEach(item => {
            var tmp = { ...item }
            _objective.push(tmp)
          })
        } else {
          if (!data.objective) {
            data.objective = []
            selectedProject.objective.forEach(item => {
              var tmp = { ...item }
              data.objective.push(tmp)
            })
          }
          data.objective.forEach(item => {
            var tmp = { ...item }
            _objective.push(tmp)
          })
        }
      }

      _objective[idx][name] = value;
      tmp = { ...data, objective: _objective }
    } else if (field === 'scope') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          selectedProject.scope.forEach(item => {
            var tmp = { ...item }
            _scope.push(tmp)
          })
        } else {
          if (!data.scope) {
            data.scope = []
            selectedProject.scope.forEach(item => {
              var tmp = { ...item }
              data.scope.push(tmp)
            })
          }
          data.scope.forEach(item => {
            var tmp = { ...item }
            _scope.push(tmp)
          })
        }
      }

      _scope[idx][name] = value;
      tmp = { ...data, scope: _scope }
    }


    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false


    this.setState({ data: tmp, validate, nameArray })
  }

  handleDeleteRow = (e, { name }) => {
    let { data, indexDelete, keyDelete, validate } = this.state;
    let { selectedProject } = this.props

    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false

    let tmp = {}
    let _targets = []
    let _product = []
    let _result = []
    let _objective = []
    let _scope = []



    if (keyDelete === 'targets') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _targets = selectedProject.targets.slice()
        } else {
          if (!data.targets) data.targets = selectedProject.targets.slice()
          _targets = data.targets.slice()
        }
      }
      _targets.splice(indexDelete, 1)
      tmp = { ...data, targets: _targets }
    } else if (keyDelete === 'product') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _product = selectedProject.product.slice()
        } else {
          if (!data.product) data.product = selectedProject.product.slice()
          _product = data.product.slice()
        }
      }
      _product.splice(indexDelete, 1)
      tmp = { ...data, product: _product }
    } else if (keyDelete === 'result') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _result = selectedProject.result.slice()
        } else {
          if (!data.result) data.result = selectedProject.result.slice()
          _result = data.result.slice()
        }
      }
      _result.splice(indexDelete, 1)
      tmp = { ...data, result: _result }
    } else if (keyDelete === 'objective') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _objective = selectedProject.objective.slice()
        } else {
          if (!data.objective) data.objective = selectedProject.objective.slice()
          _objective = data.objective.slice()
        }
      }
      _objective.splice(indexDelete, 1)
      tmp = { ...data, objective: _objective }
    }
    else if (keyDelete === 'scope') {
      if (selectedProject) {
        if (Object.keys(data).length === 0) {
          _scope = selectedProject.scope.slice()
        } else {
          if (!data.scope) data.scope = selectedProject.scope.slice()
          _scope = data.scope.slice()
        }
      }
      _scope.splice(indexDelete, 1)
      tmp = { ...data, scope: _scope }
    }

    this.setState({
      data: tmp,
      selectedTargetRow: null,
      selectedProductRow: null,
      // selectedResultRow:null,
      selectedObjectiveRow: null,
      selectedScopeRow: null,
      open: false
    })

  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  handleConfirmSaved = () => {
    this.setState({ adminSave: false })
    this.handleSubmit()
  }

  handleConfirm = () => {
    let { role } = this.props;
    if (role === 'Admin') {
      this.setState({ adminSave: true })
    } else {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    let { data, validate } = this.state;
    let { selectedProject, auth, role, listProject } = this.props;

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (this.props.selectedProject) data = { ...selectedProject, ...data }

    if (data.budgetLimit === '0') delete data.budgetLimit
    if (data.targets) {
      data.targets.forEach(x => {
        if (x.targetsCount === '0') {
          delete x.targetsCount
        }
      });
    }

    validate.attrs = {}
    validate.error = false
    validate['validateError'] = validatejs(data, TorConstraints.TorConstraints)

    if (!data.result) data['result'] = []
    if (!data.product) data['product'] = []
    if (!data.objective) data['objective'] = []
    if (!data.targets) data['targets'] = []
    if (!data.scope) data['scope'] = []



    // validate array
    let validateArrayError = validateArray(data.targets, TorArrayConstraints.TorArrayConstraints)
    let validateArrayErrorP = validateArray(data.product, TorArrayConstraintsP.TorArrayConstraintsP)
    // let validateArrayErrorR = validateArray(data.result, TorArrayConstraintsR.TorArrayConstraintsR)
    let validateArrayErrorO = validateArray(data.objective, TorArrayConstraintsO.TorArrayConstraintsO)
    let validateArrayErrorS = validateArray(data.scope, TorArrayConstraintsS.TorArrayConstraintsS)

    if (!validate['validateError']) validate['validateError'] = {}


    if (!data.targets || data.targets.length === 0) {
      validate.error = true
      validate['validateError']['targets'] = []
      validate['validateError']['targets'].push('โปรดระบุกลุ่มเป้าหมาย')
    }
    if (!data.product || data.product.length === 0) {
      validate.error = true
      validate['validateError']['product'] = []
      validate['validateError']['product'].push('โปรดระบุผลผลิต')
    }
    if (!data.objective || data.objective.length === 0) {
      validate.error = true
      validate['validateError']['objective'] = []
      validate['validateError']['objective'].push('โปรดระบุวัตถุประสงค์')
    }
    if (!data.scope || data.scope.length === 0) {
      validate.error = true
      validate['validateError']['scope'] = []
      validate['validateError']['scope'].push('โปรดระบุขอบเขต')
    }

    if (Object.keys(validateArrayError).length !== 0 && validateArrayError.constructor === Object) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayError).forEach(index => {
        if (validateArrayError[index].targetGroup) {
          validate['validateError']['targets'] = validateArrayError[index].targetGroup
        }
        if (validateArrayError[index].targetsCount) {
          validate['validateError']['targetsCount'] = validateArrayError[index].targetsCount
        }
        if (validateArrayError[index].targetsUnit) {
          validate['validateError']['targetsUnit'] = validateArrayError[index].targetsUnit
        }
        if (validateArrayError[index].targetExplan) {
          validate['validateError']['targetExplan'] = validateArrayError[index].targetExplan
        }
        validate.attrs['targets' + index] = true;
      })
    }

    if (Object.keys(validateArrayErrorP).length !== 0 && validateArrayErrorP.constructor === Object) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayErrorP).forEach(index => {
        validate['validateError']['product'] = validateArrayErrorP[index].product
        validate.attrs['product' + index] = true;

      })
    }

    if (Object.keys(validateArrayErrorO).length !== 0 && validateArrayErrorO.constructor === Object) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayErrorO).forEach(index => {
        validate['validateError']['objective'] = validateArrayErrorO[index].objective
        validate.attrs['objective' + index] = true;

      })
    }

    if (Object.keys(validateArrayErrorS).length !== 0 && validateArrayErrorS.constructor === Object) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayErrorS).forEach(index => {
        validate['validateError']['scope'] = validateArrayErrorS[index].scope
        validate.attrs['scope' + index] = true;

      })
    }

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      this.setState({ validate });
      if (role === 'Admin') {
        data['AdminEdit'] = user._id
        data['timestampAdmin'] = gettime
        this.props.deleteProposal('14b5c5402db811e9b0c8ad9e9d62c3f0')
        listProject.forEach(item => {
          item.academic = nameAcademic
          item.academic_id = idAcademic
          this.props.updateProposal(item, item._id)
        })
      } else {
        data['timestamp'] = gettime
        data['endDate'] = this.getEndTime(data.startDate, data.timeProcess)
      }
      this.props.updateTor(data, data._id)
      this.setState({ onSaveSuccess: true });
      setTimeout(() => {
        this.setState({ onSaveSuccess: false });
      }, 3000)
      // if (this.props.history) this.props.history.push('/project/torinfo/' + data._id);
    }
  }

  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state;
    let { selectedProject } = this.props;

    data = { ...selectedProject, ...data };

    validate.error = false
    validate['validateError'] = {}
    validate.attrs = {}



    data[name] = date._d.getTime();
    this.setState({ data, validate })
  }

  _renderFormField = (content) => {
    let { data, validate } = this.state;
    const { role, selectedProject } = this.props;

    data = { ...selectedProject, ...data };

    if (content.control === Input) {
      if ((data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')) {
        const component = (
          <Form.Field {...content} error={validate.attrs[content.name]} onKeyPress={this.handleKeyPress} readOnly={true} />
        )
        return component;
      } else {
        const component = (
          <Form.Field {...content} error={validate.attrs[content.name]} onKeyPress={this.handleKeyPress} />
        )
        return component;
      }
    } else {
      if ((data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')) {
        const component = (
          <Form.Field {...content} error={validate.attrs[content.name]} search={true} selection disabled={true} />
        )
        return component;
      } else {
        const component = (
          <Form.Field {...content} error={validate.attrs[content.name]} search={true} selection />
        )
        return component;
      }
    }
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true, modalPrintout: false })
  }

  handleUploaded = ({ uploaded }) => {
    let { selectedProject } = this.props
    let findArray = selectedProject[uploaded.name] && selectedProject[uploaded.name].find(x => x.fileName === uploaded.typeOfAttach + '_' + uploaded.file.name)
    if (!findArray) {
      if (!selectedProject[uploaded.name]) selectedProject[uploaded.name] = [];
      selectedProject[uploaded.name].push({ 'fileName': uploaded.typeOfAttach + '_' + uploaded.file.name, 'typeOfAttach': uploaded.typeOfAttach });
    } else {
      this.setState({ alert: true });
    }
    this.props.updateTor(selectedProject, selectedProject._id);
  }

  handleAttachedFileRemove = (name) => {
    let { selectedProject } = this.props;
    selectedProject['attachTOR'].splice(name.index, 1);
    this.props.updateTor(selectedProject, selectedProject._id);
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false })
  }

  handleCloseAttach = () => {
    this.setState({ modalPrintout: false, modalAttachment: false })
  }

  onBackClick = () => {
    const { currentData } = this.props
    this.props.history && this.props.history.push('/torinfo/' + currentData._id)
  }

  open = (key, e, { name }) => {
    this.setState({ open: true, selectedTargetRow: null, indexDelete: name, keyDelete: key })
  }

  close = () => this.setState({ open: false, adminSave: false })

  closeAlert = () => {
    this.setState({ alert: false })
  }

  handleClick = () => this.setState({ onSaveSuccess: !this.state.onSaveSuccess })

  handleClose = () => this.setState({ onSaveSuccess: false })

  onMoneyChange = (e) => {
    let { data, validate } = this.state

    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false
    // let money = Math.abs(parseFloat(e.target.rawValue))
    let money = e.target.rawValue.replace('-', '')
    data[e.target.name] = money ? money.toString() : '0'

    this.setState({ data, validate })
  }

  onMoneyTargetsChange = (idx, e) => {
    let { data, validate } = this.state
    let { selectedProject } = this.props

    // let money = Math.abs(parseFloat(e.target.rawValue))
    let money = e.target.rawValue.replace('-', '')

    let tmp = {}
    let _targets = []

    if (selectedProject) {
      if (Object.keys(data).length === 0) {
        selectedProject.targets.forEach(item => {
          var tmp = { ...item }
          _targets.push(tmp)
        })
      } else {
        if (!data.targets) {
          data.targets = []
          selectedProject.targets.forEach(item => {
            var tmp = { ...item }
            data.targets.push(tmp)
          })
        }
        data.targets.forEach(item => {
          var tmp = { ...item }
          _targets.push(tmp)
        })
      }
    }

    _targets[idx][e.target.name] = money ? money.toString() : '0'
    tmp = { ...data, targets: _targets }

    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false
    this.setState({ data: tmp, validate })
  }

  render() {
    let {
      selectedTargetRow,
      selectedProductRow,
      // selectedResultRow,
      selectedObjectiveRow,
      selectedScopeRow,
      data,
      validate,
      modalAttachment,
      modalPrintout,
      onSaveSuccess,
      alert,
    } = this.state
    const { selectedProject, match, projects, users, role, isSavingPro } = this.props;

    const optionsTarget = [
      { key: '0', text: 'เด็กและเยาวชนด้อยโอกาส อายุระหว่างแรกเกิด - ๑๘ ปี', value: 'เด็กและเยาวชนด้อยโอกาส อายุระหว่างแรกเกิด - ๑๘ ปี' },
      { key: '1', text: 'นักเรียน/นักศึกษา', value: 'นักเรียน/นักศึกษา' },
      { key: '2', text: 'ครู', value: 'ครู' },
      { key: '3', text: 'สถานศึกษา', value: 'สถานศึกษา' },
      { key: '4', text: 'องค์กร', value: 'องค์กร' },
      { key: '5', text: 'บุคลากรทางการศึกษาอื่นๆ', value: 'บุคลากรทางการศึกษาอื่นๆ' },
    ]

    let userOption = []
    if (users.data.length > 0) {
      userOption = users.data.map((item, idx) => ({
        key: item._id,
        value: item._id,
        text: item.title + ' ' + item.firstname + ' ' + item.lastname
      }));
    }

    if (this.props.match.params.id && projects.data) {
      data = { ...selectedProject, ...data };
      
    }

    if (!selectedProject) return <HeaderContent title='Term of Reference: TOR' subtitle='แบบฟอร์มการขออนุมัติข้อกำหนดและขอบเขตจ้างที่ปรึกษา' onUpload={this._onUpload} />

    let content = {
      'noBook': { control: Input, width: 6 },
      'torCode': { control: Input, width: 6 },
      'nameTh': { control: Input, },
      'nameEn': { control: Input, },
      'objective': { control: TextArea },
      'timeProcess': { control: Select, options: time, width: 5 },
      'budgetLimit': { control: Input, money: 'money', },
      'academic': { control: Select, options: userOption, width: 8 },
      'status': { control: Input, readOnly: true },
      'funding': { control: FundingFields, width: 16 },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('tor', key);
      content[key].name = key;
      content[key].onChange = this.handleChange;
      if (content[key].control === Input) {
        content[key].value = data[key] || ''
      } else if (content[key].control === FundingFields) {
        content[key].value = {
          year: data['yearBudget'],
          group: data['groupsPlan'],
          plan: data['plan'],
          activity: data['activity'],
          valueGroup: data['valueGroup'],
          valuePlan: data['valuePlan'],
          valueActivity: data['valueActivity']
        };
      } else if (content[key].control === Select) {
        if (content[key].name === 'timeProcess') {
          content[key].value = data[key] || ''
        } else {
          content[key].value = data[key] || ''
        }

      } else {
        content[key].value = data[key];
      }
    });

    var attachTOR = [
      { key: '1', text: 'TOR', value: 'TOR' },
      { key: '2', text: 'บันทึกข้อความขออนุมัติ', value: 'บันทึกข้อความขออนุมัติ' },
    ]

    return (
      <Fragment>
        <HeaderContent
          title="Term of Reference: TOR"
          subtitle="แบบฟอร์มการขออนุมัติข้อกำหนดและขอบเขตจ้างที่ปรึกษา"
          // onDownload={this._onDownload}
          onUpload={this._onUpload}
          name='TOR' />
        <AttachedComponent
          currentProposal={selectedProject}
          namePage='attachTOR'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={attachTOR}
          onClose={this.handleCloseAttach}
          checkStatus={selectedProject && selectedProject.status !== 'อนุมัติแล้ว'} />
        <PrintoutComponent
          modalPrintout={modalPrintout}
          numberOfFile={['Template TOR']}
          typeFile={[1]}
          match={match}
          onClose={this.handleCloseAttach} />
        <Form error={validate.error} >
          {this._renderFormField(content.nameTh)}
          {this._renderFormField(content.nameEn)}

          <Header as='h4' style={{ fontFamily: 'supermarket' }} content='วัตถุประสงค์ *' icon='list alternate outline' />
          <Table celled selectable color="olive">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='วัตถุประสงค์ *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.objective && data.objective.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'objective')} error={validate.attrs['objective' + idx]}>
                    {(selectedObjectiveRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center'> {(idx + 1)}</Table.Cell>}
                    <Table.Cell content={item.objective} />
                    <Table.Cell textAlign='center'><Button type='button' name={idx} color='red' icon='close'
                      disabled={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                      onClick={this.open.bind(this, 'objective')} />
                    </Table.Cell>
                  </Table.Row>
                  {selectedObjectiveRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={4}>
                        <Form.TextArea
                          readOnly={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                          label={'วัตถุประสงค์ที่ ' + (idx + 1)}
                          name='objective'
                          value={item.objective}
                          onChange={this.handleEditRow.bind(this, idx, 'objective')} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button disabled={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')} content='เพิ่ม' color='green' icon='plus' name='objective' onClick={this.handleAddRow} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Header as='h4' style={{ fontFamily: 'supermarket' }} content='ขอบเขต *' icon='list alternate outline' />
          <Table celled selectable color="olive">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='ขอบเขต *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.scope && data.scope.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'scope')} error={validate.attrs['scope' + idx]}>
                    {(selectedScopeRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center'> {(idx + 1)}</Table.Cell>}
                    <Table.Cell content={item.scope} />
                    <Table.Cell textAlign='center'>
                      <Button type='button' name={idx} color='red' icon='close'
                        disabled={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                        onClick={this.open.bind(this, 'scope')} />
                    </Table.Cell>
                  </Table.Row>
                  {selectedScopeRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={4}>
                        <Form.TextArea
                          readOnly={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                          label={'ขอบเขตที่ ' + (idx + 1)}
                          name='scope'
                          value={item.scope}
                          onChange={this.handleEditRow.bind(this, idx, 'scope')} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button
                    disabled={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                    content='เพิ่ม'
                    color='green'
                    icon='plus'
                    name='scope'
                    onClick={this.handleAddRow} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Header as='h4' style={{ fontFamily: 'supermarket' }} content='กลุ่มเป้าหมายข้อกำหนด *' icon='target' />
          <Table celled selectable color="olive">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='กลุ่มเป้าหมาย *' width={4} />
                <Table.HeaderCell content='หมายเหตุ' width={4} />
                <Table.HeaderCell content='จำนวน *' textAlign='center' width={3} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.targets && data.targets.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'target')} error={validate.attrs['targets' + idx]}>
                    {(selectedTargetRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={idx + 1} />

                    </Table.Cell>) || <Table.Cell>{'ลำดับที่ ' + (idx + 1)}</Table.Cell>}
                    <Table.Cell content={item.targetGroup} />
                    <Table.Cell content={item.detail} />
                    <Table.Cell content={(formatcomma(item.targetsCount) ? formatcomma(item.targetsCount) + ' ' + (item.targetsUnit || '') : ' ')} textAlign='center' />
                    <Table.Cell textAlign='center'>
                      <Button type='button' name={idx} color='red' icon='close'
                        disabled={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                        onClick={this.open.bind(this, 'targets')} />
                    </Table.Cell>
                  </Table.Row>
                  {selectedTargetRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={5}>
                        <Form.Group widths='equal'>
                          <Form.Select
                            readOnly={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                            label='กลุ่มเป้าหมาย'
                            name='typeTarget'
                            value={item.typeTarget || ''}
                            options={optionsTarget}
                            onChange={this.handleEditRow.bind(this, idx, 'targets')} />
                          <Form.Input
                            readOnly={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                            label='กลุ่มเป้าหมาย (อื่นๆ โปรดระบุ)'
                            name='targetGroup'
                            value={item.targetGroup || ''}
                            onChange={this.handleEditRow.bind(this, idx, 'targets')} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                          <Form.TextArea
                            label='หมายเหตุ'
                            name='detail'
                            value={item.detail || ''}
                            onChange={this.handleEditRow.bind(this, idx, 'targets')}
                            readOnly={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')} />
                          <Form.TextArea label={'ข้อความของกลุ่มเป้าหมายที่ ' + (idx + 1)} name='targetExplan'
                            value={item.targetExplan || ''} onChange={this.handleEditRow.bind(this, idx, 'targets')} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                          <Form.Field >
                            <label>{'จำนวน'}</label>
                            <Cleave name='targetsCount'
                              maxLength='16'
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand'
                              }}
                              onChange={this.onMoneyTargetsChange.bind(this, idx)}
                              value={item.targetsCount || ''}
                              readOnly={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')} />
                          </Form.Field>
                          <Form.Input label='หน่วย' name='targetsUnit'
                            value={item.targetsUnit || ''} onChange={this.handleEditRow.bind(this, idx, 'targets')} />
                        </Form.Group>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button disabled={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')} content='เพิ่ม' color='green' icon='plus' name='targets' onClick={this.handleAddRow} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Header as='h4' style={{ fontFamily: 'supermarket' }} content='ผลผลิตข้อกำหนด *' icon='list alternate outline' />
          <Table celled selectable color="olive">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='ผลผลิตข้อกำหนด *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.product && data.product.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'product')} error={validate.attrs['product' + idx]}>
                    {(selectedProductRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center'> {(idx + 1)}</Table.Cell>}
                    <Table.Cell content={item.product} />
                    <Table.Cell textAlign='center'>
                      <Button type='button' name={idx} color='red' icon='close'
                        disabled={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                        onClick={this.open.bind(this, 'product')} />
                    </Table.Cell>
                  </Table.Row>
                  {selectedProductRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={4}>
                        <Form.TextArea
                          readOnly={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                          label={'ผลผลิตที่ ' + (idx + 1)}
                          name='product'
                          value={item.product}
                          onChange={this.handleEditRow.bind(this, idx, 'product')} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button disabled={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')} content='เพิ่ม' color='green' icon='plus' name='product' onClick={this.handleAddRow} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Form.Group >
            <Form.Field width={8}>
              <label>{'งบประมาณ'}</label>
              <Cleave name='budgetLimit'
                maxLength='16'
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
                onChange={this.onMoneyChange.bind(this)}
                value={data.budgetLimit || 0} />
            </Form.Field>
            <Form.Field disabled={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') ||
              (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
              width={3} >
              <label>{'วันที่เริ่ม *'}</label>
              <DayPicker ser={'startDate'} name={'startDate'}
                onDayChange={this.handleDayChange}
                data={data.startDate || ''} />
            </Form.Field>
            {this._renderFormField(content.timeProcess)}
          </Form.Group>
          {role === 'Admin' && this._renderFormField(content.academic)}
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('tor', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
          <Button loading={projects.isSaving && isSavingPro} color='blue' icon='save'
            disabled={validate.error || (data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') ||
              (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
            onClick={this.handleConfirm} content="บันทึก" />
        </Form>
        <Confirm open={this.state.open}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton="ยืนยัน"
          onCancel={this.close}
          onConfirm={this.handleDeleteRow} />
        <Confirm open={this.state.adminSave}
          content='นักวิชาการของโครงการภายใต้ข้อกำหนด อาจเปลี่ยนแปลง ต้องการบันทึกใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton="ยืนยัน"
          onCancel={this.close}
          onConfirm={this.handleConfirmSaved} />
        <Modal
          open={alert}
          onClose={this.closeAlert}
          size='small'
        >
          <Header icon='browser' style={{ fontFamily: 'supermarket' }} content='ผิดพลาด' />
          <Modal.Content>
            <h3 style={{ fontFamily: 'supermarket' }}>ไฟล์ซ้ำ</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color='grey' onClick={this.closeAlert}>
              <Icon name='checkmark' /> ตกลง
          </Button>
          </Modal.Actions>
        </Modal>
        <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
          </Message>
        </Transition>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  projects: state.tors,
  currentData: state.tors.currentData,
  selectedProject: state.tors.data[0],
  users: state.user,
  role: state.role,
  listProject: state.projects.data,
  auth: state.auth,
  isSavingPro: state.projects.isSaving,
})

const mapDispatchToProps = dispatch => ({
  updateTor: (data, id) => dispatch(torActions.save(data, id, 'POST')),
  getTor: (id) => dispatch(torActions.fetchOne(id)),
  litTor: (id) => dispatch(torActions.fetchList({ query: { _id: id } })),
  listProposal: (query) => dispatch(projectActions.fetchList({ query: query })),
  deleteProposal: (id) => dispatch(projectActions.delete(id)),
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  listUser: () => dispatch(userActions.fetchList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TORForm);
