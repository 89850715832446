import React, { Component, Fragment } from 'react';
import { Modal, Table } from 'semantic-ui-react';
import Printout from './Printout';

class PrintoutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExists: true,
      modalOpen: false
    };
  }

  componentDidMount = () => {
    const { modalPrintout, } = this.props

    if (modalPrintout || modalPrintout === true) this.setState({ modalOpen: true })
  }

  componentWillReceiveProps = (nextProps) => {
    const { modalPrintout } = nextProps

    if (modalPrintout || modalPrintout === true) this.setState({ modalOpen: true })
  }


  closeModal = () => {
    this.props.onClose()
    this.setState({ modalOpen: false })
  }


  render() {
    const { modalOpen } = this.state
    const { modalPrintout, numberOfFile,typeFile,match,receipt,doc } = this.props

    let FilePrint = []
  
    for (let i = 0; i < numberOfFile.length; i++) {
      FilePrint.push({ name:numberOfFile[i],typeFile:typeFile[i] })
    }

    let content = <Fragment></Fragment>
    content = (
      <Modal
        open={modalOpen}
        onClose={this.closeModal}
        size='small'
        closeIcon>
        <Modal.Content>
          {/* {modalSendEmail && <ComponentModalSendEmail />} */}
          {modalPrintout &&
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content='ชื่อไฟล์' width={9} />
                  {/* <Table.HeaderCell content='ประเภทของไฟล์' width={5} /> */}
                  <Table.HeaderCell textAlign='center' content='พิมพ์' width={2} />
                  {/* {!this.props.readOnly && ( */}
                  {/* <Table.HeaderCell textAlign='center' content='download' width={2} /> */}
                  {/* )} */}
                </Table.Row>
              </Table.Header>
              <Table.Body>
              {FilePrint.map((item, idx) => (
                    <Fragment key={idx}>
                      <Printout
                        receipt={receipt}
                        match={match}
                        typeFile={item.typeFile}
                        name={item.name}
                        index={idx}
                        doc={doc} />
                    </Fragment>
                  ))}
              </Table.Body>
            </Table>
          }
          {/* <AttachedComponentReadOnly currentProposal={currentProposal} namePageReadOnly={namePageReadOnly}/> */}
        </Modal.Content>
      </Modal>
    )
    return (
      <Fragment>{content}</Fragment>
    )
  }
}




export default PrintoutComponent
