import React, { Component, Fragment } from 'react';
import { Form, Button, Header, Message, Label, Table, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { localTh } from 'Utils'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { GetServerTime as GetTime } from 'Utils'

var thisYear, gettime = ''

class ListTypeProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      selectYear: undefined,
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      selected: null,
      finalYear: undefined
    };
  }

  componentDidMount() {
    this.props.getTypeProject()
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        if (value > checkYear) {
          thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        } else {
          thisYear = new Date((new Date(value)).getFullYear() + 543).getTime()
        }
      }
    });
  }

  componentWillReceiveProps = (nextProps) => {
    let { selectYear, finalYear } = this.state
    let { typeProject } = nextProps

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        if (value > checkYear) {
          thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        } else {
          thisYear = new Date((new Date(value)).getFullYear() + 543).getTime()
        }
      }
    });


    if (typeProject) {
      typeProject.filter(item => item.type === 'ชุดโครงการ')
        .forEach(item => {
          if (!finalYear) {
            finalYear = item.year
          } else {
            if (finalYear < item.year) {
              finalYear = item.year
            }
          }
        })
    }
    if (thisYear) {
      if (!selectYear) this.setState({ selectYear: parseInt(thisYear), finalYear })
    }

  }

  handleEditRow = (idx, e, { name, value }) => {
    let { data, selectYear } = this.state;
    let { typeProject } = this.props;
    if (typeProject) data = typeProject.filter(item => item.type === 'ชุดโครงการ' && item.year === selectYear)

    data[0].list[idx][name] = value;

    this.setState({ data });
  }

  handleAddRow = () => {
    let { data, selectYear } = this.state;
    let { typeProject } = this.props;
    if (typeProject) data = typeProject.filter(item => item.type === 'ชุดโครงการ' && item.year === selectYear)

    let codeFormat = (selectYear - 2500) + (data[0].list.length + 1).toString().padStart(3, '0')
    data[0].list.push({ name: '(โปรดใส่คำอธิบายชุดโครงการ)', code: codeFormat });
    this.setState(data)
  }

  handleDeleteRow = (e, { name, title }) => {
    this.setState({ deleteRow: true, row: name, nameRow: title })
  }

  confirmDeleteRow = () => {
    let { data, selectYear, row } = this.state;
    let { typeProject } = this.props;
    if (typeProject) data = typeProject.filter(item => item.type === 'ชุดโครงการ' && item.year === selectYear)

    data[0].list.splice(row, 1)
    this.props.updateTypeProject(data[0], data[0]._id)

    this.setState({ deleteRow: false })
  }

  handleSubmit = () => {
    let { data, selectYear } = this.state;
    let { typeProject } = this.props;
    if (typeProject) data = typeProject.filter(item => item.type === 'ชุดโครงการ' && item.year === selectYear)

    this.props.updateTypeProject(data[0], data[0]._id)
    this.props.onClose()
  }

  handleSelectRow = (selected) => {
    if (selected === this.state.selected) selected = null
    this.setState({ selected })
  }

  handleChengeYear = (e, { value }) => {
    this.setState({ selectYear: value })
  }

  handleAddYearSeries = () => {
    let { typeProject } = this.props
    let { finalYear } = this.state

    if (typeProject) {
      typeProject.filter(item => item.type === 'ชุดโครงการ')
        .forEach(item => {
          if (!finalYear) {
            finalYear = item.year
          } else {
            if (finalYear < item.year) {
              finalYear = item.year
            }
          }
        })

      this.setState({ onModal: true, finalYear })
    }
  }

  close = () => {
    this.setState({ onModal: false, deleteRow: false })
  }

  confirmAddSeries = () => {
    this.props.addTypeProjec({ type: 'ชุดโครงการ', year: (this.state.finalYear + 1), list: [] })
    this.setState({ onModal: false })
  }

  render() {
    let { validate, data, selected, selectYear, finalYear } = this.state
    let { typeProject } = this.props;
    if (typeProject) {
      data = { ...typeProject, ...data };
      data = typeProject.filter(item => item.type === 'ชุดโครงการ' && item.year === selectYear)
      if (selectYear) {
        data = data.filter(item => item.year === selectYear)
      }
    }

    let yearOption = typeProject.filter(item => item.type === 'ชุดโครงการ')
      .map((item, idx) => ({
        key: idx,
        text: item.year,
        value: item.year
      }))

    return (
      <Fragment>
        <Header style={{ fontFamily: 'supermarket' }} content='รายชื่อประเภทโครงการ' />
        <Form error={validate.error}>
          <Form.Group>
            <Form.Select options={yearOption} width={8} label='ปีชุดโครงการ' value={selectYear || parseInt(thisYear)}
              onChange={this.handleChengeYear} />
            <Form.Field>
              <label>{'เพิ่มปีชุดโครงการ'}</label>
              <Button color='green' icon='plus' content='เพิ่ม' onClick={this.handleAddYearSeries} />
            </Form.Field>
          </Form.Group>
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content='รหัสชุดโครงการ' width={3} textAlign='center' />
                <Table.HeaderCell content='คำอธิบาย' width={11} textAlign='center' />
                <Table.HeaderCell content='ลบ' width={2} textAlign='center' />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data[0] && data[0].list.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx)}>
                    {(selected === idx && <Table.Cell>
                      <Label color="green" ribbon content={item.code || ''} />
                    </Table.Cell>) || <Table.Cell textAlign='center'> {item.code || ''}</Table.Cell>}
                    <Table.Cell content={item.name} />
                    <Table.Cell textAlign='center'>
                      <Button color='red' content='ลบ' name={idx} title={item.name} onClick={this.handleDeleteRow} />
                    </Table.Cell >
                  </Table.Row>
                  {selected === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={3}>
                        <Form.TextArea label={'คำอธิบาย ' + (idx + 1)} name='name' placeholder='(โปรดใส่คำอธิบาย)'
                          value={item.name || ''} onChange={this.handleEditRow.bind(this, idx)} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell >
                  <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus'
                    onClick={this.handleAddRow} />
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Footer>
          </Table>
          <Modal style={{ marginTop: 50 }}
            open={this.state.onModal}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon>
            <Modal.Header>
              {'เพิ่มชุดโครงการปั ' + (finalYear + 1)}
            </Modal.Header>
            <Modal.Actions>
              <Button onClick={this.close} content='ยกเลิก' color='red' />
              <Button onClick={this.confirmAddSeries} content='ยืนยัน' color='blue' />
            </Modal.Actions>
          </Modal>

          <Modal style={{ marginTop: 50 }}
            open={this.state.deleteRow}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon>
            <Modal.Header>
              {'ยืนยันการลบชุดโครงการ ' + this.state.nameRow}
            </Modal.Header>
            <Modal.Actions>
              <Button onClick={this.close} content='ยกเลิก' color='red' />
              <Button onClick={this.confirmDeleteRow} content='ยืนยัน' color='blue' />
            </Modal.Actions>
          </Modal>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('organization', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
          <Button onClick={this.handleSubmit} style={{ fontFamily: 'supermarket' }} color='blue' disabled={validate.error}>บันทึก</Button>
        </Form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  typeProject: state.typeproject.data
});

const mapDispatchToProps = dispatch => ({
  updateTypeProject: (data, id) => dispatch(typeProject.save(data, id, 'POST')),
  addTypeProjec: (data) => dispatch(typeProject.save(data)),
  getTypeProject: () => dispatch(typeProject.fetchList())
  // listOrganization: (id) => dispatch(organizationActions.fetchOne(id)),
  // updateForm: (data) => dispatch(formActions.update(data, PjmConstraints.PjmConstraints))
})


export default connect(mapStateToProps, mapDispatchToProps)(ListTypeProject);