import React, { Component, Fragment } from 'react'
import {
  Input,
  Header,
  Form,
  Tab,
  Segment,
  Button,
  Select,
  TextArea,
  Message,
  Transition,
  Icon,
  Checkbox,
  Modal,
  Menu
} from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css'
// import Cleave from 'cleave.js/react';
// import 'rc-time-picker/assets/index.css'
import validatejs from 'validate.js'
import jwt from 'jsonwebtoken'
import ComponentTable from './ComponentTable'
import { connect } from 'react-redux'
import { actions as scholarActions } from 'Redux/reducers/scholar'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as receiptsActions } from 'Redux/reducers/receipts'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'
import { FundingFields } from 'Utils'
import { GetServerTime as GetTime } from 'Utils'
import { localTh } from 'Utils'
import { PrintoutComponent } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'
import { constraints as borrowArrayConstraints } from './ConstraintsBorrow'
import { constraints as BorrowConditionsConstraints } from './ConstraintsBorrow'
import { constraints as BorrowConstraints } from './ConstraintsBorrow'
import { ValidateArray as validateArray } from 'Utils'
import { ComponentPerson, ComponentOrganization } from 'Utils'
import { ThaiDate } from 'Utils'

let checktypeInstitution = false
const WithdrawOptions = [
  { key: '1', text: 'รับเช็ค สั่งจ่ายในนาม', value: 'cheque' },
  { key: '2', text: 'โอนเงินเข้าบัญชี', value: 'bankAccount' }
]

const nohaveBurn = [
  { key: '0', text: 'ไม่มี', value: 'no' },
  { key: '1', text: 'มี', value: 'yes' }
]

var bankOptions = [
  { key: '1', text: 'ธนาคารกรุงเทพ', value: 'ธนาคารกรุงเทพ' },
  { key: '2', text: 'ธนาคารออมสิน', value: 'ธนาคารออมสิน' },
  { key: '3', text: 'ธนาคารกรุงไทย', value: 'ธนาคารกรุงไทย' },
  { key: '4', text: 'ธนาคารกสิกร', value: 'ธนาคารกสิกร' },
  { key: '5', text: 'ธนาคารไทยพาณิชย์', value: 'ธนาคารไทยพาณิชย์' },
  { key: '6', text: 'ธนาคารกรุงศรีอยุธยา', value: 'ธนาคารกรุงศรีอยุธยา' },
  { key: '7', text: 'ธนาคารธนชาต', value: 'ธนาคารธนชาต' },
  { key: '8', text: 'ธนาคารทหารไทย', value: 'ธนาคารทหารไทย' },
  { key: '9', text: 'ธนาคารเกียรตินาคิน', value: 'ธนาคารเกียรตินาคิน' },
  { key: '10', text: 'ธนาคารซีไอเอ็มบีไทย', value: 'ธนาคารซีไอเอ็มบีไทย' },
  { key: '11', text: 'ธนาคารทิสโก้', value: 'ธนาคารทิสโก้' },
  { key: '12', text: 'ธนาคารยูโอบี', value: 'ธนาคารยูโอบี' },
  { key: '13', text: 'ธนาคารเพื่อการเกษตร', value: 'ธนาคารเพื่อการเกษตร' },
  {
    key: '14',
    text: 'ธนาคารอิสลามแห่งประเทศไทย',
    value: 'ธนาคารอิสลามแห่งประเทศไทย'
  },
  {
    key: '15',
    text: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
    value: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)'
  },
  {
    key: '16',
    text: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
    value: 'ธนาคารไทยเครดิตเพื่อรายย่อย'
  },
  {
    key: '17',
    text: 'ธนาคารแลนด์ แอนด์ เฮาส์',
    value: 'ธนาคารแลนด์ แอนด์ เฮาส์'
  },
  { key: '18', text: 'ธนาคารไอซีบีซี (ไทย)', value: 'ธนาคารไอซีบีซี (ไทย)' },
  {
    key: '19',
    text: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย',
    value: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย'
  },
  {
    key: '20',
    text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
    value: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'
  },
  {
    key: '21',
    text: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย',
    value: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย'
  },
  { key: '22', text: 'ธนาคารอาคารสงเคราะห์', value: 'ธนาคารอาคารสงเคราะห์' }
]

let gettime = ''
let checkQuery = false

class BorrowMoney extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      modalPrintout: false,
      onSaveSuccess: false,
      openModalPerson: false,
      openModalOrg: false,
      isLoad: false,
      nameField: ''
    }
  }

  componentDidMount () {
    const { match, auth } = this.props
    if (match.params.id) {
      this.props.getBorrow(match.params.id)
    }
    this.props.listBudget()

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    let loop = this.props.burnprojects.length
    if (loop > 0) {
      for (let i = 0; i < loop; i++) {
        this.props.burnprojects.splice(0, 1)
      }
    }
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    checktypeInstitution = false
    checkQuery = false
    console.log('checkQuery')
  }

  componentWillReceiveProps (nextProps) {
    const { match, borrow, auth } = nextProps
    let { data } = this.state

    if (match.params.id) {
      data = { ...borrow, ...data }
    }

    if (!data['remuneration']) data['remuneration'] = []
    if (!data['document']) data['document'] = []
    if (!data['vehicle']) data['vehicle'] = []
    if (!data['apartment']) data['apartment'] = []
    if (!data['food']) data['food'] = []
    if (!data['other']) data['other'] = []

    if (!checkQuery && borrow) {
      checkQuery = true
      let queryListBurn = {
        sort: { nameTh: 1 },
        limit: 20,
        skip: 0,
        query: {
          // codeProject: { $regex: data.burnProject },
          academicID: data.academicID
        }
      }
      this.props.listBurnProject(queryListBurn)
    }

    this.setState(data)
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  handleChangeMoney = (e, { value }) => {
    this.setState({ money: value })
  }

  handleDayChange = (name, index, date) => {
    let { data } = this.state
    const { borrow } = this.props
    if (borrow) {
      data = { ...borrow, ...data }
    }
    data[name] = date._d.getTime()
    if (data.typeOfBorrow && name === 'startDate') {
      if (data.typeOfBorrow === 'supplies') {
        data['repayDate'] = this._increaseDate(data.startDate, 7)
      }
    } else if (data.typeOfBorrow && name === 'endDate') {
      if (data.typeOfBorrow !== 'supplies') {
        data['repayDate'] = this._increaseDate(data.endDate, 15)
      }
    }
    this.setState({ data })
  }

  handleAddRow = nameArray => {
    let { data, nameField } = this.state
    const { borrow } = this.props

    let _borrowgroup = []
    if (borrow) {
      if (Object.keys(data).length === 0 || nameArray !== nameField) {
        _borrowgroup = borrow[nameArray].slice()
        nameField = nameArray
      } else {
        _borrowgroup = data[nameArray].slice()
      }
    }

    _borrowgroup.push({ type: 'activity' })
    let tmp = { ...data, [nameArray]: _borrowgroup }

    this.setState({ data: tmp, nameField })
  }

  handleDeleteRow = (nameArray, index) => {
    let { data, nameField, validate } = this.state
    const { borrow } = this.props

    let _data = []
    if (borrow) {
      if (Object.keys(data).length === 0 || nameArray !== nameField) {
        _data = borrow[nameArray].slice()
        nameField = nameArray
      } else {
        _data = data[nameArray].slice()
      }
    }
    _data.splice(index, 1)

    let sum = 0
    if (_data) {
      _data.forEach(x => {
        if (x.total) {
          let nb = x.total.replace(/,/g, '')
          let cv = parseFloat(nb)
          sum += cv
          let sumMoney = sum.toString()
          _data[0]['sum'] = sumMoney
        }
      })
    }
    let tmp = { ...data, [nameArray]: _data }

    validate.attrs = {}
    validate.error = false
    validate.validateError = {}

    this.setState({
      data: tmp,
      nameField,
      validate
    })
  }

  handleChangePersonRow = (nameArray, value1, value2, index) => {
    let { data, validate, nameField } = this.state
    const { borrow } = this.props

    validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    let _data = []
    if (borrow !== null) {
      if (Object.keys(data).length === 0 || nameArray !== nameField) {
        borrow[nameArray].forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
        nameField = nameArray
      } else {
        data[nameArray].forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      }
    }

    _data[index]['namePerson'] = value1
    _data[index]['content'] = value2

    let tmp = { ...data, [nameArray]: _data }
    this.setState({ data: tmp, validate, nameField })
  }

  handleChangeRow = (nameArray, name, value, index) => {
    let { data, validate, nameField } = this.state
    const { borrow } = this.props

    let _data = []
    if (borrow !== null) {
      if (Object.keys(data).length === 0 || nameArray !== nameField) {
        borrow[nameArray].forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
        nameField = nameArray
      } else {
        data[nameArray].forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      }
    }

    if (name === 'type') {
      if (value === 'person') {
        _data[index]['number'] = '1'
        _data[index]['money'] = '-'
        _data[index]['total'] = ''
        _data[index]['unit'] = 'คน'
        _data[index]['content'] = ''
        let query = {
          _id: 'blank'
        }
        this.props.listPersonByOrganization(query)
      } else if (value === 'activity') {
        _data[index]['number'] = ''
        _data[index]['money'] = ''
        _data[index]['total'] = ''
        _data[index]['unit'] = ''
        _data[index]['content'] = ''
      }
    }

    if (name === 'total') {
      let money = value.replace('-', '')
      _data[index][name] = money ? money.toString() : '0'
    } else if (name === 'number') {
      let money = value.replace('-', '')
      _data[index][name] = money ? money.toString() : '0'
      if (_data[index]['money'] && _data[index]['money'] > 0) {
        let total = (
          Math.abs(parseFloat(value)) *
          Math.abs(parseFloat(_data[index]['money']))
        ).toFixed(2)
        _data[index]['total'] = total.toString()
      }
    } else if (name === 'money') {
      let money = value.replace('-', '')
      _data[index][name] = money ? money.toString() : '0'
      if (_data[index]['number'] && _data[index]['money'] > 0) {
        let total = (
          Math.abs(parseFloat(value)) *
          Math.abs(parseFloat(_data[index]['number']))
        ).toFixed(2)
        _data[index]['total'] = total.toString()
      }
    } else {
      _data[index][name] = value
    }

    validate.attrs = {}
    validate.error = false
    validate.validateError = {}

    let sum = 0
    if (_data) {
      _data.forEach(x => {
        if (x.total) {
          let nb = x.total.replace(/,/g, '')
          let cv = parseFloat(nb)
          sum += cv
          let sumMoney = sum.toString()
          _data[0]['sum'] = sumMoney
        }
      })
    }

    let tmp = { ...data, [nameArray]: _data }
    this.setState({ data: tmp, validate, nameField })
  }

  _renderFormField = content => {
    const { validate } = this.state
    let { borrow, auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (borrow) {
      if (content.control === Select) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            disabled={
              borrow.academicID !== user._id &&
              borrow.otherAcademicID !== user._id &&
              role !== 'Admin'
            }
          />
        )
      } else if (content.control === FundingFields) {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      } else
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            readOnly={
              borrow.academicID !== user._id &&
              borrow.otherAcademicID !== user._id &&
              role !== 'Admin'
            }
          />
        )
    } else {
      if (content.control === Select) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            disabled={true}
          />
        )
      } else if (content.control === FundingFields) {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      } else
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            readOnly={true}
          />
        )
    }
  }

  handleSubmit = () => {
    let { data, validate } = this.state
    const { match, borrow, burn } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })
    if (match.params.id) {
      data = { ...borrow, ...data }
    }

    if (data.remuneration) {
      data.remuneration.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }

    if (data.document) {
      data.document.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }

    if (data.vehicle) {
      data.vehicle.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }

    if (data.apartment) {
      data.apartment.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }
    if (data.food) {
      data.food.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }
    if (data.other) {
      data.other.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }

    data['status'] = 'ยืมเงินทดรองจ่าย'
    data.codeBudget = data.yearBudget + data.valueActivity
    if (data.transferMoney) {
      validate['validateError'] = validatejs(
        data,
        BorrowConditionsConstraints.BorrowConditionsConstraints
      )
    } else {
      validate['validateError'] = validatejs(
        data,
        BorrowConstraints.BorrowConstraints
      )
    }
    validate.attrs = {}
    validate.error = false
    let validateArrayErrorR = validateArray(
      data.remuneration,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorD = validateArray(
      data.document,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorV = validateArray(
      data.vehicle,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorA = validateArray(
      data.apartment,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorF = validateArray(
      data.food,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorO = validateArray(
      data.other,
      borrowArrayConstraints.borrowArrayConstraints
    )

    if (!validate['validateError']) validate['validateError'] = {}
    if (
      Object.keys(validateArrayErrorR).length !== 0 &&
      validateArrayErrorR.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorR).forEach(index => {
        if (validateArrayErrorR[index].content) {
          if (!validate['validateError']['remuneration']) {
            validate['validateError']['remuneration'] = []
            validate['validateError']['remuneration'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorR[index].total) {
          if (!validate['validateError']['remunerationT']) {
            validate['validateError']['remunerationT'] = []
            validate['validateError']['remunerationT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
        validate.attrs['remuneration' + index] = true
      })
    }
    if (
      Object.keys(validateArrayErrorD).length !== 0 &&
      validateArrayErrorD.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorD).forEach(index => {
        if (validateArrayErrorD[index].content) {
          if (!validate['validateError']['document']) {
            validate['validateError']['document'] = []
            validate['validateError']['document'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorD[index].total) {
          if (!validate['validateError']['documentT']) {
            validate['validateError']['documentT'] = []
            validate['validateError']['documentT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }

    if (
      Object.keys(validateArrayErrorV).length !== 0 &&
      validateArrayErrorV.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorV).forEach(index => {
        if (validateArrayErrorV[index].content) {
          if (!validate['validateError']['vehicle']) {
            validate['validateError']['vehicle'] = []
            validate['validateError']['vehicle'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorV[index].total) {
          if (!validate['validateError']['vehicleT']) {
            validate['validateError']['vehicleT'] = []
            validate['validateError']['vehicleT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }

    if (
      Object.keys(validateArrayErrorA).length !== 0 &&
      validateArrayErrorA.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorA).forEach(index => {
        if (validateArrayErrorA[index].content) {
          if (!validate['validateError']['apartment']) {
            validate['validateError']['apartment'] = []
            validate['validateError']['apartment'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorA[index].total) {
          if (!validate['validateError']['apartmentT']) {
            validate['validateError']['apartmentT'] = []
            validate['validateError']['apartmentT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }
    if (
      Object.keys(validateArrayErrorF).length !== 0 &&
      validateArrayErrorF.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorF).forEach(index => {
        if (validateArrayErrorF[index].content) {
          if (!validate['validateError']['food']) {
            validate['validateError']['food'] = []
            validate['validateError']['food'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorF[index].total) {
          if (!validate['validateError']['foodT']) {
            validate['validateError']['foodT'] = []
            validate['validateError']['foodT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }
    if (
      Object.keys(validateArrayErrorO).length !== 0 &&
      validateArrayErrorO.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorO).forEach(index => {
        if (validateArrayErrorO[index].content) {
          if (!validate['validateError']['other']) {
            validate['validateError']['other'] = []
            validate['validateError']['other'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorO[index].total) {
          if (!validate['validateError']['otherT']) {
            validate['validateError']['otherT'] = []
            validate['validateError']['otherT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }

    if (
      data.remuneration.length === 0 &&
      data.document.length === 0 &&
      data.vehicle.length === 0 &&
      data.apartment.length === 0 &&
      data.food.length === 0 &&
      data.other.length === 0
    ) {
      validate['validateError']['noBorrowMoney'] = []
      validate['validateError']['noBorrowMoney'].push(
        'ต้องระบุ รายละเอียดการยืมเงินทดรองจ่าย อย่างน้อย 1 รายการ'
      )
    }

    // validate['validateError'] = validatejs(data, InfoConstraints.InfoConstraints)
    if (
      Object.keys(validate['validateError']).length !== 0 &&
      validate['validateError'].constructor === Object
    ) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      validate['error'] = false

      // if (borrow && borrow.listBorrow.length === 0) {
      let sum = 0
      data['listBorrow'] = []
      data.concludeMoney = []
      if (!data.listBorrow) data.listBorrow = []

      if (data.remuneration) {
        if (data.remuneration.length !== 0) {
          data.remuneration.forEach(item => {
            if (item.type === 'person') {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                namePerson: item.namePerson,
                sum: item.total,
                compensation: item.total
              })
            } else {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                sum: item.total,
                compensation: item.total
              })
            }
          })
          let index = data.listBorrow.findIndex(
            x => x.listName === 'remuneration'
          )
          if (index === -1) {
            data.listBorrow.push({
              listName: 'remuneration',
              borrow: data.remuneration[0] ? data.remuneration[0].sum : '0',
              usedMoney: data.remuneration[0] ? data.remuneration[0].sum : '0'
            })
          } else {
            data.listBorrow[index].borrow = data.remuneration[0].sum
          }
        } else {
          data.listBorrow.push({
            listName: 'remuneration',
            borrow: '0',
            usedMoney: ''
          })
        }
      }

      if (data.document) {
        if (data.document.length !== 0) {
          data.document.forEach(item => {
            if (item.type === 'person') {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                namePerson: item.namePerson,
                sum: item.total,
                document: item.total
              })
            } else {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                sum: item.total,
                document: item.total
              })
            }
          })
          let index = data.listBorrow.findIndex(x => x.listName === 'document')
          if (index === -1) {
            data.listBorrow.push({
              listName: 'document',
              borrow: data.document[0] ? data.document[0].sum : '0',
              usedMoney: data.document[0] ? data.document[0].sum : '0'
            })
          } else {
            data.listBorrow[index].borrow = data.document[0].sum
          }
        } else {
          data.listBorrow.push({
            listName: 'document',
            borrow: '0',
            usedMoney: ''
          })
        }
      }

      if (data.vehicle) {
        if (data.vehicle.length !== 0) {
          data.vehicle.forEach(item => {
            if (item.type === 'person') {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                namePerson: item.namePerson,
                sum: item.total,
                vehicle: item.total
              })
            } else {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                sum: item.total,
                vehicle: item.total
              })
            }
          })
          let index = data.listBorrow.findIndex(x => x.listName === 'vehicle')
          if (index === -1) {
            data.listBorrow.push({
              listName: 'vehicle',
              borrow: data.vehicle[0] ? data.vehicle[0].sum : '0',
              usedMoney: data.vehicle[0] ? data.vehicle[0].sum : '0'
            })
          } else {
            data.listBorrow[index].borrow = data.vehicle[0].sum
          }
        } else {
          data.listBorrow.push({
            listName: 'vehicle',
            borrow: '0',
            usedMoney: ''
          })
        }
      }

      if (data.apartment) {
        if (data.apartment.length !== 0) {
          data.apartment.forEach(item => {
            if (item.type === 'person') {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                namePerson: item.namePerson,
                sum: item.total,
                hotel: item.total
              })
            } else {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                sum: item.total,
                hotel: item.total
              })
            }
          })
          let index = data.listBorrow.findIndex(x => x.listName === 'apartment')
          if (index === -1) {
            data.listBorrow.push({
              listName: 'apartment',
              borrow: data.apartment[0] ? data.apartment[0].sum : '0',
              usedMoney: data.apartment[0] ? data.apartment[0].sum : '0'
            })
          } else {
            data.listBorrow[index].borrow = data.apartment[0].sum
          }
        } else {
          data.listBorrow.push({
            listName: 'apartment',
            borrow: '0',
            usedMoney: ''
          })
        }
      }

      if (data.food) {
        if (data.food.length !== 0) {
          data.food.forEach(item => {
            if (item.type === 'person') {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                namePerson: item.namePerson,
                sum: item.total,
                food: item.total
              })
            } else {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                sum: item.total,
                food: item.total
              })
            }
          })
          let index = data.listBorrow.findIndex(x => x.listName === 'food')
          if (index === -1) {
            data.listBorrow.push({
              listName: 'food',
              borrow: data.food[0] ? data.food[0].sum : '0',
              usedMoney: data.food[0] ? data.food[0].sum : '0'
            })
          } else {
            data.listBorrow[index].borrow = data.food[0].sum
          }
        } else {
          data.listBorrow.push({ listName: 'food', borrow: '0', usedMoney: '' })
        }
      }

      if (data.other) {
        if (data.other.length !== 0) {
          data.other.forEach(item => {
            if (item.type === 'person') {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                namePerson: item.namePerson,
                sum: item.total,
                other: item.total
              })
            } else {
              data.concludeMoney.push({
                type: item.type,
                content: item.content,
                sum: item.total,
                other: item.total
              })
            }
          })
          let index = data.listBorrow.findIndex(x => x.listName === 'other')
          if (index === -1) {
            data.listBorrow.push({
              listName: 'other',
              borrow: data.other[0] ? data.other[0].sum : '0',
              usedMoney: data.other[0] ? data.other[0].sum : '0'
            })
          } else {
            data.listBorrow[index].borrow = data.other[0].sum
          }
        } else {
          data.listBorrow.push({
            listName: 'other',
            borrow: '0',
            usedMoney: ''
          })
        }
      }
      let sumTotal = 0
      data.concludeMoney.forEach(item => {
        sumTotal += parseFloat(item.sum)
      })
      data.sumConclude = sumTotal.toString()
      if (data.listBorrow) {
        data.listBorrow.forEach(x => {
          let nb = x.borrow.replace(/,/g, '')
          let cv = parseFloat(nb)
          sum += cv
          data['sumBorrowMoney'] = sum.toString()
          data['sumUsedMoney'] = sum.toString()
        })
      }
      data.timeStamp = gettime
      data.borrowDateText = ThaiDate('month', data.borrowDate)
      if (!data.concludeMoney) {
        data.concludeMoney = []
      }

      if (data.haveBurnPJ === 'yes') {
        data.yearBudget = (burn && burn['yearBudget']) || data.yearBudget
        data.groupsPlan = (burn && burn['groupsPlan']) || data.groupsPlan
        data.plan = (burn && burn['plan']) || data.plan
        data.activity = (burn && burn['activity']) || data.activity
        data.valueGroup = (burn && burn['valueGroup']) || data.valueGroup
        data.valuePlan = (burn && burn['valuePlan']) || data.valuePlan
        data.valueActivity =
          (burn && burn['valueActivity']) || data.valueActivity
        data.codeBudget = (burn && burn['codeBudget']) || data.codeBudget
        // data.borrowDate = gettime
      }

      this.setState({ onSaveSuccess: true })
      setTimeout(() => {
        this.setState({ onSaveSuccess: false })
      }, 3000)
      this.setState({ validate })
      this.props.updateBorrow(data, data._id)
    }
  }

  _increaseDate = (date, number) => {
    var day = new Date(date).getDate() + number
    var month = new Date(date).getMonth()
    var year = new Date(date).getFullYear()
    let resule = new Date(year, month, day).getTime()
    return resule
  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate, tmp } = this.state
    let { borrow } = this.props
    if (borrow) data = { ...borrow, ...data }

    if (name === 'burnProject') {
      data[name] = value
      this.props.getBurnProject(value)
    }

    if (name === 'budget') {
      if (data.haveBurnPJ === 'no') {
        data['yearBudget'] = value.value.year
        data['groupsPlan'] = value.value.group
        data['plan'] = value.value.plan
        data['activity'] = value.value.activity
        data['valueGroup'] = value.value.valueGroup
        data['valuePlan'] = value.value.valuePlan
        data['valueActivity'] = value.value.valueActivity
        if (data.activity) {
          data.codeBudget =
            (parseFloat(data.yearBudget) - 2500).toString() + data.valueActivity
        } else {
          data.codeBudget = undefined
        }
      }
    } else if (name === 'haveBurnPJ') {
      data[name] = value
      if (data[name] === 'no') {
        data['burnProject'] = 'ไม่มี'
      } else if (data[name] === 'yes') {
        data['burnProject'] = ''
      }
    } else if (name === 'transferMoney' || name === 'sendtoERP') {
      data[name] = checked
    } else if (name === 'nosendtoERP') {
      data.sendtoERP = false
    } else if (name === 'typeOfBorrow') {
      data[name] = value
      if (value === 'supplies') {
        if (data.startDate)
          data['repayDate'] = this._increaseDate(data.startDate, 7)
      } else {
        if (data.endDate)
          data['repayDate'] = this._increaseDate(data.endDate, 15)
      }
    } else if (name === 'noAccount') {
      var regx = /^[0-9]*$/
      const check = value.match(regx)
      if (check !== null) {
        data[name] = value
      } else if (value.length === 0) {
        data[name] = value
      }
    } else {
      data[name] = value
    }
    if (data.transferMoney) {
      validate['validateError'] = validatejs(
        data,
        BorrowConditionsConstraints.BorrowConditionsConstraints
      )
    } else {
      validate['validateError'] = validatejs(
        data,
        BorrowConstraints.BorrowConstraints
      )
    }
    validate['validateError'] = null
    validate.attrs = {}

    if (!validate.validateError) {
      validate.error = false
    } else {
      validate.error = true
      Object.keys(validate.validateError).forEach(item => {
        validate.attrs[item] = true
      })
    }
    this.setState({ data, validate, tmp })
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false })
  }

  handleCloseModal = () => {
    this.setState({
      modalSendEmail: false,
      modalAttachment: false,
      modalAddGroupFinance: false,
      modalPrintout: false
    })
  }

  onEndTimeChange = value => {
    let { data } = this.state
    const { borrow } = this.props
    if (borrow) {
      data = { ...borrow, ...data }
    }

    data['endTime'] = value

    this.setState({ data })
  }

  onStartTimeChange = value => {
    let { data } = this.state
    const { borrow } = this.props
    if (borrow) {
      data = { ...borrow, ...data }
    }

    data['startTime'] = value

    this.setState({ data })
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  onWithdrawNumberChange = e => {
    let { data, validate } = this.state
    const { borrow } = this.props
    if (borrow) {
      data = { ...borrow, ...data }
    }

    data[e.target.name] = parseFloat(e.target.rawValue)
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
    this.setState({ data, validate })
  }

  openModalPerson = () => {
    this.setState({ openModalPerson: true })
  }

  closeModalPerson = () => {
    this.setState({ openModalPerson: false })
  }

  openModalOrg = () => {
    this.setState({ openModalOrg: true })
  }

  closeModalOrg = () => {
    this.setState({ openModalOrg: false })
  }

  handleSearch = (e, { searchQuery }) => {
    let { auth, borrow } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    let queryListBurn = {
      sort: { nameTh: 1 },
      limit: 20,
      skip: 0,
      query: {
        codeProject: { $regex: searchQuery },
        academicID: borrow.academicID
      }
    }
    if (searchQuery.length >= 2) {
      this.props.listBurnProject(queryListBurn)
    }
  }

  render () {
    let {
      validate,
      data,
      modalPrintout,
      onSaveSuccess,
      openModalPerson,
      openModalOrg
    } = this.state
    const {
      auth,
      scholar,
      borrow,
      match,
      burnprojects,
      burn,
      role
    } = this.props

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (user.typeInstitution) {
      user.typeInstitution.forEach(itm => {
        if (itm === '01') {
          checktypeInstitution = true
        }
      })
    } else {
      checktypeInstitution = false
    }

    if (match.params.id) {
      data = { ...borrow, ...data }
    }

    let startT = []
    let endT = []
    let h = 0
    let m = 0
    let hour = ''
    let minute = ''
    for (let i = 0; i < 48; i++) {
      if (i !== 0) {
        m += 30
        if (m === 60) {
          m = 0
          h += 1
        }
      }
      if (h < 10) {
        hour = '0' + h.toString()
      } else {
        hour = h.toString()
      }
      if (m === 0) {
        minute = '0' + m.toString()
      } else {
        minute = m.toString()
      }
      startT.push({
        key: i + 1,
        text: hour + '.' + minute + ' น.',
        value: hour + '.' + minute
      })
      endT.push({
        key: i + 1,
        text: hour + '.' + minute + ' น.',
        value: hour + '.' + minute
      })
    }

    const typeForRefundDate = [
      {
        key: '0',
        text: 'เพื่อเป็นค่าใช้จ่ายในการเดินทางไปปฏิบัติงาน',
        value: 'travel'
      },
      {
        key: '1',
        text:
          'เพื่อเป็นค่าใช้จ่ายในการปฏิบัติงานเกี่ยวกันงาน โครงการ แยกงาน กิจกรรม หรือการพัฒนานวัฒกรรม',
        value: 'operate'
      },
      {
        key: '2',
        text: 'เพื่อจัดซื้อหรือจัดจ้างพัสดุที่จำเป็นต้องชำระเป็นเงินสด',
        value: 'supplies'
      },
      {
        key: '3',
        text: 'เพื่อเป็นค่าใช้จ่ายอื่นตามภาระหน้าที่ในการปฏิบัติงาน',
        value: 'other'
      }
    ]

    let burnProjectOptions = []

    burnProjectOptions =
      burnprojects &&
      burnprojects.map((item, idx) => ({
        key: idx,
        text: item.codeProject,
        value: item._id
      }))

    let content = {
      objective: { control: TextArea },
      typeRecive: {
        control: Select,
        options: WithdrawOptions,
        width: 8,
        search: false
      },
      budget: {
        control: FundingFields,
        width: 16,
        delBudget: false,
        disabled: data.haveBurnPJ === 'yes'
      },
      nameAccount: { control: Input },
      burnProject: {
        control: Select,
        options: burnProjectOptions,
        search: true,
        width: 4,
        disabled: data.haveBurnPJ === 'no'
      },
      namebank: { control: Input },
      branchBank: { control: Input },
      noAccount: { control: Input, maxLength: 15 },
      namePayer: { control: Input, width: 8 },
      codeDisburse: { control: Input, width: 8 },
      place: { control: Input, width: 8 },
      startTime: { control: Select, options: startT, width: 5 },
      endTime: { control: Select, options: endT, width: 5 },
      transferMoney: { control: Checkbox },
      bank: { control: Input, width: 8 },
      branch: { control: Input, width: 8 },
      typeOfBorrow: { control: Select, width: 8, options: typeForRefundDate },
      haveBurnPJ: { control: Select, width: 4, options: nohaveBurn },
      sendtoERP: { control: Checkbox }
    }

    if (burn) {
      data['codeBudget'] =
        (parseInt(burn.yearBudget) - 2500).toString() + burn.valueActivity
    } else {
      data['codeBudget'] =
        (parseInt(data.yearBudget) - 2500).toString() + data.valueActivity
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('Borrow', key)
      content[key].name = key
      if (content[key].name === 'manager')
        content[key].onChangeManager = this.handleChangeManager
      content[key].onChange = this.handleChange
      if (content[key].name === 'burnProject') {
        content[key].onSearchChange = this.handleSearch
        content[key].value = data[key] || ''
      }
      if (content[key].control === Input) {
        if (content[key].money === 'money') {
          content[key].value = this.format(data[key] || '') || ''
        } else content[key].value = data[key] || ''
      } else if (content[key].control === Checkbox) {
        content[key].checked = data[key] || false
      } else if (content[key].control === FundingFields) {
        // content[key].onDayChange = this.handleDayChange
        if (data.haveBurnPJ === 'yes') {
          content[key].value = {
            year: (burn && burn['yearBudget']) || data['yearBudget'],
            group: (burn && burn['groupsPlan']) || data['groupsPlan'],
            plan: (burn && burn['plan']) || data['plan'],
            activity: (burn && burn['activity']) || data['activity'],
            valueGroup: (burn && burn['valueGroup']) || data['valueGroup'],
            valuePlan: (burn && burn['valuePlan']) || data['valuePlan'],
            valueActivity:
              (burn && burn['valueActivity']) || data['valueActivity'],
            time: gettime
          }
        } else if (data.haveBurnPJ === 'no') {
          content[key].value = {
            year: data['yearBudget'] || '',
            group: data['groupsPlan'] || '',
            plan: data['plan'] || '',
            activity: data['activity'] || '',
            valueGroup: data['valueGroup'] || '',
            valuePlan: data['valuePlan'] || '',
            valueActivity: data['valueActivity'] || '',
            time: gettime
          }
        }
      } else {
        content[key].value = data[key] || ''
      }
    })

    const panes = [
      {
        menuItem: (
          <Menu.Item key='1' style={{ fontFamily: 'supermarket' }}>
            ค่าตอบแทน
          </Menu.Item>
        ),
        render: () => (
          <ComponentTable
            nameArray='remuneration'
            data={data.remuneration}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            match={match}
            validate={validate}
            onChangeName={this.handleChangePersonRow}
          />
        )
      },
      {
        menuItem: (
          <Menu.Item key='2' style={{ fontFamily: 'supermarket' }}>
            จัดส่งเอกสาร
          </Menu.Item>
        ),
        render: () => (
          <ComponentTable
            nameArray='document'
            data={data.document}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            match={match}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            validate={validate}
            onChangeName={this.handleChangePersonRow}
          />
        )
      },
      {
        menuItem: (
          <Menu.Item key='3' style={{ fontFamily: 'supermarket' }}>
            พาหนะ
          </Menu.Item>
        ),
        render: () => (
          <ComponentTable
            nameArray='vehicle'
            data={data.vehicle}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            match={match}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            validate={validate}
            onChangeName={this.handleChangePersonRow}
          />
        )
      },
      {
        menuItem: (
          <Menu.Item key='4' style={{ fontFamily: 'supermarket' }}>
            ที่พัก
          </Menu.Item>
        ),
        render: () => (
          <ComponentTable
            nameArray='apartment'
            data={data.apartment}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            match={match}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            validate={validate}
            onChangeName={this.handleChangePersonRow}
          />
        )
      },
      {
        menuItem: (
          <Menu.Item key='5' style={{ fontFamily: 'supermarket' }}>
            อาหารและเครื่องดืม
          </Menu.Item>
        ),
        render: () => (
          <ComponentTable
            nameArray='food'
            data={data.food}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            match={match}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            validate={validate}
            onChangeName={this.handleChangePersonRow}
          />
        )
      },
      {
        menuItem: (
          <Menu.Item key='6' style={{ fontFamily: 'supermarket' }}>
            ใช้จ่ายอื่นๆ
          </Menu.Item>
        ),
        render: () => (
          <ComponentTable
            nameArray='other'
            data={data.other}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            match={match}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            validate={validate}
            onChangeName={this.handleChangePersonRow}
          />
        )
      }
    ]

    return (
      <Fragment>
        <PrintoutComponent
          modalPrintout={modalPrintout}
          numberOfFile={['ยืมเงินทดรองจ่าย']}
          typeFile={[12]}
          match={match}
          onClose={this.handleCloseModal}
        />
        <Form error={validate.error}>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='ยืมเงินทดรองจ่าย'
              as='h3'
              icon='write'
            />
            <Form.Group widths='equal'>
              <Form.Input
                label='เลขที่ยืมเงินทดรองจ่าย'
                value={data.codeBorrow || ''}
                readOnly
              />
              <Form.Input
                label='ชื่อผู้ขอยืมเงิน'
                value={data.academic || ''}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.place)}
              {checktypeInstitution &&
                this._renderFormField(content.haveBurnPJ)}
              {this._renderFormField(content.burnProject)}
            </Form.Group>
            <Form.Group>{this._renderFormField(content.budget)}</Form.Group>
            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h3'
              content={'รหัสงบประมาณ : ' + (data.codeBudget || '')}
            />
            <Form.Group>
              <Form.Field
                width={3}
                disabled={
                  data.academicID !== user._id &&
                  data.otherAcademicID !== user._id &&
                  role !== 'Admin'
                }
              >
                <label>{'วันที่ใช้เงิน *'}</label>
                <DayPicker
                  ser={'startDate'}
                  name={'startDate'}
                  max={data.endDate || null}
                  onDayChange={this.handleDayChange}
                  data={data.startDate || ''}
                />
              </Form.Field>
              <Form.Field
                width={3}
                disabled={
                  data.academicID !== user._id &&
                  data.otherAcademicID !== user._id &&
                  role !== 'Admin'
                }
              >
                <label>{'ถึงวันที่ *'}</label>
                <DayPicker
                  ser={'endDate'}
                  name={'endDate'}
                  min={data.startDate || null}
                  onDayChange={this.handleDayChange}
                  data={data.endDate || ''}
                />
              </Form.Field>
              {/* <Form.Field width={3} disabled={(data.academicID !== user._id) && role !== 'Admin'} >
                <label>{'เริ่มใช้วันที่ *'}</label>
                <DayPicker ser={'usingDate'} name={'usingDate'}
                  onDayChange={this.handleDayChange}
                  data={data.usingDate || ''} />
              </Form.Field> */}
              <Form.Field
                width={3}
                disabled={
                  data.academicID !== user._id &&
                  data.otherAcademicID !== user._id &&
                  role !== 'Admin'
                }
              >
                <label>{'วันที่ยืมเงิน *'}</label>
                <DayPicker
                  ser={'borrowDate'}
                  name={'borrowDate'}
                  onDayChange={this.handleDayChange}
                  data={data.borrowDate || ''}
                />
              </Form.Field>
              {this._renderFormField(content.startTime)}
              {this._renderFormField(content.endTime)}
            </Form.Group>
            {this._renderFormField(content.typeOfBorrow)}
            {this._renderFormField(content.objective)}
          </Segment>
          <Header
            style={{ fontFamily: 'supermarket' }}
            content='รายละเอียดการยืมเงินทดรองจ่าย (ประมาณการค่าใช้จ่าย)'
            icon='list'
            as='h4'
          />
          <Tab panes={panes} />
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='รูปแบบการเบิกจ่ายเงิน'
              icon='money'
              as='h4'
            />
            {this._renderFormField(content.transferMoney)}
            {data.transferMoney && (
              <Fragment>
                <Form.Group widths='equal'>
                  <Form.Input
                    label='ชื่อบัญชีผู้ยืม'
                    name='nameAccount'
                    onChange={this.handleChange}
                    value={data.nameAccount}
                    readOnly={
                      data.academicID !== user._id &&
                      data.otherAcademicID !== user._id &&
                      role !== 'Admin'
                    }
                  />
                  <Form.Field
                    control={Select}
                    label='ธนาคาร'
                    options={bankOptions}
                    name='bank'
                    onChange={this.handleChange}
                    selection
                    search={true}
                    value={data.bank}
                    readOnly={
                      data.academicID !== user._id &&
                      data.otherAcademicID !== user._id &&
                      role !== 'Admin'
                    }
                  />
                  <Form.Input
                    label='ธนาคาร'
                    onChange={this.handleChange}
                    value={data.bank}
                    readOnly={
                      data.academicID !== user._id &&
                      data.otherAcademicID !== user._id &&
                      role !== 'Admin'
                    }
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Input
                    label='สาขา'
                    name='branch'
                    onChange={this.handleChange}
                    value={data.branch}
                    readOnly={
                      data.academicID !== user._id &&
                      data.otherAcademicID !== user._id &&
                      role !== 'Admin'
                    }
                  />
                  <Form.Input
                    maxLength={15}
                    label='เลขบัญชี'
                    value={data.noAccount || ''}
                    name='noAccount'
                    onChange={this.handleChange}
                    readOnly={
                      data.academicID !== user._id &&
                      data.otherAcademicID !== user._id &&
                      role !== 'Admin'
                    }
                  />
                </Form.Group>
              </Fragment>
            )}
          </Segment>
          <Form.Group>
            {/* {this._renderFormField(content.sendtoERP)} */}
            {/* <Form.Checkbox
              checked={data.sendtoERP === true}
              onChange={this.handleChange}
              label='ส่งข้อมูลไปยังระบบบัญชี *'
              error={validate.attrs['sendtoERP']}
              name='sendtoERP'
            />
            <Form.Checkbox
              checked={data.sendtoERP === false}
              onChange={this.handleChange}
              label='ยังไม่ส่งข้อมูลไปยังระบบบัญชี *'
              error={validate.attrs['sendtoERP']}
              name='nosendtoERP'
            /> */}
          </Form.Group>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('Borrow', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Button
            color='blue'
            content='บันทึก'
            icon='save'
            disabled={validate.error}
            loading={scholar.isSaving}
            onClick={this.handleSubmit}
          />
        </Form>
        <Modal
          open={openModalPerson}
          closeOnDimmerClick={false}
          onClose={this.closeModalPerson}
          closeIcon
        >
          {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
          <Modal.Content>
            <ComponentPerson
              openModalPerson={openModalPerson}
              onCloseModalPerson={this.closeModalPerson}
            />
          </Modal.Content>
        </Modal>

        <Modal
          open={openModalOrg}
          closeOnDimmerClick={false}
          onClose={this.closeModalOrg}
          closeIcon
        >
          {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
          <Modal.Content>
            <ComponentOrganization
              openModalOrganization={openModalOrg}
              onCloseModalPerson={this.closeModalOrg}
            />
          </Modal.Content>
        </Modal>

        <Transition
          visible={onSaveSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle'></Icon>บันทึกสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  borrow: state.scholar.currentData,
  auth: state.auth,
  receipt: state.receipts.data,
  burnprojects: state.burnprojects.data,
  role: state.role,
  burn: state.burnprojects.currentData
})

const mapDispatchToProps = dispatch => ({
  addBorrow: data => dispatch(scholarActions.save(data)),
  addReceipt: data => dispatch(receiptsActions.save(data)),
  updateBorrow: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
  getBorrow: id => dispatch(scholarActions.fetchOne(id)),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  listPersonByOrganization: query =>
    dispatch(individualsActions.fetchList({ query: query })),
  getReceipt: id => dispatch(receiptsActions.fetchOne(id)),
  listBurnProject: query => dispatch(burnProjectAction.fetchList({ ...query })),
  getBurnProject: id => dispatch(burnProjectAction.fetchOne(id))
  // updateProposal: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
})

export default connect(mapStateToProps, mapDispatchToProps)(BorrowMoney)
