import React, { Component, Fragment } from 'react';
import { Step } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as professional_burnActions } from 'Redux/reducers/professional_burn'
import { actions as professional_bActions } from 'Redux/reducers/professional_b'
import { YearProfesBurn } from 'Utils'
import { ListProfesBurn } from 'Utils'


class StepAddProfesBurn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'year',
      yearDoc:{}
    };
  }

  componentDidMount = () =>{
    this.props.listproburn1()
  }

  handleClickRow = (item) =>{
    this.props.onSelectedType(item)
  }

  handleClick = () =>{
    this.setState({active:'year'})
  }

  handleSelected = (yearDoc) =>{
    this.setState({yearDoc,active:'list'})
  }

  render() {
    let { data,active,yearDoc } = this.state

    return (
      <Fragment >
        <Step.Group size='small' widths={4}>
          <Step
            style={{fontFamily:'supermarket'}}
            active={active === 'year'}
            icon='calendar alternate outline'
            name='year'
            link
            onClick={this.handleClick}
            title='ปีของผู้ทรงคุณวุฒิของโครงการเผาหัว'
            description='เลือกรายการที่ต้องการแก้ไข'
          />
          <Step 
            style={{fontFamily:'supermarket'}} 
            disabled={active === 'year'}
            active={active === 'list'}
            icon='sitemap'
            name='list'
            link
            onClick={this.handleClick}
            title='รายชื่อผู้ทรงคุณวุฒิ'
            description='เลือกรายการที่ต้องการแก้ไข'
          />
          {/* <Step disabled={active === 'year' || active === 'group'}
            active={active === 'plan'}
            icon='list'
            name='plan'
            link
            onClick={this.handleClick}
            title='แผนงาน'
            description='เลือกรายการที่ต้องการแก้ไข'
          />
          <Step disabled={active === 'year' || active === 'group' || active === 'plan'}
            active={active === 'activity'}
            icon='tasks'
            name='activity'
            link
            onClick={this.handleClick}
            title='กลุ่มกิจกรรม'
            description='เลือกรายการที่ต้องการแก้ไข'
          /> */}
        </Step.Group>
        {active === 'year' && <YearProfesBurn onSelected={this.handleSelected} obj={data} />}
        {active === 'list' && <ListProfesBurn onSelected={this.handleSelected} yearDoc={yearDoc} />}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  individuals: state.individuals,
  proburn1: state.professional_b.data,
  organization: state.organizations,
  role: state.role,
});

const mapDispatchToProps = dispatch => ({
  listIndividuals: (query) => dispatch(individualsActions.fetchList({ ...query })),
  addproburn: (data) => dispatch(professional_burnActions.save(data)),
  listproburn1: () => dispatch(professional_bActions.fetchList()),
  getIndividuals: (id) => dispatch(individualsActions.fetchOne(id)),
  delIndividuals: (id) => dispatch(individualsActions.delete(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StepAddProfesBurn);