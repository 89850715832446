import React, { Component, Suspense } from 'react';
import {
  Header,
  Button,
  Form,
  Modal,
  Segment,
  Grid,
  Container,
  Input,
  Confirm,
  Menu,
  Dropdown,
  Icon
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import './TORInfo.css'
import { GetServerTime as GetTime } from 'Utils'
import { constraints as TorConstraints } from './TORConstraints'
import { connect } from 'react-redux'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { ComponentAddProject } from 'Containers/Project/RegisProject'
import { CloneProject, ListProjectDelete } from 'Utils'
import jwt from 'jsonwebtoken'

const TableProjectInTOR = React.lazy(() => import('./TableProjectInTOR'));

let gettime = ''

const filterOptions = [
  { key: '1', text: 'ทั้งหมด', value: 'ทั้งหมด' },
  { key: '2', text: 'ส่งข้อเสนอโครงการ', value: 'ส่งข้อเสนอโครงการ' },
  { key: '3', text: 'กลั่นกรอง', value: 'กลั่นกรอง' },
  { key: '4', text: 'ทำสัญญาโครงการ', value: 'ทำสัญญาโครงการ' },
  { key: '5', text: 'ประเมินความก้าวหน้าโครงการ', value: 'ประเมินความก้าวหน้าโครงการ' },
  { key: '6', text: 'ปิดโครงการ', value: 'ปิดโครงการ' },
  { key: '7', text: 'ยุติโครงการ', value: 'ยุติโครงการ' },
]

class TORInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      data: {},
      withOutId: true,
      validate: { error: false, validateError: null },
      openConfirm: false,
      typeSearch: 'name',
      status: 'ทั้งหมด',
      search: {},
      modalProjectOpen: false,
      wait: false,
      saved: true
    };
  }

  componentDidMount() {
    const { match, projects } = this.props

    if (projects.currentData) {
      delete projects.currentData
    }
    if (match.params.id && match.params.sub === 'torinfo') {
      localStorage.setItem("tor_id", match.params.id);
      this.props.getTor(match.params.id)
      this.props.fetchProjects(match.params.id)
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

  }

  componentWillReceiveProps = (nextProps) => {
    let { wait, saved } = this.state
    const { isSaving, match } = nextProps
    if (wait === true) {
      if (isSaving === true) {
        this.setState({ saved: false })
      } else if (isSaving === false && saved === false) {
        this.setState({ wait: false, saved: true })
        this.props.fetchProjects(match.params.id)
      }
    }
  }

  handleChange = () => {
    let { data, validate, name, value } = this.state;
    const { selectedTor } = this.props;

    data = { ...selectedTor, ...data };

    data[name] = value;
    if (validate.error) {
      validate['validateError'] = validatejs(data, TorConstraints.TorConstraints)
      if (!validate.validateError) {
        validate.error = false;
      }
    }
    data['timestamp'] = gettime
    this.props.updateTor(data, data._id);
    this.setState({ data, validate, openConfirm: false });
  }

  handleUpdate = () => {
    let { data } = this.state;
    const { selectedTor } = this.props;

    data = { ...selectedTor, ...data };

    this.props.updateTor(data, data._id);
    if (this.props.history) this.props.history.push('/List/tor');
  }

  handleOpenModalProject = () => this.setState({ modalProjectOpen: true, modalDimmer: false })
  handleCloseModalProject = () => {
    const { match } = this.props
    this.setState({
      modalProjectOpen: false, modalDimmer: true,
      modalListProjectOpen: false, modalDelteProject: false, modalListProjectDelete: false
    })
    this.props.fetchProjects(match.params.id)

  }

  onBackClick = () => {
    this.props.history && this.props.history.goBack()
  }

  handleEdit = () => {
    const { selectedTor } = this.props;
    localStorage.setItem('tor_id', selectedTor._id)
    this.props.history && this.props.history.push('/project/torform/' + selectedTor._id)
  }

  handleSelectProject = (_id) => {
    const { selectedTor } = this.props

    localStorage.setItem("tor_id", selectedTor._id);
    localStorage.setItem("project_id", _id);
    this.props.history && this.props.history.push('/project/menu/' + _id)
  }

  format(input) {
    if (!input) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      return input.substring(0, input.length - 1);
    }
  }

  openConfirm = (e, { name, value }) => {
    this.setState({ openConfirm: true, name: name, value: value })
  }

  close = () => this.setState({ openConfirm: false })

  onTextChange = (e, { name, value }) => {
    const { selectedTor } = this.props

    if (name === 'name') {
      let search = {}
      if (value.match(/^[0-9]{2}/) !== null) {
        search['tor_id'] = selectedTor._id
        search['codeProject'] = {}
        search['codeProject']['$regex'] = value
      } else {
        search['tor_id'] = selectedTor._id
        search['nameTh'] = {}
        search['nameTh']['$regex'] = value
      }
      if (value.length >= 3) {
        this.props.searchProjects(search)
        this.setState({ search: search })
      } else if (value.length === 0) {
        search = {}
        search['tor_id'] = selectedTor._id
        this.props.searchProjects(search)
        this.setState({ search: search })
      }
    }
  }

  handleChangeSearch = () => {
    let { typeSearch } = this.state

    if (typeSearch === 'name') this.setState({ typeSearch: 'code' })
    if (typeSearch === 'code') this.setState({ typeSearch: 'name' })
  }

  handleFilter = (e, { value }) => {
    const { selectedTor } = this.props

    let { search } = this.state
    search = {}
    search['tor_id'] = selectedTor._id
    if (value === 'ทั้งหมด') {
      if (search.status) {
        search['tor_id'] = selectedTor._id
        delete search.status
      }
    } else {
      search['status'] = value
    }
    this.props.searchProjects(search)
    this.setState({ status: value, search: search })
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  handleModalListProjectOpen = () => {
    this.setState({ modalListProjectOpen: true })
  }

  handleModalListProjectDelete = () => {
    this.setState({ modalListProjectDelete: true })
  }

  handlrOnDeleteProject = () => {    
    this.setState({ modalDelteProject: true })
    
  }

  handlrConfirmDeleteProject = () => {
    let { projects, match, auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });
    projects.currentData.userDelete = user._id
    projects.currentData.timeDelete = gettime
    projects.currentData.delete = true
    this.props.updateProject(projects.currentData, projects.currentData._id)
    this.setState({ modalDelteProject: false, wait: true })
    this.props.fetchProjects(match.params.id)
  }

  render() {
    let { data, validate, search, typeSearch, modalProjectOpen } = this.state
    const { tors, selectedTor, role, match, projects } = this.props;

    if (selectedTor) data = { ...selectedTor, ...data };


    const statusProposal = [
      { value: 'อนุมัติแล้ว', text: 'อนุมัติแล้ว' },
      { value: 'ยังไม่อนุมัติ', text: 'ยังไม่อนุมัติ' },
    ]

    let style = { paddingBottom: 0 }
    if (projects && projects.data.length < 2 && projects.data.length !== 0) {
      style = { paddingBottom: 130 }
    } else if (projects && projects.data.length === 2) {
      style = { paddingBottom: 100 }
    } else if (projects && projects.data.length > 2 && projects.data.length <= 4) {
      style = { paddingBottom: 80 }
    }

    if (tors.currentData) {
      return (
        <div style={style}>
          <Grid columns={2} className="tor-header">
            <Grid.Row>
              <Grid.Column width='8'>
                <Container fluid textAlign='justified'>
                  <Header style={{ fontFamily: 'supermarket', color: '#fff' }} as='h3'>{data.nameTh}</Header>
                  <p>
                    เลขที่หนังสือ :  {data.noBookTor} | ผู้ประสานงานโครงการ :  {data.academicName}
                  </p>
                  <p>
                    งบประมาณ : {this.format(data.budgetLimit)} บาท
                  </p>
                </Container>
              </Grid.Column>
              <Grid.Column width='4' floated='right'>
                <Grid.Row>
                  {data.status === 'อนุมัติแล้ว' && role !== 'Admin' &&
                    <Container fluid textAlign='right'>
                      <Header style={{ fontFamily: 'supermarket', color: '#fff' }} as='h3'>{data.status}</Header>
                    </Container>
                  }
                  <Form>
                    {data.status !== 'อนุมัติแล้ว' && (role === 'Academic' || role === 'Admin') &&
                      <Form.Select label='สถานะ TOR' options={statusProposal}
                        onChange={this.openConfirm}
                        name='status'
                        value={data.status}
                        disabled={validate.error || (role !== 'Academic' && role !== 'Admin')}
                      />
                    }
                    {data.status === 'อนุมัติแล้ว' && role === 'Admin' &&
                      <Form.Select label='สถานะ TOR' options={statusProposal}
                        onChange={this.openConfirm}
                        name='status'
                        value={data.status}
                        disabled={validate.error || (role !== 'Academic' && role !== 'Admin')}
                      />
                    }
                  </Form>
                </Grid.Row>
                <Grid.Row style={{ marginTop: 10, marginLeft: 280 }}>
                  {(data.status !== 'อนุมัติแล้ว' && (role === 'Academic' || role === 'Admin')) &&
                    < Button
                      className='outline-button button-bottom-right'
                      icon='edit'
                      disabled={validate.error || (role !== 'Academic' && role !== 'Admin')}
                      content="แก้ไข"
                      onClick={this.handleEdit} />
                  }
                  {data.status === 'อนุมัติแล้ว' && role === 'Admin' &&
                    < Button
                      className='outline-button button-bottom-right'
                      icon='edit'
                      disabled={validate.error || (role !== 'Academic' && role !== 'Admin')}
                      content="แก้ไข"
                      onClick={this.handleEdit} />
                  }
                  {data.status === 'อนุมัติแล้ว' && role !== 'Admin' &&
                    < Button
                      className='outline-button button-bottom-right'
                      icon='eye'
                      disabled={validate.error || (role !== 'Academic' && role !== 'Admin')}
                      content="ดูข้อกำหนด"
                      onClick={this.handleEdit} />
                  }
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="tor-content"></div>
          <Segment>
            <Menu secondary >
              <Menu.Item fitted >
                <Input name={typeSearch} onChange={this.onTextChange} icon='search' placeholder={'ชื่อหรือรหัสโครงการ..'} />
              </Menu.Item>
              <Menu.Item fitted>
                {/* <Button content='เปลี่ยนการค้นหา' fluid color='grey' icon='retweet' onClick={this.handleChangeSearch} /> */}
              </Menu.Item>
              <Menu.Item fitted>
                <Form>
                  <Dropdown
                    placeholder='กรองข้อมูล'
                    icon='filter'
                    labeled
                    button
                    className='icon search-dropdown'
                    onChange={this.handleFilter}
                    options={filterOptions} />
                </Form>
              </Menu.Item>
              <Menu.Item fitted position='right'>
                <Button fluid color='green' icon='plus'
                  onClick={this.handleOpenModalProject} disabled={(selectedTor && selectedTor.status === 'ยังไม่อนุมัติ') || (role !== 'Academic' && role !== 'Admin')} content="เพิ่มโครงการ" />
              </Menu.Item>
              <Menu.Item fitted >
                <Button fluid color='blue' icon='copy'
                  onClick={this.handleModalListProjectOpen} disabled={(selectedTor && selectedTor.status === 'ยังไม่อนุมัติ') || (role !== 'Academic' && role !== 'Admin')} content="คัดลอกโครงการ" />
              </Menu.Item>
              <Menu.Item fitted >
                <Button fluid icon='box'
                  onClick={this.handleModalListProjectDelete} disabled={(selectedTor && selectedTor.status === 'ยังไม่อนุมัติ') || (role !== 'Academic' && role !== 'Admin')} content="โครงการที่โดนลบ" />
              </Menu.Item>
            </Menu>
          </Segment>
          <Form error={validate.error} loading={tors.isSaving}>
            <Header >
              {data.name}
            </Header>
            <Modal
              open={modalProjectOpen}
              onClose={this.handleCloseModalProject}
              closeOnDimmerClick={this.state.modalDimmer}
              size='small'
              closeIcon>
              <Modal.Content>
                <ComponentAddProject onCloseModalProject={this.handleCloseModalProject} torId={selectedTor._id} />
              </Modal.Content>
            </Modal>
            <Modal
              open={this.state.modalListProjectOpen}
              onClose={this.handleCloseModalProject}
              closeOnDimmerClick
              size='small'
              closeIcon>
              <Modal.Header style={{ fontFamily: 'supermarket' }}><Icon name='copy' />คัดลอกโครงการ</Modal.Header>
              <Modal.Content>
                <CloneProject onCloseModalProject={this.handleCloseModalProject} torId={selectedTor._id} />
              </Modal.Content>
            </Modal>

            <Modal
              open={this.state.modalListProjectDelete}
              onClose={this.handleCloseModalProject}
              closeOnDimmerClick
              size='small'
              closeIcon>
              <Modal.Header style={{ fontFamily: 'supermarket' }}><Icon name='copy' />โครงการที่ลบทิ้ง</Modal.Header>
              <Modal.Content>
                <ListProjectDelete onCloseModalProject={this.handleCloseModalProject} torId={selectedTor._id} />
              </Modal.Content>
            </Modal>
            {projects && projects.data.length !== 0 &&
              <Suspense fallback={<div>Loading...</div>}>
                <TableProjectInTOR SelectProject={this.handleSelectProject}
                  match={match} query={search} DeleteProject={this.handlrOnDeleteProject} />
              </Suspense>
            }
            {projects && projects.data.length === 0 && !projects.listLoading &&
              <Segment className="ui placeholder segment" >
                <div className="ui icon header">
                  <i className="dont icon"></i>
                  <h2 style={{ fontFamily: 'supermarket' }}>
                    ไม่มีข้อมูลโครงการ
                  </h2>
                </div>
              </Segment>
            }
          </Form>
          <Confirm open={this.state.openConfirm}
            content='ต้องการเปลี่ยนสถานะใช่หรือไม่'
            cancelButton='ยกเลิก'
            confirmButton="ยืนยัน"
            onCancel={this.close}
            onConfirm={this.handleChange} />

          <Confirm open={this.state.modalDelteProject}
            content={'ต้องการลบโครงการ ' + (projects.currentData && projects.currentData.nameTh) + ' ใช่หรือไม่'}
            cancelButton='ยกเลิก'
            confirmButton="ยืนยัน"
            onCancel={this.handleCloseModalProject}
            onConfirm={this.handlrConfirmDeleteProject} />
        </div>
      )
    } else return (<div />)
  }
}

const mapStateToProps = state => ({
  tors: state.tors,
  selectedTor: state.tors.currentData,
  projects: state.projects,
  role: state.role,
  search: state.search,
  auth: state.auth,
  isSaving: state.projects.isSaving
})

const mapDispatchToProps = dispatch => ({
  listTors: () => dispatch(torActions.fetchList()),
  fetchProjects: (id) => dispatch(projectActions.fetchList({ query: { tor_id: id, delete: { $ne: true } } })),
  searchProjects: (query) => dispatch(projectActions.fetchList({ query: query })),
  getTor: (id) => dispatch(torActions.fetchOne(id)),
  addTor: (data) => dispatch(torActions.save(data)),
  updateProject: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  updateTor: (data, id) => dispatch(torActions.save(data, id, 'POST'))
})

export default connect(mapStateToProps, mapDispatchToProps)(TORInfo);
