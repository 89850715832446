import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Form, Header, Segment, Menu, Button } from 'semantic-ui-react'
import { actions as borrowActions } from 'Redux/reducers/scholar'
import { actions as countscholar } from 'Redux/reducers/countscholar'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { DatePicker as DayPicker } from 'Utils'
import { ThaiDate } from 'Utils'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import Cleave from 'cleave.js/react'

class TableBorrow extends Component {
  constructor (props) {
    super(props)
    this.state = {
      next: 0,
      query: {
        sort: { borrowDate: 1 },
        query: { delete: { $ne: true } },
        limit: 200,
        skip: 0,
        projection: {
          codeBorrow: 1,
          academic: 1,
          objective: 1,
          sumBorrowMoney: 1,
          borrowDate: 1,
          refundDate: 1,
          sumConclude: 1
        }
      },
      length: 0,
      codeBorrow: '',
      academic: '',
      objective: '',
      sumConclude: '0',
      borrowDate: 0,
      refundDate: 0,
      sumBorrowMoney: '0',
      typeBorrowMoney: 'over',
      typerefundMoney: 'over'
    }
  }

  componentDidMount = () => {
    let { query } = this.state
    this.props.listBorrow(query)
    this.props.allBorrow(query.query)
    this.props.getOffice()
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.countBorrow) {
      this.setState({ length: nextProps.countBorrow / 200 })
    }
  }

  format (input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '')
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.')
        num[0] = num[0]
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1)
        }
        input = num[0] + '.' + num[1]
        return input
      } else {
        input = num
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        return input
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1)
    }
  }

  handleSkipNext = () => {
    let { next, length, query } = this.state
    if (next < length - 1) {
      next = next + 1
      query.skip = 200 * next
      this.props.listBorrow(query)
      this.setState({ next, query })
    }
  }

  handleSkipBack = () => {
    let { next, query } = this.state
    if (next > 0) {
      next = next - 1
      query.skip = 200 * next
      this.props.listBorrow(query)
      this.setState({ next, query })
    }
  }

  handleSkipSelect = (e, { name }) => {
    let { next, query } = this.state
    next = parseInt(name)
    query.skip = 200 * next
    this.props.listBorrow(query)
    this.setState({ next, query })
  }

  onMoneyChange = e => {
    let {
      query,
      sumBorrowMoney,
      sumConclude,
      typeBorrowMoney,
      typerefundMoney
    } = this.state
    let money = e.target.rawValue.replace('-', '')

    if (e.target.name === 'sumBorrowMoney') {
      sumBorrowMoney = money ? money.toString() : '0'
      if (typeBorrowMoney === 'over') {
        query.query.sumBorrowMoney = { $gte: sumBorrowMoney }
      } else {
        query.query.sumBorrowMoney = { $lte: sumBorrowMoney }
      }
    } else {
      sumConclude = money ? money.toString() : '0'
      if (typerefundMoney === 'over') {
        query.query.sumConclude = { $gte: sumConclude }
      } else {
        query.query.sumConclude = { $lte: sumBorrowMoney }
      }
    }

    this.setState({ query, sumConclude, sumBorrowMoney })
  }

  handleDayChange = (name, index, date) => {
    let { query, borrowDate, refundDate } = this.state

    if (name === 'borrowDate') {
      borrowDate = date._d.getTime()
      query.query.borrowDate = { $gte: borrowDate }
    } else if (name === 'refundDate') {
      refundDate = date._d.getTime()
      query.query.refundDate = { $lte: refundDate }
    }
    this.setState({ query, refundDate, borrowDate })
  }

  handleChange = (e, { name, value }) => {
    let {
      query,
      typeBorrowMoney,
      typerefundMoney,
      academic,
      objective,
      codeBorrow
    } = this.state
    if (name === 'typeBorrowMoney') {
      typeBorrowMoney = value
      this.setState({ typeBorrowMoney })
    } else if (name === 'typerefundMoney') {
      typerefundMoney = value
      this.setState({ typerefundMoney })
    } else {
      query.query[name] = { $regex: value }
      if (name === 'academic') {
        academic = value
      } else if (name === 'objective') {
        objective = value
      } else if (name === 'codeBorrow') {
        codeBorrow = value
      }
    }
    this.setState({
      query,
      typeBorrowMoney,
      typerefundMoney,
      academic,
      objective,
      codeBorrow
    })
  }

  handleSearch = () => {
    this.props.listBorrow(this.state.query)
    this.props.allBorrow(this.state.query.query)
  }

  handleReset = () => {
    let { query } = this.state
    query = {
      sort: { borrowDate: 1 },
      query: { delete: { $ne: true } },
      limit: 200,
      skip: 0,
      projection: {
        codeBorrow: 1,
        academic: 1,
        objective: 1,
        sumBorrowMoney: 1,
        borrowDate: 1,
        refundDate: 1,
        sumConclude: 1
      }
    }

    this.props.listBorrow(query)
    this.props.allBorrow(query.query)
    this.setState({
      query,
      nameTh: '',
      recipientOrganizeName: '',
      academic: '',
      managerName: '',
      startDate: 0,
      endDate: 0,
      next: 0,
      typeBorrowMoney: 'over',
      typerefundMoney: 'over',
      sumBorrowMoney: '0',
      sumConclude: '0'
    })
  }

  filterOffice = code => {
    const { typeOffice } = this.props
    let typeOfficeOption = typeOffice[0]
      ? typeOffice[0].typeOffice.map((item, idx) => ({
        key: idx,
        text: item.name,
        value: item.value
      }))
      : []

    let text = typeOfficeOption.filter(x => x.value === code)
    if (text.length > 0) return text[0].text
    else return ''
  }

  render () {
    let { borrowdata } = this.props
    const {
      next,
      length,
      codeBorrow,
      academic,
      objective,
      sumConclude,
      borrowDate,
      refundDate,
      sumBorrowMoney,
      typeBorrowMoney,
      typerefundMoney
    } = this.state
    let footer = []
    for (let i = 0; i < length; i++) {
      footer.push({})
    }

    const typeMoneyOption = [
      { key: 0, text: 'เกิน', value: 'over' },
      { key: 1, text: 'ไม่เกิน', value: 'notover' }
    ]

    let listBorrowTable = []

    if (typeBorrowMoney === 'over') {
      listBorrowTable =
        borrowdata &&
        borrowdata.filter(
          i => parseFloat(i.sumBorrowMoney || 0) > parseFloat(sumBorrowMoney)
        )
    } else {
      listBorrowTable =
        borrowdata &&
        borrowdata.filter(
          i => parseFloat(i.sumBorrowMoney || 0) <= parseFloat(sumBorrowMoney)
        )
    }

    if (typerefundMoney === 'over') {
      listBorrowTable =
        borrowdata &&
        listBorrowTable.filter(
          i => parseFloat(i.sumConclude || 0) > parseFloat(sumConclude)
        )
    } else {
      listBorrowTable =
        borrowdata &&
        listBorrowTable.filter(
          i => parseFloat(i.sumConclude || 0) <= parseFloat(sumConclude)
        )
    }

    return (
      <Segment>
        <Header
          as='h1'
          style={{ fontFamily: 'supermarket' }}
          content='รายละเอียดโครงการ'
          textAlign='center'
        />

        <Form>
          <Form.Group>
            <Form.Input
              label='ชื่อผู้ยืม'
              width={4}
              name='academic'
              onChange={this.handleChange}
              value={academic}
            />
            <Form.Input
              label='วัตถุประสงค์'
              width={8}
              name='objective'
              onChange={this.handleChange}
              value={objective}
            />
            <Form.Input
              label='เลขที่สัญญายืมเงิน'
              width={4}
              name='codeBorrow'
              onChange={this.handleChange}
              value={codeBorrow}
            />
          </Form.Group>
          <Form.Group>
            <Form.Select
              label='ประเภทการค้นหาจำนวนเงินยืม'
              width={4}
              options={typeMoneyOption}
              name='typeBorrowMoney'
              onChange={this.handleChange}
              value={typeBorrowMoney}
            />
            <Form.Field width='4'>
              <label>{'จำนวนเงินยืมทั้งหมด'}</label>
              <Cleave
                name='sumBorrowMoney'
                maxLength='16'
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
                onChange={this.onMoneyChange.bind(this)}
                value={sumBorrowMoney || '0'}
              />
            </Form.Field>
            <Form.Select
              label='ประเภทการค้นหาจำนวนเงินคืน'
              width={4}
              options={typeMoneyOption}
              name='typerefundMoney'
              onChange={this.handleChange}
              value={typerefundMoney}
            />
            <Form.Field width='4'>
              <label>{'จำนวนเงินคืนทั้งหมด'}</label>
              <Cleave
                name='sumConclude'
                maxLength='16'
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
                onChange={this.onMoneyChange.bind(this)}
                value={sumConclude || '0'}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field style={{ fontFamily: 'supermarket' }}>
              <label>{'วันที่ยืมเงินตั้งแต่'}</label>
              <DayPicker
                ser={'borrowDate'}
                name={'borrowDate'}
                max={refundDate || ''}
                onDayChange={this.handleDayChange}
                data={borrowDate || ''}
              />
            </Form.Field>
            <Form.Field style={{ fontFamily: 'supermarket' }}>
              <label>{'ถึงวันที่'}</label>
              <DayPicker
                ser={'refundDate'}
                name={'refundDate'}
                min={borrowDate || ''}
                onDayChange={this.handleDayChange}
                data={refundDate || ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field style={{ textAlign: 'center' }}>
            <Button
              content='ล้างการค้นหา'
              icon='sync'
              size='big'
              onClick={this.handleReset}
              style={{ fontFamily: 'supermarket' }}
            />
            <Button
              color='green'
              content='ค้นหา'
              icon='search'
              size='big'
              onClick={this.handleSearch}
              style={{ fontFamily: 'supermarket' }}
            />
          </Form.Field>
          <ReactHTMLTableToExcel
            id='test-table-xls-button'
            className='download-table-xls-button'
            table='table-to-xls'
            filename='reportPMStablexls'
            sheet='tablexls'
            buttonText='Download as XLS'
          />
          <Segment
            style={{ overflow: 'auto scroll', width: '100%', height: '500px' }}
          >
            <pre>
              <table id='table-to-xls' border='1px black'>
                <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>เลขที่สัญญายืมเงิน</th>
                    <th>ชื่อผู้ยืม</th>
                    <th>วัตถุประสงค์</th>
                    <th>จำนวนเงินยืม = รวมทั้งหมด (บาท) </th>
                    <th>วันที่ยืม</th>
                    <th>วันที่คืนเงิน</th>
                    <th>จำนวนเงินที่คืน = รวมทั้งหมด (บาท)</th>
                  </tr>
                </thead>
                <tbody>
                  {borrowdata &&
                    listBorrowTable.map((item, idx) => (
                      // <Fragment key={idx}>
                      <tr key={idx}>
                        <td>{next * 200 + idx + 1}</td>
                        <td>{item.codeBorrow}</td>
                        <td>{item.academic}</td>
                        <td>{item.objective}</td>
                        <td align='right'>
                          {this.format((item.sumBorrowMoney || 0).toString())}
                        </td>
                        <td>{ThaiDate('month', item.borrowDate)}</td>
                        <td>{ThaiDate('month', item.refundDate)}</td>
                        <td align='right'>
                          {this.format((item.sumConclude || 0).toString())}
                        </td>
                      </tr>
                      // </Fragment>
                    ))}
                </tbody>
              </table>
            </pre>
          </Segment>
          <Menu pagination>
            <Menu.Item as='a' content='<' onClick={this.handleSkipBack} />
            {footer.map((item, idx) => (
              <Fragment key={idx}>
                <Menu.Item
                  as='a'
                  content={idx + 1}
                  active={idx === next}
                  name={idx.toString()}
                  onClick={this.handleSkipSelect}
                />
              </Fragment>
            ))}
            <Menu.Item as='a' content='>' onClick={this.handleSkipNext} />
          </Menu>
        </Form>
      </Segment>
    )
  }
}
const mapStateToProps = state => ({
  projectsIn: state.projects.data,
  countProjects: state.countproject.data,
  auth: state.auth,
  role: state.role,
  typeOffice: state.typeproject.data,
  countBorrow: state.countborrow.data,
  borrowdata: state.scholar.data
})

const mapDispatchToProps = dispatch => ({
  allBorrow: query => dispatch(countscholar.fetchList({ count: query })),
  listBorrow: query => dispatch(borrowActions.fetchList(query)),
  getOffice: () =>
    dispatch(typeProject.fetchList({ query: { name: 'สังกัดสำนัก' } }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableBorrow)
