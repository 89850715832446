import React, { Component } from 'react'
import './SearchResult.css'
import { Button, Icon, Divider } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions as scholarActions } from 'Redux/reducers/scholar'

class SearchResult extends Component {

  componentDidMount = () => {

    this.props.listBorrow()
  }
  render() {
    const { borrow } = this.props


    return (
      <div className="search-result-container">
        {borrow && borrow.map((a, i) => <div key={i} className="search-result-card">
          <div className="text">เลขที่ทดรองจ่าย: <span>CA0001/2561</span></div>
          <div className="text">จุดประสงค์ : <span>{a.objective}</span></div>
          <div className="text">ปีงบประมาณ : <span>ปี {a.yearBudget}</span></div>
          <div className="text">ผู้ประสานงานโครงการ : <span>นายจักร ตีระวานนท์</span></div>
          <Divider />
          <div className="text">STATUS : <Icon name='clipboard outline' circular inverted color="grey" /><span>{a.status}</span></div>
          <Divider />
          {/* <div className="text">โครงการทั้งหมด</div>
          <ul className="list">
            <li>พัฒนาศักยภาพผู้นำชุมชน</li>
            <li>พัฒนาศักยภาพผู้นำชุมชน</li>
            <li>พัฒนาศักยภาพผู้นำชุมชน</li>
          </ul> */}
          <Button fluid as={Link} to={"/borrow/tapBorrow/"+a._id}>VIEW</Button>
        </div>)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  borrow: state.scholar.data
});

const mapDispatchToProps = dispatch => ({
  addBorrow: (data) => dispatch(scholarActions.save(data)),
  updateBorrow: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
  listBorrow: () => dispatch(scholarActions.fetchList()),
  // updateProposal: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);

