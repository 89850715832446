import React, { Component } from 'react'
import './SearchBurnProject.css'
import jwt from 'jsonwebtoken'
import { Button, Input, Dropdown, Menu, Modal, Icon, Popup, Form, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { changeSearch } from 'Redux/actions'
import { DatePicker as DayPicker, UTC } from 'Utils'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'
import AddBurnProject from '../BurnProject/AddProject';

const sortBorrow = [
  { key: '01', value: 'nameTh', text: 'ชิ่อโครงการเผาหัว' },
  { key: '02', value: 'codeProject', text: 'เลขโครงการเผาหัว' },
  { key: '03', value: 'nameAcademic', text: 'ชื่อผู้รับผิดชอบโครงการเผาหัว' },
]

class SearchBurnProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBurnPJOpen: false,
      closeOnDimmerClick: false,
      inputText: '',
      filter: {},
    };
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  componentDidMount() {
    const { search, auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    // let search = JSON.parse(JSON.stringify(this.props.search))

    // if (role === 'Academic') {
    //   search.filter = { academicID: user._id }
    // } else {
    //   search.filter = {}
    // }


    if (role === 'Academic') {
      // search.filter = {}
      search.filter = { academicCreateID: user._id }
    } else {
      search.filter = {}
    }

    search.sortBy = "codeProject"
    search.sortValue = "ascendant"
    search.type = 'burn'
    this.setState({ inputText: '' })

    let queryburn = {
      sort: { nameTh: 1 },
      limit: 5,
      skip: 0,
      query: {
        academicCreateID: user._id
      }
    }


    this.props.changeSearch(search)

    if (role === 'Academic') {
      this.props.listBurn(queryburn)
    } else {
      this.props.firstlistBurn()
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { burn, auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }


    if (burn.params) {
      if (!burn.params.query) {
        let queryburn = {
          sort: { nameTh: 1 },
          limit: 5,
          skip: 0,
          query: {
            academicCreateID: user._id
          }
        }
        if (nextProps.role === 'Academic') {
          this.props.listBurn(queryburn)
        }
      }
    }
  }

  refreshCard = () => {
    const { role, auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }


    let queryburn = {
      sort: { nameTh: 1 },
      limit: 5,
      skip: 0,
      query: {
        academicCreateID: user._id
      }
    }
    if (role === 'Academic') {
      this.props.listBurn(queryburn)
    }
  }

  toggleSortValue = () => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (role === 'Academic') {
      // search.filter = {}
      search.filter = { academicCreateID: user._id }
    } else {
      search.filter = {}
    }

    search.sortValue = search.sortValue === 'ascendant' ? 'descendant' : 'ascendant'
    this.onChange(search)
  }

  // filterChange = (e, { value }) => {
  //   const { auth, role } = this.props
  //   let user = auth.isAuthenticated && auth.user && auth.user.user
  //   if (!user && auth.isAuthenticated) {
  //     user = this.getLocalUser()
  //   }

  //   let search = JSON.parse(JSON.stringify(this.props.search))

  //   if (role === 'Academic') {
  //     search.filter = { academicID: user._id }
  //   } else {
  //     search.filter = {}
  //   }

  //   search.filter.status = value
  //   if (value === 'ทั้งหมด' && search.filter.status) delete search.filter.status
  //   this.onChange(search)
  // }

  sortChange = (e, { value }) => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (role === 'Academic') {
      // search.filter = {}
      search.filter = { academicCreateID: user._id }
    } else {
      search.filter = {}
    }

    search.sortBy = value
    this.onChange(search)
  }

  onChange = (search) => {
    const { burn } = this.props
    search.type = 'burn'
    let tmp = burn.params
    tmp.sort = { [search.sortBy]: (search.sortValue === 'ascendant' ? 1 : -1) }
    // filter by statys
    if (search.filter && search.filter.status) tmp.query = { '$and': [{ 'status': search.filter.status }] }
    else tmp.query = { '$and': [] }
    // filter by another value
    search.filter && Object.keys(search.filter).forEach((key) => {
      if (key !== 'status') {
        if (key === 'startDate') tmp.query.$and.push({ [key]: { $gte: search.filter[key] } })
        else if (key === 'endDate') tmp.query.$and.push({ [key]: { $lte: search.filter[key] } })
        else tmp.query.$and.push({ [key]: { $regex: search.filter[key], $options: "i" } })
      }
    })
    if (tmp.query.$and.length === 0) tmp.query = {}
    // fetch data and set property
    this.props.listBurn(tmp)
    this.props.changeSearch(search)
  }

  onTextChange = (e, { name, value }) => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (role === 'Academic') {
      // search.filter = {}
      search.filter = { academicCreateID: user._id }
    } else {
      search.filter = {}
    }

    this.setState({ inputText: value })

    if (value.length >= 3) {
      search.filter.nameTh = value
      if (value.match(/^[0-9]{1}/) !== null) {
        delete search.filter.nameTh
        search.filter['codeProject'] = value
      } else {
        delete search.filter.codeProject
        let spacialtext = value
        spacialtext = spacialtext.replaceAll('(', '\\(')
        spacialtext = spacialtext.replaceAll(')', '\\)')
        search.filter['nameTh'] = spacialtext
      }
      this.onChange(search)
    } else if (value.length === 0) {
      delete search.filter.nameTh
      delete search.filter[name]
      this.onChange(search)
    }
  }

  inputTextChange = (e, { name, value }) => {
    const filter = { ...this.state.filter }

    if (name === 'valueGroup') {
      if (value.match(/^[0-9]{2}/) !== null) {
        filter[name] = value
      } else {
        filter['groupsPlan'] = value
      }
    } else if (name === 'valuePlan') {
      if (value.match(/^[0-9]{2}/) !== null) {
        filter[name] = value
      } else {
        filter['plan'] = value
      }
    } else if (name === 'valueActivity') {
      if (value.match(/^[0-9]{2}/) !== null) {
        filter[name] = value
      } else {
        filter['activity'] = value
      }
    }

    if (value === 'ทั้งหมด' || value === '') {
      delete filter[name]
      delete filter['groupPlans']
      delete filter['plan']
      delete filter['activity']
    }
    this.setState({ filter })
  }

  handleDayChange = (name, index, date) => {
    const utc7 = UTC.getUTC7(date._d)
    const filter = { ...this.state.filter }
    filter[name] = utc7.getTime()
    this.setState({ filter })
  }

  advanceSearch = () => {
    return <Form>
      <Form.Field>
        <Input fluid labelPosition='left' type='text' name="valueGroup" onChange={this.inputTextChange}>
          <Label basic className="search-modal-label">กลุ่มแผนงาน</Label>
          <input />
        </Input>
      </Form.Field>
      <Form.Field>
        <Input fluid labelPosition='left' type='text' name="valuePlan" onChange={this.inputTextChange}>
          <Label basic className="search-modal-label">แผนงาน</Label>
          <input />
        </Input>
      </Form.Field>
      <Form.Field>
        <Input fluid labelPosition='left' type='text' name="valueActivity" onChange={this.inputTextChange}>
          <Label basic className="search-modal-label">กลุ่มกิจกรรม</Label>
          <input />
        </Input>
      </Form.Field>
      <Form.Field>
        <Form.Group inline>
          <label>ช่วงเวลา ณ วันที่</label>
          <DayPicker ser={'dateContract'} name={'startDate'}
            onDayChange={this.handleDayChange}
            data={this.state.filter.startDate || ''} />
          <label> ถึงวันที่</label>
          <DayPicker ser={'dateContract'} name={'endDate'}
            onDayChange={this.handleDayChange}
            data={this.state.filter.endDate || ''} />
        </Form.Group>
      </Form.Field>
    </Form>
  }

  onAdvanceSearchClick = () => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let tmp = this.props.search
    let search = JSON.parse(JSON.stringify(tmp))

    if (role === 'Academic') {
      // search.filter = {}
      search.filter = { academicCreateID: user._id }
    } else {
      search.filter = {}
    }

    if (tmp.filter.status) search.filter.status = tmp.filter.status
    const { filter } = this.state

    if (filter.valueGroup && filter.valueGroup.length > 0) {
      search.filter.valueGroup = filter.valueGroup
    }

    if (filter.groupsPlan && filter.groupsPlan.length > 0) {
      search.filter.groupsPlan = filter.groupsPlan
    }

    if (filter.valuePlan && filter.valuePlan.length > 0) {
      search.filter.valuePlan = filter.valuePlan
    }

    if (filter.plan && filter.plan.length > 0) {
      search.filter.plan = filter.plan
    }

    if (filter.valueActivity && filter.valueActivity.length > 0) {
      search.filter.valueActivity = filter.valueActivity
    }

    if (filter.activity && filter.activity.length > 0) {
      search.filter.activity = filter.activity
    }

    if (filter.startDate && filter.startDate !== '') {
      search.filter.startDate = filter.startDate
    }
    if (filter.endDate && filter.endDate !== '') {
      search.filter.endDate = filter.endDate
    }

    this.onChange(search)
    this.setState({ isModalOpen: false, activeAdvance: true })
  }

  handleClearSearch = () => {
    const { search, auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (role === 'Academic') {
      // search.filter = {}
      search.filter = { academicCreateID: user._id }
    } else {
      search.filter = {}
    }

    this.setState({ activeAdvance: false, inputText: '' })
    this.onChange(search)

  }

  openModal = () => this.setState({ isModalOpen: true, filter: {} })
  closeModal = () => this.setState({ isModalOpen: false, activeAdvance: false }, () => {

    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let tmp = this.props.search
    let search = JSON.parse(JSON.stringify(tmp))



    if (role === 'Academic') {
      // search.filter = {}
      search.filter = { academicCreateID: user._id }
    } else {
      search.filter = {}
    }


    // if (tmp.filter.status) search.filter.status = tmp.filter.status
    this.onChange(search)
  })

  handleOpenModalAdd = () => this.setState({ modalBurnPJOpen: true, closeOnDimmerClick: false })
  handleCloseModalAdd = () => this.setState({ modalBurnPJOpen: false, closeOnDimmerClick: true })

  render() {
    const isOpen = this.props.sidebar.isOpen
    const { search, role } = this.props
    const { isModalOpen, activeAdvance } = this.state

    return (
      <div className={"search-burn-filter " + (isOpen ? '' : 'full')}>
        <div className={"left " + (isOpen ? '' : 'full')}>
          <Button.Group style={{ marginRight: 5 }}>
            <Button
              active={search.sortValue === 'ascendant'}
              onClick={this.toggleSortValue}
              icon='sort amount down' />
            <Button
              active={search.sortValue === 'descendant'}
              onClick={this.toggleSortValue}
              icon='sort amount up' />
            <Dropdown
              className="search-dropdown"
              placeholder='เรียงข้อมูล'
              onChange={this.sortChange}
              options={sortBorrow}
              button />
          </Button.Group>
          {/* <Dropdown
            placeholder='กรองข้อมูล'
            icon='filter'
            labeled
            button
            className='icon search-dropdown'
            onChange={this.filterChange}
            options={filterBorrow} /> */}
        </div>
        <div className={"right " + (isOpen ? '' : 'full')}>
          <Menu secondary>
            <Menu.Item fitted>
              <Input
                icon='search'
                value={this.state.inputText}
                name={'codeProject'}
                onChange={this.onTextChange}
                placeholder={'เลขที่หรือชื่อ โครงการ'} />
            </Menu.Item>
            <Menu.Item fitted>
              <Modal
                open={isModalOpen}
                size="small"
                trigger={<Popup trigger={<Button toggle active={activeAdvance} onClick={this.openModal} icon='cogs' />} content='ค้นหาขั้นสูง' />}
                onClose={this.onModalClose}>
                <Modal.Header style={{fontFamily:'supermarket'}}>ค้นหาโครงการเผาหัวขั้นสูง</Modal.Header>
                <Modal.Content>
                  {this.advanceSearch()}
                </Modal.Content>
                <Modal.Actions>
                  <Button color='grey' onClick={this.closeModal}>
                    <Icon name='close' />ยกเลิก
                  </Button>
                  <Button color='green' onClick={this.onAdvanceSearchClick}>
                    <Icon name='checkmark' />ค้นหา
                  </Button>
                </Modal.Actions>
              </Modal>
            </Menu.Item>
          </Menu>
          <Menu secondary className="search-burn-create-new">
            <Menu.Item fitted>
              <Button icon='plus' className="button" content='สร้างโครงการเผาหัว' 
              onClick={this.handleOpenModalAdd} disabled={(role !== 'Academic' && role !== 'Admin')} />
            </Menu.Item>
            <Menu.Item fitted>
              <Button
                icon='sync'
                content='ล้างค้นหา'
                onClick={this.handleClearSearch} />
            </Menu.Item>
          </Menu>
          {<Modal
            style={{ paddingTop: 20 }}
            open={this.state.modalBurnPJOpen}
            onClose={this.handleCloseModalAdd}
            closeOnDimmerClick={this.state.closeOnDimmerClick}
            size='small'
            closeIcon>
            <Modal.Content>
              <AddBurnProject onCloseModalProject={this.handleCloseModalAdd} onRefreshCard={this.refreshCard} />
            </Modal.Content>
          </Modal>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  burn: state.burnprojects,
  sidebar: state.sidebar,
  search: state.search,
  auth: state.auth,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  firstlistBurn: () => dispatch(burnProjectAction.fetchList({ sort: { nameTh: 1 }, limit: 5, skip: 0 })),
  changeSearch: (search) => dispatch(changeSearch(search)),
  listBurn: (query) => dispatch(burnProjectAction.fetchList({ ...query }))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBurnProject)
