import React, { Component, Fragment } from 'react'
import {
  Form,
  Button,
  Message,
  Input,
  Select,
  TextArea,
  Confirm
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { constraints as OrganizationConstraints } from '../containers/Project/RegisProject/RegisConstraints'
import { constraints as OrganizeCondiConstraints } from '../containers/Project/RegisProject/RegisConstraints'
import { actions as departmentActions } from 'Redux/reducers/department'
import { FormSearch } from 'Utils'
import { localTh } from 'Utils'
import validatejs from 'validate.js'

class ComponentDepartment extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      cid: undefined,
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      confirm: false
    }
  }

  componentDidMount = () => {
    if (this.props.checkOrganization) {
      this.props.getOrganization(this.props.organizationsID)
    }
  }

  componentWillReceiveProps = nextProps => {
    const { checkOrganization } = this.props
    if (checkOrganization && nextProps.currentOrganization) {
      let tmp = { ...nextProps.currentOrganization }
      if(!tmp.governmentAgencies){
        tmp.governmentAgencies = false
      }
      if(!tmp.legalEntity){
        tmp.legalEntity = false
      }

      if (
        nextProps.currentOrganization.readed === undefined &&
        this.props.role === 'Law'
      ) {
        nextProps.currentOrganization.readed = true
        this.updateRaeded(
          nextProps.currentOrganization,
          nextProps.currentOrganization._id
        )
      }
      let { data } = this.state
      this.setState({ data: { ...tmp, ...data } })
    }
  }

  updateRaeded = data => {
    this.props.updateOrganization(data, data._id)
  }

  validateCid = value => {
    if (value === null) return '^กรุณากรอกเลขบัตรประชาชน'
    else if (value.length !== 13) return '^ต้องมี 13 หลัก'
    else {
      var num = value.replace(/-/g, '')
      let digits = num.split('')
      // .map((st) => parseInt(st));
      if (digits[0] > 0 || digits[0] < 9) {
        let result = 0
        for (var i = 0; i < 12; i++) {
          result += digits[i] * (13 - i)
        }
        result = (11 - (result % 11)) % 10
        if (result !== parseInt(digits[12], 10)) {
          return '^เลขบัตรประชาชนไม่ถูกต้อง'
        }
      } else if (value[0] === 'P') return null
    }
  }

  onCleaveChange = e => {
    let { currentPerson, openModalPerson } = this.props
    let { data, validate } = this.state
    if (!openModalPerson) data = { ...currentPerson, ...data }
    data[e.target.name] = e.target.rawValue
    validate.attrs = {}
    validate.validateError = {}
    validate.error = false
    this.setState({ data, validate })
  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate } = this.state
    let { currentOrganization, checkOrganization } = this.props

    if (this.props.currentOrganization && checkOrganization) { data = { ...currentOrganization, ...data } }

    if (name === 'governmentAgencies' || name === 'legalEntity') {
      data[name] = checked
      if (data.governmentAgencies === true) data.legalEntity = true
    } else if(name === 'nogovernmentAgencies'){
      data.governmentAgencies = false
    } else if(name === 'nolegalEntity'){
      data.legalEntity = false
    } else data[name] = value

    validate.attrs = {}
    validate.validateError = {}
    validate.error = false

    this.setState({ data, validate })
  }

  handleApprove = () => {
    this.setState({ confirm: true })
  }

  handleSubmit = () => {
    let { data, validate } = this.state
    let { currentOrganization, checkOrganization } = this.props

    if (this.props.currentOrganization && checkOrganization) { data = { ...currentOrganization, ...data } }

    validate.attrs = {}
    
    if (this.props.role === 'Law') {
      OrganizationConstraints.OrganizationConstraints.governmentAgencies = {presence: {allowEmpty: false, message: "^ต้องระบุ"}}
      OrganizationConstraints.OrganizationConstraints.legalEntity = {presence: {allowEmpty: false, message: "^ต้องระบุ"}}
    }

    if (data.organizationBossTitle !== 'อื่นๆ') {
      validate['validateError'] = validatejs(
        data,
        OrganizationConstraints.OrganizationConstraints
      )
    } else {
      validate['validateError'] = validatejs(
        data,
        OrganizeCondiConstraints.OrganizeCondiConstraints
      )
    }

    if (validate['validateError'] === undefined) {
      validate['validateError'] = {}
    }

    if (data.organizationBossid) {
      if (data.organizationBossid.length > 0) {
        if (data.organizationBossid.length !== 13) {
          validate['validateError'].organizationBossid = [
            'กรุณาใส่ให้ครบ 13 หลัก'
          ]
        } else {
          var regx = /^[0-9]*$/
          const check = data.organizationBossid.match(regx)
          if (check !== null) {
            let validCID = this.validateCid(data.organizationBossid)
            if (validCID !== undefined) {
              validate['validateError'].organizationBossid = [validCID]
            }
          } else {
            validate['validateError'].organizationBossid = [
              'กรุณาใส่เลขบัตรเป็นตัวเลข'
            ]
          }
        }
      }
    }

    if (data.organizationEmail) {
      if (data.organizationEmail.length > 0) {
        var constraintsEmail = {
          email: {
            email: true
          }
        }
        let test = validatejs(
          { email: data.organizationEmail.trim() },
          constraintsEmail
        )
        if (test) {
          validate['validateError'].organizationEmail = [
            'กรุณาใส่ E-mail ให้ถูกต้อง'
          ]
        }
      }
    }

    if (data.organizationTel) {
      if (data.organizationTel.length > 0) {
        if (data.organizationTel.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.organizationTel.match(regx)
          if (check === null) { validate['validateError'].organizationTel = ['กรุณาใส่เป็นตัวเลข'] }
        } else {
          validate['validateError'].organizationTel = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.organizationPhone) {
      if (data.organizationPhone.length > 0) {
        if (data.organizationPhone.length === 10) {
          let regx = /^[0-9]*$/
          const check = data.organizationPhone.match(regx)
          if (check === null) { validate['validateError'].organizationPhone = ['กรุณาใส่เป็นตัวเลข'] }
        } else {
          validate['validateError'].organizationPhone = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.organizationFax) {
      if (data.organizationFax.length > 0) {
        if (data.organizationFax.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.organizationFax.match(regx)
          if (check === null) { validate['validateError'].organizationFax = ['กรุณาใส่เป็นตัวเลข'] }
        } else {
          validate['validateError'].organizationFax = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.organizationBossEmail) {
      if (data.organizationBossEmail.length > 0) {
        let constraintsEmail = {
          email: {
            email: true
          }
        }
        let test = validatejs(
          { email: data.organizationBossEmail.trim() },
          constraintsEmail
        )
        if (test) {
          validate['validateError'].organizationBossEmail = [
            'กรุณาใส่ E-mail ให้ถูกต้อง'
          ]
        }
      }
    }

    if (data.organizationBossTel) {
      if (data.organizationBossTel.length > 0) {
        if (data.organizationBossTel.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.organizationBossTel.match(regx)
          if (check === null) {
            validate['validateError'].organizationBossTel = [
              'กรุณาใส่เป็นตัวเลข'
            ]
          }
        } else {
          validate['validateError'].organizationBossTel = [
            'กรุณาใส่ตัวเลขให้ครบ'
          ]
        }
      }
    }

    if (data.organizationBossPhone) {
      if (data.organizationBossPhone.length > 0) {
        if (data.organizationBossPhone.length === 10) {
          let regx = /^[0-9]*$/
          const check = data.organizationBossPhone.match(regx)
          if (check === null) {
            validate['validateError'].organizationBossPhone = [
              'กรุณาใส่เป็นตัวเลข'
            ]
          }
        } else {
          validate['validateError'].organizationBossPhone = [
            'กรุณาใส่ตัวเลขให้ครบ'
          ]
        }
      }
    }

    if (data.organizationBossFax) {
      if (data.organizationBossFax.length > 0) {
        if (data.organizationBossFax.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.organizationBossFax.match(regx)
          if (check === null) {
            validate['validateError'].organizationBossFax = [
              'กรุณาใส่เป็นตัวเลข'
            ]
          }
        } else {
          validate['validateError'].organizationBossFax = [
            'กรุณาใส่ตัวเลขให้ครบ'
          ]
        }
      }
    }

    if (!validate['validateError']) validate['validateError'] = {}

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      validate['error'] = false
      this.setState({ validate, confirm: false })
      if (this.props.role === 'Law') {
        data.approve = true
        data.readed = true
      }
      if (this.props.currentOrganization && checkOrganization) {
        this.props.updateOrganization(data, data._id)
      } else {
        this.props.addOrganization(data)
      }
      this.props.onCloseModalPerson()
      if (this.props.history) this.props.history.push('/Area')
    }
  }

  _renderFormField = content => {
    const { validate } = this.state
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  handleCancel = () => {
    this.setState({ confirm: false })
  }

  render () {
    let { validate, data } = this.state
    let { currentOrganization, currentState, checkOrganization } = this.props

    if (checkOrganization) data = { ...currentOrganization, ...data }

    let content = {
      organizationName: { control: Input, width: 8 },
      organizationCode: { control: Input },
      organizationAdress: { control: FormSearch },
      organizationAdressNo: { control: TextArea },
      organizationEmail: { control: Input, width: 4 },
      organizationPhone: { control: Input, width: 4, maxLength: 11 },
      organizationTel: { control: Input, width: 4, maxLength: 11 },
      organizationFax: { control: Input, width: 4, maxLength: 11 }
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('organization', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === FormSearch) {
        content[key].value = data[key]
      } else {
        content[key].value = data[key] || ''
      }
    })
    return (
      <Fragment>
        <Form error={validate.error}>
          <Form.Group>
            {this._renderFormField(content.organizationName)}
            {this.props.role === 'Law' && <Form.Field style={{ paddingTop: '20px' }}>
              <Form.Checkbox
                label='เป็นหน่วยงานภาครัฐ'
                name='governmentAgencies'
                checked={data.governmentAgencies}
                onChange={this.handleChange}
                error={validate.attrs['governmentAgencies']}
                disabled={data.approve === true}
              />
              <Form.Checkbox
                label='ประเภทเป็นนิติบุคคล'
                name='legalEntity'
                checked={data.legalEntity}
                onChange={this.handleChange}
                disabled={data.governmentAgencies === true || data.approve === true}
                error={validate.attrs['legalEntity']}
              />
            </Form.Field>}
            {this.props.role === 'Law' && <Form.Field style={{ paddingTop: '20px' }}>
              <Form.Checkbox
                label='เป็นหน่วยงานนอกภาครัฐ'
                name='nogovernmentAgencies'
                checked={data.governmentAgencies === false}
                onChange={this.handleChange}
                error={validate.attrs['governmentAgencies']}
                disabled={data.approve === true}
              />
              <Form.Checkbox
                label='ประเภทไม่เป็นนิติบุคคล'
                name='nolegalEntity'
                checked={data.legalEntity === false}
                onChange={this.handleChange}
                disabled={data.governmentAgencies === true || data.approve === true}
                error={validate.attrs['legalEntity']}
              />
            </Form.Field>}
          </Form.Group>
          {this._renderFormField(content.organizationAdressNo)}
          {this._renderFormField(content.organizationAdress)}
          <Form.Group widths='equal'>
            {this._renderFormField(content.organizationEmail)}
            {this._renderFormField(content.organizationPhone)}
            {this._renderFormField(content.organizationTel)}
            {this._renderFormField(content.organizationFax)}
          </Form.Group>

          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {/* <Message.Item >{'เลขบัตรประชาชน - ' + cid}</Message.Item> */}
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('organization', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Form.Group>
            {this.props.role === 'Law' && (
              <Button
                onClick={this.handleApprove}
                style={{ fontFamily: 'supermarket' }}
                color='blue'
                loading={currentState.isSaving}
                disabled={validate.error}
              >
                อนุมัติ
              </Button>
            )}
            {this.props.role !== 'Law' && (
              <Button
                onClick={this.handleSubmit}
                style={{ fontFamily: 'supermarket' }}
                color='blue'
                loading={currentState.isSaving}
                disabled={validate.error}
              >
                บันทึก
              </Button>
            )}
          </Form.Group>
        </Form>
        {/* confirm */}
        <Confirm
          open={this.state.confirm}
          header='คำเตือน'
          content='เมื่อกดยืนยันแล้ว รายชื่อดังกล่าวจะสามารถใช้งานได้ในเมนูโครงการ และไม่สามารถแก้ไขข้อมูลประเภทหน่วยงานได้'
          onCancel={this.handleCancel}
          onConfirm={this.handleSubmit}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentOrganization: state.department.currentData,
  currentState: state.department,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  addOrganization: data => dispatch(departmentActions.save(data)),
  updateOrganization: (data, id) =>
    dispatch(departmentActions.save(data, id, 'POST')),
  listOrganization: query => dispatch(departmentActions.fetchList(query)),
  getOrganization: id => dispatch(departmentActions.fetchOne(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentDepartment)
