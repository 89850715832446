import React, { Component, Fragment } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Header,
  Message,
  Segment,
  Checkbox,
  Table,
  Label,
  Confirm,
  Transition,
  Icon,
  TextArea
} from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css';
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react';
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
// import { HeaderContent } from 'Components/Header'
import { constraints as ApproveConstraints } from './BurnProjectConstraints'
import { constraints as ApproveArrayConstraints } from './BurnProjectConstraints'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { actions as userActions } from 'Redux/reducers/user'
import { generate } from 'Redux/reducers/counting'
import { DatePicker as DayPicker } from 'Utils'
import { localTh } from 'Utils'
import { FundingFields } from 'Utils'
import { formatcomma, ThaiDate } from 'Utils'
import { ValidateArray as validateArray } from 'Utils'


let gettime = ''

class BurnPJApprove extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      selectedObjectiveRow: null,
      openConfirm: false,
      onSaveSuccess: false,
      onSaveFail: false,
      openConfirmApprove: false,
      onConfirmApprove: false
    };
  }

  componentDidMount() {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

    if (match.params.id) {
      let query = {
        _id: match.params.id
      }
      this.props.getBurnProject(query)
    }

  }


  handleChange = (e, { name, value, checked }) => {
    let { data, validate } = this.state
    const { match, users, burnprojects } = this.props

    if (match.params.id) data = { ...burnprojects, ...data }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}


    let usersOptions = users.data.map((item, idx) => ({
      text: item.title === 'อื่นๆ' ? item.otherTitle + '  ' + item.firstname + ' ' + item.lastname : item.title + '  ' + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    let inspectorOptions = usersOptions.filter((x => x.value !== data.academicID))

    if (name === 'inspector') {
      inspectorOptions.map(r => {
        if (value === r.value) {
          data[name] = value;
          data['nameInspector'] = r.text
        }
        return r
      })
    } else if (name === 'burnHaveAttach') {
      data[name] = checked
    } else {
      data[name] = value
    }

    this.setState({ data, validate });
  }

  handleSubmit = () => {
    let { data, validate } = this.state;
    const { burnprojects, match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    });
    if (match.params.id) data = { ...burnprojects, ...data }

    if (!data.budgetDetail) data.budgetDetail = []

    validate.attrs = {};
    validate['validateError'] = validatejs(data, ApproveConstraints.ApproveConstraints)

    if (!validate['validateError']) validate['validateError'] = {}

    let validateArrayError = validateArray(data.budgetDetail, ApproveArrayConstraints.ApproveArrayConstraints)

    if (Object.keys(validateArrayError).length !== 0 && validateArrayError.constructor === Object) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayError).forEach(index => {
        if (validateArrayError[index].budgetContent) {
          validate['validateError']['budgetContent'] = validateArrayError[index].budgetContent
        }
        if (validateArrayError[index].budget) {
          validate['validateError']['budget'] = validateArrayError[index].budget
        }
        validate.attrs['budgetDetail' + index] = true;
      })
    }

    if (data.budgetDetail.length === 0) {
      validate.error = true
      validate['validateError']['budgetDetail'] = []
      validate['validateError']['budgetDetail'].push('โปรดระบุงบประมาณอย่างน้อย 1 ข้อ')
    }

    let sum = 0
    data.budgetDetail.forEach((x => {
      let money = parseFloat(x.budget)
      sum += money

    }))

    // if (sum > data.budget) {
    //   validate.error = true
    //   validate['validateError']['budget'] = []
    //   validate['validateError']['budget'].push('งบประมาณรวมเกินจากงบประมาณโครงการ')
    // }

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      validate['error'] = false
      this.setState({ validate });
      if (sum > data.budget) {
        this.setState({ onSaveFail: true });
        setTimeout(() => {
          this.setState({ onSaveFail: false });
        }, 6000)
      } else {
        if (this.props.match.params.id) {
          data['timestamp'] = gettime
          data.status = 'ขออนุมัติโครงการและจัดทำคำสั่งเสร็จสิ้น'
          data['sumBudgetDetail'] = sum
          data['submit'] = 'done'
          this.props.updateBurnPJ(data, data._id)
          this.setState({ onSaveSuccess: true });
          setTimeout(() => {
            this.setState({ onSaveSuccess: false });
          }, 3000)
        }
      }
    }
    // if (this.props.onCloseModalProject) this.props.onCloseModalProject();
    // }
  }


  getEndTime = (start, endTime) => {
    var day = ((new Date(start)).getDate());
    var month = ((new Date(start)).getMonth() + endTime);
    var year = ((new Date(start)).getFullYear());
    var date = new Date(year, month, day).getTime();
    return date
  }

  _renderFormField = (content) => {
    let { data, validate } = this.state;
    const { match, burnprojects, role } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data };
    }
    if (data.approve === 'done' && role !== 'Admin') {
      return <Form.Field {...content} error={validate.attrs[content.name]} disabled />
    } else return <Form.Field {...content} error={validate.attrs[content.name]} />
  }


  handleComponentPerson = () => {
    this.setState({ openModalPerson: true })
  }

  handleComponentOrganization = () => {
    this.setState({ openModalOrganization: true })
  }

  close = () => {
    this.setState({ openModalPerson: false, openModalOrganization: false, openConfirmApprove: false, openConfirm: false })
  }

  onMoneyChange = (e) => {
    let { data, validate } = this.state

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    let money = e.target.rawValue.replace('-', '')
    data[e.target.name] = money ? money.toString() : '0'
    validate['validateError'] = {}
    validate.attrs = {};
    if (!validate.validateError) {
      validate.error = false;
    } else {
      validate.error = true;
      Object.keys(validate.validateError).forEach((item) => {
        validate.attrs[item] = true;
      })
    }
    this.setState({ data, validate })
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  handleSelectRow = (selectedRow, name) => {
    let { selectedObjectiveRow, selectedBudgetRow } = this.state

    if (name === 'objective') {
      if (selectedRow === this.state.selectedObjectiveRow) selectedObjectiveRow = null;
      else selectedObjectiveRow = null
    } else if (name === 'budgetDetail') {
      if (selectedRow === this.state.selectedBudgetRow) selectedBudgetRow = null;
      else selectedBudgetRow = selectedRow
    }
    this.setState({ selectedObjectiveRow, selectedBudgetRow })
  }

  handleAddRow = (e, { name }) => {
    let { data, validate } = this.state;
    let { burnprojects } = this.props

    let _data = []
    if (burnprojects !== null) {
      if (Object.keys(data).length === 0) {
        _data = burnprojects.budgetDetail.slice()
      } else {
        _data = data.budgetDetail.slice()
      }
    }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    let indexO, indexB = null
    if (name === 'objective') {
      if (!_data) _data = [];
      _data.push({ objective: '' });
      indexO = _data.length - 1
    } else if (name === 'budgetDetail') {
      if (!_data) _data = [];
      _data.push({ budgetContent: '' });
      indexB = _data.length - 1
    }

    let tmp = { ...data, budgetDetail: _data }

    this.setState({
      data: tmp,
      selectedObjectiveRow: indexO,
      selectedBudgetRow: indexB,
    })
  }

  handleDeleteRow = () => {
    let { data, name, indexDelete, validate } = this.state;
    const { burnprojects } = this.props

    let _data = []
    if (burnprojects !== null) {
      if (Object.keys(data).length === 0) {
        _data = burnprojects.budgetDetail.slice()
      } else {
        _data = data.budgetDetail.slice()
      }
    }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    if (name === 'objective') {
      if (_data) _data.splice(indexDelete, 1)
    } else if (name === 'budgetDetail') {
      if (_data) _data.splice(indexDelete, 1)
    }

    let tmp = { ...data, budgetDetail: _data }

    let sum = 0
    tmp.budgetDetail.forEach((x => {
      let money = parseFloat(x.budget)
      sum += money
    }))

    tmp.sumBudgetDetail = sum

    this.setState({ data: tmp, selectedObjectiveRow: null, openConfirm: false })
  }

  handleEditRow = (idx, field, e, { name, value }) => {
    let { data, validate } = this.state;
    const { burnprojects } = this.props

    // if (this.props.burnprojects) data = { ...burnprojects, ...data }
    let _data = []
    if (burnprojects !== null) {
      if (Object.keys(data).length === 0) {
        burnprojects.budgetDetail.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      } else {
        data.budgetDetail.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      }
    }
    _data[idx][name] = value;
    let tmp = { ...data, budgetDetail: _data }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    this.setState({ data: tmp, validate })
  }

  onMoneyBudgetChange = (idx, e) => {
    let { data, validate } = this.state
    const { burnprojects } = this.props

    let _data = []
    if (burnprojects !== null) {
      if (Object.keys(data).length === 0) {
        burnprojects.budgetDetail.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      } else {
        data.budgetDetail.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      }
    }
    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    let money = e.target.rawValue.replace('-', '')
    _data[idx][e.target.name] = money ? money.toString() : '0'
    let tmp = { ...data, budgetDetail: _data }

    let sum = 0
    tmp.budgetDetail.forEach((x => {
      let money = parseFloat(x.budget)
      sum += money
    }))

    tmp.sumBudgetDetail = sum

    this.setState({ data: tmp, validate })
  }

  openConfirm = (idx, name, ) => {
    this.setState({ openConfirm: true, indexDelete: idx, name: name })
  }

  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state;
    const { match, burnprojects } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data };
    }
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    data[name] = date._d.getTime();

    this.setState({ data, validate })
  }

  handleConfirmApprove = () => {
    let { data } = this.state
    const { match, burnprojects } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data };
    }

    data['approve'] = 'done'
    data.status = 'ขออนุมัติโครงการและจัดทำคำสั่งเสร็จสิ้น'

    this.props.updateBurnPJ(data, data._id)
    this.setState({ openConfirmApprove: false })
    this.setState({ onConfirmApprove: true });
    setTimeout(() => {
      this.setState({ onConfirmApprove: false });
    }, 3000)
  }

  handleOpenConfirmApprove = () => {
    this.setState({ openConfirmApprove: true })
  }

  render() {
    let { validate, data, selectedBudgetRow, onSaveSuccess, onSaveFail, onConfirmApprove } = this.state
    const { auth, burnprojects, match, users, role } = this.props

    if (match.params.id) data = { ...burnprojects, ...data }

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let usersOptions = users.data.map((item, idx) => ({
      text: item.title === 'อื่นๆ' ? item.otherTitle + '  ' + item.firstname + ' ' + item.lastname : item.title + '  ' + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    let inspectorfilterOptions = []

    if (data.screen) {
      let inspectorOptions = usersOptions.filter((x => x.value !== data.academicID))
      let screen = []
      data.screen.forEach((x => screen.push(x.screenProfessional)))
      inspectorfilterOptions = inspectorOptions.filter(f => !screen.includes(f.value));
    }


    let content = {
      'nameTh': { control: Input },
      'activity': { control: Input, width: 8, readOnly: true },
      'academicID': { control: Select, options: usersOptions, disabled: true },
      'burnScreen': { control: Input },
      'burnEditPJ': { control: TextArea },
      // 'burnDate': { control: Input },
      'burnHaveAttach': { control: Checkbox },
      // 'academic': { control: Input },
      'inspector': { control: Select, options: inspectorfilterOptions },
      // 'funding': { control: FundingFields, width: 16 },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('burnApprove', key);
      content[key].name = key;
      content[key].onChange = this.handleChange;
      if (content[key].control === Input && content[key].name === 'nameTh') {
        content[key].value = data[key] || ''
      } else if (content[key].control === FundingFields) {
        content[key].value = {
          year: data['yearBudget'],
          group: data['groupsPlan'],
          plan: data['plan'],
          activity: data['activity'],
          valueGroup: data['valueGroup'],
          valuePlan: data['valuePlan'],
          valueActivity: data['valueActivity']
        };
      } else if (content[key].control === Checkbox) {
        content[key].checked = data[key] || false
      } else {
        content[key].value = data[key] || ''
      }
    });


    let checkLock = data.approve === 'done' && role !== 'Admin'

    return (
      <Fragment>
        <div style={{ paddingBottom: 140 }}>
          <Form error={validate.error} >
            {data && data.screen &&
              <Segment>
                <Header style={{ fontFamily: 'supermarket' }} icon='hotjar' content='โครงการประเภท "เผาหัว"' as='h4' />
                <Header as='h4' style={{ fontFamily: 'supermarket' }} content='วัตถุประสงค์ *' icon='list alternate outline' />
                {data.objective && data.objective.map((item, idx) => (
                  <Fragment key={idx}>
                    <Header style={{ fontFamily: 'supermarket' }}
                      content={'- ' + item.objective} as='h5' />
                  </Fragment>
                ))}
                <Header style={{ fontFamily: 'supermarket' }} icon='clock'
                  content={'ระยะเวลาดำเนินการ ' + ThaiDate('month', data.startDate) + ' - ' + ThaiDate('month', data.endDate)} as='h4' />
                <Form.Group widths='equal'>
                  {this._renderFormField(content.burnEditPJ)}
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field width={4} disabled={checkLock}>
                    <label>{'วันที่ทบทวนส่งโครงการ'}</label>
                    <DayPicker ser={'checkProjectDate'} name={'checkProjectDate'}
                      onDayChange={this.handleDayChange}
                      data={data.checkProjectDate || ''} />
                  </Form.Field>
                </Form.Group>
                <Header as='h4' style={{ fontFamily: 'supermarket' }} content={'งบประมาณโครงการ ' + formatcomma(data.budget) + ' บาท แบ่งออกเป็นดังนี้'} icon='list alternate outline' />
                <Table celled selectable color="olive">
                  <Table.Header>
                    <Table.Row >
                      <Table.HeaderCell content='ลำดับ' width={2} />
                      <Table.HeaderCell content='เรื่องที่ใช้งบประมาณ *' width={12} />
                      <Table.HeaderCell content='งบประมาณ *' width={12} />
                      <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.budgetDetail && data.budgetDetail.map((item, idx) => (
                      <Fragment key={idx}>
                        <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'budgetDetail')} error={validate.attrs['budgetDetail' + idx]}>
                          {(selectedBudgetRow === idx && <Table.Cell>
                            <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                          </Table.Cell>) || <Table.Cell textAlign='center'> {(idx + 1)}</Table.Cell>}
                          <Table.Cell content={item.budgetContent} />
                          <Table.Cell content={formatcomma(item.budget)} />
                          <Table.Cell textAlign='center'>
                            <Button
                              type='button'
                              name='budgetDetail'
                              color='red' icon='close'
                              onClick={this.openConfirm.bind(this, idx, 'budgetDetail')}
                              disabled={validate.error || checkLock} />
                          </Table.Cell>
                        </Table.Row>
                        {selectedBudgetRow === idx && (
                          <Table.Row active>
                            <Table.Cell colSpan={4}>
                              <Form.Group widths='equal'>
                                <Form.TextArea
                                  label={'งบประมาณที่ ' + (idx + 1)}
                                  name='budgetContent'
                                  value={item.budgetContent}
                                  onChange={this.handleEditRow.bind(this, idx, 'budgetDetail')}
                                  disabled={checkLock} />
                              </Form.Group>
                              <Form.Group >
                                <Form.Field width={8} disabled={checkLock}>
                                  <label>{'งบประมาณ'}</label>
                                  <Cleave name='budget'
                                    index={idx}
                                    maxLength='16'
                                    options={{
                                      numeral: true,
                                      numeralThousandsGroupStyle: 'thousand'
                                    }}
                                    onChange={this.onMoneyBudgetChange.bind(this, idx)}
                                    value={item.budget || ''} />
                                </Form.Field>
                              </Form.Group>
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Fragment>
                    ))}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan='2'>
                        <Button
                          type='button'
                          content='เพิ่ม'
                          color='green'
                          icon='plus'
                          name='budgetDetail'
                          onClick={this.handleAddRow}
                          disabled={checkLock} />
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan='2'
                        content={data.sumBudgetDetail ?
                          ('จำนวนเงินรวม : ' + formatcomma(data.sumBudgetDetail) + ' บาท') :
                          ('จำนวนเงินรวม : ' + formatcomma(data.sumOperationPlan) + ' บาท')}
                      />
                    </Table.Row>
                  </Table.Footer>
                </Table>
                <label><b>เอกสารแนบเพื่อพิจารณา</b></label>
                {this._renderFormField(content.burnHaveAttach)}
                <Header style={{ fontFamily: 'supermarket' }} as='h4' content={'ผู้รับผิดชอบโครงการ : ' + (data.nameAcademic)} />
                <Button
                  style={{ fontFamily: 'supermarket' }}
                  color='blue'
                  content='บันทึก'
                  icon='save'
                  // disabled={validate.error} loading={currentState.isSaving}
                  onClick={this.handleSubmit}
                  disabled={checkLock} />
                <Button
                  style={{ fontFamily: 'supermarket' }}
                  color='blue'
                  content='ขออนุมัติโครงการจัดทำคำสั่ง'
                  icon='file alternate'
                  // disabled={validate.error} loading={currentState.isSaving}
                  onClick={this.handleOpenConfirmApprove}
                  disabled={data.submit !== 'done' || checkLock} />
              </Segment>
            }
            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
              <Message.List>
                {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}> {localTh('burnApprove', key)} - {item} </Message.Item>
                    ))}
                  </Fragment>
                ))}
              </Message.List>
            </Message>

            {data && !data.screen &&
              <Segment className="ui placeholder segment" >
                <div className="ui icon header">
                  <i className="dont icon"></i>
                  <h2 style={{ fontFamily: 'supermarket' }}>
                    กรุณากลั่นกรองให้ครบก่อนทำขออนุมัติ
                  </h2>
                </div>
              </Segment>
            }
          </Form>
          <Confirm open={this.state.openConfirm}
            content='ต้องการลบใช่หรือไม่'
            cancelButton='ยกเลิก'
            confirmButton="ยืนยัน"
            onCancel={this.close}
            onConfirm={this.handleDeleteRow} />
          <Confirm open={this.state.openConfirmApprove}
            content='ยืนยันขออนุมัติโครงการจัดทำคำสั่งแล้ว จะไม่สามารถกลับไปแก้ไขโครงการเผาหัวนี้ได้ กรุณาตรวจสอบข้อมูลให้ครบถ้วน'
            cancelButton='ยกเลิก'
            confirmButton="ยืนยัน"
            onCancel={this.close}
            onConfirm={this.handleConfirmApprove} />
          <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
            </Message>
          </Transition>
          <Transition visible={onConfirmApprove} animation='horizontal flip' duration={500} >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>ยืนยันการขอนุมัติขอตกลงโครงการและจัดทำคำสั่ง</Message.Header>
            </Message>
          </Transition>
          <Transition visible={onSaveFail} animation='horizontal flip' duration={500} >
            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='close'></Icon>บันทึกไม่สำเร็จ งบประมาณรวมในแผนดำเนินงานเกิน งบประมาณโครงการ</Message.Header>
            </Message>
          </Transition>
        </div>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  users: state.user,
  counter: state.counter,
  auth: state.auth,
  burnprojects: state.burnprojects.data[0],
  role: state.role
});

const mapDispatchToProps = dispatch => ({
  addBurnProject: (data) => dispatch(burnProjectAction.save(data)),
  getBurnProject: (query) => dispatch(burnProjectAction.fetchList({ query: query })),
  updateBurnPJ: (data, id) => dispatch(burnProjectAction.save(data, id, "POST")),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  listUser: () => dispatch(userActions.fetchList()),
  genId: (name) => dispatch(generate(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BurnPJApprove);
