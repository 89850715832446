import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Form, Header, Segment } from 'semantic-ui-react';
import { actions as departmentActions } from 'Redux/reducers/department';

class ManageDB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      next: 0,
    };
  }

  componentDidMount = () => {
  }

  handleLoop = () => {
    // const obj = { name: 'JP' }
    // jsonfile.writeFile('test.json', obj)
    // console.log(file)
  }

  render() {
    const { loading } = this.props

    return (
      <Segment>
        <Form>
          <Form.Group>
            <Header content='ทดสอบเปลี่ยนแปลงข้อมูล' />
            <Form.Button content='เริ่ม' loading={loading} onClick={this.handleLoop} />
          </Form.Group>
        </Form>
      </Segment>
    )
  }

}
const mapStateToProps = state => ({
  projects: state.projects.data,
  loading: state.projects.listLoading,
  auth: state.auth,
  role: state.role,
})

const mapDispatchToProps = dispatch => ({
  // listProposal: () => dispatch(projectActions.fetchList({
  // 	aggregate: [{ $match: {} },
  // 	{ $group: { _id: '$recipientOrganize', project: { $sum: 1 }, name: { "$first": "$recipientOrganizeName" }, total: { $sum: { $toLong: '$budget' } } } },
  // 	{ $sort: { _id: 1 } },
  // 	]
  // })),
  addDepartment: (data) => dispatch(departmentActions.save(data)),
  updateProposal: (data, id) => dispatch(departmentActions.save(data, id, "POST")),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageDB)