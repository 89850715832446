import React, { Component, Fragment } from 'react';
import { Table, Button, Menu, Segment, Input, Modal, Header, Icon, Confirm } from 'semantic-ui-react'
import { connect } from 'react-redux'
// import { actions as natualPersonActions } from 'Redux/reducers/natualPerson'
// import { actions as departmentActions } from 'Redux/reducers/department'

import { actions as organizationActions } from 'Redux/reducers/organizations'
import { actions as individualsActions } from 'Redux/reducers/individuals'

import { ComponentPerson } from 'Utils'

class ListIndividuals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: {}
      },
      openModalOrganizations: false
    };
  }

  componentDidMount = () => {
    const { match } = this.props
    let query = {}
    if (match.params.id === 'Freelance') {
      query = {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: { haveOrganization: 'no' }
      }
    } else if (match.params.id === 'HaveOrg') {
      query = {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: { haveOrganization: 'yes' }
      }
    } else {
      this.props.getOrganization(match.params.id)
      query = {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: { organization: match.params.id }
      }
    }
    this.props.listIndividuals(query)
    this.setState({ query })
    window.scrollTo(0, 0)
  }

  onSearch = (e, { value }) => {
    const { match } = this.props
    const { query } = this.state

    query['query'] = {}
    let test = value.split(' ')
    test.forEach((item, idx) => {
      if (item.length === 0) {
        test.splice(idx, 1)
      }
    })
    query['query']['firstname'] = {}
    query['query']['firstname']['$regex'] = test[0] || ''
    if (test[1]) {
      query['query']['lastname'] = {}
      query['query']['lastname']['$regex'] = test[1] || ''
    }
    if (match.params.id === 'Freelance') {
      query['query']['haveOrganization'] = 'no'

    } else if (match.params.id === 'HaveOrg') {
      query['query']['haveOrganization'] = 'yes'
    } else {
      query['query']['organization'] = match.params.id
    }
    // query['query']['$or']['firstname']['$regex'] = value
    if (value.length >= 3) {
      this.props.listIndividuals(query)
      // this.setState({ search: search })
    } else if (value.length === 0) {
      if (match.params.id === 'Freelance') {
        query['query'] = { haveOrganization: 'no' }
      } else if (match.params.id === 'HaveOrg') {
        query['query'] = { haveOrganization: 'yes' }
      } else {
        query['query'] = { organization: match.params.id }
      }
      this.props.listIndividuals(query)
      // this.setState({ search: search })
    }
  }


  paging = (skip) => {
    let { query } = this.state
    query.skip = query.skip + skip
    this.props.listIndividuals(query)
    this.setState({ query })
  }

  handleEditIndividual = (idx) => {
    let { individuals } = this.props
    if (individuals) {
      this.props.getIndividuals(individuals.data[idx]._id)
    }
    this.setState({ openModalIndividual: true })
  }

  close = () => {
    this.setState({ openModalIndividual: false, checkIndividual: false, confirmDelPerson: false })
  }

  handleAddIndividual = () => {
    this.setState({ openModalIndividual: true, checkIndividual: true })
  }

  handleDeletePerson = () => {
    const { idPerson } = this.state
    this.props.delIndividuals(idPerson)
    this.setState({ confirmDelPerson: false })

  }

  handleConfirmDeletePerson = (item) => {
    this.setState({ confirmDelPerson: true, idPerson: item })
  }


  render() {
    let { openModalIndividual, checkIndividual } = this.state
    const { individuals, match, role, organizations } = this.props

    return (
      <Fragment>
        <Header as='h2'>
          <Icon name='users' />
          {match.params.id === 'Freelance' &&
            <Header.Content style={{ fontFamily: 'supermarket' }}>
              รายชื่อบุคลากร ที่ไม่สังกัดองค์กร
        </Header.Content>
          }
          {match.params.id === 'HaveOrg' &&
            <Header.Content style={{ fontFamily: 'supermarket' }}>
              รายชื่อบุคลากรที่สังกัดองค์กร
        </Header.Content>
          }
          {match.params.id !== 'HaveOrg' && match.params.id !== 'Freelance' &&
            <Header.Content style={{ fontFamily: 'supermarket' }}>
              รายชื่อบุคลากรของ {organizations && organizations.organizationName}
            </Header.Content>
          }
        </Header>
        <Modal
          open={openModalIndividual}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon>
          <Modal.Header style={{ fontFamily: 'supermarket' }}>เพิ่ม บุคลากร</Modal.Header>
          <Modal.Content>
            <ComponentPerson openModalPerson={checkIndividual} onCloseModalPerson={this.close} />
          </Modal.Content>
        </Modal>
        <Segment>
          <Menu secondary >
            <Menu.Item fitted >
              <Input onChange={this.onSearch} icon='search' placeholder={'ไม่ต้องใส่คำนำหน้า'} />
            </Menu.Item>
            {/* <Menu.Item fitted>
              <Form>
                <Dropdown
                  placeholder='กรองข้อมูล'
                  icon='filter'
                  labeled
                  button
                  className='icon search-dropdown'
                  onChange={this.handleFilter}
                  options={rolesOptions} />
              </Form>
            </Menu.Item> */}
            <Menu.Item fitted>
            </Menu.Item>
          </Menu>
        </Segment>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width='4'>ชื่อ-นามสกุล</Table.HeaderCell>
              <Table.HeaderCell width='4'>ชื่อองค์กร</Table.HeaderCell>
              <Table.HeaderCell width='2'>ตำแหน่ง</Table.HeaderCell>
              <Table.HeaderCell width='4'>ที่อยู่</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width='2'>แก้ไข</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width='2'>ลบ</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {individuals.data.map((item, idx) =>
              <Table.Row key={idx} >
                <Table.Cell>
                  {((item.title === 'อื่นๆ' ? item.otherTitle : item.title) ||
                    (item.title === null ? '' : item.title))
                    + ' ' + item.firstname + ' ' + item.lastname}
                </Table.Cell>
                <Table.Cell>{item.organizationName}</Table.Cell>
                <Table.Cell>{item.role && item.role.map((itm, idx) => itm + ' ')}</Table.Cell>
                <Table.Cell>
                  {item.address && (item.addressNo || '') + (item.address.province === null ? '' : ' จ. ' + item.address.province) +
                    (item.address.city === null ? '' : ' อ. ' + item.address.city) +
                    (item.address.tumbon === null ? '' : ' ต. ' + item.address.tumbon) + ' ' + (item.address.zipcode === null ? '' : item.address.zipcode)}
                </Table.Cell>
                <Table.Cell textAlign='center'><Button icon='edit' onClick={this.handleEditIndividual.bind(this, idx)} /></Table.Cell>
                <Table.Cell textAlign='center'><Button disabled={role !== 'Admin'} icon='close' color='red' onClick={this.handleConfirmDeletePerson.bind(this, item._id)}></Button></Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='1'><Button color='green' content='เพิ่มบุคลากร' onClick={this.handleAddIndividual} /></Table.HeaderCell>
              <Table.HeaderCell colSpan='5' textAlign='right'>
                {individuals.params && individuals.params.skip !== 0 &&
                  <Button content='ย้อนกลับ' onClick={this.paging.bind(null, -10)} />
                }
                {
                  individuals.data && individuals.data.length >= 10 && individuals.data.length !== 0 &&
                  <Button content='ถัดไป' onClick={this.paging.bind(null, +10)} />
                }
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Confirm
          content='ต้องการลบใช่หรือไม่'
          open={this.state.confirmDelPerson}
          onCancel={this.close}
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onConfirm={this.handleDeletePerson} />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  individuals: state.individuals,
  role: state.role,
  organizations: state.organizations.currentData,
});

const mapDispatchToProps = dispatch => ({
  listIndividuals: (query) => dispatch(individualsActions.fetchList({ ...query })),
  getIndividuals: (id) => dispatch(individualsActions.fetchOne(id)),
  delIndividuals: (id) => dispatch(individualsActions.delete(id)),
  getOrganization: (id) => dispatch(organizationActions.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListIndividuals);