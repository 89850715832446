import React, { Component, Fragment } from 'react'
import {
  Table,
  Header,
  Button,
  Modal,
  ModalContent,
  Transition,
  Message,
  Icon,
  Confirm
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as professional_burnActions } from 'Redux/reducers/professional_burn'
import { actions as professional_bActions } from 'Redux/reducers/professional_b'
import { ModalAddProfesBurn } from 'Utils'

class ListProfesBurn extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modal: false,
      data: {},
      nextPage: 0,
      editData: {},
      onSaveSuccess: false,
      openConfirmDel: false,
      idDel: ''
    }
  }

  componentDidMount = () => {
    const { yearDoc } = this.props
    let query = {
      year: yearDoc.name.toString()
    }
    this.props.listproburns(query)
    this.props.listproburn(query)
  }

  componentDidUpdate = () => {
    const { yearDoc, proburn, proburns } = this.props
    let { data } = this.state

    if (proburns[0]) data = { ...proburns[0], ...data }

    let checkLoop = false
    proburn.forEach(x => {
      let checkIndex =
        proburns[0] && proburns[0].list.findIndex(y => y._id === x._id)
      if (checkIndex === -1) {
        checkLoop = true
      } else {
        proburns[0] &&
          proburns[0].list.forEach(d => {
            if (d._id === x._id) {
              if (
                d.rank !== x.rank ||
                d.title !== x.title ||
                d.titleOther !== x.titleOther ||
                d.firstname !== x.firstname ||
                d.lastname !== x.lastname ||
                d.organization !== x.organization ||
                d.specialization !== x.specialization ||
                d.type !== x.type
              ) {
                checkLoop = true
              }
            }
          })
      }
    })

    proburns[0] &&
      proburns[0].list.forEach(p => {
        let checkDelIndex = proburn.findIndex(pd => pd._id === p._id)
        if (checkDelIndex === -1) {
          checkLoop = true
        }
      })

    data.list = proburn
    if (data._id && checkLoop) {
      this.props.updateProburns(data, data._id)
      let query = {
        year: yearDoc.name.toString()
      }
      this.props.listproburns(query)
      this.props.listproburn(query)
      checkLoop = false
    }
  }

  handleOpenModalAdd = () => {
    this.setState({ modal: true, editData: undefined })
  }
  handleCloseModalAdd = () => {
    this.setState({ modal: false, onSaveSuccess: true })
    setTimeout(() => {
      this.setState({ onSaveSuccess: false })
    }, 3000)
  }

  handleEdit = (data) => {
    this.setState({ modal: true, editData: data })
  }

  handleDelete = () => {
    let { idDel } = this.state
    this.props.delProburn(idDel)
    this.setState({ openConfirmDel: false })
  }

  handleConfirmDel = data => {
    this.setState({ openConfirmDel: true, idDel: data._id })
  }

  nextPage = () => {
    let { nextPage } = this.state
    const { proburns } = this.props

    let list = proburns[0] && proburns[0].list

    if (list.length > nextPage) {
      this.setState({ nextPage: nextPage + 20 })
    }
  }

  backPage = () => {
    let { nextPage } = this.state

    if (nextPage !== 0) {
      this.setState({ nextPage: nextPage - 20 })
    }
  }

  close = () => {
    this.setState({ openConfirmDel: false })
  }

  render () {
    let { nextPage, editData, onSaveSuccess, openConfirmDel } = this.state
    const { yearDoc, proburns } = this.props

    let list = proburns[0] && proburns[0].list
    let newList = []
    if (list) {
      for (let i = nextPage; i < nextPage + 20; i++) {
        if (list[i]) newList.push(list[i])
      }
    }

    return (
      <Fragment>
        <Header
          style={{ fontFamily: 'supermarket' }}
          content={'รายชื่อผู้ทรงคุณวุฒิของโครงการเผาหัว ปี ' + yearDoc.name}
        />
        <Table selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content='รายชื่อของผู้ทรงคุณวุฒิ' width='4' />
              <Table.HeaderCell content='องค์กร' width='6' />
              <Table.HeaderCell content='ตำแหน่งหรือความเชี่ยวชาญ' />
              <Table.HeaderCell textAlign='center' content='แก้ไข' />
              <Table.HeaderCell textAlign='center' content='ลบ' />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {newList &&
              newList.map((row, idx) => (
                <Fragment key={idx}>
                  <Table.Row>
                    <Table.Cell
                      content={
                        (row.titleOther || row.title === 'อื่นๆ'
                          ? row.titleOther
                          : row.title) +
                        ' ' +
                        row.firstname +
                        ' ' +
                        row.lastname
                      }
                    />
                    <Table.Cell content={row.organization} />
                    <Table.Cell
                      content={
                        row.rank === ''
                          ? row.specialization
                          : row.rank || row.rank
                          ? row.rank
                          : row.specialization
                      }
                    />
                    <Table.Cell textAlign='center'>
                      <Button
                        icon='edit'
                        onClick={this.handleEdit.bind(this, row)}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Button
                        icon='close'
                        color='red'
                        onClick={this.handleConfirmDel.bind(this, row)}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Fragment>
              ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='1'>
                <Button
                  content='เพิ่มรายชื่อของผู้ทรงคุณวุฒิ'
                  color='blue'
                  onClick={this.handleOpenModalAdd}
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='5' textAlign='right'>
                {nextPage !== 0 && (
                  <Button content='ย้อนกลับ' onClick={this.backPage} />
                )}
                {list && list.length > nextPage && (
                  <Button content='ถัดไป' onClick={this.nextPage} />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Modal
          open={this.state.modal}
          onClose={this.handleCloseModalAdd}
          closeOnDimmerClick={this.state.closeOnDimmerClick}
          size='large'
          closeIcon
        >
          <Modal.Header
            style={{ fontFamily: 'supermarket' }}
            content=''
            as='h3'
          />
          <ModalContent>
            <ModalAddProfesBurn
              year={yearDoc}
              onClose={this.handleCloseModalAdd}
              editData={editData}
            />
          </ModalContent>
          {/* <Modal.Actions>
            <Button style={{fontFamily:'supermarket'}} content='ยินยัน' color='blue' icon='check' onClick={this.handleConfirmModalAdd} />
            <Button style={{fontFamily:'supermarket'}} content='ยกเลิก' color='red' icon='close' onClick={this.handleCloseModalAdd} />
          </Modal.Actions> */}
        </Modal>
        <Confirm
          open={openConfirmDel}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.handleDelete}
        />
        <Transition
          visible={onSaveSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle'></Icon>บันทึกสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  proburn: state.professional_b.data,
  proburns: state.professional_burn.data
})

const mapDispatchToProps = dispatch => ({
  addproburn: data => dispatch(professional_burnActions.save(data)),
  updateProburns: (data, id) =>
    dispatch(professional_burnActions.save(data, id, 'POST')),
  listproburns: query =>
    dispatch(professional_burnActions.fetchList({ query: query })),
  listproburn: query =>
    dispatch(professional_bActions.fetchList({ query: query })),
  delProburn: id => dispatch(professional_bActions.delete(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListProfesBurn)
