import React, { Component, Fragment } from 'react'
import {
  Form,
  Button,
  Select,
  Input,
  Segment,
  TextArea,
  Header,
  Transition,
  Icon,
  Message
} from 'semantic-ui-react'
import Cleave from 'cleave.js/react'
import validatejs from 'validate.js'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { connect } from 'react-redux'
import { constraints as Constraints } from './EndProjectConstraints'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { generate } from 'Redux/reducers/counting'
import { localTh } from 'Utils'

import { ThaiDate } from 'Utils'
import { AttachedComponent } from 'Utils'
import { PrintoutComponent } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'

let gettime,
  noBook,
  currentNoBook,
  thisYear = ''
let checkNobook = true

const multiAttached = [
  { key: 'conclude', text: 'สรุปผลงานที่ผ่านมา', value: 'conclude' },
  {
    key: 'listPayment',
    text: 'รายงานการใช้จ่ายเงินโครงการ',
    value: 'listPayment'
  },
  {
    key: 'quitDetail',
    text: 'รายละเอียดการขอยุติโครงการ',
    value: 'quitDetail'
  },
  { key: 'other', text: 'อื่นๆ', value: 'other' }
]

const quitProjectOption = [
  {
    key: 'yes_Nocondition',
    text: 'อนุมัติโดยไม่มีเงื่อนไข',
    value: 'อนุมัติโดยไม่มีเงื่อนไข'
  },
  {
    key: 'yes_HaveCondition',
    text: 'อนุมัติโดยมีเงื่อนไข',
    value: 'อนุมัติโดยมีเงื่อนไข'
  },
  {
    key: 'no_Because',
    text: 'ไม่อนุมัติการยุติโครงการ',
    value: 'ไม่อนุมัติการยุติโครงการ'
  }
]

const options = [
  { key: '1', text: 'มี', value: 'มี' },
  { key: '2', text: 'ไม่มี', value: 'ไม่มี' }
]

const content = {
  recipientOrganizeName: { control: Input, width: 8, readOnly: true },
  managerName: {
    control: Input,
    width: 8,
    readOnly: true
  },
  startDate: { control: Input, width: 4, readOnly: true },
  endDate: { control: Input, width: 4, readOnly: true },
  askBudget: { control: Input, width: 8, readOnly: true, money: 'money' },
  installmentCount: { control: Input },
  totalSpend: { control: Input, money: 'money' },
  extendStartDate: { control: Input, width: 8 },
  extendEndDate: { control: Input, width: 8 },
  endingCause: { control: TextArea },
  spending: { control: TextArea },
  quitProjectAttachmentType: {
    control: Select,
    options: multiAttached,
    width: 8
  },
  quitProjectApprovement: {
    control: Select,
    options: quitProjectOption,
    width: 8
  },
  quitProjectDescription: { control: TextArea },
  extendTime: { control: Select, options: options, width: 8 }
}

// const attachmentQuitForm = {
//   'conclude': {
//     control: Checkbox,
//     label: 'สรุปผลงานที่ผ่านมา',
//     name: 'conclude'
//   },
//   'reportUseMoney': {
//     control: Checkbox,
//     label: 'รายงานการใช้จ่ายเงินโครงการ',
//     name: 'reportUseMoney'
//   },
//   'descriptionQuitProject': {
//     control: Checkbox, label: 'รานละเอียดการขอยุติโครงการ',
//     name: 'descriptionQuitProject'
//   },
//   'other': {
//     control: Checkbox, label: 'แนบเอกสารอื่น ๆ (ระบุ)', name: 'other'
//   },
//   'noteOther': {
//     control: Input, width: 12, name: 'noteOther'
//   }
// }

class PageQuitProject extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fileUpload: [],
      type: 'single',
      validate: { error: false, validateError: null, attrs: {} },
      modalAttachment: false,
      onSaveSuccess: false
    }
  }

  componentDidMount () {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    this.props.listProject()
    this.props.listTor()
    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }

    currentNoBook = undefined
    noBook = undefined
    checkNobook = false
    if (this.props.counter.counter['noBookInside-' + thisYear]) {
      currentNoBook = this.props.counter.counter['noBookInside-' + thisYear]
    }
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
      }
    })
  }

  componentWillReceiveProps (nextProps) {
    let { counter } = nextProps
    if (nextProps.currentProposal) {
      let tmp = { ...nextProps.currentProposal }
      let { data } = this.state
      if (!tmp.attachmentQuitForm) {
        tmp.attachmentQuitForm = {}
      }
      this.setState({ data: { ...tmp, ...data } })
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    // if (!thisYear || thisYear === '') this.getTimeFuction()
    
    checkNobook = false
    if (counter.counter['noBookInside-' + thisYear]) {
      if (currentNoBook) {
        if (currentNoBook !== counter.counter['noBookInside-' + thisYear]) {
          noBook = counter.counter['noBookInside-' + thisYear]
          checkNobook = true
          currentNoBook = counter.counter['noBookInside-' + thisYear]
          this.handleSubmit()
        }
      } else {
        currentNoBook = counter.counter['noBookInside-' + thisYear]
        noBook = counter.counter['noBookInside-' + thisYear]
        checkNobook = true
        this.handleSubmit()
      }
    }
  }

  _renderFormField = content => {
    const { validate } = this.state
    const { currentProposal, role } = this.props
    if (
      (currentProposal &&
        currentProposal.status === 'ปิดโครงการ' &&
        role !== 'Admin') ||
      (currentProposal &&
        currentProposal.status !== 'ปิดโครงการ' &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')
    ) {
      if (content.control === Select) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            disabled
          />
        )
      } else
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            readOnly
          />
        )
    } else {
      if (content.control === Select) {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      } else
        return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true })
  }

  handleUploaded = ({ uploaded }) => {
    let { currentProposal } = this.props
    if (!currentProposal[uploaded.name]) currentProposal[uploaded.name] = []
    currentProposal[uploaded.name].push({
      fileName: uploaded.typeOfAttach + '_' + uploaded.file.name,
      typeOfAttach: uploaded.typeOfAttach
    })
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleAttachedFileRemove = name => {
    let { currentProposal } = this.props
    currentProposal['attachQuitProject'].splice(name.index, 1)
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state

    data[name] = value
    validate['validateError'] = validatejs(
      data,
      Constraints.QuitProjectConstraints
    )
    validate.attrs = {}
    validate.error = false

    this.setState({ data, validate })
  }

  handleChangeAttachment = (e, { name, checked, value }) => {
    let { data } = this.state

    data.attachmentQuitForm[name] = value || checked

    this.setState({ data })
  }

  handleSubmit = () => {
    let { data } = this.state
    let { currentProposal } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })
    if (currentProposal) {
      data = { ...currentProposal, ...data }
      const result = validatejs(data, Constraints.QuitProjectConstraints)
      if (result) {
        let attrs = {}
        Object.keys(result).forEach(item => (attrs[item] = true))
        this.setState({
          validate: { error: true, validateError: result, attrs }
        })
      } else {
        if (!data.noBookQuitProject) {
          if (noBook) {
            data.noBookQuitProject =
              'กสศ. น. 19/' +
              noBook.toString().padStart(5, '0') +
              '/' +
              thisYear
            data['status'] = 'ยุติโครงการ'
            data['timestamp'] = gettime
            this.props.updateProposal(data, data._id)
          } else {
            this.props.genId('noBookInside-' + thisYear)
          }
        } else if (!checkNobook) {
          checkNobook = true
          data['status'] = 'ยุติโครงการ'
          data['timestamp'] = gettime
          this.props.updateProposal(data, data._id)
        }

        this.setState({
          validate: { error: false, validateError: result, attrs: {} },
          onSaveSuccess: true
        })
        setTimeout(() => {
          this.setState({ onSaveSuccess: false })
        }, 3000)
      }
    }
  }

  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props

    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false

    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    data[name] = date._d.getTime()
    this.setState({ data, validate })
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false })
  }

  handleCloseModal = () => {
    this.setState({
      modalSendEmail: false,
      modalAttachment: false,
      modalAddGroupFinance: false,
      modalPrintout: false
    })
  }

  onMoneyChange = e => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    // let money = Math.abs(parseFloat(e.target.rawValue))
    let money = e.target.rawValue.replace('-', '')
    data[e.target.name] = money ? money.toString() : '0'
    // validate array
    if (!validate.attrs) validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    this.setState({ data, validate })
  }

  render () {
    const { currentProposal, currentState, match, role } = this.props
    let {
      data,
      validate,
      modalAttachment,
      modalPrintout,
      onSaveSuccess
    } = this.state

    if (!currentProposal)
      return (
        <div>
          <HeaderContent
            subtitle='ขออนุมัติยุติโครงการ'
            onUpload={this._onUpload}
          />
        </div>
      )

    data = { ...currentProposal, ...data }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('proposal', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === Input) {
        if (
          content[key].name === 'startDate' ||
          content[key].name === 'endDate'
        ) {
          content[key].value = ThaiDate('month', data[key]) || '-'
        } else if (content[key].readOnly) {
          content[key].value = data[key] || '-'
        } else {
          content[key].value = data[key]
        }
        if (content[key].attrs) {
          let val = ''
          content[key].attrs.forEach(key => {
            if (data[key]) val += ' ' + data[key] || '-'
          })
          content[key].value = val.substr(1) || '-'
        }
      } else {
        content[key].value = data[key] || ''
      }
    })

    var QuitFormOptions = [
      { text: 'สรุปผลงานที่ผ่าน', value: 'สรุปผลงานที่ผ่าน' },
      {
        text: 'รายงานการใช้จ่ายเงินโครงการ',
        value: 'รายงานการใช้จ่ายเงินโครงการ'
      },
      {
        text: 'รายละเอียดการขอยุติโครงการ',
        value: 'รายละเอียดการขอยุติโครงการ'
      },
      { text: 'อื่นๆ', value: 'อื่นๆ' }
    ]

    if (gettime) {
      // if (!data.quitProjectDate) data.quitProjectDate = gettime
    } else {
      // this.getTimeFuction()
    }

    return (
      <div>
        <HeaderContent subtitle='ขออนุมัติยุติโครงการ' title={data.nameTh} />
        <AttachedComponent
          currentProposal={currentProposal}
          namePage='attachQuitProject'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={QuitFormOptions}
          onClose={this.handleCloseModal}
          checkStatus={
            currentProposal && currentProposal.status === 'ยุติโครงการ'
          }
        />
        <PrintoutComponent
          modalPrintout={modalPrintout}
          numberOfFile={['แบบรายงานสรุปเพื่อขออนุมัติยุติโครงการ']}
          typeFile={[11]}
          match={match}
          onClose={this.handleCloseModal}
        />
        <Form error={validate.error}>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h5'
              content='ข้อมูลโครงการ'
              icon='book'
            />
            <Form.Group widths='equal'>
              {this._renderFormField(content.recipientOrganizeName)}
              {this._renderFormField(content.managerName)}
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.startDate)}
              {this._renderFormField(content.endDate)}
              <Form.Field width='8'>
                <label>{'งบประมาณ'}</label>
                <Cleave
                  name='budget'
                  readOnly
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.budget || ''}
                />
              </Form.Field>
              {/* {this._renderFormField(content.askBudget)} */}
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field error={validate.attrs['installmentCount']}>
                <label>{'เบิกไปแล้ว (งวด) *'}</label>
                <Cleave
                  name='installmentCount'
                  readOnly={
                    (currentProposal &&
                      currentProposal.status === 'ปิดโครงการ' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status !== 'ปิดโครงการ' &&
                      currentProposal.status === 'ยุติโครงการ' &&
                      role !== 'Admin' &&
                      role !== 'Academic' &&
                      role !== 'Co-Academic')
                  }
                  maxLength='1'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.installmentCount || ''}
                />
              </Form.Field>
              {/* {this._renderFormField(content.installmentCount)} */}
              <Form.Field error={validate.attrs['totalSpend']}>
                <label>{'ใช้เงินทั้งหมด'}</label>
                <Cleave
                  name='totalSpend'
                  readOnly={
                    (currentProposal &&
                      currentProposal.status === 'ปิดโครงการ' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status !== 'ปิดโครงการ' &&
                      role !== 'Admin' &&
                      role !== 'Academic' &&
                      role !== 'Co-Academic')
                  }
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.totalSpend || ''}
                />
              </Form.Field>
            </Form.Group>
            {this._renderFormField(content.spending)}
            <Form.Group>
              {this._renderFormField(content.extendTime)}
              <Form.Field>
                <label>{'วันที่ยุติโครงการ *'}</label>
                <DayPicker
                  ser={'quitProjectDate'}
                  name={'quitProjectDate'}
                  onDayChange={this.handleDayChange}
                  data={data.quitProjectDate || ''}
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          {/* <Segment>
            <Header style={{ fontFamily: 'supermarket' }} content='ข้อมูลการยุติโครงการ' as='h5' icon='edit' />
            {this._renderFormField(content.endingCause)}
            <Form.Field {...attachmentQuitForm.conclude}
              readOnly={(currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ปิดโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin')}
              onChange={this.handleChangeAttachment}
              checked={(data.attachmentQuitForm && data.attachmentQuitForm.conclude) || false} />
            <Form.Field {...attachmentQuitForm.reportUseMoney}
              readOnly={(currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ปิดโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin')}
              onChange={this.handleChangeAttachment}
              checked={(data.attachmentQuitForm && data.attachmentQuitForm.reportUseMoney) || false} />
            <Form.Field {...attachmentQuitForm.descriptionQuitProject}
              readOnly={(currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ปิดโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin')}
              onChange={this.handleChangeAttachment}
              checked={(data.attachmentQuitForm && data.attachmentQuitForm.descriptionQuitProject) || false} />
            <Form.Group>
              <Form.Field {...attachmentQuitForm.other}
                readOnly={(currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ปิดโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin')}
                onChange={this.handleChangeAttachment}
                checked={(data.attachmentQuitForm && data.attachmentQuitForm.other) || false} />
              <Form.Field {...attachmentQuitForm.noteOther}
                readOnly={(currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ปิดโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin')}
                onChange={this.handleChangeAttachment}
                value={(data.attachmentQuitForm && data.attachmentQuitForm.noteOther) || ''} />
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.quitProjectApprovement)}
            </Form.Group>
            {this._renderFormField(content.quitProjectDescription)}
          </Segment> */}
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Button
            color='blue'
            content='บันทึก'
            icon='save'
            type='submit'
            disabled={
              (currentProposal &&
                currentProposal.status === 'ปิดโครงการ' &&
                role !== 'Admin') ||
              (currentProposal &&
                currentProposal.status !== 'ปิดโครงการ' &&
                role !== 'Admin' &&
                role !== 'Academic' &&
                role !== 'Co-Academic')
            }
            loading={currentState.isSaving}
            onClick={this.handleSubmit}
          />
        </Form>
        <Transition
          visible={onSaveSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle'></Icon>บันทึกสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  form: state.proposalForm,
  currentState: state.projects,
  counter: state.counter,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  listProject: () => dispatch(torActions.fetchList()),
  listTor: () =>
    dispatch(torActions.fetchList({ sort: { nameTh: 1 }, limit: 5, skip: 0 })),
  addProposal: data => dispatch(projectActions.save(data)),
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id)),
  genId: name => dispatch(generate(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(PageQuitProject)
