import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Form, Header, Menu, Button, Confirm, Segment } from 'semantic-ui-react';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more'
import { actions as projectActions } from 'Redux/reducers/projects';
import { actions as allProjectActions } from 'Redux/reducers/allprojects';
import { actions as countProjectActions } from 'Redux/reducers/countproject';
import { ThaiDate } from 'Utils'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
HighchartsMore(Highcharts)

let optionsOffice = [
  { key: '01', text: 'สำนักบริหารกลาง', value: '01' },
  { key: '02', text: 'สำนักพัฒนานโยบาย กลยุทธ์ และงบประมาณ', value: '02' },
  { key: '03', text: 'สำนักบริหารเงินอุดหนุนผู้ขาดแคลนทุนทรัพย์', value: '03' },
  { key: '04', text: 'สำนักพัฒนา นวัตกรรมการเรียนรู้เชิงพื้นที่', value: '04' },
  { key: '05', text: 'สำนักพัฒนาคุณภาพครู นักเรียนครูและสถานศึกษา', value: '05' },
  { key: '06', text: 'สถาบันวิจัยพัฒนาองค์ความรู้และเทคโนโลยีสารสนเทศเพื่อความเสมอภาคทางการศึกษา', value: '06' },
  { key: '07', text: 'สำนักส่งเสริมการมีส่วนร่มนวัตกรรมและทุนการศึกษา', value: '07' },
  { key: '08', text: 'ฝ่ายตรวจสอบภายใน', value: '08' },
]

class GraphProvince extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: '2561',
      next: 0,
      length: 0,
      province: '',
      query: {
        sort: { nameTh: 1 },
        query: { delete: { $ne: true } },
        limit: 10000,
        skip: 0,
        projection: { nameTh: 1, managerName: 1, codeProject: 1, noAgreement: 1, academic: 1, budget: 1, status: 1, askBudget: 1, projectGroupArea: 1, startDate: 1, endDate: 1 }
      },
      dataReport: {},
    };
  }

  componentDidMount = () => {
    this.props.listProposal()
    this.props.listAllProposal(this.state.query)
    this.props.countProject(this.state.query.query)

  }

  componentWillReceiveProps = (nextProps) => {    
    if (nextProps.count) {
      this.setState({ length: nextProps.count / 10000 })
    }
  }

  handleSkipNext = () => {
    let { next, length, query } = this.state
    if (next < length - 1) {
      next = next + 1
      query.skip = 10000 * next
      this.props.listAllProposal(query)
      this.setState({ next, query })
    }
  }

  handleSkipBack = () => {
    let { next, query } = this.state
    if (next > 0) {
      next = next - 1
      query.skip = 10000 * next
      this.props.listAllProposal(query)
      this.setState({ next, query })
    }
  }

  handleSkipSelect = (e, { name }) => {
    let { next, query } = this.state
    next = parseInt(name)
    query.skip = 10000 * next
    this.props.listAllProposal(query)
    this.setState({ next, query })
  }

  handleChange = (e, { name, value }) => {
    let { query, dataReport } = this.state
    if (name === 'province') {
      query.query['projectGroupArea.province'] = value
      dataReport[name] = value
      this.setState({ province: value })
    } else {
      query.query[name] = value
      let check = optionsOffice.filter(x => x.value === value)
      dataReport[name] = check[0].text
    }
    this.setState({ query, dataReport })
  }

  format(input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1);
    }
  }
  handleReset = () => {
    let { query } = this.state
    query = {
      sort: { nameTh: 1 },
      query: { delete: { $ne: true } },
      limit: 10000,
      skip: 0,
      projection: { nameTh: 1, managerName: 1, codeProject: 1, noAgreement: 1, academic: 1, budget: 1, status: 1, askBudget: 1, projectGroupArea: 1, startDate: 1, endDate: 1 }
    }
    this.props.listAllProposal(query)
    this.props.countProject(query.query)
    this.setState({ query, province: '' })
  }

  handleSearch = () => {
    let { query } = this.state
    query.skip = 0
    this.props.listAllProposal(query)
    this.props.countProject(query.query)
    this.setState({ next: 0, query })
  }

  handleOpenReport = () => {
    this.setState({ modalReport: true })
  }

  handlePrintTabal = () => {
    let { dataReport } = this.state
    let queryR = {
      query: {
        delete: { $ne: true }
      }
    }
    if (dataReport.province) {
      queryR.query['projectGroupArea.province'] = dataReport.province
    }
    if (dataReport.typeOffice) {
      queryR.query.typeOffice = dataReport.typeOffice
    }
    this.props.ReportAllProposal(queryR)
    this.setState({ modalReport: false })
  }

  close = () => {
    this.setState({ modalReport: false })
  }

  mapAraeProject = (group) => {
    let text = ''
    group.forEach((r, idx) => {
      if (idx < group.length - 1) {
        text += (r.tumbon ? r.province !== 'กรุงเทพมหานคร' ? 'ตำบล' + r.tumbon + ' ' : 'แขวง' + r.tumbon + ' ' : '') +
          (r.city ? r.province !== 'กรุงเทพมหานคร' ? 'อำเภอ' + r.city + ' ' : 'เขต' + r.city + ' ' : '') +
          (r.province ? r.province !== 'กรุงเทพมหานคร' ? 'จังหว้ด' + r.province + ', \n' : r.province + ', \n' : '')
      }
      else {
        text += (r.tumbon ? r.province !== 'กรุงเทพมหานคร' ? 'ตำบล' + r.tumbon + ' ' : 'แขวง' + r.tumbon + ' ' : '') +
          (r.city ? r.province !== 'กรุงเทพมหานคร' ? 'อำเภอ' + r.city + ' ' : 'เขต' + r.city + ' ' : '') +
          (r.province ? r.province !== 'กรุงเทพมหานคร' ? 'จังหว้ด' + r.province : r.province : '')
      }
    })
    return text
  }

  render() {
    const { projects, projectAll } = this.props
    const { next, length, query, province, dataReport } = this.state
    let data = []
    let optionsProvince = []
    projects.length > 0 && projects.forEach(item => {
      if (item.projectGroupArea) {
        let checkProvince = []
        item.projectGroupArea.forEach(row => {
          if (data.length === 0) {
            data.push([row.province, 1])
            checkProvince.push(row.province)
            optionsProvince.push({ key: 0, text: row.province, value: row.province })
          } else {
            if (checkProvince.findIndex(x => x === row.province) === -1) {
              checkProvince.push(row.province)
              let index = data.findIndex(x => x[0] === row.province)
              if (index === -1) {
                optionsProvince.push({ key: optionsProvince[optionsProvince.length - 1].key + 1, text: row.province, value: row.province })
                data.push([row.province, 1])
              } else {
                data[index][1]++
              }
            }
          }
        })
      }
    })

    let footer = []
    for (let i = 0; i < length; i++) {
      footer.push({})
    }

    const options = {
      chart: {
        type: 'column'
      },
      title: {
        text: ' '
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif'
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'จำนวนโครงการ'
        }
      },
      legend: {
        enabled: true
      },
      credits: {
        enabled: false
      },
      // tooltip: {
      //   pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
      // },
      series: [{
        name: 'จำนวนโครงการ ',
        data: data,
        dataLabels: {
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y:.1f}', // one decimal
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif'
          }
        }
      }]
    };

    return (
      <Form>
        <Header as='h1' style={{ fontFamily: 'supermarket' }}
          content='จำนวนพื่นที่ดำเนินงานทั้งหมดของ กสศ.' textAlign='center' />
        <HighchartsReact highcharts={Highcharts} options={options} />
        <Form.Group>
          <Form.Select label='จังหวัดพื้นที่ดำเนินการ (เฉพาะที่มี)' options={optionsProvince} search width={3} value={province} name='province' onChange={this.handleChange} />
          <Form.Select label='สำนักเข้าของโครงการ' options={optionsOffice} search width={7} value={query.query.typeOffice} name='typeOffice' onChange={this.handleChange} />
          {/* <Form.Field>
            <label>{"พิมพ์"}</label>
            <Button color='blue' icon='print' content='Report' onClick={this.handleOpenReport} />
          </Form.Field> */}
        </Form.Group>
        <Form.Field style={{ 'textAlign': 'center' }}>
          <Button content='ล้างการค้นหา' icon='sync' size='big' onClick={this.handleReset} style={{ fontFamily: 'supermarket' }} />
          <Button color='green' content='ค้นหา' icon='search' size='big' onClick={this.handleSearch} style={{ fontFamily: 'supermarket' }} />
        </Form.Field>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="reportPMStablexls"
          sheet="tablexls"
          buttonText="Download as XLS" />
        <Segment style={{ "overflow": "auto scroll", "width": "100%", "height": "500px" }}>
          <pre>
            <table id="table-to-xls" border="1px black" >
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>รหัสโครงการ</th>
                  <th>เลขที่สัญญา</th>
                  <th>ชื่อโครงการ</th>
                  <th>สถานะโครงการ</th>
                  <th>ผู้รับผิดชอบโครงการ</th>
                  <th>นักวิชาการ</th>
                  <th>พื้นที่ดำเนินการ</th>
                  <th>งบประมาณ (บาท)</th>
                  <th>วันที่เริ่มต้นโครงการ</th>
                  <th>วันที่สิ้นสุดโครงการ</th>
                </tr>
              </thead>
              <tbody>
                {projectAll && projectAll.map((item, idx) => (
                  // <Fragment key={idx}>
                  <tr key={idx}>
                    <td>{((next) * 10000) + idx + 1}</td>
                    <td>{item.codeProject}</td>
                    <td>{item.noAgreement}</td>
                    <td>{item.nameTh}</td>
                    <td>{item.status}</td>
                    <td>{item.managerName}</td>
                    <td>{item.academic}</td>
                    <td width="500px">{this.mapAraeProject(item.projectGroupArea || [])}</td>
                    <td align="right">{this.format((item.budget || 0).toString())}</td>
                    <td >{ThaiDate('month', item.startDate)}</td>
                    <td >{ThaiDate('month', item.endDate)}</td>
                  </tr>
                  // </Fragment>
                ))}
              </tbody>
            </table>
          </pre>
        </Segment>
        <Menu pagination >
          <Menu.Item as='a' content='<' onClick={this.handleSkipBack} />
          {footer.map((item, idx) => (
            <Fragment key={idx}>
              <Menu.Item as='a' content={idx + 1} active={idx === next} name={idx.toString()} onClick={this.handleSkipSelect} />
            </Fragment>
          ))}
          <Menu.Item as='a' content='>' onClick={this.handleSkipNext} />
        </Menu>
        <Confirm
          content={'ต้องการพิมพ์ตารางข้อมูล พื้นที่ดำเนินการ จังหวัด : ' + (dataReport.province || '-') + ' ' + (dataReport.typeOffice || '') + '  ใช่หรือไม่'}
          open={this.state.modalReport}
          onCancel={this.close}
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onConfirm={this.handlePrintTabal} />
      </Form>
    )
  }

}
const mapStateToProps = state => ({
  projects: state.projects.data,
  projectAll: state.allprojects.data,
  borrow: state.scholar.data,
  search: state.search,
  auth: state.auth,
  role: state.role,
  counter: state.counter,
  count: state.countproject.data
})

const mapDispatchToProps = dispatch => ({
  listProposal: () => dispatch(projectActions.fetchList({
    query: { delete: { $ne: true } },
    projection: { projectGroupArea: 1 }
  })),
  listAllProposal: (query) => dispatch(allProjectActions.fetchList(query)),
  countProject: (query) => dispatch(countProjectActions.fetchList({ count: query })),
  ReportAllProposal: (query) => dispatch(allProjectActions.fetchList(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GraphProvince)