import React, { Component, Fragment } from 'react'
import {
  Form,
  Button,
  Select,
  Segment,
  Input,
  Message,
  Header,
  TextArea
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as professional_bActions } from 'Redux/reducers/professional_b'
import { actions as professional_burnActions } from 'Redux/reducers/professional_burn'
import { localTh } from 'Utils'
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react';

const titleOptions = [
  { value: 'นาย', text: 'นาย' },
  { value: 'นาง', text: 'นาง' },
  { value: 'นางสาว', text: 'นางสาว' },
  { value: 'อื่นๆ', text: 'อื่นๆ(ระบุ)' }
]

class ModalAddProfesBurn extends Component {
  constructor (props) {
    super(props)
    this.state = {
      query: {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: {}
      },
      data: {},
      validate: { error: false, validateError: null, attrs: {} }
      // openModalOrganizations: false
    }
  }

  componentDidMount = () => {
    // const { year } = this.props
    let { data } = this.state
    // data.year = year.name
    this.setState({ data })
  }

  _renderFormField = content => {
    const { validate } = this.state
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state
    const { editData } = this.props

    if (editData) data = { ...editData, ...data }

    validate.attrs = {}
    validate.error = false

    data[name] = value
    this.setState({ data })
  }

  handleSubmit = () => {
    let { data, validate } = this.state
    const { editData, year } = this.props

    if (editData) data = { ...editData, ...data }
    validate['validateError'] = {}
    validate.attrs = {}
    validate['error'] = false

    var Constraints = {
      title: {
        presence: {
          allowEmpty: false,
          message: '^ต้องระบุ'
        }
      },
      firstname: {
        presence: {
          allowEmpty: false,
          message: '^ต้องระบุ'
        }
      },
      lastname: {
        presence: {
          allowEmpty: false,
          message: '^ต้องระบุ'
        }
      },
      addressNo: {
        presence: {
          allowEmpty: false,
          message: '^ต้องระบุ'
        }
      }
    }

    if (data.title === 'อื่นๆ') {
      Constraints.titleOther = {
        presence: {
          allowEmpty: false,
          message: '^ต้องระบุ'
        }
      }
    }

    validate['validateError'] = validatejs(data, Constraints)
    if (!validate['validateError']) validate['validateError'] = {}

    if (data.cid) {
      if (data.cid.length > 0) {
        if (data.cid.length !== 13) {
          validate['validateError'].cid = ['กรุณาใส่ให้ครบ 13 หลัก']
        } else {
          var regx = /^[0-9]*$/
          const check = data.cid.match(regx)
          if (check !== null) {
            let validCID = this.validateCid(data.cid)
            if (validCID !== undefined) {
              validate['validateError'].cid = [validCID]
            }
          } else {
            validate['validateError'].cid = ['กรุณาใส่เลขบัตรเป็นตัวเลข']
          }
        }
      }
    } else {
      validate['validateError'].cid = ['กรุณากรอกเลขบัตรประชาชน']
    }

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      validate['error'] = false
      if (data.title === 'อื่นๆ') {
        data.title = data.titleOther
      }
      if (editData) {
        this.props.updateProfes(data, data._id)
      } else {
        data.year = year.name
        this.props.addProfes(data)
      }
      this.props.onClose()
      this.setState({ validate })
    }
  }

  onCleaveChange = (e) => {
    let { data, validate } = this.state
    const { editData } = this.props

    if (editData) data = { ...editData, ...data }
    data[e.target.name] = e.target.rawValue;
    validate.attrs = {}
    validate.validateError = {}
    validate.error = false

    this.setState({ data, validate });
  }

  validateCid = value => {
    if (value === null) return '^กรุณากรอกเลขบัตรประชาชน'
    else if (value.length !== 13) return '^ต้องมี 13 หลัก'
    else {
      var num = value.replace(/-/g, '')
      let digits = num.split('')
      // .map((st) => parseInt(st));
      if (digits[0] > 0 || digits[0] < 9) {
        let result = 0
        for (var i = 0; i < 12; i++) {
          result += digits[i] * (13 - i)
        }
        result = (11 - (result % 11)) % 10
        if (result !== parseInt(digits[12], 10)) {
          return 'เลขบัตรประชาชนไม่ถูกต้อง'
        }
      } else if (value[0] === 'P') return null
    }
  }

  render () {
    let { data, validate } = this.state
    const { editData } = this.props

    if (editData) data = { ...editData, ...data }

    let content = {
      title: { control: Select, options: titleOptions },
      titleOther: { control: Input, disabled: data.title !== 'อื่นๆ' },
      firstname: { control: Input },
      lastname: { control: Input },
      organization: { control: Input },
      rank: { control: Input },
      specialization: { control: Input },
      type: { control: Input, width: 8 },
      year: { control: Input, readOnly: true },
      addressNo: { control: TextArea }
    }
    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('profes', key)
      content[key].name = key
      content[key].value = data[key] || ''
      content[key].onChange = this.handleChange
    })
    return (
      <Fragment>
        <Segment>
          <Form error={validate.error}>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='เพิ่มผู้ทรงคุณวุฒิ'
              as='h4'
              icon='newspaper'
            />
            <Form.Group widths='equal'>
              {this._renderFormField(content.title)}
              {this._renderFormField(content.titleOther)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.firstname)}
              {this._renderFormField(content.lastname)}
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field error={validate.attrs['cid']}>
                <label>{'เลขบัตรประชาชน'}</label>
                <Cleave
                  name='cid'
                  maxLength='17'
                  options={{
                    // numeral: true,
                    delimiter: ' ',
                    blocks: [1, 4, 5, 2, 1]
                  }}
                  onChange={this.onCleaveChange}
                  value={data.cid || ''}
                />
              </Form.Field>
              {this._renderFormField(content.organization)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.rank)}
              {this._renderFormField(content.specialization)}
            </Form.Group>
            {this._renderFormField(content.addressNo)}
            <Button
              style={{ fontFamily: 'supermarket' }}
              color='green'
              content={'บันทึก'}
              onClick={this.handleSubmit}
            />

            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                กรอกข้อมูลไม่ครบถ้วน
              </Message.Header>
              <Message.List>
                {validate.error &&
                  Object.keys(validate.validateError).map((key, idx) => (
                    <Fragment key={idx}>
                      {validate.validateError[key].map((item, idx) => (
                        <Message.Item key={idx}>
                          {' '}
                          {localTh('profes', key)} - {item}{' '}
                        </Message.Item>
                      ))}
                    </Fragment>
                  ))}
              </Message.List>
            </Message>
          </Form>
        </Segment>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  professional_b: state.professional_b,
  organization: state.organizations,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  addProfes: data => dispatch(professional_bActions.save(data)),
  updateProfes: (data, id) =>
    dispatch(professional_bActions.save(data, id, 'POST')),
  updateListProfes: (data, id) =>
    dispatch(professional_burnActions.save(data, id, 'POST'))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAddProfesBurn)
