const initialState = {
  isFetching: false,
  isLoginError: false,
  isAuthenticated: localStorage.getItem('id_token') ? true : false,
  user: null
}

function requestLogin(creds) {
  return {
    type:'LOGIN_REQUEST',
    creds
  }
}

function loginError(mesg) {
  return {
    type:'LOGIN_ERROR',
    message:mesg
  }
}

function receiveLogin(user) {
  return {
    type:'LOGIN_RECEIVE',
    user
  }
}

export function logout() {
  localStorage.removeItem('id_token')
  return {
    type:'LOGOUT_REQUEST'
  }
}

export function login(creds) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' },
    body: JSON.stringify({
      'username':creds.username,
      'password':creds.password
    })
  }

  return dispatch => {
    dispatch(requestLogin(creds))
    return fetch('/auth/login', config)
      .then(response => response.json().then(user => ({ user, response })))
      .then(({ user, response }) =>  {
        if (!response.ok) {
          dispatch(loginError(user.message))
          // return Promise.reject(user)
        } else {
          localStorage.setItem('id_token', user.token)
          localStorage.setItem('currentrole', user.user.roles[0])
          dispatch(receiveLogin(user))
        } 
      })
      .catch(err => console.log("Error: ", err))
  }
}


export const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUIRED':
      return {
        ...state,
        isFetching:false,
        isAuthenticated:false,
        user: null
      }
    case 'LOGIN_REQUEST':
      return {
        ...state,
        isFetching:true,
        isAuthenticated:false,
        user: action.creds
      }
    case 'LOGIN_RECEIVE':
      return {
        ...state,
        isFetching:false,
        isLoginError:false,
        errorMessage:null,
        isAuthenticated:true,
        user: action.user
      }
    case 'LOGOUT_REQUEST':
      return {
        ...state,
        isFetching:false,
        isAuthenticated:false,
        user: null
      }
    case 'LOGIN_ERROR':
      return {
        ...state,
        user:null,
        isLoginError:true,
        errorMessage:action.message
      }
    default:
      return state
  }
}




