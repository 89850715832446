import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions as projectActions } from 'Redux/reducers/projects';
import { actions as userAction } from 'Redux/reducers/user'
import { Table } from 'semantic-ui-react';

class ComponentGetCount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valueCount: ''
    }
  }

  handleSelectManager = (name, value, manager) => {
    if (this.props.onChange) this.props.onChange(name, value, null, manager)
    this.setState({ valuePerson: value })
  }

  render() {
    const { name } = this.props
    return (
      <Table.Row >
        <Table.Cell textAlign='center' content={2} />
        <Table.Cell textAlign='center' content={name} />
        <Table.Cell textAlign='center' content={0} />
      </Table.Row>
    )
  }
}

const mapStateToProps = state => ({
  projects: state.projects.data,
})

const mapDispatchToProps = dispatch => ({
  listProposalWithOffice: (id) => dispatch(projectActions.fetchList({
    query: { academic_id: id },
  })),
  dataUser: (id) => dispatch(userAction.fetchList({
    query: { _id: id },
  })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentGetCount) 
