import React, { Component } from 'react'
import './SearchResult.css'
import { Button, Divider,Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'
import { localTh } from 'Utils'

class SearchBurnProjectResult extends Component {
  paging = (skip) => {
    const { burn } = this.props
    let tmp = burn.params
    tmp.skip = tmp.skip + skip
    this.props.listBurnProject(tmp)
  }

  render() {
    const { burnProjects, burn } = this.props

    let isShowPrevButton = false
    let isShowNextButton = false
    isShowPrevButton = burn && burn.params && burn.params.skip !== 0
    isShowNextButton = burn && burn.data && burn.data.length >= 5

    return (
      <div className="search-result-container">
        {burnProjects && burnProjects.map((a, i) => <div key={i} className="search-result-card">

          <div className="text">โครงการ : <span>{a.nameTh}</span></div>
          <div className="text">เลขที่โครงการเผาหัว : <span>{a.codeProject}</span></div>
          <div className="text">สำนักงาน : <span>{localTh("typeOffice",a.typeOffice)}</span></div>
          <div className="text">ปีงบประมาณ : <span>ปี {a.yearBudget}</span></div>
          <div className="text">ผู้รับผิดชอบโครงการ : <span>{a.nameAcademic}</span></div>
          {/* <Divider /> */}
          {/* <div className="text">STATUS : <Icon name='clipboard outline' circular inverted color="grey" /><span>{a.status}</span></div> */}
          <Divider />
          <Button fluid as={Link} to={"/burnproject/tapburnproject/" + a._id}>VIEW</Button>
          
        </div>)}
        {!burn.listLoading  && burnProjects && burnProjects.length === 0 &&
          <Container textAlign='center'>
            <div className="ui icon header">
              <i className="dont icon"></i>
              <h2 style={{ fontFamily: 'supermarket' }}>
                ไม่มีข้อมูลโครงการเผาหัว
                  </h2>
            </div>
          </Container>
        }
        <div className="search-result-footer">
          {isShowNextButton && <Button
            content='ถัดไป'
            floated='right'
            icon='right arrow'
            labelPosition='right'
            onClick={this.paging.bind(null, +5)} />
          }
          {isShowPrevButton && <Button
            content='ก่อนหน้า'
            floated='left'
            icon='left arrow'
            labelPosition='left'
            onClick={this.paging.bind(null, -5)} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  burn: state.burnprojects,
  burnProjects: state.burnprojects.data
});

const mapDispatchToProps = dispatch => ({
  addBurnProject: (data) => dispatch(burnProjectAction.save(data)),
  updateBurnProject: (data, id) => dispatch(burnProjectAction.save(data, id, 'POST')),
  listBurnProject: (query) => dispatch(burnProjectAction.fetchList({ ...query }))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBurnProjectResult);

