var RegisterConstraints = {
  user: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  title: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  firstname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  firstnameEN: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastnameEN: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  tel: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  fax: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  workGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var updateUserConstraints = {
  title: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  firstname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  firstnameEN: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastnameEN: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  tel: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  fax: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  workGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var updateUserConditionConstraints = {
  titleOther: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  firstname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  firstnameEN: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastnameEN: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  tel: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  fax: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  workGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var RegisterConditionConstraints = {
  user: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  titleOther: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  firstname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  firstnameEN: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastnameEN: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  tel: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  fax: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  workGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}


let constraints = {
  RegisterConstraints: RegisterConstraints,
  RegisterConditionConstraints: RegisterConditionConstraints,
  updateUserConditionConstraints: updateUserConditionConstraints,
  updateUserConstraints: updateUserConstraints
}


module.exports = { constraints }
