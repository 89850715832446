import React, { Component } from 'react'
import Sidebar from 'Containers/Sidebar'
import { Route, HashRouter, Switch } from 'react-router-dom'
import { Tab, Segment } from 'semantic-ui-react'
import './Drashbroad.css'
import {
  TableOffice,
  TablePersonOffice,
  TableProject,
  TableProgressMoney,
  TableYear,
  TableStatus,
  GraphProvince,
  GraphTargetGroup,
  TableBorrow,
  ListProjectSendToAX,
  TableProgressMoneysplit
} from 'Containers/GraphMap'
import { connect } from 'react-redux'

class Drashbroad extends Component {
  render () {
    const { role } = this.props
    if(role !== 'Admin' && role !== 'Contract') {
      window.location.href = '/'
    }
    const panes = [
      {
        menuItem: { content: 'สำนัก กสศ.', icon: 'home', key: 0 },
        render: () => (
          <Tab.Pane attached={false}>
            <TableOffice {...this.props} />
          </Tab.Pane>
        )
      },
      {
        menuItem: { content: 'ปีงบประมาณ', icon: 'money', key: 1 },
        render: () => (
          <Tab.Pane attached={false}>
            <TableYear />
          </Tab.Pane>
        )
      },
      {
        menuItem: { content: 'สถานะโครงการ', icon: 'file outline', key: 2 },
        render: () => (
          <Tab.Pane attached={false}>
            <TableStatus />
          </Tab.Pane>
        )
      },
      {
        menuItem: {
          content: 'พื้นที่การดำเนินงานทั้งหมด',
          icon: 'truck',
          key: 3
        },
        render: () => (
          <Tab.Pane attached={false}>
            <GraphProvince />
          </Tab.Pane>
        )
      },
      {
        menuItem: { content: 'กลุ่มเป้าหมาย', icon: 'target', key: 4 },
        render: () => (
          <Tab.Pane attached={false}>
            <GraphTargetGroup />
          </Tab.Pane>
        )
      }
      // {
      //   menuItem: { content: 'โครงการที่รับทุนโดย กสศ.', icon: 'target', key: 5 },
      //   render: () => <Tab.Pane attached={false}><TableProject /></Tab.Pane>
      // },
      // {
      //   menuItem: { content: 'อัปเดตข้อมูลงวดเงิน', icon: 'target', key: 6 },
      //   render: () => <Tab.Pane attached={false}><ManageDB /></Tab.Pane>
      // }
    ]

    return (
      <Sidebar {...this.props}>
        <HashRouter>
          <Switch>
            <Route
              path='/graph/search'
              component={() => (
                <div>
                  <div className={'graph full'}>
                    <div className={'right full'}>
                      <Tab
                        color='red'
                        panes={panes}
                        onTabChange={this.handleTabChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
            <Route
              path='/graph/graphPersonOffice'
              component={() => (
                <div>
                  <div className={'graph full'}>
                    <div className={'right full'}>
                      <Segment>
                        <TablePersonOffice {...this.props} />
                      </Segment>
                    </div>
                  </div>
                </div>
              )}
            />
            <Route
              path='/graph/conclude'
              component={() => (
                <div>
                  <div className={'graph full'}>
                    <div className={'right full'}>
                      <Segment>
                        <TableProject {...this.props} />
                      </Segment>
                    </div>
                  </div>
                </div>
              )}
            />
            <Route
              path='/graph/checkdata'
              component={() => (
                <div>
                  <div className={'graph full'}>
                    <div className={'right full'}>
                      <Segment>
                        <ListProjectSendToAX {...this.props} />
                      </Segment>
                    </div>
                  </div>
                </div>
              )}
            />
            <Route
              path='/graph/progress'
              component={() => (
                <div>
                  <div className={'graph full'}>
                    <div className={'right full'}>
                      <Segment>
                        <TableProgressMoney {...this.props} />
                      </Segment>
                    </div>
                  </div>
                </div>
              )}
            />
            <Route
              path='/graph/progress_split'
              component={() => (
                <div>
                  <div className={'graph full'}>
                    <div className={'right full'}>
                      <Segment>
                        <TableProgressMoneysplit {...this.props} />
                      </Segment>
                    </div>
                  </div>
                </div>
              )}
            />
            <Route
              path='/graph/borrow'
              component={() => (
                <div>
                  <div className={'graph full'}>
                    <div className={'right full'}>
                      <Segment>
                        <TableBorrow {...this.props} />
                      </Segment>
                    </div>
                  </div>
                </div>
              )}
            />
            {/* TableProgressMoney */}
          </Switch>
        </HashRouter>
      </Sidebar>
    )
  }
}

const mapStateToProps = state => ({
  role: state.role
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Drashbroad)
