const PromiseConstraints = {
  codeProject: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  typeProject: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  witness1: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  witness2: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  budget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  typePay: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  sendSignedContract: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // withdrawBank: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  // withdrawBankBranch: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  // withdrawAccountNumber: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  //   numericality: {
  //     message: '^ต้องเป็นตัวเลข'
  //   },
  // },
  dateContract: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // codeDisburse:{
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  askBudget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  copyright: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // dateScreen: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // }
}

const PromiseConfirmConstraints = {
  codeProject: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  typeProject: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  witness1: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  witness2: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  budget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  typePay: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  sendSignedContract: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  withdrawBank: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  withdrawBankBranch: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  withdrawAccountNumber: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
    numericality: {
      message: '^ต้องเป็นตัวเลข'
    },
  },
  dateContract: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // codeDisburse:{
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  askBudget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  copyright: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // dateScreen: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // }
}

var PromiseArrayConstraints = {
  content: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // mainCom: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  // manage: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}

let constraints = {
  PromiseConstraints,
  PromiseConfirmConstraints,
  PromiseArrayConstraints: PromiseArrayConstraints
}


module.exports = { constraints }