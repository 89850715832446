var BorrowConstraints = {
  objective: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  place: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  burnProject: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  borrowDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valuePlan: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueActivity: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  yearBudget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // sendtoERP:{
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}

var BorrowConditionsConstraints = {
  objective: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  place: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  nameAccount: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  bank: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  branch: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  noAccount: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  borrowDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valuePlan: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueActivity: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  yearBudget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // sendtoERP:{
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}

var BorrowExConstraints = {
  objective: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  place: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  burnProject: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  borrowDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  academicID: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valuePlan: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueActivity: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  yearBudget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // sendtoERP:{
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}

var BorrowConditionsExConstraints = {
  objective: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  place: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  academicID: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  nameAccount: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  bank: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  branch: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  noAccount: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  borrowDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valuePlan: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueActivity: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  yearBudget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // sendtoERP:{
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}

var borrowArrayConstraints = {
  content: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  total: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var borrowMoneyConstraints = {
  content: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}



let constraints = {
  BorrowConstraints: BorrowConstraints,
  borrowArrayConstraints: borrowArrayConstraints,
  BorrowConditionsConstraints: BorrowConditionsConstraints,
  borrowMoneyConstraints: borrowMoneyConstraints,
  BorrowExConstraints: BorrowExConstraints,
  BorrowConditionsExConstraints: BorrowConditionsExConstraints,
}


module.exports = { constraints }
