import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Select } from 'semantic-ui-react'

let manager

class PersonDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valuePerson:''
    }
  }

  handleSelectManager = (name, value, manager) => {
    if (this.props.onChange) this.props.onChange(name, value, null, manager)
    this.setState({valuePerson:value})
  }

  render() {
    const { people, disabled } = this.props

    let options = people.map((item, idx) => ({
      key: idx,
      text: item.title ==='อื่นๆ'? item.otherTitle + item.firstname + ' ' + item.lastname : item.title + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    manager = people.map((item, idx) => ({
      id: item._id,
      name: item.title ==='อื่นๆ'? item.otherTitle + item.firstname + ' ' + item.lastname : item.title + item.firstname + ' ' + item.lastname,
    }))

    return (
      <Select
        options={options}
        fluid
        manager={manager}
        name={this.props.name}
        value={this.props.value}
        disabled={disabled}
        search
        selection
        // onChange={this.props.onChange}
        onChange={this.handleSelectManager}
        onSearchChange={this.props.onSearchChange}
      />
    )
  }
}

const mapStateToProps = state => ({
  people: state.individuals_w.data
})

export default connect(mapStateToProps)(PersonDropdown)
