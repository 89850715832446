import React, { Component } from 'react'
import './Sidebar.css'
import { Menu, Icon, Button, Popup } from 'semantic-ui-react'
import SidebarP from "react-sidebar"
import { sidebarState } from 'Redux/actions'
import { connect } from 'react-redux'

const mql = window.matchMedia(`(min-width: 800px)`)


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: true
    }
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open }, this.onChange);
  }

  mediaQueryChanged = () => {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false }, this.onChange);
  }

  onChange = () => {
    const { sidebarOpen, sidebarDocked } = this.state
    this.props.sidebarState(sidebarOpen && sidebarDocked)
  }

  onHideSidebar = () => {
    this.setState({ sidebarDocked: false, sidebarOpen: false }, this.onChange);
  }

  onShowSidebar = () => {
    this.setState({ sidebarDocked: true, sidebarOpen: true }, this.onChange);
  }

  getList = (sub) => {
    let menu = [
      { text: 'หน้าหลัก', icon: 'home', link: '/' },
      { text: 'สำนัก กสศ.', icon: 'file outline', link: '/graphoffice' },
      { text: 'ปีงบประมาณ', icon: 'file outline', link: '/graphYear' },
      { text: 'สถานะโครงการ', icon: 'file outline', link: '/graphStatus' },
      { text: 'พื้นที่การดำเนินงานทั้งหมด', icon: 'file outline', link: '/graphProvince' },
      { text: 'กลุ่มเป้าหมาย', icon: 'file outline', link: '/graphTargetGroup' }
    ]
    if (sub.toLowerCase() === 'graphpersonoffice') {
      menu = [
        { text: 'หน้าหลัก', icon: 'home', link: '/' },
        { text: 'สำนัก กสศ.', icon: 'file outline', link: '/graphoffice' },
        { text: 'บุคลากรสำนัก', icon: 'home', link: '/graphPersonOffice' }
      ]
      return menu
    } else {
      return menu
    }
  }

  onMenuClick = (m) => {
    if (m === '/') return this.props.history.push(m)
    else return this.props.history.push('/graph' + m)
    // const path = '/admin' + m
    // if (m === '/') return this.props.history.push(m)
    // if (m === '/fundings') {
    //   return this.props.match.params.sub !== 'fundings' && this.props.history.push(path)
    // }
    // if (m === '/register') {
    //   return this.props.match.params.sub !== 'register' && this.props.history.push(path)
    // }
    // if (m === '/stepaddprofesburn') {
    //   return this.props.match.params.sub !== 'stepaddprofesburn' && this.props.history.push(path)
    // }
  }

  render() {
    const { sidebarOpen, sidebarDocked } = this.state
    const { match } = this.props
    const list = this.getList(match.params.sub)
    return (
      <div className="sidebar-container">
        <SidebarP
          sidebar={
            <div className="project-sidebar">
              <Button floated="right" icon='angle double left' onClick={this.onHideSidebar} />
              <br />
              <br />
              {list.map((m, i) => <Menu vertical fluid key={i}>
                <Menu.Item color='green' active={match.params.sub === m.link.replace('/', '')}
                  onClick={this.onMenuClick.bind(this, m.link)}>
                  {m.text}<Icon name={m.icon} /></Menu.Item>
              </Menu>)}
            </div>
          }
          open={sidebarOpen}
          docked={sidebarDocked}
          styles={{ sidebar: { marginTop: 60, width: 250 }, content: { marginTop: 60 } }}
          onSetOpen={this.onSetSidebarOpen}>
          {!sidebarOpen && !sidebarDocked && <div className="sidebar-button">
            <Button icon='angle double right' onClick={this.onShowSidebar} />
            {list.map((m, i) =>
              <Popup key={i}
                content={m.text}
                trigger={<Button basic icon={m.icon} onClick={this.onMenuClick.bind(this, m.link)} />} />
            )}
          </div>}
          <div className="sidebar-content">
            {this.props.children}
          </div>
        </SidebarP>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tor: state.tors.currentData,
  project: state.projects.currentData,
  sidebar: state.sidebar
})

const mapDispatchToProps = dispatch => ({
  sidebarState: (isOpen) => dispatch(sidebarState(isOpen))
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
