import React, { Component, Fragment } from 'react'
import validatejs from 'validate.js'
import {
  Form,
  Button,
  Header,
  Input,
  Select,
  Message,
  Segment,
  TextArea,
  Transition,
  Icon
} from 'semantic-ui-react'

import { localTh } from 'Utils'
import { FormSearch } from 'Utils'
import { connect } from 'react-redux'
import { actions as userActions } from 'Redux/reducers/user'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { constraints as updateUserConstraints } from '../containers/Register/RegisterConstraints'
import { constraints as updateUserConditionConstraints } from '../containers/Register/RegisterConstraints'

const titleOptions = [
  { value: 'นาย', text: 'นาย' },
  { value: 'นาง', text: 'นาง' },
  { value: 'นางสาว', text: 'นางสาว' },
  { value: 'อื่นๆ', text: 'อื่นๆ(ระบุ)' },
]

class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      onSaveSuccess: false,
      query: {
        sort: { user: 1 },
        limit: 5,
        skip: 0,
      }
    };
  }

  componentDidMount = () => {
    const { userID } = this.props
    this.props.getUser(userID)
    this.props.getTypeProject('071f9ae0d12f11e89e8a73d5ec6ec9ef')
  }

  _renderFormField = (content) => {
    const { validate } = this.state;
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state

    validate.attrs = {};
    validate.error = false

    data[name] = value
    this.setState({ data })

  }

  validateCid = (value) => {
    if (value === null) return '^กรุณากรอกเลขบัตรประชาชน'
    else if (value.length !== 13) return '^ต้องมี 13 หลัก'
    else {
      var num = value.replace(/-/g, '');
      let digits = num.split("")
      // .map((st) => parseInt(st));
      if (digits[0] > 0 || digits[0] < 9) {
        let result = 0;
        for (var i = 0; i < 12; i++) {
          result += digits[i] * (13 - i)
        }
        result = (11 - (result % 11)) % 10;
        if (result !== parseInt(digits[12], 10)) {
          return 'เลขบัตรประชาชนไม่ถูกต้อง'
        }
      } else if (value[0] === 'P') return null
    }
  }

  handleSubmit = () => {
    let { data, validate } = this.state
    let { user, userID } = this.props

    if (user) {
      data = { ...user, ...data }
    }

    validate.attrs = {};

    if (data.title === 'อื่นๆ') {
      validate['validateError'] = validatejs(data, updateUserConditionConstraints.updateUserConditionConstraints)
    } else {
      validate['validateError'] = validatejs(data, updateUserConstraints.updateUserConstraints)
    }

    if (validate['validateError'] === undefined) {
      validate['validateError'] = {}
    }

    if (!data.cid) {
      validate['validateError'].cid = ['กรุณาใส่เลขบัตรเป็นตัวเลข']
    } else {
      if (data.cid.length !== 13) {
        validate['validateError'].cid = ['กรุณาใส่ให้ครบ 13 หลัก']
      } else {
        var regx = /^[0-9]*$/
        const check = data.cid.match(regx)
        if (check !== null) {
          let validCID = this.validateCid(data.cid)
          if (validCID !== undefined) {
            validate['validateError'].cid = [validCID]
          }
        } else {
          validate['validateError'].cid = ['กรุณาใส่เลขบัตรเป็นตัวเลข']
        }
      }
    }

    if (data.title === 'อื่นๆ') {
      data.title = data.titleOther
    }

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      this.props.updateUser(data, userID)
      this.setState({ onSaveSuccess: true });
      setTimeout(() => {
        this.setState({ onSaveSuccess: false });
        // this.props.onCloseModal()
      }, 3000)
      validate['error'] = false
      this.setState({ validate });
    }
  }

  render() {
    let { data, validate, onSaveSuccess } = this.state
    let { user, typeProject } = this.props

    let typeOffice = typeProject ? typeProject.name === 'สังกัดสำนัก' ? typeProject.typeOffice.map((item, idx) => ({
      key: idx,
      text: item.name,
      value: item.value
    })) : [] : []

    if (user) {
      data = { ...user, ...data }
    }
    let content = {
      'title': { control: Select, options: titleOptions },
      'titleOther': { control: Input, disabled: data['title'] !== 'อื่นๆ' },
      'firstname': { control: Input },
      'lastname': { control: Input },
      'firstnameEN': { control: Input },
      'lastnameEN': { control: Input },
      'cid': { control: Input, width: 8 },
      'email': { control: Input, width: 8 },
      'tel': { control: Input, width: 4 },
      'fax': { control: Input, width: 4 },
      // 'roles': { control: Select, options: rolesOptions, multiple: true },
      'workGroup': { control: Input, width: 8 },
      'typeInstitution': { control: Select, width: 8, options: typeOffice, multiple: true },
      "address": { control: TextArea },
      "addressCity": { control: FormSearch }
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('register', key);
      content[key].name = key;
      if (key === 'typeInstitution') content[key].value = data[key] || [];
      else content[key].value = data[key] || '';
      content[key].onChange = this.handleChange;
    });

    return (
      <Fragment>
        <Segment>
          <Form error={validate.error}>
            <Header style={{ fontFamily: 'supermarket' }} content={'ข้อมูลของ User : ' + data.user} as='h4' icon='newspaper' />
            <Form.Group widths='equal'>
              {this._renderFormField(content.title)}
              {this._renderFormField(content.titleOther)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.firstname)}
              {this._renderFormField(content.lastname)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.firstnameEN)}
              {this._renderFormField(content.lastnameEN)}
            </Form.Group>
            {this._renderFormField(content.cid)}
            {this._renderFormField(content.address)}
            {this._renderFormField(content.addressCity)}
            <Form.Group >
              {this._renderFormField(content.email)}
              {this._renderFormField(content.tel)}
              {this._renderFormField(content.fax)}
            </Form.Group>
            <Form.Group >
              {this._renderFormField(content.workGroup)}
              {this._renderFormField(content.typeInstitution)}
            </Form.Group>
            <Button style={{ fontFamily: 'supermarket' }} color='green' content='แก้ไข' onClick={this.handleSubmit} />
            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
              <Message.List>
                {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}> {localTh('register', key)} - {item} </Message.Item>
                    ))}
                  </Fragment>
                ))}
              </Message.List>
            </Message>
          </Form>
        </Segment>

        <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>แก้ไขข้อมูลสำเร็จ</Message.Header>
          </Message>
        </Transition>
        {/* </div> */}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.currentData,
  typeProject: state.typeproject.currentData
});

const mapDispatchToProps = dispatch => ({
  updateUser: (data, id) => dispatch(userActions.save(data, id, 'POST')),
  getUser: (id) => dispatch(userActions.fetchOne(id)),
  getTypeProject: (id) => dispatch(typeProject.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser)