import React, { Component, Fragment } from 'react';
import { Form, Button, Header, Message, Label, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { localTh } from 'Utils'
import { actions as typeProject } from 'Redux/reducers/typeproject'


class ListTypeProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      cid: undefined,
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      selected: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentOrganization) {
      let tmp = { ...nextProps.currentOrganization }
      let { data } = this.state;
      this.setState({ data: { ...tmp, ...data } });
    }
    this.props.getTypeProject('584ccec0e1e111e889cf8170c54de9cb')
  }

  handleEditRow = (idx, e, { name, value }) => {
    let { data } = this.state;
    let { typeProject } = this.props;
    if (typeProject) data = { ...typeProject, ...data };

    data.type[idx][name] = value;

    this.setState({ data });
  }

  handleAddRow = () => {
    let { data } = this.state;
    let { typeProject } = this.props;
    if (typeProject) data = { ...typeProject, ...data };

    if (data.type.length < 9) {
      data.type.push({ name: '(โปรดใส่คำอธิบาย)', value: data.type.length });
    }
    this.setState({ data })
  }

  handleSubmit = () => {
    let { data } = this.state;
    let { typeProject } = this.props;

    if (typeProject) data = { ...typeProject, ...data };

    this.props.updateTypeProject(data, data._id)
    this.props.onClose()
  }

  handleSelectRow = (selected) => {
    if (selected === this.state.selected) selected = null
    this.setState({ selected })
  }

  render() {
    let { validate, data, selected } = this.state
    let { typeProject } = this.props;

    if (typeProject) data = { ...typeProject, ...data };
    // console.log(data)
    return (
      <Fragment>
        <Header style={{ fontFamily: 'supermarket' }} content='รายชื่อประเภทโครงการ' />
        <Form error={validate.error}>
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content='ลำดับ/รหัสประเภท' width={3} textAlign='center' />
                <Table.HeaderCell content='คำอธิบาย' width={13} textAlign='center' />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.type && data.type.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx)}>
                    {(selected === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center'> {(idx + 1)}</Table.Cell>}
                    <Table.Cell content={item.name} />
                  </Table.Row>
                  {selected === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={2}>
                        <Form.TextArea label={'คำอธิบาย ' + (idx + 1)} name='name' placeholder='(โปรดใส่คำอธิบาย)'
                          value={item.name} onChange={this.handleEditRow.bind(this, idx)} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell >
                  <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus' name='objective' onClick={this.handleAddRow} />
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Footer>
          </Table>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {/* <Message.Item >{'เลขบัตรประชาชน - ' + cid}</Message.Item> */}
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('organization', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
          <Button onClick={this.handleSubmit} style={{ fontFamily: 'supermarket' }} color='blue' disabled={validate.error}>บันทึก</Button>
        </Form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  typeProject: state.typeproject.currentData
});

const mapDispatchToProps = dispatch => ({
  updateTypeProject: (data, id) => dispatch(typeProject.save(data, id, 'POST')),
  getTypeProject: (id) => dispatch(typeProject.fetchOne(id))
  // listOrganization: (id) => dispatch(organizationActions.fetchOne(id)),
  // updateForm: (data) => dispatch(formActions.update(data, PjmConstraints.PjmConstraints))
})


export default connect(mapStateToProps, mapDispatchToProps)(ListTypeProject);