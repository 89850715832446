import React, { Component, Fragment } from 'react'
// import validatejs from 'validate.js'
import {
  Form,
  Button,
  Input,
  Modal,
  Table,
  Menu,
  Segment,
  Dropdown
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as receiptsActions } from 'Redux/reducers/receipts'
import AddBill from './AddBill'


const rolesOptions = [
  { value: 'All', text: 'ทั้งหมด' },
  { value: 'Academic', text: 'นักวิชาการ' },
  { value: 'Contract', text: 'ศูนย์สัญญา' },
  { value: 'Staff', text: 'เจ้าหน้าที่' },
  { value: 'Admin', text: 'ผู้ดูแลระบบ' },
  { value: "auditor", text: "ผู้ตรวจสอบบัญชี" }
]


class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    const { match } = this.props
    let query = {}
    if (match) {
      query = {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: { borrow_id: match.params.id }
      }
    }
    this.props.listReceipts(query)
    this.setState({ query: query })
  }

  // handleChange = (e, { name, value }) => {
  //   let { data, validate } = this.state

  //   validate.attrs = {};
  //   validate.error = false

  //   data[name] = value
  //   this.setState({ data })

  // }


  // handleSubmit = () => {
  //   const { data, validate } = this.state

  //   validate.attrs = {};
  //   if (data.title === 'อื่นๆ') {
  //     validate['validateError'] = validatejs(data, RegisterConditionConstraints.RegisterConditionConstraints)
  //   } else {
  //     validate['validateError'] = validatejs(data, RegisterConstraints.RegisterConstraints)
  //   }

  //   if (data.title === 'อื่นๆ') {
  //     data.title = data.titleOther
  //   }

  //   if (Object.keys(validate['validateError']).length !== 0) {
  //     validate['error'] = true
  //     Object.keys(validate['validateError']).forEach((item) => {
  //       validate.attrs[item] = true;
  //     })
  //     this.setState({ validate });
  //   } else {
  //     if (data.password) {
  //       data['password'] = passwordHash.generate(data.password);
  //     }
  //     // this.props.addUser(data)
  //     this.setState({ onSaveSuccess: true });
  //     setTimeout(() => {
  //       this.setState({ onSaveSuccess: false });
  //     }, 3000)
  //     validate['error'] = false
  //     this.setState({ validate });
  //   }
  // }

  // onSearch = (e, { value }) => {
  //   const { query } = this.state

  //   query['query'] = {}
  //   if ((value.toLowerCase()).match(/^[a-z]/)) {
  //     query['query']['user'] = {}
  //     query['query']['user']['$regex'] = value
  //   } else {
  //     query['query']['$or'] = []
  //     query['query']['$or'].push({ 'firstname': { '$regex': value } })
  //     query['query']['$or'].push({ 'lastname': { '$regex': value } })
  //     query['query']['$or'].push({ 'workGroup': { '$regex': value } })
  //     // query['query']['$or']['firstname']['$regex'] = value
  //   }
  //   if (value.length >= 3) {
  //     this.props.listUser(query)
  //     // this.setState({ search: search })
  //   } else if (value.length === 0) {
  //     query['query'] = {}
  //     this.props.listUser(query)
  //     // this.setState({ search: search })
  //   }
  // }

  // handleFilter = (e, { value }) =>{
  //   const { query } = this.state

  //   if(value==='All') value =''

  //   query['query'] = {}
  //   query['query']['roles'] = {}
  //   query['query']['roles']['$regex'] = value
  //   this.props.listUser(query)
  // }

  // paging = (skip) => {
  //   let { query } = this.state
  //   query.skip = query.skip + skip
  //   this.props.listUser(query)
  //   this.setState({ query })
  // }

  handleReceipt = (item) => {

    this.props.history.push('/borrow/bill/' + item._id);
  }

  handleAddReceipt = () => {
    this.setState({ modalAddReceipt: true })
  }

  handleCloseModalReceipt = () => {
    this.setState({ modalAddReceipt: false })
  }

  render() {
    const { receipts, match } = this.props
    const { modalAddReceipt } = this.state

    return (
      <Fragment>
        <Segment>
          <Menu secondary >
            <Menu.Item fitted >
              <Input onChange={this.onSearch} icon='search' placeholder={'USERNAME หรือ ชื่อ..'} />
            </Menu.Item>
            <Menu.Item fitted>
              <Form>
                <Dropdown
                  placeholder='กรองข้อมูล'
                  icon='filter'
                  labeled
                  button
                  className='icon search-dropdown'
                  onChange={this.handleFilter}
                  options={rolesOptions} />
              </Form>
            </Menu.Item>
            <Menu.Item fitted>
            </Menu.Item>
          </Menu>
        </Segment>
        {receipts && receipts.length === 0 &&
          <Segment className="ui placeholder segment" >
            <div className="ui icon header">
              <i className="dont icon"></i>
              <h2 style={{ fontFamily: 'supermarket' }}>
                ไม่มีข้อมูลใบสำคัญรับเงิน
              </h2>
              <Button content='เพิ่ม' color='blue' onClick={this.handleAddReceipt} />
            </div>
          </Segment>
        }
        {receipts && receipts.length !== 0 &&
          <Table selectable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width='1'>ลำดับ</Table.HeaderCell>
                <Table.HeaderCell width='6'>ชื่อ-นามสกุล</Table.HeaderCell>
                <Table.HeaderCell>ที่อยู่</Table.HeaderCell>
                <Table.HeaderCell width='2'>จำนวนเงิน</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {receipts.map((item, idx) =>
                <Table.Row key={idx} onClick={this.handleReceipt.bind(this, item)}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <Table.Cell>{item.title + ' ' + item.firstname + ' ' + item.lastname}</Table.Cell>
                  <Table.Cell>{item.billAddressNo + ' ตำบล ' + item.billAddress.tumbon + ' อำเภอ ' + item.billAddress.city + ' จังหวัด ' + item.billAddress.province + ' ' + item.billAddress.zipcode}</Table.Cell>
                  <Table.Cell>{item.sumListBill || 0}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            <Table.Footer fullWidth >
              <Table.Row>
                <Table.HeaderCell colSpan='2'><Button content='เพิ่ม' onClick={this.handleAddReceipt} /></Table.HeaderCell>
                <Table.HeaderCell colSpan='2' textAlign='right'>
                  {receipts.params && receipts.params.skip !== 0 &&
                    <Button content='ย้อนกลับ' onClick={this.paging.bind(null, -10)} />
                  }
                  {
                    receipts && receipts.length >= 10 && receipts.length !== 0 &&
                    <Button content='ถัดไป' onClick={this.paging.bind(null, +10)} />
                  }
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        }
        {<Modal
          open={modalAddReceipt}
          onClose={this.handleCloseModalReceipt}
          closeOnDimmerClick={false}
          size='small'
          closeIcon>
          <Modal.Content>
            <AddBill match={match} onCloseModal={this.handleCloseModalReceipt} />
          </Modal.Content>
        </Modal>}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  receipts: state.receipts.data
  // users: state.user.data
});

const mapDispatchToProps = dispatch => ({
  // addUser: (data) => dispatch(userActions.save(data)),
  listReceipts: (query) => dispatch(receiptsActions.fetchList(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
