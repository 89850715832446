import React, { Component } from 'react'
import './SearchBorrow.css'
import jwt from 'jsonwebtoken'
import { Button, Input, Dropdown, Menu, Modal, Icon, Popup, Form, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { changeSearch } from 'Redux/actions'
import { DatePicker as DayPicker, UTC, ListBorrowDelete } from 'Utils'
import { actions as borrowActions } from 'Redux/reducers/scholar'
import { AddBorrow, AddBorrowEx } from '../Borrow'
import { generate } from 'Redux/reducers/counting'

const sortBorrow = [
  { key: '01', value: 'codeBorrow', text: 'เลขที่ยืมเงินทดรองจ่าย' },
  { key: '02', value: 'borrower', text: 'ชื่อผู้ขอยืมเงิน' },
  { key: '03', value: 'startDate', text: 'วันเริ่มต้นโครงการ' }
]

const filterBorrow = [
  { key: '00', value: 'ทั้งหมด', text: 'ทั้งหมด' },
  { key: '01', value: 'ทำเรื่องขอเบิกเงิน', text: 'ทำเรื่องขอเบิกเงิน' },
  { key: '02', value: 'ทำเรื่องขอคืนเงิน', text: 'ทำเรื่องขอคืนเงิน' }
]

class SearchBorrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTorOpen: false,
      modalAddExOpen: false,
      closeOnDimmerClick: false,
      inputText: '',
      filter: {},
    };
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  componentDidMount() {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (role === 'Academic') {
      // search.filter = {}
      search.filter = { academicID: user._id, otherAcademicID: user._id }
    } else {
      search.filter = {}
    }
    search.sortBy = "codeBorrow"
    search.sortValue = "ascendant"
    search.type = 'borrow'
    this.setState({ inputText: '' })


    let queryborrow = {
      sort: { nameTh: 1 },
      limit: 5,
      skip: 0,
      query: {
        $or: [
          { academicID: user._id },
          { otherAcademicID: user._id }
        ],
        delete: { $ne: true }
      }
    }

    this.props.changeSearch(search)
    if (role === 'Academic' || role === 'Staff') {
      this.props.listBorrow(queryborrow)
    } else {
      this.props.firstlistBorrow()
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { scholar, auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (scholar.params) {
      if (!scholar.params.query) {
        let queryBorrow = {
          sort: { nameTh: 1 },
          limit: 5,
          skip: 0,
          query: {
            $or: [
              { academicID: user._id },
              { otherAcademicID: user._id }
            ],
            delete: { $ne: true }
          }
        }
        if (nextProps.role === 'Academic' || nextProps.role === 'Staff') {
          this.props.listBorrow(queryBorrow)
        }
      }
    }
  }

  refreshCard = () => {
    const { scholar, auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (scholar.params) {
      let queryBorrow = {
        sort: { nameTh: 1 },
        limit: 5,
        skip: 0,
        query: {
          $or: [
            { academicID: user._id },
            { otherAcademicID: user._id }
          ],
          delete: { $ne: true }
        }
      }
      if (role === 'Academic' || role === 'Staff') {
        this.props.listBorrow(queryBorrow)
      }
    }
  }

  toggleSortValue = () => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (role === 'Academic') {
      search.filter = { academicID: user._id, otherAcademicID: user._id }
      // search.filter = {}
    } else {
      search.filter = {}
    }

    search.sortValue = search.sortValue === 'ascendant' ? 'descendant' : 'ascendant'
    this.onChange(search)
  }

  filterChange = (e, { value }) => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (role === 'Academic') {
      search.filter = { academicID: user._id, otherAcademicID: user._id }
      // search.filter = {}
    } else {
      search.filter = {}
    }

    search.filter.status = value
    if (value === 'ทั้งหมด' && search.filter.status) delete search.filter.status
    this.onChange(search)
  }

  sortChange = (e, { value }) => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (role === 'Academic') {
      search.filter = { academicID: user._id, otherAcademicID: user._id }
      // search.filter = {}
    } else {
      search.filter = {}
    }
    search.sortBy = value
    this.onChange(search)
  }

  onChange = (search) => {
    const { scholar, role, auth } = this.props
    search.type = 'borrow'
    let tmp = scholar.params
    tmp.sort = { [search.sortBy]: (search.sortValue === 'ascendant' ? 1 : -1) }
    // filter by statys
    if (search.filter && search.filter.status) tmp.query = { '$and': [{ 'status': search.filter.status }] }
    else {
      if (!tmp.query) {
        tmp.query = {}
      }
    }
    // filter by another value
    search.filter && Object.keys(search.filter).forEach((key) => {
      if (key !== 'status') {
        if (key === 'startDate') {
          if (!tmp.query.$and) tmp.query = { '$and': [] }
          tmp.query.$and.push({ [key]: { $gte: search.filter[key] } })
        }
        else if (key === 'endDate') {
          if (!tmp.query.$and) tmp.query = { '$and': [] }
          tmp.query.$and.push({ [key]: { $lte: search.filter[key] } })
        }
        else if (key === 'academicID' || key === 'otherAcademicID') {
          if (!tmp.query.$or) tmp.query = { '$or': [] }
          tmp.query.$or.push({ [key]: { $regex: search.filter[key], $options: "i" } })
        }
        else {
          if (!tmp.query.$and) tmp.query = { '$and': [] }
          tmp.query.$and.push({ [key]: { $regex: search.filter[key], $options: "i" } })
        }
      }
    })
    if (!tmp.query.$and && !tmp.query.$or) tmp.query = {}

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }


    if (role === 'Academic') {
      if (!tmp.query.$or) tmp.query.$or = []
      tmp.query.$or = [{ academicID: user._id }, { otherAcademicID: user._id }]
    }

    tmp.query.delete = { $ne: true }

    // fetch data and set property
    this.props.listBorrow(tmp)
    this.props.changeSearch(search)
  }

  onTextChange = (e, { name, value }) => {
    const { auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    this.setState({ inputText: value })
    let search = JSON.parse(JSON.stringify(this.props.search))
    if (value.length >= 3) {
      search.filter[name] = value
      this.onChange(search)
    } else if (value.length === 0) {
      delete search.filter[name]
      search.filter['academicID'] = user._id
      // search.filter = {}
      this.onChange(search)
    }
  }

  inputTextChange = (e, { name, value }) => {
    const filter = { ...this.state.filter }
    filter[name] = value
    if (value === 'ทั้งหมด' || value === '') delete filter[name]
    this.setState({ filter })
  }

  handleDayChange = (name, index, date) => {
    const utc7 = UTC.getUTC7(date._d)
    const filter = { ...this.state.filter }
    filter[name] = utc7.getTime()
    this.setState({ filter })
  }

  handleClearSearch = () => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    let search = JSON.parse(JSON.stringify(this.props.search))

    if (role === 'Academic') {
      search.filter = { academicID: user._id, otherAcademicID: user._id }
      // search.filter = {}
    } else {
      search.filter = {}
    }

    this.setState({ activeAdvance: false })
    this.onChange(search)
  }


  advanceSearch = () => {
    const { role } = this.props
    return <Form>
      <Form.Field>
        <Input fluid labelPosition='left' type='text' name="valueGroup" onChange={this.inputTextChange}>
          <Label basic className="search-modal-label">กลุ่มแผนงาน</Label>
          <input />
        </Input>
      </Form.Field>
      <Form.Field>
        <Input fluid labelPosition='left' type='text' name="valuePlan" onChange={this.inputTextChange}>
          <Label basic className="search-modal-label">แผนงาน</Label>
          <input />
        </Input>
      </Form.Field>
      <Form.Field>
        <Input fluid labelPosition='left' type='text' name="valueActivity" onChange={this.inputTextChange}>
          <Label basic className="search-modal-label">กลุ่มกิจกรรม</Label>
          <input />
        </Input>
      </Form.Field>
      <Form.Field>
        <Input fluid labelPosition='left' type='text' name="objective" onChange={this.inputTextChange}>
          <Label basic className="search-modal-label">จุดประสงค์</Label>
          <input />
        </Input>
      </Form.Field>
      {role === 'Admin' && <Form.Field>
        <Input fluid labelPosition='left' type='text' name="academic" onChange={this.inputTextChange}>
          <Label basic className="search-modal-label">ชื่อผู้ยืมเงิน</Label>
          <input />
        </Input>
      </Form.Field>}
      <Form.Field>
        <Form.Group inline>
          <label>ช่วงเวลา ณ วันที่</label>
          <DayPicker ser={'dateContract'} name={'startDate'}
            onDayChange={this.handleDayChange}
            data={this.state.filter.startDate || ''} />
          <label> ถึงวันที่</label>
          <DayPicker ser={'dateContract'} name={'endDate'}
            onDayChange={this.handleDayChange}
            data={this.state.filter.endDate || ''} />
        </Form.Group>
      </Form.Field>
    </Form>
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  onAdvanceSearchClick = () => {
    let { role, auth } = this.props
    let tmp = this.props.search
    let search = JSON.parse(JSON.stringify(tmp))
    search.filter = {}
    if (role !== 'Admin') {
      let user = auth.isAuthenticated && auth.user && auth.user.user
      if (!user && auth.isAuthenticated) {
        user = this.getLocalUser()
      }
      search.filter.academicID = user._id
    }
    if (tmp.filter.status) search.filter.status = tmp.filter.status
    const { filter } = this.state
    if (filter.valueGroup && filter.valueGroup.length > 0) {
      search.filter.valueGroup = filter.valueGroup
    }
    if (filter.valuePlan && filter.valuePlan.length > 0) {
      search.filter.valuePlan = filter.valuePlan
    }
    if (filter.valueActivity && filter.valueActivity.length > 0) {
      search.filter.valueActivity = filter.valueActivity
    }
    if (filter.objective && filter.objective.length > 0) {
      search.filter.objective = filter.objective
    }
    if (filter.academic && filter.academic.length > 0) {
      search.filter.academic = filter.academic
    }
    if (filter.startDate && filter.startDate !== '') {
      search.filter.startDate = filter.startDate
    }
    if (filter.endDate && filter.endDate !== '') {
      search.filter.endDate = filter.endDate
    }
    this.onChange(search)
    this.setState({ isModalOpen: false, activeAdvance: true })
  }

  openModal = () => this.setState({ isModalOpen: true, filter: {} })
  closeModal = () => this.setState({ isModalOpen: false, activeAdvance: false }, () => {

    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let tmp = this.props.search
    let search = JSON.parse(JSON.stringify(tmp))
    if (role === 'Academic') {
      search.filter = { academicID: user._id, otherAcademicID: user._id }
      // search.filter = {}
    } else {
      search.filter = {}
    }
    if (tmp.filter.status) search.filter.status = tmp.filter.status
    this.onChange(search)
  })

  handleOpenModalAdd = () => this.setState({ modalTorOpen: true, closeOnDimmerClick: false })
  handleCloseModalAdd = () => this.setState({ modalTorOpen: false, closeOnDimmerClick: true })

  handleOpenModalAddEx = () => this.setState({ modalAddExOpen: true, closeOnDimmerClick: false })
  handleCloseModalAddEx = () => this.setState({ modalAddExOpen: false, closeOnDimmerClick: true })

  genCounting = (text) => {
    this.props.genId(text)
  }

  render() {
    const isOpen = this.props.sidebar.isOpen
    const { search } = this.props

    const { isModalOpen, activeAdvance } = this.state
    return (
      <div className={"search-borrow-filter " + (isOpen ? '' : 'full')}>
        <div className={"left " + (isOpen ? '' : 'full')}>
          <Button.Group style={{ marginRight: 5 }}>
            <Button
              active={search.sortValue === 'ascendant'}
              onClick={this.toggleSortValue}
              icon='sort amount down' />
            <Button
              active={search.sortValue === 'descendant'}
              onClick={this.toggleSortValue}
              icon='sort amount up' />
            <Dropdown
              className="search-dropdown"
              placeholder='เรียงข้อมูล'
              onChange={this.sortChange}
              options={sortBorrow}
              button />
          </Button.Group>
          <Dropdown
            placeholder='กรองข้อมูล'
            icon='filter'
            labeled
            button
            className='icon search-dropdown'
            onChange={this.filterChange}
            options={filterBorrow} />
        </div>
        <div className={"right " + (isOpen ? '' : 'full')}>
          <Menu secondary>
            <Menu.Item fitted>
              <Input
                icon='search'
                value={this.state.inputText}
                name={'codeBorrow'}
                onChange={this.onTextChange}
                placeholder={'เลขที่ทดรองจ่าย...'} />
            </Menu.Item>
            <Menu.Item fitted>
              <Modal
                open={isModalOpen}
                size="small"
                trigger={<Popup trigger={<Button toggle active={activeAdvance} onClick={this.openModal} icon='cogs' />} content='ค้นหาขั้นสูง' />}
                onClose={this.onModalClose}>
                <Modal.Header style={{ fontFamily: 'supermarket' }}>ค้นหาทดรองจ่ายขั้นสูง</Modal.Header>
                <Modal.Content>
                  {this.advanceSearch()}
                </Modal.Content>
                <Modal.Actions>
                  <Button color='grey' onClick={this.closeModal}>
                    <Icon name='close' />ยกเลิก
                  </Button>
                  <Button color='green' onClick={this.onAdvanceSearchClick}>
                    <Icon name='checkmark' />ค้นหา
                  </Button>
                </Modal.Actions>
              </Modal>
            </Menu.Item>
            <Menu.Item fitted>
              <Button
                style={{ fontFamily: 'supermarket' }}
                icon='sync'
                content='ล้างค้นหา'
                onClick={this.handleClearSearch} />
            </Menu.Item>
          </Menu>
          <Menu secondary className="search-borrow-create-new">
            <Menu.Item fitted>
              <Button icon='plus' className="button" content='เพิ่มหัวข้อยืมเงินทดรองจ่าย'
                onClick={this.handleOpenModalAdd} />
            </Menu.Item>
            <Menu.Item fitted>
              <Button icon='plus' className="button" content='เพิ่มหัวข้อยืมเงินทดรองจ่าย (คนในสำนัก)'
                onClick={this.handleOpenModalAddEx} />
            </Menu.Item>
            <Menu.Item fitted>
              <Button icon='plus' className="button" content='ทดรองจ่ายที่ลบไปแล้ว'
                onClick={() => this.setState({ modalDeleteOpen: true })} />
            </Menu.Item>
          </Menu>
          {<Modal
            open={this.state.modalTorOpen}
            onClose={this.handleCloseModalAdd}
            closeOnDimmerClick={this.state.closeOnDimmerClick}
            size='large'
            closeIcon>
            <Modal.Content>
              <AddBorrow onCloseModalTor={this.handleCloseModalAdd} onRefresh={this.refreshCard} />
            </Modal.Content>
          </Modal>}
          {<Modal
            open={this.state.modalAddExOpen}
            onClose={this.handleCloseModalAddEx}
            closeOnDimmerClick={this.state.closeOnDimmerClick}
            size='large'
            closeIcon>
            <Modal.Content>
              <AddBorrowEx onCloseModalTor={this.handleCloseModalAddEx} onRefresh={this.refreshCard} />
            </Modal.Content>
          </Modal>}
          {<Modal
            style={{ marginTop: 30 }}
            open={this.state.modalDeleteOpen}
            onClose={() => {
              this.refreshCard()
              this.setState({ modalDeleteOpen: false })
            }}
            closeOnDimmerClick
            size='small'
            closeIcon>
            <Modal.Content>
              <ListBorrowDelete onCloseModalProject={() => {
                this.refreshCard()
                this.setState({ modalDeleteOpen: false })
              }
              } />
            </Modal.Content>
          </Modal>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  sidebar: state.sidebar,
  borrow: state.scholar.data,
  search: state.search,
  auth: state.auth,
  role: state.role,
  counter: state.counter
})

const mapDispatchToProps = dispatch => ({
  firstlistBorrow: () => dispatch(borrowActions.fetchList({ sort: { codeBorrow: 1 }, limit: 5, skip: 0 })),
  changeSearch: (search) => dispatch(changeSearch(search)),
  listBorrow: (query) => dispatch(borrowActions.fetchList({ ...query })),
  genId: (name) => dispatch(generate(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBorrow)