import React, { Component, Fragment } from 'react'
import {
  Table,
  Button,
  Form,
  Modal,
  Header,
  Icon,
  Confirm
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as natualPersonActions } from 'Redux/reducers/natualPerson'
import { actions as departmentActions } from 'Redux/reducers/department'
import { ComponentNatualPerson } from 'Utils'

class ListNatualPerson extends Component {
  constructor (props) {
    super(props)
    this.state = {
      query: {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: {}
      },
      openModalOrganizations: false,
      natualPersonID: ''
    }
  }

  componentDidMount = () => {
    let query = {
      sort: { firstname: 1 },
      limit: 10,
      skip: 0,
      query: {}
    }

    this.props.listIndividuals(query)
    this.setState({ query })
    window.scrollTo(0, 0)
  }

  onSearch = (e, { value }) => {
    // let { query } = this.state
    let query = {}
    if (value.length >= 3) {
      let test = value.split(' ')
      test.forEach((item, idx) => {
        if (item.length === 0) {
          test.splice(idx, 1)
        }
      })
      query['query'] = {
        firstname: { $regex: test[0] || '' },
        lastname: { $regex: test[1] || '' },
        approve: { $ne: true }
      }
      this.props.listIndividuals(query)
    } else if (value.length === 0) {
      query = {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: {  }
      }
      this.props.listIndividuals(query)
    }
    this.setState({ query })
  }

  paging = skip => {
    let { query } = this.state
    query.skip = query.skip + skip
    this.props.listIndividuals(query)
    this.setState({ query })
  }

  handleEditIndividual = idx => {
    let { individuals } = this.props
    this.setState({
      openModalIndividual: true,
      natualPersonID: individuals.data[idx]._id,
      checkIndividual: true
    })
  }

  close = () => {
    this.setState({
      openModalIndividual: false,
      checkIndividual: false,
      confirmDelPerson: false,
      natualPersonID: ''
    })
    let query = {
      sort: { firstname: 1 },
      limit: 10,
      skip: 0,
      query: {  }
    }
    this.props.listIndividuals(query)
  }

  handleAddIndividual = () => {
    this.setState({ openModalIndividual: true, checkIndividual: false })
  }

  handleDeletePerson = () => {
    const { idPerson } = this.state
    this.props.delIndividuals(idPerson)
    this.setState({ confirmDelPerson: false })
  }

  handleConfirmDeletePerson = item => {
    this.setState({ confirmDelPerson: true, idPerson: item })
  }

  render () {
    let { openModalIndividual, checkIndividual, natualPersonID } = this.state
    const { individuals } = this.props

    return (
      <Fragment>
        <Header as='h2'>
          <Icon name='users' />
          <Header.Content
            style={{ fontFamily: 'supermarket' }}
            content='รายชื่อบุคคลที่บรรจุเข้าฐานข้อมูล'
          />
        </Header>
        <Modal
          open={openModalIndividual}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon
        >
          <Modal.Header style={{ fontFamily: 'supermarket' }}>
            เพิ่ม บุคลากร
          </Modal.Header>
          <Modal.Content>
            <ComponentNatualPerson
              checkIndividual={checkIndividual}
              onCloseModalPerson={this.close}
              natualPersonID={natualPersonID}
            />
          </Modal.Content>
        </Modal>

        <Form>
          <Form.Input
            onChange={this.onSearch}
            icon='search'
            label='( ไม่ต้องใส่คำนำหน้าชื่อ )'
            placeholder={'ชื่อ - นามสกุล...'}
            width={6}
          />
        </Form>

        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell textAlign='center' width={1} content='ใหม่' /> */}
              <Table.HeaderCell
                textAlign='center'
                width={4}
                content='ชื่อ-นามสกุล'
              />
              <Table.HeaderCell
                textAlign='center'
                width={3}
                content='รหัสบัตรประชาชน'
              />
              <Table.HeaderCell
                textAlign='center'
                width={7}
                content='ที่อยู่'
              />
              {/* <Table.HeaderCell textAlign='center' width={1} content='ลบ' /> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {individuals.data.map((item, idx) => (
              <Table.Row key={idx}>
                {/* {!item.readed && (
                  <Table.Cell textAlign='center'>
                    <Icon name='warning circle' color='red' size='large' />
                  </Table.Cell>
                )}
                {item.readed === true && (
                  <Table.Cell textAlign='center'>
                    <Icon name='minus circle' size='large' />
                  </Table.Cell>
                )} */}
                <Table.Cell onClick={this.handleEditIndividual.bind(this, idx)}>
                  {((item.title === 'อื่นๆ'
                    ? item.otherTitle || ''
                    : item.title || '') ||
                    (item.title === null ? '' : item.title || '')) +
                    (item.firstname || '') +
                    ' ' +
                    (item.lastname || '')}
                </Table.Cell>
                <Table.Cell onClick={this.handleEditIndividual.bind(this, idx)}>
                  {item.cid}
                </Table.Cell>
                <Table.Cell onClick={this.handleEditIndividual.bind(this, idx)}>
                  {item.address &&
                    (item.addressNo || '') +
                      (item.address.province === null
                        ? ''
                        : ' จ. ' + item.address.province) +
                      (item.address.city === null
                        ? ''
                        : ' อ. ' + item.address.city) +
                      (item.address.tumbon === null
                        ? ''
                        : ' ต. ' + item.address.tumbon) +
                      ' ' +
                      (item.address.zipcode === null
                        ? ''
                        : item.address.zipcode)}
                </Table.Cell>
                {/* <Table.Cell textAlign='center'>
                  <Button
                    disabled={role !== 'Admin'}
                    icon='close'
                    color='red'
                    onClick={this.handleConfirmDeletePerson.bind(
                      this,
                      item._id
                    )}
                  />
                </Table.Cell> */}
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>
                <Button
                  color='green'
                  content='เพิ่มบุคลากร'
                  onClick={this.handleAddIndividual}
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='4' textAlign='right'>
                {individuals.params && individuals.params.skip !== 0 && (
                  <Button
                    content='ย้อนกลับ'
                    onClick={this.paging.bind(null, -10)}
                  />
                )}
                {individuals.data &&
                  individuals.data.length >= 10 &&
                  individuals.data.length !== 0 && (
                  <Button
                    content='ถัดไป'
                    onClick={this.paging.bind(null, +10)}
                  />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Confirm
          content='ต้องการลบใช่หรือไม่'
          open={this.state.confirmDelPerson}
          onCancel={this.close}
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onConfirm={this.handleDeletePerson}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  individuals: state.natualPerson,
  role: state.role,
  organizations: state.department.currentData
})

const mapDispatchToProps = dispatch => ({
  listIndividuals: query => dispatch(natualPersonActions.fetchList(query)),
  getIndividuals: id => dispatch(natualPersonActions.fetchOne(id)),
  delIndividuals: id => dispatch(natualPersonActions.delete(id)),
  getOrganization: id => dispatch(departmentActions.fetchOne(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListNatualPerson)
