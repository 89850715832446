import React, { Component, Fragment } from 'react'
import {
  Segment,
  Header,
  Form,
  Table,
  Select,
  Button,
  Input,
  TextArea,
  Label,
  Confirm,
  Transition,
  Icon,
  Message,
  Checkbox
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Cleave from 'cleave.js/react'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { connect } from 'react-redux'
import { constraints as Constraints } from './EndProjectConstraints'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { localTh } from 'Utils'
import { FormSearch } from 'Utils'

import { DatePicker as DayPicker } from 'Utils'
import { ThaiDate } from 'Utils'
import { AttachedComponent } from 'Utils'
import { formatcomma } from 'Utils'
import { FormSearchAddress } from 'Utils'

let gettime = ''

const otherBudget = [
  { key: '1', text: 'งบประมาน', value: 'งบประมาน' },
  { key: '2', text: 'สิ่งของอื่นๆ', value: 'สิ่งของอื่นๆ' }
]

const optionActualPeriod = [
  {
    key: '1',
    value: 'เป็นไปตามข้อเสนอโครงการ',
    text: 'เป็นไปตามข้อเสนอโครงการ'
  },
  { key: '2', value: 'ขยายเวลา', text: 'ขยายเวลา' }
]

const optionType = [
  {
    key: '1',
    value: 'เป็นไปตามข้อเสนอโครงการ',
    text: 'เป็นไปตามข้อเสนอโครงการ'
  },
  { key: '2', value: 'ส่งคืน', text: 'ส่งคืน' },
  { key: '3', value: 'ใช้เกิน', text: 'ไม่ส่งคืน' }
]

const optionTypeOther = [
  {
    key: '1',
    value: 'เป็นไปตามข้อเสนอโครงการ',
    text: 'เป็นไปตามข้อเสนอโครงการ'
  },
  { key: '2', value: 'สูงกว่า', text: 'สูงกว่า' },
  { key: '3', value: 'ต่ำกว่า', text: 'ต่ำกว่า' }
]

class ResultProgress extends Component {
  constructor (props) {
    super(props)
    this.state = {
      progress: true,
      progressType: true,
      Budget: true,
      BudgetType: true,
      OtherBudget: true,
      OtherBudgetType: false,
      validate: { error: false, validateError: null, attrs: {} },
      modalAttachment: false,
      onSaveSuccess: false
    }
  }

  componentDidMount () {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    this.props.listProject()
    this.props.listTor()
    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.currentProposal) {
      let tmp = { ...nextProps.currentProposal }
      let { data } = this.state
      if (tmp.concludeProductProgress && tmp.concludeProductProgress.length !== tmp.product.length) {
        // tmp.concludeProductProgress = []
        for(let i = 0; i < tmp.product.length; i++) {
          if(!tmp.concludeProductProgress[i]) {
            tmp.concludeProductProgress.push({
              concludeProduct: '',
              productCheck: false
            })
          }
        }
        // tmp.product.forEach(element => {
        //   tmp.concludeProductProgress.push({
        //     concludeProduct: '',
        //     productCheck: false
        //   })
        // })
      }
      if (tmp.concludeResultProgress && tmp.concludeResultProgress.length !== tmp.result.length) {
        for(let i = 0; i < tmp.result.length; i++) {
          if(!tmp.concludeResultProgress[i]) {
            tmp.concludeResultProgress.push({
              concludeResult: '',
              productCheck: false
            })
          }
        }
        // tmp.concludeResultProgress = []
        // tmp.result.forEach(element => {
        //   tmp.concludeResultProgress.push({
        //     concludeResult: '',
        //     productCheck: false
        //   })
        // })
      }
      this.setState({ data: { ...tmp, ...data } })
    }
  }

  _renderFormField = content => {
    const { validate } = this.state
    const { currentProposal, role } = this.props
    if (
      (currentProposal &&
        currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
        role !== 'Admin') ||
      (currentProposal &&
        currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')
    ) {
      if (content.control === Select) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            disabled
          />
        )
      } else
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            readOnly
          />
        )
    } else {
      if (content.control === Select) {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      } else
        return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  _checkReference = (name, data) => {
    if (name === 'isTimeFollowProposal') {
      delete data['isCompleteInTime']
      delete data['extendStartDate']
      delete data['extendEndDate']
    }
    if (name === 'isCompleteInTime') {
      delete data['extendStartDate']
      delete data['extendEndDate']
    }

    if (name === 'isSpendAskBudgetFollowProposal') {
      delete data['isSendAskBudgetBack']
      delete data['askBudgetDiff']
      delete data['askBudgetFrom']
    }
    if (name === 'isSendAskBudgetBack') {
      data['askBudgetFrom'] = null
    }

    if (name === 'isSpendOtherBudgetFollowProposal') {
      delete data['isSendOtherBudgetBack']
      delete data['otherudgetDiff']
      delete data['otherBudgetFrom']
    }
    if (name === 'isSendOtherBudgetBack') {
      data['otherBudgetFrom'] = null
    }
    return data
  }

  handleSelectRow = (selectedRow, name) => {
    const { currentProposal, role } = this.props
    if (
      (currentProposal &&
        currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
        role !== 'Admin') ||
      (currentProposal &&
        currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')
    ) {
      selectedRow = null
    }

    if (name === 'product') {
      if (selectedRow === this.state.productRow) selectedRow = null
      this.setState({ productRow: selectedRow })
    } else if (name === 'result') {
      if (selectedRow === this.state.resultRow) selectedRow = null
      this.setState({ resultRow: selectedRow })
    } else {
      if (selectedRow === this.state.targetsRow) selectedRow = null
      this.setState({ targetsRow: selectedRow })
    }
  }

  handleEditRow = (idx, e, { name, value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) {
    //   data = { ...currentProposal, ...data }
    // }

    let _targetGroup = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.targetGroup.forEach(item => {
          var tmp = { ...item }
          _targetGroup.push(tmp)
        })
      } else {
        data.targetGroup.forEach(item => {
          var tmp = { ...item }
          _targetGroup.push(tmp)
        })
      }
    }

    _targetGroup[idx][name] = value

    let tmp = { ...data, targetGroup: _targetGroup }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    this.setState({ data: tmp, validate })
  }

  handleAddRow = () => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    let _targetGroup = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _targetGroup = currentProposal.targetGroup.slice()
      } else {
        _targetGroup = data.targetGroup.slice()
      }
    }

    // if (!data.targetGroup) data['targetGroup'] = [];
    _targetGroup.push({
      targetGroup: '',
      detail: '',
      targetsCount: '',
      targetExplan: '',
      targetsUnit: ''
    })

    let tmp = { ...data, targetGroup: _targetGroup }

    this.setState({ data: tmp, selectedRow: _targetGroup.length - 1, validate })
  }

  handleDeleteRow = () => {
    let { data, validate, indexDelete } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    let _targetGroup = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _targetGroup = currentProposal.targetGroup.slice()
      } else {
        _targetGroup = data.targetGroup.slice()
      }
    }

    _targetGroup.splice(indexDelete, 1)

    let tmp = { ...data, targetGroup: _targetGroup }

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    this.setState({ data: tmp, validate, openConfirm: false })
  }

  handleChange = (e, { name, value, index, checked }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) data = { ...currentProposal, ...data }

    data[name] = value || checked

    data = this._checkReference(name, data)
    validate['validateError'] = validatejs(data, Constraints.ResultProgress)
    validate.attrs = {}
    validate.error = false

    this.setState({ data, validate })
  }

  handleTableInputChange = (e, { name, value, index, checked }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) data = { ...currentProposal, ...data }

    let tmp = {}
    let _concludeProductProgress = []
    let _concludeResultProgress = []

    if (name === 'concludeProduct') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          currentProposal.concludeProductProgress.forEach(item => {
            var tmp = { ...item }
            _concludeProductProgress.push(tmp)
          })
        } else {
          data.concludeProductProgress.forEach(item => {
            var tmp = { ...item }
            _concludeProductProgress.push(tmp)
          })
        }
      }
      _concludeProductProgress[index]['concludeProduct'] = value
      tmp = { ...data, concludeProductProgress: _concludeProductProgress }
    } else if (name === 'concludeResult') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          currentProposal.concludeResultProgress.forEach(item => {
            var tmp = { ...item }
            _concludeResultProgress.push(tmp)
          })
        } else {
          data.concludeResultProgress.forEach(item => {
            var tmp = { ...item }
            _concludeResultProgress.push(tmp)
          })
        }
      }
      _concludeResultProgress[index]['concludeResult'] = value
      tmp = { ...data, concludeResultProgress: _concludeResultProgress }
    }

    if (name === 'productCheck') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          currentProposal.concludeProductProgress.forEach(item => {
            var tmp = { ...item }
            _concludeProductProgress.push(tmp)
          })
        } else {
          data.concludeProductProgress.forEach(item => {
            var tmp = { ...item }
            _concludeProductProgress.push(tmp)
          })
        }
      }
      _concludeProductProgress[index]['productCheck'] = checked
      tmp = { ...data, concludeProductProgress: _concludeProductProgress }
    } else if (name === 'resultCheck') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          currentProposal.concludeResultProgress.forEach(item => {
            var tmp = { ...item }
            _concludeResultProgress.push(tmp)
          })
        } else {
          data.concludeResultProgress.forEach(item => {
            var tmp = { ...item }
            _concludeResultProgress.push(tmp)
          })
        }
      }
      _concludeResultProgress[index]['resultCheck'] = checked
      tmp = { ...data, concludeResultProgress: _concludeResultProgress }
    }

    data = this._checkReference(name, data)
    validate['validateError'] = validatejs(data, Constraints.ResultProgress)
    validate.attrs = {}
    validate.error = false

    this.setState({ data: tmp, validate })
  }

  handleSubmit = () => {
    let { data } = this.state
    const result = validatejs(data, Constraints.ResultProgress)
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })
    if (result) {
      let attrs = {}
      Object.keys(result).forEach(item => (attrs[item] = true))
      this.setState({ validate: { error: true, validateError: result, attrs } })
    } else {
      this.setState({
        validate: { error: false, validateError: result, attrs: {} },
        onSaveSuccess: true
      })
      if (this.props.currentProposal) {
        data = { ...this.props.currentProposal, ...data }
        setTimeout(() => {
          this.setState({ onSaveSuccess: false })
        }, 3000)
        this.props.updateProposal(data, data._id)
        data['timestamp'] = gettime
      } else {
        this.props.addProposal(data)
      }
    }
  }

  _radioChange = (e, { index, datavalue }) => {
    let { data } = this.state
    data.resultorProduct[index].isComplete = datavalue === 'true'
    this.setState({ data })
  }

  handleDayChange = (name, index, date) => {
    let { data } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    data[name] = date._d.getTime()
    this.setState({ data })
  }

  handleDayChangeRow = (name, index, date) => {
    let { data } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    data.targetGroup[index][name] = date._d.getTime()
    this.setState({ data })
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true })
  }

  handleUploaded = ({ uploaded }) => {
    let { currentProposal } = this.props
    if (!currentProposal[uploaded.name]) currentProposal[uploaded.name] = []
    currentProposal[uploaded.name].push({
      fileName: uploaded.typeOfAttach + '_' + uploaded.file.name,
      typeOfAttach: uploaded.typeOfAttach
    })
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleAttachedFileRemove = name => {
    let { currentProposal } = this.props
    currentProposal['attachResultProgress'].splice(name.index, 1)
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleCloseModal = () => {
    this.setState({
      modalSendEmail: false,
      modalAttachment: false,
      modalAddGroupFinance: false,
      modalPrintout: false
    })
  }

  onMoneyChange = e => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    // let money = Math.abs(parseFloat(e.target.rawValue))
    let money = e.target.rawValue.replace('-', '')
    data[e.target.name] = money ? money.toString() : '0'
    // validate array
    if (!validate.attrs) validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    this.setState({ data, validate })
  }

  handleChangeArea = (e, { attr, value, index }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (this.props.currentProposal) data = { ...currentProposal, ...data }

    let _Area = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.projectGroupArea.forEach(item => {
          var tmp = { ...item }
          _Area.push(tmp)
        })
      } else {
        data.projectGroupArea.forEach(item => {
          var tmp = { ...item }
          _Area.push(tmp)
        })
      }
    }

    validate.error = false
    validate['validateError'] = {}

    if (value[attr] === '') {
      _Area[index][attr] = value[attr]
    } else {
      _Area[index] = value
    }
    if (attr === 'city') {
      delete _Area[index].tumbon
    } else if (attr === 'province') {
      delete _Area[index].tumbon
      delete _Area[index].city
    }

    let tmp = { ...data, projectGroupArea: _Area }

    this.setState({ data: tmp, validate })
  }

  handleAddArea = () => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    let _Area = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _Area = currentProposal.projectGroupArea.slice()
      } else {
        _Area = data.projectGroupArea.slice()
      }
    }

    validate.error = false
    validate['validateError'] = {}

    _Area.push({
      tumbon: '',
      city: '',
      province: '',
      zipcode: ''
    })

    let tmp = { ...data, projectGroupArea: _Area }

    this.setState({ data: tmp, validate })
  }

  handleDeleteArea = () => {
    let { data, validate, indexDeleteArea } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    let _Area = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _Area = currentProposal.projectGroupArea.slice()
      } else {
        _Area = data.projectGroupArea.slice()
      }
    }

    // if (data.projectGroupArea) {
    _Area.splice(indexDeleteArea, 1)
    validate.error = false
    validate['validateError'] = {}

    let tmp = { ...data, projectGroupArea: _Area }

    this.setState({ data: tmp, openConfirmArea: false, validate })
    // }
  }

  openConfirm = (e, { name }) => {
    this.setState({ openConfirm: true, indexDelete: name })
  }

  openConfirmArea = (e, { name }) => {
    this.setState({ openConfirmArea: true, indexDeleteArea: name })
  }

  close = () => this.setState({ openConfirm: false, openConfirmArea: false })

  render () {
    const { currentProposal, currentState, role } = this.props
    let {
      data,
      validate,
      modalAttachment,
      productRow,
      resultRow,
      onSaveSuccess,
      targetsRow
    } = this.state
    data = { ...currentProposal, ...data }
    if (!currentProposal)
      return (
        <div>
          <HeaderContent
            title={data.nameTh}
            subtitle='ข้อสรุปผลการดำเนินงานโครงการ'
            onUpload={this._onUpload}
          />
        </div>
      )

    let content = {
      budget: { control: Input, readOnly: true },
      otherBudget: { control: Input, readOnly: true },
      otherBudgetItem: { control: Input, width: 8 },
      otherBudgetDropdown: { control: Select, options: otherBudget },
      period: { control: Input, readOnly: true, width: 16 },
      isTimeFollowProposal: {
        control: Select,
        options: optionActualPeriod,
        width: 8
      },
      extendStartDate: {
        control: DatePicker,
        selected: data.extendStartDate,
        width: 4,
        disabled: !(data.isTimeFollowProposal === 'ขยายเวลา')
      },
      extendEndDate: {
        control: DatePicker,
        selected: data.extendStartDate,
        width: 4,
        disabled: !(data.isTimeFollowProposal === 'ขยายเวลา')
      },
      isSpendAskBudgetFollowProposal: {
        control: Select,
        options: optionType,
        width: 8
      },
      askBudgetDiff: {
        control: Input,
        width: 8,
        disabled: !(
          data.isSpendAskBudgetFollowProposal === 'ส่งคืน' ||
          data.isSpendAskBudgetFollowProposal === 'ใช้เกิน'
        ),
        money: 'money'
      },
      isSpendOtherBudgetFollowProposal: {
        control: Select,
        options: optionTypeOther,
        width: 8
      },
      otherudgetDiff: {
        control: Input,
        width: 8,
        disabled: !(
          data.isSpendOtherBudgetFollowProposal === 'สูงกว่า' ||
          data.isSpendOtherBudgetFollowProposal === 'ต่ำกว่า'
        ),
        money: 'money'
      },
      resultOutsideProposal: { control: TextArea, width: 16 },
      resultArea1: { control: FormSearch, width: 16 },
      resultArea2: { control: FormSearch, width: 16 },
      resultGroup1Activity: { control: Input, width: 12 },
      resultGroup1Member: { control: Input, width: 4 },
      resultGroup1StartDate: {
        control: DatePicker,
        width: 6,
        selected: data.resultGroup1StartDate
      },
      resultGroup1EndDate: {
        control: DatePicker,
        width: 6,
        selected: data.resultGroup1EndDate
      },
      resultGroup2Activity: { control: Input, width: 12 },
      resultGroup2Member: { control: Input, width: 4 },
      resultGroup2StartDate: {
        control: DatePicker,
        width: 6,
        selected: data.resultGroup2StartDate
      },
      resultGroup2EndDate: {
        control: DatePicker,
        width: 6,
        selected: data.resultGroup2EndDate
      }
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('proposal', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === Input) {
        content[key].value = data[key] || ''
      } else if (content[key].control === DatePicker) {
        content[key].onChange = selected => {
          this.handleChange(null, { name: key, value: selected._d.getTime() })
        }
        content[key].selected = data[key] ? moment(data[key]) : null
      } else {
        content[key].value = data[key] || ''
      }
    })

    var LastProgressOptions = [{ text: 'เล่มรายงาน', value: 'เล่มรายงาน' }]

    let checkLock =
      (currentProposal &&
        currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
        role !== 'Admin') ||
      (currentProposal &&
        currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')

    return (
      <div>
        <HeaderContent
          subtitle='ข้อสรุปผลการดำเนินงานโครงการ'
          title={data.nameTh}
        />
        <AttachedComponent
          currentProposal={currentProposal}
          namePage='attachResultProgress'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={LastProgressOptions}
          onClose={this.handleCloseModal}
          checkStatus={
            currentProposal &&
            currentProposal.status === 'ประเมินความก้าวหน้าโครงการ'
          }
        />
        <Form error={validate.error}>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='สรุปข้อมูลการดำเนินโครงการ'
              as='h5'
              icon='edit'
            />
            <Form.Group widths='equal'>
              <Form.Field>
                <label>{'งบประมาณ'}</label>
                <Cleave
                  name='budget'
                  readOnly
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.budget || ''}
                />
              </Form.Field>
              {this._renderFormField(content.otherBudgetDropdown)}
              {/* <Form.Field>
                <label>{'งบประมาณจากแหล่งอื่น'}</label>
                <Cleave name='otherBudget'
                  readOnly
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.otherBudget || ''} />
              </Form.Field> */}
            </Form.Group>
            <Form.Group>
              {data.otherBudgetDropdown === 'งบประมาน' && (
                <Form.Field width='8'>
                  <label>{'งบประมาณจากแหล่งอื่น'}</label>
                  <Cleave
                    name='otherBudget'
                    maxLength='16'
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand'
                    }}
                    onChange={this.onMoneyChange.bind(this)}
                    value={data.otherBudget || ''}
                    disabled={checkLock}
                  />
                </Form.Field>
              )}
              {data.otherBudgetDropdown === 'สิ่งของอื่นๆ' && (
                <Form.Field width='16'>
                  <label>{'สิ่งของอื่นๆจากแหล่งอื่น'}</label>

                  <TextArea
                    name='otherBudgetItem'
                    onChange={this.handleChange}
                    value={data.otherBudgetItem}
                    readOnly={checkLock}
                  />
                </Form.Field>
              )}
              {/* {this._renderFormField(content.askBudget)}
              {this._renderFormField(content.otherBudget)} */}
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <Header
                  style={{ fontFamily: 'supermarket' }}
                  as='h5'
                  content={
                    'ระยะเวลาดำเนินการ : ' +
                    (ThaiDate('month', data.startDate) || 'ไม่ได้ระบุ') +
                    ' - ' +
                    (ThaiDate('month', data.endDate) || 'ไม่ได้ระบุ')
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.isTimeFollowProposal)}
              <Form.Field disabled={data.isTimeFollowProposal !== 'ขยายเวลา'}>
                <label>{'เริ่มต้นโครงการ'}</label>
                <DayPicker
                  ser={'extendStartDate'}
                  name={'extendStartDate'}
                  onDayChange={this.handleDayChange}
                  data={data.extendStartDate || ''}
                />
              </Form.Field>
              <Form.Field disabled={data.isTimeFollowProposal !== 'ขยายเวลา'}>
                <label>{'สิ้นสุดโครงการ'}</label>
                <DayPicker
                  ser={'extendEndDate'}
                  name={'extendEndDate'}
                  onDayChange={this.handleDayChange}
                  data={data.extendEndDate || ''}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.isSpendAskBudgetFollowProposal)}
              <Form.Field
                width='8'
                disabled={
                  !(
                    data.isSpendAskBudgetFollowProposal === 'ส่งคืน' ||
                    data.isSpendAskBudgetFollowProposal === 'ใช้เกิน'
                  )
                }
              >
                <label>{'เป็นเงิน(บาท)'}</label>
                <Cleave
                  name='askBudgetDiff'
                  readOnly={checkLock}
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.askBudgetDiff || ''}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.isSpendOtherBudgetFollowProposal)}
              <Form.Field
                width='8'
                disabled={
                  !(
                    data.isSpendOtherBudgetFollowProposal === 'สูงกว่า' ||
                    data.isSpendOtherBudgetFollowProposal === 'ต่ำกว่า'
                  )
                }
              >
                <label>{'เป็นเงิน(บาท)'}</label>
                <Cleave
                  name='otherBudgetDiff'
                  readOnly={checkLock}
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.otherBudgetDiff || ''}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field disabled={checkLock}>
                <label>{'วันส่งงาน (งวดสุดท้าย)'}</label>
                <DayPicker
                  ser={'sendWorkTime'}
                  name={'sendWorkTime'}
                  // onDayChange={this.handleDayChange}
                  data={
                    (currentProposal &&
                      data.progressPeriod &&
                      data.progressPeriod[data.progressPeriod.length - 1] &&
                      data.progressPeriod[data.progressPeriod.length - 1]
                        .sendWorkTime) ||
                    ''
                  }
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='รายละเอียดโครงการ'
              as='h5'
              icon='book'
            />
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='ผลผลิตของโครงการ'
              as='h5'
            />
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    content='รายการ'
                    textAlign='center'
                    width={7}
                  />
                  <Table.HeaderCell
                    content='สรุปสาระข้อมูล/เหตุผลกรณีไม่เกิด'
                    textAlign='center'
                    width={9}
                  />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {currentProposal &&
                  currentProposal.product &&
                  currentProposal.product.map((d, i) => (
                    <Fragment key={i}>
                      <Table.Row
                        onClick={this.handleSelectRow.bind(this, i, 'product')}
                      >
                        {(productRow === i && (
                          <Table.Cell>
                            <Label color='green' ribbon content={d.content} />
                          </Table.Cell>
                        )) || (
                          <Table.Cell
                            content={
                              <Form.Checkbox
                                onChange={this.handleTableInputChange}
                                checked={
                                  (data.concludeProductProgress &&
                                    data.concludeProductProgress[i] &&
                                    data.concludeProductProgress[i]
                                      .productCheck) ||
                                  false
                                }
                                index={i}
                                label={d.product}
                                name='productCheck'
                                disabled={checkLock}
                              />
                            }
                          />
                        )}
                        <Table.Cell
                          content={
                            (data.concludeProductProgress &&
                              data.concludeProductProgress[i] &&
                              data.concludeProductProgress[i]
                                .concludeProduct) ||
                            ''
                          }
                        />
                      </Table.Row>
                      {productRow === i && (
                        <Table.Row active>
                          <Table.Cell colSpan='2'>
                            <Form.Group>
                              <Form.Checkbox
                                onChange={this.handleTableInputChange}
                                checked={
                                  (data.concludeProductProgress &&
                                    data.concludeProductProgress[i] &&
                                    data.concludeProductProgress[i]
                                      .productCheck) ||
                                  false
                                }
                                index={i}
                                label={d.product}
                                name='productCheck'
                                disabled={checkLock}
                              />
                            </Form.Group>
                            <Form.TextArea
                              name='concludeProduct'
                              onChange={this.handleTableInputChange}
                              value={
                                (data.concludeProductProgress &&
                                  data.concludeProductProgress[i] &&
                                  data.concludeProductProgress[i]
                                    .concludeProduct) ||
                                ''
                              }
                              index={i}
                              label='สรุปสาระข้อมูล/เหตุผลกรณีไม่เกิด'
                              disabled={checkLock}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
              </Table.Body>
            </Table>
            {currentProposal && currentProposal.result && (
              <Header
                style={{ fontFamily: 'supermarket' }}
                content='ผลลัพธ์ของโครงการ'
                as='h5'
              />
            )}
            {currentProposal && currentProposal.result && (
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      content='รายการ'
                      textAlign='center'
                      width={7}
                    />
                    <Table.HeaderCell
                      content='สรุปสาระข้อมูล/เหตุผลกรณีไม่เกิด'
                      textAlign='center'
                      width={9}
                    />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {currentProposal &&
                    currentProposal.result &&
                    currentProposal.result.map((d, i) => (
                      <Fragment key={i}>
                        <Table.Row
                          onClick={this.handleSelectRow.bind(this, i, 'result')}
                        >
                          {(resultRow === i && (
                            <Table.Cell>
                              <Label color='green' ribbon content={d.result} />
                            </Table.Cell>
                          )) || (
                            <Table.Cell
                              content={
                                <Form.Checkbox
                                  onChange={this.handleTableInputChange}
                                  checked={
                                    (data.concludeResultProgress &&
                                      data.concludeResultProgress[i] &&
                                      data.concludeResultProgress[i]
                                        .resultCheck) ||
                                    false
                                  }
                                  index={i}
                                  label={d.result}
                                  name='resultCheck'
                                  key={d}
                                  disabled={checkLock}
                                />
                              }
                            />
                          )}
                          <Table.Cell
                            content={
                              (data.concludeResultProgress &&
                                data.concludeResultProgress[i] &&
                                data.concludeResultProgress[i]
                                  .concludeResult) ||
                              ''
                            }
                          />
                        </Table.Row>
                        {resultRow === i && (
                          <Table.Row active>
                            <Table.Cell colSpan='2'>
                              <Form.Group>
                                <Form.Checkbox
                                  onChange={this.handleTableInputChange}
                                  checked={
                                    (data.concludeResultProgress &&
                                      data.concludeResultProgress[i] &&
                                      data.concludeResultProgress[i]
                                        .resultCheck) ||
                                    false
                                  }
                                  index={i}
                                  label={d.result}
                                  name='resultCheck'
                                  disabled={checkLock}
                                />
                              </Form.Group>
                              <Form.TextArea
                                name='concludeResult'
                                onChange={this.handleTableInputChange}
                                value={
                                  (data.concludeResultProgress &&
                                    data.concludeResultProgress[i] &&
                                    data.concludeResultProgress[i]
                                      .concludeResult) ||
                                  ''
                                }
                                index={i}
                                label='สรุปสาระข้อมูล/เหตุผลกรณีไม่เกิด'
                                disabled={checkLock}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Fragment>
                    ))}
                </Table.Body>
              </Table>
            )}
            {this._renderFormField(content.resultOutsideProposal)}
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='พื้นที่ดำเนินงานและกลุ่มผู้ได้รับประโยชน์'
              as='h5'
            />
            <Form.Field>
              <Checkbox
                name='editArea'
                label='แก้ไขพื้นที่ดำเนินการ'
                onChange={this.handleChange}
              />
            </Form.Field>
            {data &&
              data.projectGroupArea &&
              data.projectGroupArea.map((item, idx) => (
                <Segment key={idx}>
                  <Button
                    style={{ position: 'absolute', top: 10, right: 10 }}
                    color='red'
                    type='button'
                    icon='close'
                    onClick={this.openConfirmArea}
                    index={idx}
                    disabled={checkLock || !data.editArea}
                  />
                  <Header
                    as='h3'
                    style={{ marginTop: 0, fontFamily: 'supermarket' }}
                  >
                    พื้นที่ดำเนินการ {idx + 1}
                  </Header>
                  <Form.Group>
                    <Form.Field
                      width={16}
                      control={FormSearchAddress}
                      label={'ตำบล/แขวง (พิมตัวอักษรเพื่อค้นหา)'}
                      attr='tumbon'
                      index={idx}
                      onChange={this.handleChangeArea}
                      value={item || 'กรุณากรอกพื้นที่ดำเนินการ'}
                      readOnly={checkLock || !data.editArea}
                    />
                    <Form.Field
                      width={16}
                      control={FormSearchAddress}
                      label={'อำเภอ/เขต (พิมตัวอักษรเพื่อค้นหา)'}
                      attr='city'
                      index={idx}
                      onChange={this.handleChangeArea}
                      value={item || 'กรุณากรอกพื้นที่ดำเนินการ'}
                      readOnly={checkLock || !data.editArea}
                    />
                    <Form.Field
                      width={16}
                      control={FormSearchAddress}
                      label={'จังหวัด (พิมตัวอักษรเพื่อค้นหา)'}
                      attr='province'
                      index={idx}
                      onChange={this.handleChangeArea}
                      value={item || 'กรุณากรอกพื้นที่ดำเนินการ'}
                      readOnly={checkLock || !data.editArea}
                    />
                  </Form.Group>
                </Segment>
              ))}
            <Button
              disabled={checkLock || !data.editArea}
              color='green'
              type='button'
              content='เพิ่มพื้นที่ดำเนินการ'
              onClick={this.handleAddArea}
            />
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='กลุ่มผู้ได้รับผลประโยชน์จากโครงการ'
              as='h5'
            />
            <Form.Field>
              <Checkbox
                name='editTarget'
                label='แก้ไขกลุ่มผู้ได้รับผลประโยชน์จากโครงการ'
                onChange={this.handleChange}
              />
            </Form.Field>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    content='กลุ่มผู้ได้รับผลประโยชน์'
                    textAlign='center'
                    width={4}
                  />
                  <Table.HeaderCell
                    content='จำนวน'
                    textAlign='center'
                    width={2}
                  />
                  <Table.HeaderCell
                    content='ระหว่างวันที่'
                    textAlign='center'
                    width={4}
                  />
                  <Table.HeaderCell
                    content='กิจกรรมสำคัญ'
                    textAlign='center'
                    width={6}
                  />
                  <Table.HeaderCell content='ลบ' textAlign='center' width={2} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.targetGroup &&
                  data.targetGroup.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row
                        onClick={this.handleSelectRow.bind(
                          this,
                          idx,
                          'targets'
                        )}
                      >
                        {(targetsRow === idx && (
                          <Table.Cell>
                            <Label
                              color='green'
                              ribbon
                              content={item.targetGroup}
                            />
                          </Table.Cell>
                        )) || (
                          <Table.Cell textAlign='center'>
                            {item.targetGroup}
                          </Table.Cell>
                        )}
                        <Table.Cell
                          content={
                            formatcomma(item.targetsCount)
                              ? formatcomma(item.targetsCount) +
                                ' ' +
                                (item.targetsUnit || '')
                              : ' '
                          }
                          textAlign='center'
                        />
                        <Table.Cell
                          content={
                            (ThaiDate('month', item.startDate) ||
                              'ไม่ได้ระบุ') +
                            ' - ' +
                            (ThaiDate('month', item.endDate) || 'ไม่ได้ระบุ')
                          }
                        />
                        <Table.Cell content={item.event || ''} />
                        <Table.Cell>
                          <Button
                            disabled={checkLock || !data.editTarget}
                            type='button'
                            name={idx}
                            color='red'
                            icon='close'
                            onClick={this.openConfirm}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {targetsRow === idx && (
                        <Table.Row active>
                          <Table.Cell colSpan={4}>
                            <Form.Group widths='equal'>
                              <Form.Input
                                label='กลุ่มเป้าหมาย'
                                name='targetGroup'
                                readOnly={checkLock || !data.editTarget}
                                value={item.targetGroup}
                                onChange={this.handleEditRow.bind(this, idx)}
                              />
                              <Form.Input
                                label='จำนวน'
                                name='targetsCount'
                                readOnly={checkLock || !data.editTarget}
                                value={formatcomma(item.targetsCount)}
                                onChange={this.handleEditRow.bind(this, idx)}
                              />
                              <Form.Input
                                label='หน่วย'
                                name='targetsUnit'
                                readOnly={checkLock || !data.editTarget}
                                value={item.targetsUnit}
                                onChange={this.handleEditRow.bind(this, idx)}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Field disabled={checkLock} width={4}>
                                <label>{'เริ่มต้น'}</label>
                                <DayPicker
                                  ser={'startDate'}
                                  name={'startDate'}
                                  index={idx}
                                  onDayChange={this.handleDayChangeRow}
                                  data={item.startDate || ''}
                                />
                              </Form.Field>
                              <Form.Field disabled={checkLock} width={4}>
                                <label>{'สิ้นสุด'}</label>
                                <DayPicker
                                  ser={'endDate'}
                                  name={'endDate'}
                                  index={idx}
                                  onDayChange={this.handleDayChangeRow}
                                  data={item.endDate || ''}
                                />
                              </Form.Field>
                            </Form.Group>
                            <Form.TextArea
                              label='กิจกรรมสำคัญ'
                              value={item.event}
                              name='event'
                              onChange={this.handleEditRow.bind(this, idx)}
                              disabled={checkLock}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
              </Table.Body>
            </Table>
            <Button
              disabled={checkLock || !data.editTarget}
              color='green'
              type='button'
              content='เพิ่มกลุ่มเป้าหมาย'
              onClick={this.handleAddRow}
            />
          </Segment>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Button
            color='blue'
            content='บันทึก'
            icon='save'
            type='submit'
            disabled={checkLock}
            loading={currentState.isSaving}
            onClick={this.handleSubmit}
          />
        </Form>
        <Confirm
          open={this.state.openConfirmArea}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.handleDeleteArea}
        />
        <Confirm
          open={this.state.openConfirm}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.handleDeleteRow}
        />
        <Transition
          visible={onSaveSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle'></Icon>บันทึกสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentState: state.projects,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  listProject: () => dispatch(torActions.fetchList()),
  listTor: () =>
    dispatch(torActions.fetchList({ sort: { nameTh: 1 }, limit: 5, skip: 0 })),
  addProposal: data => dispatch(projectActions.save(data)),
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResultProgress)
