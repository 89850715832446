import React, { Component, Fragment } from 'react'
import {
  Table,
  Select,
  Form,
  Button,
  Modal,
  TextArea,
  Label,
  Confirm,
  Checkbox,
  Header,
  Transition,
  Icon,
  Message
} from 'semantic-ui-react'
import Cleave from 'cleave.js/react'
import validatejs from 'validate.js'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { ComponentPerson, ComponentOrganization } from 'Utils'
import { constraints as ScreenContraints } from './ScreenContraints'
import { constraints as ScreenArrayContraints } from './ScreenContraints'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as organizationsActions } from 'Redux/reducers/organizations'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as oldprojectsActions } from 'Redux/reducers/oldprojects'
import { generate } from 'Redux/reducers/counting'
import { ValidateArray as validateArray } from 'Utils'
import { localTh } from 'Utils'
import { formatcomma } from 'Utils'
import jwt from 'jsonwebtoken'

let gettime,
  budget,
  money = ''
// let otherbudget = ''

const choice = [
  { key: '1', value: 'ยังไม่ทราบผล', text: 'ยังไม่ทราบผล' },
  {
    key: '2',
    value: 'ให้การสนับสนุนโดยไม่ต้องแก้ไขโครงการ',
    text: 'ให้การสนับสนุนโดยไม่ต้องแก้ไขโครงการ'
  },
  {
    key: '3',
    value: 'สนับสนุนแต่ต้องแก้ไขโครงการตามประเด็นในเอกสารแนบ',
    text: 'สนับสนุนแต่ต้องแก้ไขโครงการตามประเด็นในเอกสารแนบ'
  },
  { key: '4', value: 'ไม่ควรให้การสนับสนุน', text: 'ไม่ควรให้การสนับสนุน' }
]

let individualsName

class PageScreen extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fileUpload: [],
      type: 'single',
      Professional: false,
      amount: 0,
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      modalAttachment: false,
      modalSummary: false,
      checkSummary: false,
      onSaveSuccess: false,
      isLoadOrganize: false,
      openModalPerson: true,
      onScreen: false,
      confirmReset: false
    }
  }

  componentDidMount () {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
    this.props.listOldProposal(match.params.id)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.currentProposal) {
      let tmp = { ...nextProps.currentProposal }
      let { data } = this.state

      if (tmp.numberOfGroupBudget === 1) {
        budget = parseFloat((tmp.askBudget || 0).replace(/,/g, ''), 10)
        // otherbudget = parseInt((tmp.otherBudget ? tmp.otherBudget : '0').replace(/,/g, ''), 10)
      } else if (tmp.numberOfGroupBudget === 2) {
        budget =
          parseInt(tmp.askBudget.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget1.replace(/,/g, ''), 10)
        // otherbudget = parseInt((tmp.otherBudget ? tmp.otherBudget : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget1 ? tmp.otherBudget1 : '0').replace(/,/g, ''), 10)
      } else if (tmp.numberOfGroupBudget === 3) {
        budget =
          parseInt(tmp.askBudget.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget1.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget2.replace(/,/g, ''), 10)
        // otherbudget = parseInt((tmp.otherBudget ? tmp.otherBudget : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget1 ? tmp.otherBudget1 : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget2 ? tmp.otherBudget2 : '0').replace(/,/g, ''), 10)
      } else if (tmp.numberOfGroupBudget === 4) {
        budget =
          parseInt(tmp.askBudget.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget1.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget2.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget3.replace(/,/g, ''), 10)
        // otherbudget = parseInt((tmp.otherBudget ? tmp.otherBudget : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget1 ? tmp.otherBudget1 : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget2 ? tmp.otherBudget2 : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget3 ? tmp.otherBudget3 : '0').replace(/,/g, ''), 10)
      } else if (tmp.numberOfGroupBudget === 5) {
        budget =
          parseInt(tmp.askBudget.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget1.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget2.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget3.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget4.replace(/,/g, ''), 10)
        // otherbudget = parseInt((tmp.otherBudget ? tmp.otherBudsget : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget1 ? tmp.otherBudget1 : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget2 ? tmp.otherBudget2 : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget3 ? tmp.otherBudget3 : '0').replace(/,/g, ''), 10) +
        //   parseInt((tmp.otherBudget4 ? tmp.otherBudget4 : '0').replace(/,/g, ''), 10)
      } else {
        budget = parseFloat(tmp.askBudget.replace(/,/g, ''), 10)
        // otherbudget = parseInt((tmp.otherBudget ? tmp.otherBudget : '0').replace(/,/g, ''), 10)
      }
      money = budget

      if (!tmp.screen && !tmp.beforeEditMoney) {
        tmp.screen = []
        let test = []
        if (money <= 500000) {
          for (let i = 0; i < 1; i++) {
            test.push({})
          }
        } else if (money <= 1000000) {
          for (let i = 0; i < 3; i++) {
            test.push({})
          }
        } else if (money <= 10000000) {
          for (let i = 0; i < 5; i++) {
            test.push({})
          }
        } else {
          for (let i = 0; i < 7; i++) {
            test.push({})
          }
        }
        tmp.screen = test
        tmp.profesAmount = test.length
      } else if (tmp.beforeEditMoney && tmp.beforeEditMoney !== tmp.askBudget) {
        tmp.beforeEditMoney = tmp.askBudget
        tmp.screen = []
        let test = []
        // let budget = 0 //parseInt((tmp.askBudget).replace(/,/g, ''), 10)
        // let otherbudget = 0 // parseInt((tmp.otherBudget ? tmp.otherBudget : '0').replace(/,/g, ''), 10)

        if (money <= 500000) {
          for (let i = 0; i < 1; i++) {
            test.push({})
          }
        } else if (money <= 1000000) {
          for (let i = 0; i < 3; i++) {
            test.push({})
          }
        } else if (money <= 10000000) {
          for (let i = 0; i < 5; i++) {
            test.push({})
          }
        } else {
          for (let i = 0; i < 7; i++) {
            test.push({ screenProfessional: '', screenresult: '' })
          }
        }
        tmp.screen = test
      }

      this.setState({ data: { ...tmp, ...data } })
    }
  }

  handleUploaded = ({ name, index, file, typeOfAttach, uploaded }) => {
    let { data } = this.state
    let { currentProposal } = this.props
    if (this.props.currentProposal) data = { ...currentProposal, ...data }
    if (!currentProposal[uploaded.name]) currentProposal[uploaded.name] = []
    currentProposal[uploaded.name].push({
      fileName: uploaded.typeOfAttach + '_' + uploaded.file.name,
      typeOfAttach: uploaded.typeOfAttach
    })
    // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
    //   data['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
    //   sessionStorage.setItem('attachSendProposal', null)
    // }
    this.props.updateProposal(data, data._id)
  }

  handleAttachedFileRemove = name => {
    let { currentProposal } = this.props
    currentProposal['attachScreen'].splice(name.index, 1)
    // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
    //   currentProposal['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
    //   sessionStorage.setItem('attachSendProposal', null)
    // }
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleAddProfessional = e => {
    if (this.props.history) this.props.history.push('/List/name')
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true, modalPrintout: false })
  }

  handleSubmit = condition => {
    let { data, validate } = this.state
    let { currentProposal, role, auth } = this.props

    if (this.props.currentProposal) data = { ...currentProposal, ...data }

    var valueArr = data.screen.map(function (item) {
      return item.screenProfessional
    })
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx
    })

    let dupManager = data.screen.filter(
      x => x.screenProfessional === data.manager
    )

    if (!dupManager) dupManager = []

    let countResult = 0
    data &&
      data.screen.forEach(x => {
        if (x.screenresult === 'ไม่ควรให้การสนับสนุน') {
          countResult += 1
        }
      })

    validate.attrs = {}
    validate['validateError'] = validatejs(
      data,
      ScreenContraints.ScreenContraints
    )

    // validate array
    let validateArrayError = validateArray(
      data.screen,
      ScreenArrayContraints.ScreenArrayContraints
    )

    if (!data.objective) data.objective = []
    if (!data.product) data.product = []
    if (!data.projectGroupArea) data.projectGroupArea = []

    if (data.product.length === 0) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      validate['validateError']['product'] = []
      validate['validateError']['product'].push(
        'คุณยังไม่ได้ระบุ ผลผลิตข้อกำหนด โปรดกลับไปที่เมนู เป้าประสงค์'
      )
    }

    if (data.objective.length === 0) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      validate['validateError']['objective'] = []
      validate['validateError']['objective'].push(
        'คุณยังไม่ได้ระบุ วัตถุประสงค์ โปรดกลับไปที่เมนู เป้าประสงค์'
      )
    }

    if (!data.projectGroupArea || data.projectGroupArea.length === 0) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      if (!validate.validateError) validate.validateError = {}
      validate['validateError']['projectGroupArea'] = []
      validate['validateError']['projectGroupArea'].push(
        'คุณยังไม่ได้ระบุ พื้นที่ดำเนินการ โปรดกลับไปที่เมนู พื้นที่ดำเนินการ'
      )
    }

    if (!data.purpose) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      validate['validateError']['purpose'] = []
      validate['validateError']['purpose'].push(
        'คุณยังไม่ได้ระบุ เป้าประสงค์ โปรดกลับไปที่เมนู เป้าประสงค์'
      )
    }

    if (!data.evaluation) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      validate['validateError']['evaluation'] = []
      validate['validateError']['evaluation'].push(
        'คุณยังไม่ได้ระบุ การประเมินผล โปรดกลับไปที่เมนู เป้าประสงค์'
      )
    }

    if (!validate['validateError']) validate['validateError'] = {}
    if (
      Object.keys(validateArrayError).length !== 0 &&
      validateArrayError.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayError).forEach(index => {
        validate['validateError']['screen'] =
          validateArrayError[index].nameProfessional
        validate.attrs['screen' + index] = true
      })
    }
    // end validate array
    if (!validate['validateError']['screen']) {
      validate['validateError']['screen'] = []
      if (isDuplicate) {
        validate.error = true
        validate['validateError']['screen'].push('ผู้กลั่นกรองซ้ำกัน')
      } else if (dupManager.length !== 0) {
        validate.error = true
        validate['validateError']['screen'].push(
          'ผู้กลั่นกรองซ้ำกับผู้รับผิดชอบโครงการ'
        )
      } else {
        validate['error'] = false
        if (!validate.validateError.screenFeedback) {
          validate['validateError'] = {}
        }
      }
    }

    if (data.noScreen) {
      validate.error = false
      validate.attrs = {}
      validate.validateError = {}
    }

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (
      Object.keys(validate['validateError']).length !== 0 &&
      validate['validateError'].constructor === Object
    ) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      validate['error'] = false
      this.setState({ validate })
      let checkscreen = false
      if (countResult >= 1 && !data.noScreen) {
        if (data.screenConfirm) {
          data['status'] = 'กลั่นกรอง'
          checkscreen = true
        }
      }
      if (data.screenConfirm || data.noScreen) {
        if (data.noScreen) {
          countResult = 0
        }
        if (role !== 'Admin') {
          if (countResult >= 1) {
            data['status'] = 'กลั่นกรอง'
          } else {
            data['status'] = 'กลั่นกรอง'
          }
        }
      }

      if (role === 'Admin') {
        data['adminEdit'] = user._id
        data['adminEditTime'] = gettime
      } else {
        data['timestamp'] = gettime
      }

      data['dateScreen'] = gettime
      if (!checkscreen) {
        this.setState({ onSaveSuccess: true })
        setTimeout(() => {
          this.setState({ onSaveSuccess: false })
          if (condition === 'next') {
            this.props.history.push(
              '/project/withdrawtime/' + data._id
            )
          } else if (condition === 'back') {
            this.props.history.push('/project/area/' + data._id)
          }
        }, 3000)
      } else {
        this.setState({ onScreen: true })
        setTimeout(() => {          
          this.setState({ onScreen: false })
          if (condition === 'next') {
            this.props.history.push(
              '/project/withdrawtime/' + data._id
            )
          } else if (condition === 'back') {
            this.props.history.push('/project/area/' + data._id)
          }
        }, 7000)
      }
      // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
      //   data['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
      //   sessionStorage.setItem('attachSendProposal', null)
      // }
      this.props.updateProposal(data, data._id)
      // if (this.props.history) this.props.history.push('/project/pagepromise/' + data._id);
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  handleNextPage = () => {
    this.handleSubmit('next')
  }

  handleBackPage = () => {
    this.handleSubmit('back')
  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate } = this.state

    if (name === 'screenConfirm') {
      if (!data['screenConfirm']) {
        data[name] = false
        this.setState({ checkSummary: checked, modalSummary: true })
      } else {
        this.setState({ checkSummary: checked, modalSummary: false })
        data[name] = checked
      }
    } else if (name === 'noScreen') {
      data[name] = checked
      // this.props.genId('noAgreement-' + data.yearBudget)
    } else if (name === 'haveProfesAmount') {
      data[name] = checked
      data['budgetProfes'] = data.screen && data.screen.length
      // this.props.genId('noAgreement-' + data.yearBudget)
    } else {
      data[name] = value
    }

    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false
    this.setState({ data, validate })
  }

  _renderFormField = content => {
    const { validate } = this.state
    let { currentProposal, role } = this.props

    let checkLockScreen =
      (currentProposal &&
        currentProposal.status !== 'ส่งข้อเสนอโครงการ' &&
        currentProposal.status !== 'กลั่นกรอง' &&
        role !== 'Admin') ||
      (currentProposal &&
        currentProposal.status === 'ส่งข้อเสนอโครงการ' &&
        currentProposal.status === 'กลั่นกรอง' &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')

    if (currentProposal) {
      if (checkLockScreen) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            readOnly
          />
        )
      } else {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      }
    } else {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  handleSelectOrganization = value => {
    this.props.listPersonByOrganization(value)
  }

  handleSelectRow = selectedRow => {
    let { data } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    // if ((currentProposal && (currentProposal.status !== 'กลั่นกรอง' || currentProposal.status !== 'ส่งข้อเสนอโครงการ') && role !== 'Admin') || (currentProposal && currentProposal.status === 'กลั่นกรอง' && role !== 'Admin' && role !== 'Academic')) {
    //   selectedRow = null
    // } else {
    if (selectedRow === this.state.selectedRow) {
      selectedRow = null
    } else {
      if (data.screen[selectedRow].OrganizationProfessional)
        this.props.listOrganizations({
          _id: data.screen[selectedRow].OrganizationProfessional
        })
      if (data.screen[selectedRow].OrganizationProfessional)
        this.props.listPersonByOrganization(
          data.screen[selectedRow].OrganizationProfessional
        )
      if (data.screen[selectedRow].screenProfessional) {
        let qurey = {
          _id: data.screen[selectedRow].screenProfessional
        }
        this.props.listPersonFreelance(qurey)
      }
      // }
    }
    // }

    this.setState({ selectedRow, modalAttachment: false })
  }

  handleSearch = (e, { name, index }) => {
    let { data } = this.state

    if (name === 'OrganizationProfessional') {
      if (data.screen[index].typeProfessional === 'yes') {
        if (e.target.value.length >= 3) {
          let query = {
            $or: [{ organizationName: { $regex: e.target.value } }]
          }
          this.props.listOrganizations(query)
        }
      }
    } else if (name === 'screenProfessional') {
      if (data.screen[index].typeProfessional === 'no') {
        if (e.target.value.length >= 3) {
          let test = e.target.value.split(' ')
          let qurey = {
            firstname: { $regex: test[0] },
            lastname: { $regex: test[1] || '' },
            haveOrganization: 'no',
            role: { $in: ['ผู้ทรงคุณวุฒิ'] }
          }
          this.props.listPersonFreelance(qurey)
        }
      } else if (data.screen[index].typeProfessional === 'yes') {
        if (
          data.screen[index].OrganizationProfessional &&
          data.screen[index].OrganizationProfessional !== ''
        ) {
          let test = e.target.value.split(' ')
          let qurey = {
            organization: data.screen[index].OrganizationProfessional,
            firstname: { $regex: test[0] },
            lastname: { $regex: test[1] || '' },
            role: { $in: ['ผู้ทรงคุณวุฒิ'] }
          }
          this.props.listPersonFreelance(qurey)
        }
      } else if (data.screen[index].typeProfessional === 'nothing') {
        if (e.target.value.length >= 3) {
          let test = e.target.value.split(' ')
          let qurey = {
            firstname: { $regex: test[0] },
            lastname: { $regex: test[1] || '' },
            role: { $in: ['ผู้ทรงคุณวุฒิ'] }
          }
          this.props.listPersonFreelance(qurey)
        }
      }
    }
  }

  handleEditRow = (idx, e, { name, value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    data.screen[idx][name] = value

    // validate array
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
    // let validateArrayError = validateArray(data.screen, ScreenArrayContraints.ScreenArrayContraints)
    // if (Object.keys(validateArrayError).length !== 0 && validateArrayError.constructor === Object) {
    //   validate.error = true
    //   Object.keys(validateArrayError).forEach(index => {
    //     validate.attrs['screen' + index] = true;
    //     validate['validateError']['screen'] = validateArrayError[index].nameProfessional
    //   })
    // }

    if (name === 'typeProfessional') {
      if (value === 'no') {
        delete data.screen[idx].OrganizationProfessional
        let query = { organization: 'blank', role: { $in: ['ผู้ทรงคุณวุฒิ'] } }
        this.props.listPersonFreelance(query)
      } else if (value === 'yes') {
        this.props.listPersonByOrganization('blank')
      }
    }

    if (name === 'screenProfessional') {
      individualsName.map(r => {
        if (value === r.id) {
          data.screen[idx]['nameProfessional'] = r.name
          if (data.screen[idx].typeProfessional === 'nothing') {
            data.screen[idx]['OrganizationProfessional'] = r.orgName
            if (r.orgName !== '') {
              let query = {
                _id: r.orgName
              }
              this.props.listOrganizations(query)
            }
          }
        }
        return r
      })
    }
    if (name === 'OrganizationProfessional') {
      this.props.listPersonByOrganization(value)
    }

    if (name === 'screenresult') this.setState({ data, selectedRow: null })
    else {
      this.setState({ data, validate })
    }
  }

  handleAddRow = () => {
    let { data } = this.state
    let { currentProposal } = this.props
    if (currentProposal) data = { ...currentProposal, ...data }

    if (!data.screen) data['screen'] = []
    data.screen.push({})
    this.setState({ data })
  }

  handleDeleteRow = () => {
    let { data, amount } = this.state
    let { currentProposal } = this.props
    if (currentProposal) data = { ...currentProposal, ...data }
    if (data.screen && data.screen.length > amount) {
      let last = data.screen.length - 1
      data.screen.splice(last, 1)
      this.setState({ data })
    }
  }

  _onDownload = () => {
    this.setState({
      modalPrintout: true,
      modalAttachment: false,
      modalSummary: false
    })
  }

  handleCloseModal = () => {
    this.setState({
      modalSendEmail: false,
      modalAttachment: false,
      modalAddGroupFinance: false,
      modalPrintout: false,
      modalSummary: false
    })
  }

  handleOpenModalPerson = () =>
    this.setState({ modalPersonOpen: true, personDimmer: false })
  handleCloseModalPerson = () => {
    let query = { organization: 'blank', role: { $in: ['ผู้ทรงคุณวุฒิ'] } }
    this.props.listPersonFreelance(query)
    this.setState({ modalPersonOpen: false, personDimmer: true })
  }

  handleOpenModalOrganization = () =>
    this.setState({ modalOrgOpen: true, orgDimmer: false })
  handleCloseModalOrganization = () =>
    this.setState({ modalOrgOpen: false, orgDimmer: true })

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  handleConfirm = () => {
    const { checkSummary, data } = this.state
    data['screenConfirm'] = checkSummary
    // this.props.genId('noAgreement-' + data.yearBudget)
    this.setState({ data, modalSummary: false })
  }

  handleConfirmEditProposal = () => {
    this.setState({ confirmEditProposal: true })
  }

  handleEditProposal = () => {
    const { currentProposal, oldProposal } = this.props
    let { data } = this.state
    data = { ...currentProposal, ...data }
    let tmp = { ...currentProposal, ...data }
    data['proposal_id'] = currentProposal._id
    data['edit'] = oldProposal && oldProposal.data.length + 1
    tmp['edit'] = oldProposal && oldProposal.data.length + 1
    tmp.status = 'ส่งข้อเสนอโครงการ'
    this.props.addOldProposal(data)
    // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
    //   data['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
    //   sessionStorage.setItem('attachSendProposal', null)
    // }
    this.props.updateProposal(tmp, tmp._id)
    this.props.history.push('/project/information/' + tmp._id)
  }

  close = () => {
    this.setState({ confirmEditProposal: false, confirmReset: false })
  }

  handleResetProfess = () => {
    const { currentProposal } = this.props
    let { data, validate } = this.state
    data = { ...currentProposal, ...data }

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    data.screen.forEach(x => {
      delete x.OrganizationProfessional
      delete x.nameProfessional
      delete x.typeProfessional
      x.screenProfessional = ''
      x.screenresult = ''
    })
    // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
    //   data['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
    //   sessionStorage.setItem('attachSendProposal', null)
    // }
    this.setState({ data, validate, confirmReset: false })
    this.props.updateProposal(data, data._id)
  }

  handleConfirmResetProfess = () => {
    this.setState({ confirmReset: true })
  }

  onAmountProfesChange = e => {
    let { data, validate } = this.state
    const { match, currentProposal } = this.props
    if (match.params.id) {
      data = { ...currentProposal, ...data }
    }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    let profes = e.target.rawValue.replace('-', '')
    let moneyBudget = parseInt(data.askBudget.replace(/,/g, ''), 10)
    let maxProfes = 1
    if (profes) {
      if (moneyBudget <= 500000) {
        maxProfes = 1
      } else if (moneyBudget <= 1000000) {
        maxProfes = 3
      } else if (moneyBudget <= 10000000) {
        maxProfes = 5
      } else {
        maxProfes = 7
      }
      if (profes < maxProfes) {
        validate.error = true
        validate['validateError']['profesAmount'] = []
        validate['validateError']['profesAmount'].push('น้อยกว่างบประมานกำหนด')
      } else {
        if (profes > 20) {
          validate.error = true
          validate['validateError']['profesAmount'] = []
          validate['validateError']['profesAmount'].push('มากเกินไป')
        } else {
          data.screen = []
          for (let i = 0; i < profes; i++) {
            data.screen.push({})
          }
        }
      }
    }

    data[e.target.name] = profes ? profes.toString() : '0'
    // data[e.target.name] = e.target.rawValue

    this.setState({ data, validate })
  }

  render () {
    const {
      currentProposal,
      currentState,
      organizations,
      individuals,
      role
    } = this.props
    let { data, validate, selectedRow, onSaveSuccess, onScreen } = this.state
    data = { ...currentProposal, ...data }
    if (!currentProposal)
      return (
        <div>
          <HeaderContent
            title='กำหนดผู้กลั่นกรองโครงการ'
            onUpload={this._onUpload}
          />
        </div>
      )
    let content = {
      screensuggestion: { control: TextArea, rows: 5 },
      screenFeedback: { control: TextArea, rows: 5 },
      screenConfirm: { control: Checkbox, rows: 5 },
      noScreen: { control: Checkbox, rows: 5 }
    }

    let organizationsOptions = organizations.data.map((item, idx) => ({
      text: item.organizationName,
      value: item._id
    }))

    let individualsOptions = individuals.data.map((item, idx) => ({
      key: idx,
      text:
        item.title === 'อื่นๆ'
          ? item.otherTitle + item.firstname + ' ' + item.lastname
          : item.title + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    individualsName = individuals.data.map((item, idx) => ({
      id: item._id,
      name:
        item.title === 'อื่นๆ'
          ? item.otherTitle + item.firstname + ' ' + item.lastname
          : item.title + item.firstname + ' ' + item.lastname,
      orgName: item.organization || ''
    }))

    let personType = [
      { key: '0', text: 'มี', value: 'yes' },
      { key: '1', text: 'ไม่มี', value: 'no' },
      { key: '2', text: 'ไม่ทราบ', value: 'nothing' }
    ]

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('proposal', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === TextArea) {
        content[key].value = data[key] || ''
      } else if (content[key].control === Checkbox) {
        content[key].checked = data[key]
      } else {
        content[key].value = data[key]
      }
    })

    if (data.screen) {
      var ScreenOptions = []

      for (let i = 0; i < data.screen.length; i++) {
        ScreenOptions.push({
          text: 'ไฟล์ผู้ทรงคุณวุฒิ' + (i + 1),
          value: 'ไฟล์ผู้ทรงคุณวุฒิ' + (i + 1)
        })
      }
      ScreenOptions.push(
        { text: 'ใบปะหน้า สแกน พร้อมลายเซ็น', value: 'ใบปะหน้า พร้อมลายเซ็น' },
        { text: 'Proposal แก้ไขแล้ว', value: 'Proposal แก้ไขแล้ว' }
      )
    }

    let checkLockScreen =
      (currentProposal &&
        currentProposal.status !== 'ส่งข้อเสนอโครงการ' &&
        currentProposal.status !== 'กลั่นกรอง' &&
        role !== 'Admin') ||
      (currentProposal &&
        (currentProposal.status === 'ส่งข้อเสนอโครงการ' ||
          currentProposal.status === 'กลั่นกรอง') &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')

    return (
      <div>
        <HeaderContent
          title={currentProposal.nameTh}
          subtitle='กำหนดผู้ทรงคุณวุฒิของโครงการ'
        />
        <Form error={validate.error}>
          <Message color='yellow'>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              หมายเหตุ*
            </Message.Header>
            <p>
              ผู้ใช้ สามารถปริ้นแบบฟอร์มกลั่นกรองเปล่า ได้ที่ปุ่มพิมพ์ได้ทันที
            </p>
          </Message>
          <Form.Group>
            <Form.Input
              onKeyPress={this.handleKeyPress}
              label='ชื่อโครงการ'
              width={11}
              value={currentProposal.nameTh}
              readOnly={currentProposal.status !== 'กลั่นกรอง'}
            />
            <Form.Field width={5}>
              <label>{'งบประมาณที่เสนอ'}</label>
              <Cleave
                name='money'
                maxLength='16'
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
                value={parseFloat(money) || 0}
                readOnly
              />
            </Form.Field>
          </Form.Group>
          <Form.Checkbox
            name='haveProfesAmount'
            onChange={this.handleChange}
            label={<label>ต้องการเปลี่ยนแปลงจำนวนผู้ทรงคุณวุฒิ</label>}
            checked={data.haveProfesAmount}
            disabled={checkLockScreen}
          />
          <Form.Group>
            <Form.Field
              error={
                validate.validateError['profesAmount'] &&
                validate.validateError['profesAmount'].length !== 0
              }
            >
              <label>
                {
                  'จำนวนผู้ทรงคุณวุฒิ (ไม่เกิน 10 คน และต้องไม่น้อยกว่าจำนวนที่กำหนดมาตามงบประมาณ)'
                }
              </label>
              <Cleave
                name='profesAmount'
                maxLength='2'
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
                value={parseFloat(data.profesAmount) || 0}
                onChange={this.onAmountProfesChange.bind(this)}
                disabled={!data.haveProfesAmount || checkLockScreen}
              />
            </Form.Field>
          </Form.Group>
          {/* <Grid>
            <Grid.Row centered>
              <Form.Group>

              </Form.Group>
            </Grid.Row>
          </Grid> */}
          <Modal
            open={this.state.modalPersonOpen}
            onClose={this.handleCloseModalPerson}
            closeOnDimmerClick={this.state.personDimmer}
            size='small'
            closeIcon
          >
            <Modal.Content>
              <ComponentPerson
                onCloseModalPerson={this.handleCloseModalPerson}
                super={true}
              />
            </Modal.Content>
          </Modal>
          <Modal
            open={this.state.modalOrgOpen}
            onClose={this.handleCloseModalOrganization}
            closeOnDimmerClick={this.state.orgDimmer}
            size='small'
            closeIcon
          >
            <Modal.Content>
              <ComponentOrganization
                onCloseModalPerson={this.handleCloseModalOrganization}
              />
            </Modal.Content>
          </Modal>
          <Button
            type='button'
            content='ล้างข้อมูลผู้ทรงคุณวุฒิ'
            icon='sync'
            onClick={this.handleConfirmResetProfess}
            disabled={checkLockScreen}
          />
          {/* <Button
            content='เพิ่มองค์กร' icon='plus'
            color='teal' onClick={this.handleOpenModalOrganization} /> */}
          <Button
            content='เพิ่มผู้ทรงคุณวุฒิ'
            icon='plus'
            color='green'
            onClick={this.handleOpenModalPerson}
          />
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  textAlign='center'
                  content='คนที่'
                  width={2}
                />
                <Table.HeaderCell
                  textAlign='center'
                  content='รายชื่อผู้ทรงคุณวุฒิ'
                  width={8}
                />
                {/* <Table.HeaderCell textAlign='center' content='อัพโหลด' width={3} /> */}
                <Table.HeaderCell
                  textAlign='center'
                  content='ผลการกลั่นกรอง'
                  width={6}
                />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {/* {formfix} */}
              {data.screen &&
                data.screen.map((item, idx) => (
                  <Fragment key={idx}>
                    <Table.Row
                      onClick={this.handleSelectRow.bind(this, idx)}
                      error={validate.attrs['screen' + idx]}
                    >
                      {(selectedRow === idx && (
                        <Table.Cell width={2}>
                          <Label
                            color='green'
                            ribbon
                            content={'คนที่ ' + (idx + 1)}
                          />
                        </Table.Cell>
                      )) || (
                        <Table.Cell
                          width={2}
                          textAlign='center'
                          content={idx + 1}
                        />
                      )}
                      <Table.Cell
                        width={8}
                        content={item.nameProfessional}
                        // content={<ComponentName id={item.screenProfessional} />}
                      />
                      <Table.Cell content={item.screenresult} />
                    </Table.Row>
                    {selectedRow === idx && (
                      <Table.Row active>
                        <Table.Cell colSpan={3}>
                          <Form.Group>
                            <Form.Select
                              label='สังกัดอยู่ในองค์กร'
                              width={6}
                              name='typeProfessional'
                              value={item.typeProfessional}
                              onChange={this.handleEditRow.bind(this, idx)}
                              disabled={checkLockScreen}
                              // disabled={(currentProposal && (currentProposal.status !== 'กลั่นกรอง' || currentProposal.status !== 'ส่งข้อเสนอโครงการ')
                              //   && role !== 'Admin') ||
                              //   (currentProposal && (currentProposal.status === 'กลั่นกรอง' || currentProposal.status !== 'ส่งข้อเสนอโครงการ')
                              //     && role !== 'Admin' && role !== 'Academic')}
                              options={personType}
                            />
                            <Form.Field
                              control={Select}
                              placeholder='เลือกองค์กรผู้ทรงคุณวุฒิ'
                              label='ชื่อองต์กร (พิมพ์อย่างน้อย 3 ตัวอักษรเพิ่อค้นหารายชื่อ)'
                              search={true}
                              width={10}
                              index={idx}
                              // {...DropdownExtensions}
                              value={item.OrganizationProfessional}
                              options={organizationsOptions}
                              onChange={this.handleEditRow.bind(this, idx)}
                              name='OrganizationProfessional'
                              disabled={checkLockScreen}
                              // disabled={(currentProposal && (currentProposal.status === 'กลั่นกรอง' || currentProposal.status !== 'ส่งข้อเสนอโครงการ')
                              //   && role !== 'Admin') || (currentProposal && currentProposal.status === 'กลั่นกรอง' && role !== 'Admin' && role !== 'Academic')
                              //   || (!item.typeProfessional || item.typeProfessional === 'no')}
                              onSearchChange={this.handleSearch}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Select
                              placeholder='เลือกผู้ทรงคุณวุฒิ'
                              label='ผู้ทรงคุณวุฒิ (พิมพ์อย่างน้อย 3 ตัวอักษรเพิ่อค้นหารายชื่อ)'
                              search={true}
                              width={8}
                              index={idx}
                              disabled={checkLockScreen}
                              // disabled={!item.OrganizationProfessional && (!item.typeProfessional || item.typeProfessional === 'yes')}
                              // {...DropdownExtensions}
                              value={item.screenProfessional}
                              options={individualsOptions}
                              onChange={this.handleEditRow.bind(this, idx)}
                              name='screenProfessional'
                              onSearchChange={this.handleSearch}
                            />
                            <Form.Select
                              placeholder='กรุณากรอกข้อมูล'
                              label='ผลการกลั่นกรอง'
                              // {...DropdownExtensions}
                              disabled={checkLockScreen}
                              // disabled={(currentProposal && (currentProposal.status === 'กลั่นกรอง' || currentProposal.status !== 'ส่งข้อเสนอโครงการ')
                              //   && role !== 'Admin') || (currentProposal && (currentProposal.status === 'กลั่นกรอง' || currentProposal.status !== 'ส่งข้อเสนอโครงการ')
                              //     && role !== 'Admin' && role !== 'Academic') || (data.screen[idx] && data.screen[idx].screenProfessional === '')}
                              width={8}
                              name={'screenresult'}
                              options={choice}
                              onChange={this.handleEditRow.bind(this, idx)}
                              value={item.screenresult}
                            />
                          </Form.Group>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Fragment>
                ))}
            </Table.Body>
          </Table>
          {this._renderFormField(content.screenFeedback)}
          {this._renderFormField(content.screensuggestion)}
          <Form.Group>
            {/* {this._renderFormField(content.screenConfirm)} */}
            {this._renderFormField(content.noScreen)}
          </Form.Group>
          <Form.Group>
            <Form.Field>
              {/* <label style={{ fontSize: 15, color: 'red' }}>*หมายเหตุ </label>
              <label style={{ fontSize: 15, color: 'red' }}> 1. ถ้าติ๊กถูกช่อง "โครงการนี้ไม่ต้องกลั่นกรอง" แล้วบันทึก สามารถทำสัญญาได้ทันที </label>
              <label style={{ fontSize: 15, color: 'red' }}> 2. กรุณาติ๊กถูกช่องยืนยันการกลั่นกรอง ถ้าต้องการทำการกลั่นกรอง (ถ้าไม่ติ๊กถูก ข้อมูลจะไม่กลั่นกรอง แต่จะบันทึกข้อมูลเท่านั้น) </label> */}
            </Form.Field>
          </Form.Group>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Transition
            visible={onSaveSuccess}
            animation='horizontal flip'
            duration={500}
          >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle'></Icon>บันทึกสำเร็จ
              </Message.Header>
            </Message>
          </Transition>
          <Transition
            visible={onScreen}
            animation='horizontal flip'
            duration={500}
          >
            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle'></Icon>บันทึกสำเร็จ
                แต่กลั่นกรองไม่ผ่าน ไม่สามารถทำสัญญาได้
              </Message.Header>
            </Message>
          </Transition>
          <Confirm
            open={this.state.openConfirm}
            header='คำเตือน'
            content='เมื่อกดยืนยันแล้ว โครงการนี้จะมีสถานะกลั่นกรอง จะไม่สามารถกลับไปแก้ไขข้อมูลก่อนหน้านี้ได้'
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
          />
          <Modal
            open={this.state.modalSummary}
            onClose={this.handleCloseModal}
            size='small'
            closeIcon
          >
            <Modal.Header style={{ fontFamily: 'supermarket' }}>
              ยืนยันข้อมูลการกลั่นกรอง
            </Modal.Header>
            <Modal.Content>
              <Header style={{ fontFamily: 'supermarket' }}>
                ข้อมูลพื้นฐาน
              </Header>
              <p>ชื่อโครงการ : {currentProposal.nameTh || ''}</p>
              <p>กลุ่มแผนงาน : {currentProposal.groupsPlan || ''}</p>
              <p>แผนงาน : {currentProposal.plan || ''}</p>
              <p>กิจกรรม : {currentProposal.activity || ''}</p>
              <p>
                งบประมาณ : {formatcomma(currentProposal.askBudget) || '-'} บาท
                || งบประมาณอื่น :{' '}
                {formatcomma(currentProposal.otherBudget) || '-'} บาท
              </p>
              <Header style={{ fontFamily: 'supermarket' }}>
                กลุ่มเป้าหมาย
              </Header>
              {currentProposal &&
                currentProposal.targetGroup &&
                currentProposal.targetGroup.map((item, index) => (
                  <p key={index}>
                    กลุ่มเป้าหมายที่{index + 1} : {item.targetGroup} || จำนวน :{' '}
                    {formatcomma(item.targetsCount)
                      ? formatcomma(item.targetsCount) +
                        ' ' +
                        (item.targetsUnit || '')
                      : ' '}{' '}
                    || รายละเอียด : {item.detail}
                  </p>
                ))}
              <Header style={{ fontFamily: 'supermarket' }}>เป้าประสงค์</Header>
              <p>เป้าประสงค์ : {currentProposal.purpose}</p>
              <p>การประเมินผล : {currentProposal.evaluation}</p>
              <Header style={{ fontFamily: 'supermarket' }}>
                วัตถุประสงค์
              </Header>
              {currentProposal &&
                currentProposal.objective &&
                currentProposal.objective.map((item, index) => (
                  <p key={index}>
                    วัตถุประสงค์ที่{index + 1} : {item.objective}{' '}
                  </p>
                ))}
              <Header style={{ fontFamily: 'supermarket' }}>
                ผลผลิตข้อกำหนด
              </Header>
              {currentProposal &&
                currentProposal.product &&
                currentProposal.product.map((item, index) => (
                  <p key={index}>
                    ผลผลิตข้อกำหนดที่{index + 1} : {item.product}{' '}
                  </p>
                ))}
              <Header style={{ fontFamily: 'supermarket' }}>
                ผลลัพธ์ข้อกำหนด
              </Header>
              {currentProposal &&
                currentProposal.result &&
                currentProposal.result.map((item, index) => (
                  <p key={index}>
                    ผลลัพธ์ข้อกำหนดที่{index + 1} : {item.result}{' '}
                  </p>
                ))}
              <Header style={{ fontFamily: 'supermarket' }}>
                พื้นที่ดำเนินการ
              </Header>
              {currentProposal &&
                currentProposal.projectGroupArea &&
                currentProposal.projectGroupArea.map((item, index) => (
                  <p key={index}>
                    พื้นที่ดำเนินการที่{index + 1} : ตำบล{item.tumbon || ''}{' '}
                    อำเภอ{item.city || ''} จังหวัด{item.province || ''}{' '}
                    {item.zipcode || ''}
                  </p>
                ))}
              <Header style={{ fontFamily: 'supermarket' }}>
                งวดงานงวดเงิน
              </Header>
              {currentProposal &&
                currentProposal.withDrawTime &&
                currentProposal.withDrawTime.map((item, index) => (
                  <p key={index}>
                    งวดงานงวดเงินที่{index + 1} : ผลงานที่นำส่งและเงื่อนไข :{' '}
                    {item.content || ''} จำนวนเงิน : {formatcomma(item.sum)}
                  </p>
                ))}
              <Header style={{ fontFamily: 'supermarket' }}>
                ผลการกลั่นกรอง
              </Header>
              {data &&
                data.screen &&
                data.screen.map((item, index) => (
                  <p key={index}>
                    ผู้ทรงคุณวุฒิที่{index + 1} : ชื่อ-นามสกุล :{' '}
                    {item.nameProfessional} || ผลการกลั่นกรอง :{' '}
                    {item.screenresult}
                  </p>
                ))}
              <Header style={{ fontFamily: 'supermarket' }}>ไฟล์แนบ</Header>
              {currentProposal && !currentProposal.attachSendProposal && (
                <p>ยังไม่มีไฟล์แนบ</p>
              )}
              {currentProposal &&
                currentProposal.attachSendProposal &&
                currentProposal.attachSendProposal.map((item, index) => (
                  <p key={index}>
                    ไฟล์แนบที่{index + 1} : {item.fileName}
                  </p>
                ))}
              <Header style={{ fontFamily: 'supermarket' }}>
                ผู้รับผิดชอบโครงการ
              </Header>
              <p>ผู้รับผิดชอบโครงการ : {currentProposal.managerName || ''}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleConfirm}>
                ยืนยัน
              </Button>
            </Modal.Actions>
          </Modal>
          <Confirm
            open={this.state.confirmEditProposal}
            content='ต้องการแก้ไขข้อเสนอโครงการใช่หรือไม่'
            cancelButton='ยกเลิก'
            confirmButton='ยืนยัน'
            onCancel={this.close}
            onConfirm={this.handleEditProposal}
          />
          <Confirm
            open={this.state.confirmReset}
            content='ต้องการล้างข้อมูลผู้ทรงคุณวุฒิใช่หรือไม่'
            cancelButton='ยกเลิก'
            confirmButton='ยืนยัน'
            onCancel={this.close}
            onConfirm={this.handleResetProfess}
          />
          <Form.Button
            color='blue'
            content='บันทึก'
            icon='save'
            disabled={checkLockScreen}
            loading={currentState.isSaving}
            onClick={this.handleSubmit}
            type='submit'
          />
          <Button
            className='button-back-bottom-right'
            type='button'
            color='blue'
            content='ย้อนกลับ'
            icon='step backward'
            disabled={checkLockScreen}
            // disabled={validate.error}
            onClick={this.handleBackPage}
          />
          <Button
            className='button-bottom-right'
            type='button'
            color='blue'
            content='ถัดไป'
            icon='step forward'
            disabled={checkLockScreen}
            // disabled={validate.error}
            onClick={this.handleNextPage}
          />
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // proposals: state.projects,
  currentProposal: state.projects.currentData,
  form: state.proposalForm,
  currentState: state.projects,
  currentTor: state.tors.data,
  tor: state.tors,
  organizations: state.organizations,
  individuals: state.individuals,
  oldProposal: state.oldprojects,
  counter: state.counter,
  role: state.role,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  addOldProposal: data => dispatch(oldprojectsActions.save(data)),
  listOldProposal: id =>
    dispatch(oldprojectsActions.fetchList({ query: { proposal_id: id } })),
  updateProposal: (currentProposal, id) =>
    dispatch(projectActions.save(currentProposal, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id)),
  getTorByTorIdFromProposal: id =>
    dispatch(torActions.fetchList({ query: { _id: id } })),
  listOrganizations: query =>
    dispatch(organizationsActions.fetchList({ query: query })),
  genId: name => dispatch(generate(name)),
  listPersonFreelance: query =>
    dispatch(individualsActions.fetchList({ query: query })),
  listPersonByOrganization: id =>
    dispatch(
      individualsActions.fetchList({
        query: { organization: id, role: { $in: ['ผู้ทรงคุณวุฒิ'] } }
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(PageScreen)
