import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { 
  Dropdown, 
  Form, 
  Button, 
  Input 
} from 'semantic-ui-react'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { DatePicker as DayPicker } from 'Utils'

class YearBudget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      day: {},
      value: {},
      disabledGroup: true,
      disabledPlan: true,
      disabledFocus: true,
      disabledStrategy: true,
      search: true,
      lastValue: '',
      lastName: '',
      lastYear: ''
    }
  }

  componentDidMount() {
    this.props.getAll()
  }

  componentWillReceiveProps(nextProps) {


    let obj = {
      disabledGroup: false,
      disabledPlan: false,
      disabledFocus: false,
      disabledStrategy: false,
      disabledYear: false,
      search: true,
    };

    if (nextProps.search === false) {
      obj = {
        disabledGroup: false,
        disabledPlan: false,
        disabledFocus: false,
        disabledStrategy: false,
        disabledYear: false,
        search: false,
      }
    }

    if (nextProps.delBudget === false) {
      obj = {
        disabledGroup: false,
        disabledPlan: false,
        disabledFocus: false,
        disabledStrategy: false,
        disabledYear: false,
        search: false,
      }
    }

    if (nextProps.readOnly) {
      obj = {
        disabledGroup: true,
        disabledPlan: true,
        disabledFocus: true,
        disabledStrategy: true,
        disabledYear: true,
        search: false,
      }
    }

    if (nextProps.search) {
      obj = {
        disabledGroup: false,
        disabledPlan: false,
        disabledFocus: false,
        disabledStrategy: false,
        disabledYear: false,
        search: true,
      }
    }



    if (nextProps.value) {
      this.setState({
        value: nextProps.value,
        ...obj
      });
    


      /*
      if(this.props.fundings) {
        let selectedYear = this.props.fundings.filter(item => item.year === nextProps.value.year)
        if(selectedYear.length == 1) {
          this.props.getDoc(selectedYear[0].key);    
        }
        this.setState({value:nextProps.value,
          disabledGroup:false,
          disabledPlan:false,
          disabledFocus:false,
          disabledStrategy:false
        });
      }
      */
    }
  }

  handleAddItem = (e, { name, value }) => {
    let { fundings } = this.props
    let _value = this.state.value
    let setArry = ['group', 'plan']
      .filter((item, idx, self) => self.indexOf(item) > self.indexOf(name))

    if (name === 'year') {

      let tmp = {}
      tmp[name] = value
      tmp['focus'] = []
      tmp['list'] = []
      tmp['strategy'] = []

      this.props.addDoc(tmp)
    }
    let current = fundings.filter(item => item.year === _value.year)[0]
    if (current) {
      if (name === 'group' || name === 'plan') {
        let obj = {}
        setArry.forEach(item => { obj[item] = null });
        let s = { ...this.state.value, ...obj };
        s[name] = value;
        current.list.push(s);
        this.props.updateDoc(current, current._id);
        this.setState({ value: s })
      } else if (name === 'focus') {
        // let obj = {}
        // setArry.forEach(item => { obj[item] = null });
        let s = { ...this.state.value };
        s[name] = value;
        current.focus.push(s);
        this.props.updateDoc(current, current._id);
        this.setState({ value: s })
      } else {
        let s = { ...this.state.value };
        s[name] = value;
        current.strategy.push(s);
        this.props.updateDoc(current, current._id);
        this.setState({ value: s })
      }
    }
  }

  handleDayChange = (name, index, date) => {
    let { day } = this.state
    let data = date._d.getTime()
    day[name] = data
    this.setState(day)
    this.props.onDayChange(name, date._d.getTime())
  }

  handleChange = (e, { name, value, options }) => {
    let obj = {
      ...this.state,
      disabledGroup: true,
      disabledPlan: true,
      disabledFocus: true,
      disabledStrategy: true
    }


    obj['value'][name] = value;
    if (name === 'year') {
      let selectedYear = options.filter(item => item.value === value)
      if (selectedYear.length === 1) {
        //  this.props.getDoc(selectedYear[0].key);
        this.setState({ ...obj, disabledGroup: false, lastYear: value });
      }
    } else {
      if (name === 'group') {
        this.setState({ ...obj, disabledGroup: false, disabledPlan: false })
      }
      if (name === 'plan') {
        this.setState({ ...obj, disabledGroup: false, disabledPlan: false, disabledFocus: false })
        if (this.props.onChange) this.props.onChange(e, {
          name: this.props.name,
          value: { ...obj }
        });
      }
    }

    this.setState({ lastValue: value, lastName: name })

  }

  handleDelete = () => {
    const { lastValue, lastName, lastYear } = this.state
    let { fundings } = this.props

    if (lastName === 'year') {
      fundings = fundings.filter(x => x.year === lastValue)
      this.props.deleteDoc(fundings[0]._id)
    }

    if (lastName === 'group') {
      let index = fundings.findIndex(y => y.year === lastYear)
      let list = fundings[index].list.filter(x => x.group !== lastValue)
      fundings[index].list = list
    }

    if (lastName === 'plan') {
      let index = fundings.findIndex(y => y.year === lastYear)
      let list = fundings[index].list.filter(x => x.plan !== lastValue)
      fundings[index].list = list
    }

    for (let i = 0; i < fundings.length; i++) {
      this.props.updateDoc(fundings[i], fundings[i]._id)
    }
  }


  render() {
    let { fundings } = this.props
    const {
      day,
      value,
      search,
      lastValue
    } = this.state


    const yearOptions = fundings.map((item, idx) => ({
      key: item._id,
      value: item.year,
      text: item.year
    }));

    let current = fundings.filter(item => item.year === value.year)[0]

    const groups = current ?
      current.list
        .filter(item => item.group)
        .map(item => item.group)
        .filter((item, idx, self) => self.indexOf(item) === idx)
        .map((item, idx) => ({ key: idx, value: item, text: item }))
      : [];


    const plans = current ?
      current.list
        .filter(item => item.plan && item.group === value.group)
        .map(item => item.plan)
        .filter((item, idx, self) => self.indexOf(item) === idx)
        .map((item, idx) => ({ key: idx, value: item, text: item }))
      : [];



    return (
      <Fragment>
        <Form.Group>
          <Form.Field control={Dropdown} width={8}
            label='ปีงบประมาณ'
            options={yearOptions}
            placeholder='ปีงบประมาณ'
            value={value.year}
            name='year'
            search={search}
            selection
            allowAdditions
            onAddItem={this.handleAddItem}
            onChange={this.handleChange} />
          <Form.Field control={Dropdown} width={8}
            label='กลุ่มงาน'
            options={groups}
            search={search}
            selection
            value={value.group}
            name='group'
            allowAdditions
            onAddItem={this.handleAddItem}
            onChange={this.handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Field control={Dropdown} width={8}
            label='แผนงาน'
            options={plans}
            search={search}
            selection
            value={value.plan}
            name='plan'
            allowAdditions
            onAddItem={this.handleAddItem}
            onChange={this.handleChange}
          />
          <Form.Field  >
            <label>{'วันที่ใช้เงิน'}</label>
            <DayPicker ser={'startDate'} name={'startDate'}
              onDayChange={this.handleDayChange}
              data={day.startDate || value.startDate || ''} />
          </Form.Field>
          <Form.Field  >
            <label>{'ถึงวันที่'}</label>
            <DayPicker ser={'endDate'} name={'endDate'}
              onDayChange={this.handleDayChange}
              data={day.endDate || value.endDate || ''} />
          </Form.Field>
        </Form.Group>
        {search &&
          <Form.Group>
            <Form.Field control={Input} value={lastValue} readOnly />
            {lastValue &&
              <Button color='red' content='ลบ' onClick={this.handleDelete} />
            }
          </Form.Group>
        }
      </Fragment>
    )
  }
}

const mapState = state => ({
  fundings: state.fundings.data,
  //current:state.fundings.currentData,
})

const mapAction = dispatch => ({
  getDoc: (id) => dispatch(fundingsActions.fetchOne(id)),
  // getAll:() => dispatch(actions.fetchList({projection:{year:1}})),
  getAll: () => dispatch(fundingsActions.fetchList()),
  updateDoc: (data, id) => dispatch(fundingsActions.save(data, id, 'POST')),
  deleteDoc: (id) => dispatch(fundingsActions.delete(id)),
  addDoc: (data) => dispatch(fundingsActions.save(data))
})

export default connect(mapState, mapAction)(YearBudget)
