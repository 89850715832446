import React, { Component, Fragment } from 'react'
import {
  Form,
  Header,
  Message,
  Input,
  Button,
  Select,
  TextArea
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import { FormSearch } from 'Utils'
import { connect } from 'react-redux'
import { constraints as PersonConstraints } from 'Containers/Project/RegisProject/RegisConstraints'
import { constraints as PersonConConstraints } from 'Containers/Project/RegisProject/RegisConstraints'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as organizationsActions } from 'Redux/reducers/organizations'
import { localTh } from 'Utils'
import Cleave from 'cleave.js/react'
import jwt from 'jsonwebtoken'
import { GetServerTime as GetTime } from 'Utils'

const titleOptions = [
  { value: 'นาย', text: 'นาย' },
  { value: 'นาง', text: 'นาง' },
  { value: 'นางสาว', text: 'นางสาว' },
  { value: 'อื่นๆ', text: 'อื่นๆ(ระบุ)' }
]

const roleOptions = [
  { value: 'ผู้รับผิดชอบโครงการ', text: 'ผู้รับผิดชอบโครงการ' },
  { value: 'ผู้ทรงคุณวุฒิ', text: 'ผู้ทรงคุณวุฒิ' }
]

let organization = ''
let gettime = ''

class ComponentPerson extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      check: false,
      data: {},
      validate: { error: false, validateError: null, attrs: {} }
    }
  }

  componentDidMount () {
    const { data_id } = this.props
    // this.props.listOrganizations()
    if (data_id) {
      this.props.getPerson(data_id)
    }
    if (this.props.super) {
      let { data } = this.state
      if (!data.role) {
        data.role = ['ผู้ทรงคุณวุฒิ']
        this.setState(data)
      }
    }    
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.currentPerson) {
      let tmp = { ...nextProps.currentPerson }
      let { data } = this.state
      // tmp['startDate'] = moment(tmp['startDate'])
      // tmp['endDate'] = moment(tmp['endDate'])
      this.setState({ data: { ...tmp, ...data } })
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  validateCid = value => {
    if (value === null) return '^กรุณากรอกเลขบัตรประชาชน'
    else if (value.length !== 13) return '^ต้องมี 13 หลัก'
    else {
      var num = value.replace(/-/g, '')
      let digits = num.split('')
      // .map((st) => parseInt(st));
      if (digits[0] > 0 || digits[0] < 9) {
        let result = 0
        for (var i = 0; i < 12; i++) {
          result += digits[i] * (13 - i)
        }
        result = (11 - (result % 11)) % 10
        if (result !== parseInt(digits[12], 10)) {
          return 'เลขบัตรประชาชนไม่ถูกต้อง'
        }
      } else if (value[0] === 'P') return null
    }
  }

  handleChange = (e, { name, value }) => {
    let { currentPerson, openModalPerson } = this.props
    let { data, validate } = this.state
    if (!openModalPerson) data = { ...currentPerson, ...data }
    if (name === 'title') {
      if (data.title === 'อื่นๆ') {
        data.otherTitle = value
      } else {
        data[name] = value
        data.otherTitle = value
      }
    } else {
      data[name] = value
    }

    validate['validateError'] = {}
    validate.error = false
    validate.attrs = {}

    if (name === 'organization') {
      organization.map(r => {
        if (value === r.id) data['organizationName'] = r.name
        return r
      })
    }

    this.setState({ data, validate })
  }

  handleSubmit = () => {
    let { data, validate } = this.state
    let { currentPerson, openModalPerson, auth } = this.props

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

    if (!openModalPerson) data = { ...currentPerson, ...data }

    // if(data.title==='อื่นๆ'){
    //   data.title = data.otherTitle
    // }

    validate.attrs = {}

    if (data.haveOrganization === 'no') {
      validate['validateError'] = validatejs(
        data,
        PersonConConstraints.PersonConConstraints
      )
    } else {
      validate['validateError'] = validatejs(
        data,
        PersonConstraints.PersonConstraints
      )
    }

    if (validate['validateError'] === undefined) {
      validate['validateError'] = {}
    }

    if (data.cid) {
      if (data.cid.length > 0) {
        if (data.cid.length !== 13) {
          validate['validateError'].cid = ['กรุณาใส่ให้ครบ 13 หลัก']
        } else {
          var regx = /^[0-9]*$/
          const check = data.cid.match(regx)
          if (check !== null) {
            let validCID = this.validateCid(data.cid)
            if (validCID !== undefined) {
              validate['validateError'].cid = [validCID]
            }
          } else {
            validate['validateError'].cid = ['กรุณาใส่เลขบัตรเป็นตัวเลข']
          }
        }
      }
    } else if (data.role && (data.role[0] === 'ผู้ทรงคุณวุฒิ' || data.role[1] === 'ผู้ทรงคุณวุฒิ')) {
      validate['validateError'].cid = ['กรุณาใส่เลขบัตรประชาชน']
    }

    if(!data.addressNo){
      validate['validateError'].addressNo = ['ต้องระบุ']
    }

    if(!data.address){
      validate['validateError'].address = ['ต้องระบุ']
    }

    if (data.email) {
      if (data.email.length > 0) {
        var constraintsEmail = {
          email: {
            email: true
          }
        }
        let test = validatejs({ email: data.email }, constraintsEmail)
        if (test) { validate['validateError'].email = ['กรุณาใส่ E-mail ให้ถูกต้อง'] }
      }
    }

    if (data.homePhoneNumber) {
      if (data.homePhoneNumber.length > 0) {
        if (data.homePhoneNumber.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.homePhoneNumber.match(regx)
          if (check === null) { validate['validateError'].homePhoneNumber = ['กรุณาใส่เป็นตัวเลข'] }
        } else {
          validate['validateError'].homePhoneNumber = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.phoneNumber) {
      if (data.phoneNumber.length > 0) {
        if (data.phoneNumber.length === 10) {
          let regx = /^[0-9]*$/
          const check = data.phoneNumber.match(regx)
          if (check === null) { validate['validateError'].phoneNumber = ['กรุณาใส่เป็นตัวเลข'] }
        } else {
          validate['validateError'].phoneNumber = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.fax) {
      if (data.fax.length > 0) {
        if (data.fax.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.fax.match(regx)
          if (check === null) { validate['validateError'].fax = ['กรุณาใส่เป็นตัวเลข'] }
        } else {
          validate['validateError'].fax = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (!validate['validateError']) validate['validateError'] = {}

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      validate['error'] = false
      this.setState({ validate })
      if (!openModalPerson) {
        data['academicEdit'] = (user._id || '')
        data['timestamp'] = gettime
        this.props.updatePerson(data, data._id)
      } else {
        data['academicCreat'] = (user._id || '')
        data['creatTime'] = gettime
        data['timestamp'] = gettime
        this.props.addPerson(data)
      }
      if (this.props.savedData) this.props.savedData()
      this.props.onCloseModalPerson()
    }
  }

  onCleaveChange = e => {
    let { currentPerson, openModalPerson } = this.props
    let { data, validate } = this.state
    if (!openModalPerson) data = { ...currentPerson, ...data }
    data[e.target.name] = e.target.rawValue
    validate.attrs = {}
    validate.validateError = {}
    validate.error = false

    this.setState({ data, validate })
  }

  _renderFormField = content => {
    const { validate } = this.state
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else { return <Form.Field {...content} error={validate.attrs[content.name]} /> }
  }

  handleSearch = e => {
    if (e.target.value.length === 3) {
      let query = {
        $or: [{ organizationName: { $regex: e.target.value } }]
      }
      this.props.listOrganizations(query)
    }
  }

  render () {
    let { validate, data } = this.state
    let {
      currentPerson,
      currentState,
      organizations,
      openModalPerson
    } = this.props

    if (!openModalPerson) {
      data = { ...currentPerson, ...data }
    }

    let organizationsOptions = organizations.data.map((item, idx) => ({
      text: item.organizationName,
      value: item._id
    }))

    organization = organizations.data.map((item, idx) => ({
      name: item.organizationName,
      id: item._id
    }))

    const haveOrg = [
      { key: '0', text: 'มี', value: 'yes' },
      { key: '1', text: 'ไม่มี', value: 'no' }
    ]

    let content = {
      organization: {
        control: Select,
        options: organizationsOptions,
        disabled: data.haveOrganization === 'no' || !data.haveOrganization,
        search: true
      },
      title: { control: Select, options: titleOptions },
      otherTitle: { control: Input, disabled: data.title !== 'อื่นๆ' },
      firstname: { control: Input },
      lastname: { control: Input },
      cid: { control: Input, maxLength: 13 },
      rank: { control: Input },
      role: {
        control: Select,
        fluid: true,
        multiple: true,
        selection: true,
        options: roleOptions,
        width: 5
      },
      address: { control: FormSearch },
      addressNo: { control: TextArea },
      email: { control: Input },
      homePhoneNumber: { control: Input, maxLength: 9 },
      phoneNumber: { control: Input, maxLength: 10 },
      fax: { control: Input, maxLength: 9 },
      haveOrganization: { control: Select, options: haveOrg }
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('individual', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (!openModalPerson) {
        if (content[key].control === Input) {
          content[key].value = data[key] || ''
        } else if (
          content[key].control === Select &&
          content[key].options === organizationsOptions
        ) {
          content[key].value = data[key]
          if (key === 'organization') {
            content[key].onSearchChange = this.handleSearch
          }
        } else if (key === 'role') {
          content[key].value = data[key] || []
        } else {
          content[key].value = data[key]
        }
      } else {
        if (content[key].control === FormSearch) {
          content[key].value = data[key]
        } else if (
          content[key].control === Select &&
          content[key].options === organizationsOptions
        ) {
          content[key].value = data[key]
          if (key === 'organization') {
            content[key].onSearchChange = this.handleSearch
          }
        }
      }
    })

    return (
      <Fragment>
        <Header style={{ fontFamily: 'supermarket' }} content='บุคลากร' />
        <Form error={validate.error}>
          <Form.Group widths='equal'>
            {this._renderFormField(content.haveOrganization)}
            {this._renderFormField(content.organization)}
          </Form.Group>
          <Form.Group widths='equal'>
            {this._renderFormField(content.title)}
            {this._renderFormField(content.otherTitle)}
          </Form.Group>
          <Form.Group widths='equal'>
            {this._renderFormField(content.firstname)}
            {this._renderFormField(content.lastname)}
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field error={validate.attrs['cid']}>
              <label>{'เลขบัตรประชาชน'}</label>
              <Cleave
                name='cid'
                maxLength='17'
                options={{
                  // numeral: true,
                  delimiter: ' ',
                  blocks: [1, 4, 5, 2, 1]
                }}
                onChange={this.onCleaveChange}
                value={data.cid || ''}
              />
            </Form.Field>
            {this._renderFormField(content.rank)}
          </Form.Group>
          <Form.Group widths='equal'>
            {this._renderFormField(content.role)}
          </Form.Group>
          {this._renderFormField(content.addressNo)}
          {this._renderFormField(content.address)}
          <Form.Group widths='equal'>
            {this._renderFormField(content.email)}
            {this._renderFormField(content.phoneNumber)}
          </Form.Group>
          <Form.Group widths='equal'>
            {this._renderFormField(content.homePhoneNumber)}
            {this._renderFormField(content.fax)}
          </Form.Group>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('individual', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Button
            onClick={this.handleSubmit}
            style={{ fontFamily: 'supermarket' }}
            color='blue'
            loading={currentState.isSaving}
            disabled={validate.error}
          >
            บันทึก
          </Button>
        </Form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentPerson: state.individuals.currentData,
  currentState: state.individuals,
  organizations: state.organizations,
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  addPerson: data => dispatch(individualsActions.save(data)),
  updatePerson: (data, id) =>
    dispatch(individualsActions.save(data, id, 'POST')),
  getPerson: id => dispatch(individualsActions.fetchOne(id)),
  listOrganizations: query =>
    dispatch(organizationsActions.fetchList({ query: query }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentPerson)
