const toggleSelectedOptions = (e, { options, value }) => {
  if (!e) return
  const index = options.findIndex(x => x.value === value)
  if (index === -1) return
  const text = options[index].text
  let dl = e.target.querySelector('div.menu')
  if (e.target.className.indexOf('icon') > -1 || e.target.className.indexOf('text') > -1) {
    dl = e.target.parentNode.querySelector('div.menu')
  }
  dl && dl.querySelectorAll('div.item').forEach(el => {
    el.style.display = el.innerText === text ? 'none' : 'block'
  })
}

module.exports = {
  onClose: toggleSelectedOptions,
  onOpen: toggleSelectedOptions
}