import React, { Component } from 'react'
import './Step.css'
import { Link } from 'react-router-dom'
import { Button, Icon, } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as projectActions } from 'Redux/reducers/projects'

const list = [
  {
    title: 'ข้อกำหนด', link: '/project/torflow', menu: [{ title: 'รายละเอียดข้อกำหนด', link: '/project/torflow' }]
  },
  {
    title: 'ส่งข้อเสนอ', link: '/project/information', menu: [
      { title: 'ข้อมูลพื้นฐาน', link: '/project/information' },
      { title: 'เป้าประสงค์', link: '/project/objective' },
      { title: 'พื้นที่ดำเนินการ', link: '/project/area' },
      { title: 'กลั่นกรอง', link: '/project/pageScreen' },
      { title: 'งวดงานงวดเงิน', link: '/project/withdrawTime' },
    ]
  },
  {
    title: 'สัญญา', link: '/project/pagePromise', menu: [{ title: 'ทำสัญญา', link: '/project/pagePromise' }]
  },
  {
    title: 'ความก้าวหน้า', link: '/project/tabProgressProject', menu: [
      { title: 'ความก้าวหน้ารายงวด', link: '/project/tabProgressProject' },
      { title: 'เบิกเงินรายงวด', link: '/project/tabWithdrawForm' },
      { title: 'ขยายเวลาดำเนินโครงการ', link: '/project/ExtendTime', icon: 'clock outline' }
    ]
  },
  {
    title: 'ปิด/ยุติ', link: '/project/resultProgress', menu: [
      { title: 'สรุปผลข้อมูลการดำเนินโครงการ', link: '/project/resultProgress' },
      { title: 'ปิดโครงการ', link: '/project/endForm' },
      { title: 'ยุติโครงการ', link: '/project/pageQuitProject' },
      // { title: 'ใบสำคัญรับเงิน', link: '/project/bill', icon: 'dont' }
    ]
  },
]

const CustomButton = (props) => (
  <div className="step-button-container">
    <div className="step-button" onClick={() => {
      props.history.push(props.data.link === '/project/torflow' ? props.data.link + '/' + props.tors_id : props.data.link + '/' + props.id)
    }}>
      <div className="step-button-inner">
        {props.data.title || ''}
      </div>
    </div>
    <div className='step-button-list'>
      {props.data.menu.map((m, i) => {
        return <Button key={i} as={Link} to={m.link === '/project/torflow' ? m.link + '/' + (props.tors_id || '') : m.link + '/' + (props.id || '')} labelPosition='right'>
          <Icon name='genderless' />
          {m.title}
        </Button>
      })}
    </div>
  </div>
)

class Step extends Component {
  componentDidMount() {
    this.props.getProposal(localStorage.getItem('project_id'))
  }

  render() {
    const { project } = this.props
    let tor_id, project_id = ''
    if (project) {
      tor_id = project.tor_id
      project_id = project._id
    }
    return <div className="step-container">
      {list.map((li, i) => (
        <CustomButton key={i} id={project_id} data={li} history={this.props.history} tors_id={tor_id} />
      ))}
    </div>
  }
}

const mapStateToProps = state => ({
  project: state.projects.currentData,
});

const mapDispatchToProps = dispatch => ({
  getProposal: (id) => dispatch(projectActions.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Step)
