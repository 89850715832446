import React, { Component } from 'react'
import './Admin.css'
import Paper from 'Components/Paper'
import SidebarBorrow from "Containers/Admin/SidebarAdmin"
import { routes } from 'Containers/Router'
import { Route, HashRouter, Switch } from 'react-router-dom'
import { StepBudget } from '../Admin'
import { connect } from 'react-redux'

const Wrapper = ({ match, component, history }) => {
  const children = React.cloneElement(component, { match, history })
  return (<Paper history={history} match={match}>{children}</Paper >)
}

class Admin extends Component {
  render() {
    const { path } = this.props.match
    const { match, history,role } = this.props
    if(role !== 'Admin') {
      window.location.href = '/'
    }
    return (
      <div >
        <SidebarBorrow {...this.props}>
          <HashRouter>
            <Switch>
              <Route
                path='/admin/fundings'
                component={() => <div>
                  <Paper match={match} history={history}>
                    <StepBudget {...this.props} />
                    {/* <FundingAddorUp {...this.props} /> */}
                  </Paper>
                  {/* <SearchAdminResult {...this.props}/> */}
                </div>
                } />
              {routes.map((r, i) => <Route key={i}
                exact={r.exact || false}
                path={path + r.path}
                component={() => {
                  return <Wrapper {...this.props} component={r.component} />
                }} />)}
            </Switch>
          </HashRouter>
        </SidebarBorrow>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  role: state.role
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
