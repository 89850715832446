import React, { Component, Fragment } from 'react';
import { Form, Button, Header, Message, Input, Select, TextArea } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { constraints as OrganizationConstraints } from '../containers/Project/RegisProject/RegisConstraints'
import { constraints as OrganizeCondiConstraints } from '../containers/Project/RegisProject/RegisConstraints'
import { actions as organizationActions } from 'Redux/reducers/organizations'
import { FormSearch } from 'Utils'
import { localTh } from 'Utils'
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react';
import jwt from 'jsonwebtoken'
import { GetServerTime as GetTime } from 'Utils'

const titleOptions = [
  { value: 'นาย', text: 'นาย' },
  { value: 'นาง', text: 'นาง' },
  { value: 'นางสาว', text: 'นางสาว' },
  { value: 'อื่นๆ', text: 'อื่นๆ(ระบุ)' },
]
let gettime = ''

class ComponentOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      cid: undefined,
      data: {},
      validate: { error: false, validateError: null, attrs: {} }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentOrganization && nextProps.checkOrganization) {
      let tmp = { ...nextProps.currentOrganization }
      let { data } = this.state;
      this.setState({ data: { ...tmp, ...data } });
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  validateCid = (value) => {
    if (value === null) return '^กรุณากรอกเลขบัตรประชาชน'
    else if (value.length !== 13) return '^ต้องมี 13 หลัก'
    else {
      var num = value.replace(/-/g, '');
      let digits = num.split("")
      // .map((st) => parseInt(st));
      if (digits[0] > 0 || digits[0] < 9) {
        let result = 0;
        for (var i = 0; i < 12; i++) {
          result += digits[i] * (13 - i)
        }
        result = (11 - (result % 11)) % 10;
        if (result !== parseInt(digits[12], 10)) {
          return '^เลขบัตรประชาชนไม่ถูกต้อง'
        }
      } else if (value[0] === 'P') return null
    }
  }

  onCleaveChange = (e) => {
    let { currentPerson, openModalPerson } = this.props
    let { data, validate } = this.state;
    if (!openModalPerson) data = { ...currentPerson, ...data }
    data[e.target.name] = e.target.rawValue;
    validate.attrs = {}
    validate.validateError = {}
    validate.error = false
    this.setState({ data, validate });
  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate } = this.state;
    let { currentOrganization, checkOrganization } = this.props;

    if (this.props.currentOrganization && checkOrganization) data = { ...currentOrganization, ...data }

    if (name === 'governmentAgencies' || name === 'legalEntity') {
      data[name] = checked;
      if (data.governmentAgencies) data.legalEntity = true
    } else if (name === 'nogovernmentAgencies') {
      data.governmentAgencies = false
    } else if (name === 'nolegalEntity') {
      data.legalEntity = false
    } else data[name] = value;

    validate.attrs = {};
    validate.validateError = {}
    validate.error = false;

    this.setState({ data, validate });
  }

  validateCid = value => {
    if (!value) return '^กรุณากรอกเลขบัตรประชาชน'
    else if (value.length !== 13) return '^ต้องมี 13 หลัก'
    else {
      var num = value.replace(/-/g, '')
      let digits = num.split('')
      // .map((st) => parseInt(st));
      if (digits[0] > 0 || digits[0] < 9) {
        let result = 0
        for (var i = 0; i < 12; i++) {
          result += digits[i] * (13 - i)
        }
        result = (11 - (result % 11)) % 10
        if (result !== parseInt(digits[12], 10)) {
          return 'เลขบัตรประชาชนไม่ถูกต้อง'
        }
      } else if (value[0] === 'P') return null
    }
  }

  handleSubmit = () => {
    let { data, validate } = this.state;
    let { currentOrganization, checkOrganization, auth } = this.props;

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

    if (this.props.currentOrganization && checkOrganization) data = { ...currentOrganization, ...data }

    validate.attrs = {};

    OrganizationConstraints.OrganizationConstraints.governmentAgencies = { presence: { allowEmpty: false, message: "^ต้องระบุ" } }
    OrganizationConstraints.OrganizationConstraints.legalEntity = { presence: { allowEmpty: false, message: "^ต้องระบุ" } }
    OrganizationConstraints.OrganizeCondiConstraints.governmentAgencies = { presence: { allowEmpty: false, message: "^ต้องระบุ" } }
    OrganizationConstraints.OrganizeCondiConstraints.legalEntity = { presence: { allowEmpty: false, message: "^ต้องระบุ" } }
    if (data.organizationBossTitle !== 'อื่นๆ') {
      validate['validateError'] = validatejs(data, OrganizationConstraints.OrganizationConstraints)

    } else {
      validate['validateError'] = validatejs(data, OrganizeCondiConstraints.OrganizeCondiConstraints)
    }

    if (validate['validateError'] === undefined) {
      validate['validateError'] = {}
    }

    if (data.organizationBossid) {
      if (data.organizationBossid.length > 0) {
        if (data.organizationBossid.length !== 13) {
          validate['validateError'].organizationBossid = ['กรุณาใส่ให้ครบ 13 หลัก']
        } else {
          var regx = /^[0-9]*$/
          const check = data.organizationBossid.match(regx)
          if (check !== null) {
            let validCID = this.validateCid(data.organizationBossid)
            if (validCID !== undefined) {
              validate['validateError'].organizationBossid = [validCID]
            }
          } else {
            validate['validateError'].organizationBossid = ['กรุณาใส่เลขบัตรเป็นตัวเลข']
          }
        }
      }
    }

    if (data.organizationEmail) {
      if (data.organizationEmail.length > 0) {
        var constraintsEmail = {
          email: {
            email: true
          }
        };
        let test = validatejs({ email: data.organizationEmail.trim() }, constraintsEmail)
        if (test) validate['validateError'].organizationEmail = ['กรุณาใส่ E-mail ให้ถูกต้อง']
      }
    }

    if (data.organizationTel) {
      if (data.organizationTel.length > 0) {
        if (data.organizationTel.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.organizationTel.match(regx)
          if (check === null) validate['validateError'].organizationTel = ['กรุณาใส่เป็นตัวเลข']
        } else {
          validate['validateError'].organizationTel = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.organizationPhone) {
      if (data.organizationPhone.length > 0) {
        if (data.organizationPhone.length === 10) {
          let regx = /^[0-9]*$/
          const check = data.organizationPhone.match(regx)
          if (check === null) validate['validateError'].organizationPhone = ['กรุณาใส่เป็นตัวเลข']
        } else {
          validate['validateError'].organizationPhone = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.organizationFax) {
      if (data.organizationFax.length > 0) {
        if (data.organizationFax.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.organizationFax.match(regx)
          if (check === null) validate['validateError'].organizationFax = ['กรุณาใส่เป็นตัวเลข']
        } else {
          validate['validateError'].organizationFax = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.organizationBossEmail) {
      if (data.organizationBossEmail.length > 0) {
        let constraintsEmail = {
          email: {
            email: true
          }
        };
        let test = validatejs({ email: data.organizationBossEmail.trim() }, constraintsEmail)
        if (test) validate['validateError'].organizationBossEmail = ['กรุณาใส่ E-mail ให้ถูกต้อง']
      }
    }

    if (data.organizationBossTel) {
      if (data.organizationBossTel.length > 0) {
        if (data.organizationBossTel.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.organizationBossTel.match(regx)
          if (check === null) validate['validateError'].organizationBossTel = ['กรุณาใส่เป็นตัวเลข']
        } else {
          validate['validateError'].organizationBossTel = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.organizationBossPhone) {
      if (data.organizationBossPhone.length > 0) {
        if (data.organizationBossPhone.length === 10) {
          let regx = /^[0-9]*$/
          const check = data.organizationBossPhone.match(regx)
          if (check === null) validate['validateError'].organizationBossPhone = ['กรุณาใส่เป็นตัวเลข']
        } else {
          validate['validateError'].organizationBossPhone = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    if (data.organizationBossFax) {
      if (data.organizationBossFax.length > 0) {
        if (data.organizationBossFax.length === 9) {
          let regx = /^[0-9]*$/
          const check = data.organizationBossFax.match(regx)
          if (check === null) validate['validateError'].organizationBossFax = ['กรุณาใส่เป็นตัวเลข']
        } else {
          validate['validateError'].organizationBossFax = ['กรุณาใส่ตัวเลขให้ครบ']
        }
      }
    }

    let validCID = this.validateCid(data.cid)
    if (validCID !== undefined) {
      validate['validateError'].cid = [validCID]
    }

    if (!validate['validateError']) validate['validateError'] = {}

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      validate['error'] = false
      this.setState({ validate });
      if (this.props.currentOrganization && checkOrganization) {
        data['academicEdit'] = (user._id || '')
        data['timestamp'] = gettime
        this.props.updateOrganization(data, data._id)
      } else {
        data['academicCreat'] = (user._id || '')
        data['creatTime'] = gettime
        data['timestamp'] = gettime
        this.props.addOrganization(data)
      }
      this.props.onCloseModalPerson()
      if (this.props.history) this.props.history.push('/Area');
    }
  }

  _renderFormField = (content) => {
    const { validate } = this.state;
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  render() {
    let { validate, data } = this.state
    let { currentOrganization, currentState, checkOrganization } = this.props;

    console.log(currentOrganization)

    if (checkOrganization) data = { ...currentOrganization, ...data };

    let content = {
      'organizationName': { control: Input },
      'organizationCode': { control: Input },
      'organizationAdress': { control: FormSearch },
      'organizationAdressNo': { control: TextArea },
      'organizationEmail': { control: Input, width: 4 },
      'organizationPhone': { control: Input, width: 4, maxLength: 10 },
      'organizationTel': { control: Input, width: 4, maxLength: 9 },
      'organizationFax': { control: Input, width: 4, maxLength: 9 },
      'organizationBossTitle': { control: Select, options: titleOptions },
      'organizationBossOtherTitle': { control: Input, disabled: data.organizationBossTitle !== 'อื่นๆ' },
      'organizationBossName': { control: Input },
      'organizationBossLastname': { control: Input },
      'organizationBossid': { control: Input, width: 8 },
      'organizationBossAdress': { control: FormSearch },
      'organizationBossAdressNo': { control: TextArea },
      'organizationBossEmail': { control: Input, width: 4 },
      'organizationBossPhone': { control: Input, width: 4, maxLength: 10 },
      'organizationBossTel': { control: Input, width: 4, maxLength: 9 },
      'organizationBossFax': { control: Input, width: 4, maxLength: 9 },
      'cid': { control: Input, width: 8, maxLength: 13 },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('organization', key);
      content[key].name = key;
      content[key].onChange = this.handleChange;
      if (content[key].control === FormSearch) {
        content[key].value = data[key];
      } else {
        content[key].value = data[key] || '';
      }
    });
    return (
      <Fragment>
        <Header style={{ fontFamily: 'supermarket' }} content='องค์กร' />
        <Form error={validate.error}>
          <Form.Group widths='equal'>
            {this._renderFormField(content.organizationName)}
            <Form.Field style={{ 'paddingTop': '20px' }}>
              <Form.Checkbox label='เป็นหน่วยงานภาครัฐ' name='governmentAgencies' checked={data.governmentAgencies} onChange={this.handleChange} error={validate.attrs['governmentAgencies']} />
              <Form.Checkbox label='ประเภทเป็นนิติบุคคล' name='legalEntity' checked={data.legalEntity} onChange={this.handleChange} disabled={data.governmentAgencies} error={validate.attrs['legalEntity']} />
            </Form.Field>
            <Form.Field style={{ 'paddingTop': '20px' }}>
              <Form.Checkbox label='เป็นหน่วยงานนอกภาครัฐ' name='nogovernmentAgencies' checked={data.governmentAgencies === false} onChange={this.handleChange} error={validate.attrs['governmentAgencies']} />
              <Form.Checkbox label='ประเภทไม่เป็นนิติบุคคล' name='nolegalEntity' checked={data.legalEntity === false} onChange={this.handleChange} disabled={data.governmentAgencies} error={validate.attrs['legalEntity']} />
            </Form.Field>
          </Form.Group>
          {this._renderFormField(content.cid)}
          {this._renderFormField(content.organizationAdressNo)}
          {this._renderFormField(content.organizationAdress)}
          <Form.Group widths='equal'>
            {this._renderFormField(content.organizationEmail)}
            {this._renderFormField(content.organizationPhone)}
            {this._renderFormField(content.organizationTel)}
            {this._renderFormField(content.organizationFax)}
          </Form.Group>
          <Header style={{ fontFamily: 'supermarket' }} content='หัวหน้าองค์กร' />
          <Form.Group widths='equal'>
            {this._renderFormField(content.organizationBossTitle)}
            {this._renderFormField(content.organizationBossOtherTitle)}
          </Form.Group>
          <Form.Group widths='equal'>
            {/* {this._renderFormField(content.organizationBossTitle)} */}
            {this._renderFormField(content.organizationBossName)}
            {this._renderFormField(content.organizationBossLastname)}
          </Form.Group>
          <Form.Group >
            <Form.Field error={validate.attrs['organizationBossid']} width={8}>
              <label>{'เลขบัตรประชาชน'}</label>
              <Cleave name='organizationBossid'
                maxLength='17'
                options={{
                  delimiter: ' ',
                  blocks: [1, 4, 5, 2, 1],
                }}
                onChange={this.onCleaveChange}
                value={data.organizationBossid || ''} />
            </Form.Field>
          </Form.Group>
          {this._renderFormField(content.organizationBossAdressNo)}
          {this._renderFormField(content.organizationBossAdress)}
          <Form.Group >
            {this._renderFormField(content.organizationBossEmail)}
            {this._renderFormField(content.organizationBossPhone)}
            {this._renderFormField(content.organizationBossTel)}
            {this._renderFormField(content.organizationBossFax)}
          </Form.Group>

          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {/* <Message.Item >{'เลขบัตรประชาชน - ' + cid}</Message.Item> */}
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('organization', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
          <Button onClick={this.handleSubmit} style={{ fontFamily: 'supermarket' }} color='blue' loading={currentState.isSaving} disabled={validate.error}>บันทึก</Button>
        </Form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentData,
  currentState: state.organizations,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  addOrganization: (data) => dispatch(organizationActions.save(data)),
  updateOrganization: (data, id) => dispatch(organizationActions.save(data, id, 'POST')),
  // listOrganization: (id) => dispatch(organizationActions.fetchOne(id)),
  // updateForm: (data) => dispatch(formActions.update(data, PjmConstraints.PjmConstraints))
})


export default connect(mapStateToProps, mapDispatchToProps)(ComponentOrganization);
