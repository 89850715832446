import React, { Component } from 'react'
import './Paper.css'
import { Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'

class Paper extends Component {
  onBackClick = () => {
    let { match, tor, project, borrow, shareuser } = this.props
    const sub = match.params.sub

    let checkShare = []

    shareuser.forEach(x => {
      checkShare.push(x.projectShareId)
    });

    if (!project) {
      project = {}
      project['_id'] = localStorage.getItem('project_id')
    }

    if (!borrow) {
      borrow = {}
      borrow['_id'] = localStorage.getItem('borrow_id')
    }


    let checkShareId = checkShare.indexOf(project._id)
    if (sub.toLowerCase() === 'menu' || sub.toLowerCase() === 'torform') {
      if (checkShareId !== -1) this.props.history.push('/project/search')
      else if (tor && tor._id && !match.params.callback) this.props.history.push('/project/torinfo/' + tor._id)
      else this.props.history.push('/project/search')
    }
    else if (sub.toLowerCase() === 'torflow') this.props.history.push('/project/menu/' + project._id)
    else if (sub.toLowerCase() === 'torinfo') this.props.history.push('/project/search')
    else if (sub.toLowerCase() === 'listorganization') this.props.history.push('/')
    else if (sub.toLowerCase() === 'listindividuals') this.props.history.push('/project/listorganization/' + project._id)
    else if (sub.toLowerCase() === 'fundings') this.props.history.push('/')
    else if (sub.toLowerCase() === 'bill') this.props.history.push('/borrow/tapborrow/' + borrow._id)
    else if (sub.toLowerCase() === 'tapburnproject') this.props.history.push('/burnproject/searchburnproject')
    else if (sub.toLowerCase() === 'tapborrow') {
      this.props.history.push('/borrow/searchBorrow')
    } else if (sub.toLowerCase() === 'fundingsgroup') {
      this.props.history.push('/admin/fundings')
    } else if (sub.toLowerCase() === 'register') this.props.history.push('/')
    else if (sub.toLowerCase() === 'stepaddprofesburn') this.props.history.push('/')
    else if (sub.toLowerCase() === 'graphoffice') this.props.history.push('/')
    else if (sub.toLowerCase() === 'graphyear') this.props.history.push('/')
    else if (sub.toLowerCase() === 'graphstatus') this.props.history.push('/')
    else if (sub.toLowerCase() === 'graphprovince') this.props.history.push('/')
    else if (sub.toLowerCase() === 'graphtargetgroup') this.props.history.push('/')
    else if (sub.toLowerCase() === 'graphpersonoffice') this.props.history.push('/graph/graphoffice')
    else {
      this.props.history.push('/project/menu/' + project._id)
    }
  }

  render() {
    const isOpen = this.props.sidebar.isOpen
    return (
      <div className={"paper-container " + (isOpen ? '' : 'full')}>
        <Button onClick={this.onBackClick}><Icon name='angle left' />ย้อนกลับ</Button>
        <br />
        <br />
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tor: state.tors.currentData,
  project: state.projects.currentData,
  sidebar: state.sidebar,
  shareuser: state.shareuser.data
})

export default connect(mapStateToProps)(Paper)
