import React, { Component, Fragment } from 'react'
import './Register.css'
import passwordHash from 'password-hash'
import validatejs from 'validate.js'
import {
  Form,
  Button,
  Input,
  Modal,
  Table,
  Menu,
  Segment,
  Dropdown
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as userActions } from 'Redux/reducers/user'
import { constraints as RegisterConstraints } from './RegisterConstraints'
import { constraints as RegisterConditionConstraints } from './RegisterConstraints'
import AddUser from './AddUser';


const rolesOptions = [
  { value: 'All', text: 'ทั้งหมด' },
  { value: 'Academic', text: 'นักวิชาการ' },
  { value: 'Contract', text: 'ศูนย์สัญญา' },
  { value: 'Staff', text: 'เจ้าหน้าที่' },
  { value: 'Admin', text: 'ผู้ดูแลระบบ' },
  { value: "auditor", text: "ผู้ตรวจสอบบัญชี" }
]


class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      onSaveSuccess: false,
      query: {
        sort: { user: 1 },
        limit: 10,
        skip: 0,
      },
      modalUser: false,
      userData: {}
    };
  }

  componentDidMount = () => {
    let query = {
      sort: { user: 1 },
      limit: 10,
      skip: 0,
      query: {}
    }
    this.props.listUser(query)
    this.setState({ query: query })
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state

    validate.attrs = {};
    validate.error = false

    data[name] = value
    this.setState({ data })

  }


  handleSubmit = () => {
    const { data, validate } = this.state

    validate.attrs = {};
    if (data.title === 'อื่นๆ') {
      validate['validateError'] = validatejs(data, RegisterConditionConstraints.RegisterConditionConstraints)
    } else {
      validate['validateError'] = validatejs(data, RegisterConstraints.RegisterConstraints)
    }

    if (data.title === 'อื่นๆ') {
      data.title = data.titleOther
    }

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      if (data.password) {
        data['password'] = passwordHash.generate(data.password.trim());
      }
      // this.props.addUser(data)
      this.setState({ onSaveSuccess: true });
      setTimeout(() => {
        this.setState({ onSaveSuccess: false });
      }, 3000)
      validate['error'] = false
      this.setState({ validate });
    }
  }

  onSearch = (e, { value }) => {
    const { query } = this.state
    query['query'] = {}
    if ((value.toLowerCase()).match(/^[a-z]/)) {
      query['query']['user'] = {}
      query['query']['user']['$regex'] = value
    } else {
      let test = value.split(' ')
      test.forEach((item, idx) => {
        if (item.length === 0) {
          test.splice(idx, 1)
        }
      })
      query['query']['firstname'] = {}
      query['query']['firstname']['$regex'] = test[0] || ''
      if (test[1]) {
        query['query']['lastname'] = {}
        query['query']['lastname']['$regex'] = test[1] || ''
      }
      // query['query']['$or'] = []
      // query['query']['$or'].push({ 'firstname': { '$regex': value } })
      // query['query']['$or'].push({ 'lastname': { '$regex': value } })
      // query['query']['$or'].push({ 'workGroup': { '$regex': value } })
      // query['query']['$or']['firstname']['$regex'] = value
    }
    if (value.length >= 3) {
      this.props.listUser(query)
      // this.setState({ search: search })
    } else if (value.length === 0) {
      query['query'] = {}
      this.props.listUser(query)
      // this.setState({ search: search })
    }
  }

  handleFilter = (e, { value }) => {
    const { query } = this.state

    if (value === 'All') value = ''

    query['query'] = {}
    query['query']['roles'] = {}
    query['query']['roles']['$regex'] = value
    this.props.listUser(query)
  }

  paging = (skip) => {
    let { query } = this.state
    query.skip = query.skip + skip
    this.props.listUser(query)
    this.setState({ query })
  }

  handleAddUser = () => {
    this.setState({ modalUser: true, userData: undefined })
  }

  handleCloseModalUser = () => {
    this.setState({ modalUser: false })
  }

  editUser = (item) => {
    this.setState({ modalUser: true, userData: item })
  }

  render() {
    const { users, user } = this.props
    const { modalUser, userData } = this.state


    // console.log(passwordHash.generate('songkran@EEF'))
    return (
      <Fragment>
        <Segment>
          <Menu secondary >
            <Menu.Item fitted >
              <Input onChange={this.onSearch} icon='search' placeholder={'USERNAME หรือ ชื่อ..'} />
            </Menu.Item>
            <Menu.Item fitted>
              <Form>
                <Dropdown
                  placeholder='กรองข้อมูล'
                  icon='filter'
                  labeled
                  button
                  className='icon search-dropdown'
                  onChange={this.handleFilter}
                  options={rolesOptions} />
              </Form>
            </Menu.Item>
            <Menu.Item fitted>
            </Menu.Item>
          </Menu>
        </Segment>
        {users && users.length === 0 &&
          <Segment className="ui placeholder segment" >
            <div className="ui icon header">
              <i className="dont icon"></i>
              <h2 style={{ fontFamily: 'supermarket' }}>
                ไม่มีข้อมูลผู้ใช้งาน
              </h2>
              {/* <Button content='เพิ่ม' onClick={this.handleAddUser}/> */}
            </div>
          </Segment>
        }
        {users && users.length !== 0 &&
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>USERNAME</Table.HeaderCell>
                <Table.HeaderCell>ชื่อ-นามสกุล</Table.HeaderCell>
                <Table.HeaderCell>กลุ่มงาน</Table.HeaderCell>
                <Table.HeaderCell>แก้ไข</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users.map((item, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>{item.user}</Table.Cell>
                  <Table.Cell>{item.title + ' ' + item.firstname + ' ' + item.lastname}</Table.Cell>
                  <Table.Cell>{item.workGroup}</Table.Cell>
                  <Table.Cell textAlign='center'><Button icon='edit' onClick={this.editUser.bind(this, item)} /></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            <Table.Footer fullWidth >
              <Table.Row>
                <Table.HeaderCell colSpan='1'><Button content='ลงทะเบียน' onClick={this.handleAddUser} /></Table.HeaderCell>
                <Table.HeaderCell colSpan='3' textAlign='right'>
                  {user.params && user.params.skip !== 0 &&
                    <Button content='ย้อนกลับ' onClick={this.paging.bind(null, -10)} />
                  }
                  {
                    users && users.length >= 10 && users.length !== 0 &&
                    <Button content='ถัดไป' onClick={this.paging.bind(null, +10)} />
                  }
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        }
        {users && users.length === 0 &&
          <Button content='ย้อนกลับ' onClick={this.paging.bind(null, -10)} />
        }
        {<Modal
          open={modalUser}
          onClose={this.handleCloseModalUser}
          closeOnDimmerClick={false}
          size='small'
          closeIcon>
          <Modal.Content>
            <AddUser onCloseModal={this.handleCloseModalUser} userData={userData} />
          </Modal.Content>
        </Modal>}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  users: state.user.data
});

const mapDispatchToProps = dispatch => ({
  addUser: (data) => dispatch(userActions.save(data)),
  listUser: (query) => dispatch(userActions.fetchList(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
