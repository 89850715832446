var InfoConstraints = {
    nameTh: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    startDate: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    endDate: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    budget: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    typeOffice: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    valueGroup: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      },
    },
    valuePlan: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      },
    },
    valueActivity: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      },
    },
    academicID : {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      },
    },
  }
  
  var Info2Constraints = {
    nameTh: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    startDate: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    endDate: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    budget: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    typeOffice: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    groupsPlan: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      },
    },
    plan: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      },
    },
    activity: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      },
    },
    academicID: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      },
    },
    principle: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      },
    },
  }

  var ObjectiveArrayConstraints = {
    objective: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
  }

  var TargetGroupArrayConstraints = {
    targetGroup: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
  }

  var OperationPlanArrayConstraints = {
    operationPlan: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    startDate: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    endDate: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    budget: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
  }


  var ResultArrayConstraints = {
    result: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
  }

  var ScreenArrayConstraints = {
    screenProfessional: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    // screenresult: {
    //   presence: {
    //     allowEmpty: false,
    //     message: '^ต้องระบุ'
    //   }
    // },
  }


  var ApproveConstraints = {
    checkProjectDate: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
  }

  var ApproveArrayConstraints = {
    budgetContent: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
    budget: {
      presence: {
        allowEmpty: false,
        message: '^ต้องระบุ'
      }
    },
  }

  
  
  let constraints = {
    InfoConstraints: InfoConstraints,
    Info2Constraints:Info2Constraints,
    ObjectiveArrayConstraints:ObjectiveArrayConstraints,
    TargetGroupArrayConstraints:TargetGroupArrayConstraints,
    OperationPlanArrayConstraints:OperationPlanArrayConstraints,
    ResultArrayConstraints:ResultArrayConstraints,
    ScreenArrayConstraints:ScreenArrayConstraints,
    ApproveConstraints:ApproveConstraints,
    ApproveArrayConstraints:ApproveArrayConstraints
  }
  
  
  module.exports = { constraints }
  