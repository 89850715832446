const initialState = {
  "filter": {},
  "sortBy": "nameTh",
  "sortValue": "ascendant",
  "type": "tor" //proposal or tor
}

const search = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_SEARCH':
      return action.search
    default:
      return state
  }
}
export default search
