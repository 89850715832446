import React, { Component, Fragment } from 'react'
import {
  Form,
  Header,
  Button,
  Select,
  Input,
  Checkbox,
  Message,
  Segment,
  Transition,
  Icon,
  Confirm
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
import { actions as projectActions } from 'Redux/reducers/projects'
import { generate } from 'Redux/reducers/counting'
import { localTh } from 'Utils'
import { constraints as WithdrawFormConstraints } from './ProgressConstraints'
import { constraints as WithdrawFormConditionConstraints } from './ProgressConstraints'
import jwt from 'jsonwebtoken'
// import { ThaiDate } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'

let gettime

let noBookTime

let currentNoBook

let noDisburse

let currentNoDisburse

let thisYear = ''
let checkNobook

let checkNoDisburse = true

// let noBook = []
const MoneyLastPeriodOptions = [
  { key: 'y', text: 'ใช้เกิน', value: 'overuse' },
  { key: 'n', text: 'คงเหลือ', value: 'balance' }
]

const PaymentOptions = [
  { key: 'y', text: 'ส่งเช็คทางไปรษณีย์', value: 'delivery' },
  { key: 'n', text: 'โอนเงินเข้าบัญชีธนาคาร', value: 'bank' }
]

class WithdrawForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileUpload: [],
      type: 'single',
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      onSaveSuccess: false,
      onSaveSuccessSP: false,
      validateSP: false,
      checkValidate: false
    }
  }

  componentDidMount() {
    const { data } = this.state
    const { match } = this.props

    checkNobook = false
    checkNoDisburse = false
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(new Date(value).getFullYear(), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date(new Date(value).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
    currentNoBook = undefined
    noBookTime = undefined
    currentNoDisburse = undefined
    noDisburse = undefined
    if (
      this.props.counter.counter[
      'noBookInside-' + (new Date(gettime).getFullYear() + 543)
      ]
    ) {
      currentNoBook = this.props.counter.counter[
        'noBookInside-' + (new Date(gettime).getFullYear() + 543)
      ]
    }

    if (
      this.props.counter.counter[
      'noDisburse' + (new Date(gettime).getFullYear() + 543)
      ]
    ) {
      currentNoDisburse = this.props.counter.counter[
        'noDisburse' + (new Date(gettime).getFullYear() + 543)
      ]
    }

    if (Object.getOwnPropertyNames(this.props.counter.counter).length > 0) {
      Object.keys(this.props.counter.counter).forEach(key => {
        delete this.props.counter.counter[key]
      })
    }
    this.setState({ data })
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
      }
    })
  }

  componentWillReceiveProps(nextProps) {

    // var promise1 = new Promise(function (resolve, reject) {
    //   GetTime(receive => {
    //     gettime = receive
    //     resolve(gettime)
    //   })
    // })

    // promise1.then(function (value) {
    //   if (value) {
    //     // let checkYear = new Date(new Date(value).getFullYear(), 8, 30).getTime()
    //     // if (value > checkYear) {
    //     //   thisYear = new Date(new Date(value).getFullYear() + 544).getTime()
    //     // } else {
    //     thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
    //     // }
    //   }
    // })

    console.log('thisYear', thisYear)

    // if (!thisYear || thisYear === '') this.getTimeFuction()

    //let { currentProposal, index, counter, special } = nextProps
    let { currentProposal, counter } = nextProps
    let countWithdraw = currentProposal.withDrawTime.length
    if (
      currentProposal.withdrawForm &&
      currentProposal.withdrawForm.length === 0
    ) {
      for (let i = 0; i < countWithdraw; i++) {
        currentProposal.withdrawForm.push({ dateOfReceipt: '' })
      }
    }

    // if (!special) {
    //   if (!nextProps.currentProposal.withdrawForm[index].withdrawPeriodAmount || !nextProps.currentProposal.withdrawForm[index].noBookTakeMoney) {
    //     let tmp = { ...nextProps.currentProposal }
    //     tmp.withdrawForm[index].dateOfReceipt =
    //       tmp.withDrawTime[index].submitTime || ''
    //     tmp.withdrawForm[index].withdrawPeriodAmount =
    //       tmp.withDrawTime[index].sum
    //     tmp.withdrawForm[index].withdrawCompensationManager =
    //       tmp.withDrawTime[index].mainCom
    //     tmp.withdrawForm[index].withdrawCompensationProject =
    //       tmp.withDrawTime[index].supCom
    //     tmp.withdrawForm[index].withdrawCostOfOperation =
    //       tmp.withDrawTime[index].manage
    //     tmp.withdrawForm[index].withdrawServiceFee =
    //       tmp.withDrawTime[index].charge
    //     this.setState({ data: { ...tmp } })
    //   }
    // }

    // if (nextProps.currentProposal) {
    //   if(nextProps.currentProposal.special !== "yes"){
    //     if (
    //       index > 1 &&
    //       !nextProps.currentProposal.progressPeriod[index - 1].sendWorkTime
    //     ) {
    //       this.setState({ checkValidate: true })
    //     } else {
    //       this.setState({ checkValidate: false })
    //     }
    //   }
    // }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(new Date(value).getFullYear(), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date(new Date(value).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    // if (!thisYear || thisYear === '') this.getTimeFuction()

    if (counter.counter['noBookInside-' + thisYear]) {
      if (currentNoBook) {
        if (currentNoBook !== counter.counter['noBookInside-' + thisYear]) {
          currentNoBook = counter.counter['noBookInside-' + thisYear]
          noBookTime = counter.counter['noBookInside-' + thisYear]
          checkNobook = true
          // this.handleSubmit()
        }
      } else {
        currentNoBook = counter.counter['noBookInside-' + thisYear]
        noBookTime = counter.counter['noBookInside-' + thisYear]
        checkNobook = true
        // this.handleSubmit()
      }
    }

    if (counter.counter['noDisburse' + thisYear]) {
      if (currentNoDisburse) {
        if (currentNoDisburse !== counter.counter['noDisburse' + thisYear]) {
          currentNoDisburse = counter.counter['noDisburse' + thisYear]
          noDisburse = counter.counter['noDisburse' + thisYear]
          checkNoDisburse = true
          // this.handleSubmit()
        }
      } else {
        currentNoDisburse = counter.counter['noDisburse' + thisYear]
        noDisburse = counter.counter['noDisburse' + thisYear]
        checkNoDisburse = true
        // this.handleSubmit()
      }
    }
    if (checkNobook && checkNoDisburse && noDisburse && noBookTime) {
      this.handleSubmit()
    }
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state
    const { index, currentProposal, special } = this.props
    data = { ...currentProposal, ...data }

    if (special) {
      data.withdrawFormSP[index][name] = value
    } else {
      data.withdrawForm[index][name] = value
    }

    validate.validateError = {}
    validate.attrs = {}
    validate.error = false

    this.setState({ data, validate })
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  handleSubmit = () => {
    let { data, validate, checkValidate } = this.state
    let { currentProposal, index, special, role, auth } = this.props
    if (this.props.match.params.id) {
      data = { ...currentProposal, ...data }
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    validate.attrs = {}

    if (special) {
      if (data.withdrawFormSP[index]) {
        if (data.withdrawFormSP[index].withdrawPayment !== 'delivery') {
          validate['validateError'] = validatejs(
            data.withdrawFormSP[index],
            WithdrawFormConstraints.WithdrawFormConstraints
          )
        } else {
          validate['validateError'] = validatejs(
            data.withdrawFormSP[index],
            WithdrawFormConditionConstraints.WithdrawFormConditionConstraints
          )
        }
        let sumary = 0
        data.withdrawFormSP.forEach(item => {
          sumary += parseFloat(item.withdrawPeriodAmount || '0')
        })
        data.withdrawForm.forEach((item, idx) => {
          if (idx !== 1) sumary += parseFloat(item.withdrawPeriodAmount || '0')
        })

        if (sumary > parseFloat(data.withDrawTime[1].sum || '0')) {
          if (!validate['validateError']) validate['validateError'] = {}
          validate['validateError']['จำนวนเงินเบิก'] = [
            'จำนวนเงินรวมเกินจำนวนเงินงวด'
          ]
        }
      }
    } else {
      if (data.withdrawForm[index]) {
        if (data.withdrawForm[index].withdrawPayment !== 'delivery') {
          validate['validateError'] = validatejs(
            data.withdrawForm[index],
            WithdrawFormConstraints.WithdrawFormConstraints
          )
        } else {
          validate['validateError'] = validatejs(
            data.withdrawForm[index],
            WithdrawFormConditionConstraints.WithdrawFormConditionConstraints
          )
        }
      }
      let sumary = 0
      if (data.special === 'yes') {
        data.withdrawFormSP.forEach(item => {
          sumary += parseFloat(item.withdrawPeriodAmount || '0')
        })
        data.withdrawForm.forEach((item, idx) => {
          if (idx !== 1) sumary += parseFloat(item.withdrawPeriodAmount || '0')
        })
        if (index > 2 && (!data.withdrawForm[index - 1].sendWorkTime || data.withdrawForm[index].sendWorkTime < (data.withdrawForm[index - 1].sendWorkTime || 0))) {
          if (!validate['validateError']) validate['validateError'] = {}
          validate['validateError']['วันที่ลงบันทึก'] = [
            'วันที่บันทึกข้อมูลต้องมากกว่างวดก่อนหน้า'
          ]
        }
      } else {
        data.withdrawForm.forEach(item => {
          sumary += parseFloat(item.withdrawPeriodAmount || '0')
        })
        if (index > 0 && (!data.withdrawForm[index - 1].sendWorkTime || data.withdrawForm[index].sendWorkTime < (data.withdrawForm[index - 1].sendWorkTime || 0))) {
          if (!validate['validateError']) validate['validateError'] = {}
          validate['validateError']['วันที่ลงบันทึก'] = [
            'วันที่บันทึกข้อมูลต้องมากกว่างวดก่อนหน้า'
          ]
        }
      }

      if (sumary > parseFloat(data.budget)) {
        if (!validate['validateError']) validate['validateError'] = {}
        validate['validateError']['จำนวนเงินเบิก'] = [
          'จำนวนเงินรวมเกินงบประมาณที่อนุมัติ'
        ]
      }

    }

    if (validate['validateError'] || checkValidate) {
      if (!validate['validateError']) validate['validateError'] = {}
      // if (checkValidate) {
      //   validate['validateError'][
      //     'รายงานสรุปประกอบการขออนุมัติเบิกเงินรายงวดที่ ' + index
      //   ] = ['ต้องระบุ']
      // }
      if (Object.keys(validate['validateError']).length > 0) {
        validate['error'] = true
        Object.keys(validate['validateError']).forEach(item => {
          validate.attrs[item] = true
        })
        this.setState({ validate })
      }
    } else {
      if (special) {
        if (!data.withdrawFormSP[index].noBookTakeMoney) {
          if (noBookTime) {
            data.withdrawFormSP[index].noBookTakeMoney =
              'กสศ. น. 19/' +
              noBookTime.toString().padStart(5, '0') +
              '/' +
              thisYear

            data.withdrawFormSP[index].noDisburse =
              'PMS-PV ' +
              noDisburse.toString().padStart(5, '0') +
              '/' +
              thisYear

            validate['error'] = false
            if (role === 'Admin') {
              data['adminEdit'] = user._id
              data['adminEditTime'] = gettime
            } else {
              data['timestamp'] = gettime
            }
            checkNobook = false
            checkNoDisburse = false
            noBookTime = undefined
            noDisburse = undefined
            this.props.updateProposal(data, data._id)
          } else {
            this.props.genId('noBookInside-' + thisYear)
            this.props.genId('noDisburse' + thisYear)
          }
        } else if (!checkNobook) {
          checkNobook = true
          if (role === 'Admin') {
            data['adminEdit'] = user._id
            data['adminEditTime'] = gettime
          } else {
            data['timestamp'] = gettime
          }
          validate['error'] = false
          this.props.updateProposal(data, data._id)
        }
      } else {
        if (!data.withdrawForm[index].noBookTakeMoney) {
          if (noBookTime) {
            data.withdrawForm[index].noBookTakeMoney =
              'กสศ. น. 19/' +
              noBookTime.toString().padStart(5, '0') +
              '/' +
              thisYear

            data.withdrawForm[index].noDisburse =
              'PMS-PV ' +
              noDisburse.toString().padStart(5, '0') +
              '/' +
              thisYear

            data.withdrawForm[index].dateOfReceipt =
              data.withDrawTime[index].submitTime || ''

            if (!data.withdrawForm[index].withdrawPeriodAmount) {
              data.withdrawForm[index].withdrawPeriodAmount =
                data.withDrawTime[index].sum
              data.withdrawForm[index].withdrawCompensationManager =
                data.withDrawTime[index].mainCom
              data.withdrawForm[index].withdrawCompensationProject =
                data.withDrawTime[index].supCom
              data.withdrawForm[index].withdrawCostOfOperation =
                data.withDrawTime[index].manage
              data.withdrawForm[index].withdrawServiceFee =
                data.withDrawTime[index].charge
            }

            validate['error'] = false
            if (role === 'Admin') {
              data['adminEdit'] = user._id
              data['adminEditTime'] = gettime
            } else {
              data['timestamp'] = gettime
            }
            checkNobook = false
            checkNoDisburse = false
            noBookTime = undefined
            noDisburse = undefined
            this.props.updateProposal(data, data._id)
            if (
              Object.getOwnPropertyNames(this.props.counter.counter).length > 0
            ) {
              Object.keys(this.props.counter.counter).forEach(key => {
                delete this.props.counter.counter[key]
              })
            }
          } else {
            this.props.genId('noBookInside-' + thisYear)
            this.props.genId('noDisburse' + thisYear)
          }
        } else if (!checkNobook) {
          checkNobook = true
          if (role === 'Admin') {
            data['adminEdit'] = user._id
            data['adminEditTime'] = gettime
          } else {
            data['timestamp'] = gettime
          }
          validate['error'] = false
          this.props.updateProposal(data, data._id)
        }
      }

      this.setState({ validate, onSaveSuccess: true })
      setTimeout(() => {
        this.setState({ onSaveSuccess: false })
      }, 3000)
    }
  }

  _renderFormField = content => {
    const { validate } = this.state
    const { currentProposal, index, role, special } = this.props
    if (
      (special && index !== 0 && role !== 'Academic' && role !== 'Co-Academic' && role !== 'Admin') ||
      (!special && index === 0 && role !== 'Contract' && role !== 'Admin') ||
      (currentProposal &&
        currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
        role !== 'Admin')
    ) {
      if (content.control === Select) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            disabled
          />
        )
      } else {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            readOnly
          />
        )
      }
    } else {
      if (content.control === Select) {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      } else { return <Form.Field {...content} error={validate.attrs[content.name]} /> }
    }
  }

  handleDayChangeRow = (name, index, date) => {
    let { data, validate } = this.state
    let { currentProposal, special } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    if (special) {
      data.withdrawFormSP[index][name] = date._d.getTime()
    } else {
      data.withdrawForm[index][name] = date._d.getTime()
    }

    validate.error = false
    validate.validateError = {}
    this.setState({ data })
  }

  handleBackPage = () => {
    const { currentProposal } = this.props
    if (this.props.history) {
      this.props.history.push(
        '/project/tabProgressProject/' + currentProposal._id
      )
    }
  }

  onMoneyChange = e => {
    let { data, validate } = this.state
    let { index, currentProposal, special } = this.props

    data = { ...currentProposal, ...data }
    let money = e.target.rawValue.replace('-', '')

    if (special) {
      data.withdrawFormSP[index][e.target.name] = money ? money.toString() : '0'
      // data.withdrawForm[index][e.target.name] = parseFloat(e.target.rawValue)
      data.withdrawFormSP[index]['withdrawPeriodAmount'] =
        Math.abs(
          parseFloat(data.withdrawFormSP[index]['withdrawCostOfOperation'] || 0)
        ) +
        Math.abs(
          parseFloat(data.withdrawFormSP[index]['withdrawServiceFee'] || 0)
        ) +
        Math.abs(
          parseFloat(
            data.withdrawFormSP[index]['withdrawCompensationManager'] || 0
          )
        ) +
        Math.abs(
          parseFloat(
            data.withdrawFormSP[index]['withdrawCompensationProject'] || 0
          )
        )
    } else {
      data.withdrawForm[index][e.target.name] = money ? money.toString() : '0'
      // data.withdrawForm[index][e.target.name] = parseFloat(e.target.rawValue)
      data.withdrawForm[index]['withdrawPeriodAmount'] =
        Math.abs(
          parseFloat(data.withdrawForm[index]['withdrawCostOfOperation'] || 0)
        ) +
        Math.abs(
          parseFloat(data.withdrawForm[index]['withdrawServiceFee'] || 0)
        ) +
        Math.abs(
          parseFloat(
            data.withdrawForm[index]['withdrawCompensationManager'] || 0
          )
        ) +
        Math.abs(
          parseFloat(
            data.withdrawForm[index]['withdrawCompensationProject'] || 0
          )
        )
    }
    // validate array

    if (!validate.attrs) validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
    this.setState({ data, validate })
  }

  handleChangeSelected = (e, { name, value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    data[name] = value

    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false
    this.setState({ data, validate, validateSP: false })
  }

  openModal = () => {
    this.setState({ modalEditTime: true })
  }

  handleCancel = () => {
    this.setState({ modalEditTime: false })
  }

  handleConfirm = () => {
    let { data } = this.state
    let { currentProposal } = this.props
    if (this.props.currentProposal) data = { ...currentProposal, ...data }

    let check = false
    if (!data.manyOfEdit || !data.indexOfEdit) {
      check = true
    }

    if (check) {
      this.setState({ validateSP: true, modalEditTime: false })
    } else {
      data.confirmOFEditTime = true
      data.withdrawFormSP = []
      data.progressPeriodSP = []
      for (let i = 0; i < data.manyOfEdit; i++) {
        data.withdrawFormSP[i] = {}
        data.progressPeriodSP[i] = {}
      }
      this.setState({
        validateSP: false,
        onSaveSuccessSP: true,
        modalEditTime: false
      })
      setTimeout(() => {
        this.setState({ onSaveSuccessSP: false })
      }, 3000)
      data['timestamp'] = gettime
      this.props.updateProposal(data, data._id)
    }
  }

  render() {
    let {
      validate,
      data,
      onSaveSuccess,
      onSaveSuccessSP,
      validateSP
    } = this.state
    const { currentProposal, currentState, index, role, special } = this.props

    data = { ...currentProposal, ...data }

    let content = {
      withdrawDateOfReceipt: { control: Input },
      withdrawOfProjectPeriod: { control: Input, width: 8 },
      withdrawPeriodAmount: { control: Input, readOnly: true },
      withdrawAlready: { control: Input },
      withdrawBalance: { control: Input, width: 8 },
      withdrawMoneyLastPeriod: {
        control: Select,
        options: MoneyLastPeriodOptions
      },
      withdrawMoneyLastPeriodAmount: { control: Input },
      withdrawCostOfOperation: { control: Input, money: 'money' },
      withdrawServiceFee: { control: Input, money: 'money' },
      withdrawCompensationManager: { control: Input, money: 'money' },
      withdrawCompensationProject: { control: Input, money: 'money' },
      withdrawAmount: { control: Input },
      withdrawPayChequeBy: {
        control: Input,
        disabled:
          data.withdrawForm[index] &&
          data.withdrawForm[index].withdrawPayment !== 'delivery',
        width: 8
      },
      withdrawBank: { control: Input },
      withdrawBankBranch: { control: Input },
      withdrawAccountNumber: { control: Input },
      withdrawTaxDeduction: { control: Input },
      withdrawPayment: { control: Select, options: PaymentOptions, width: 8 },
      codeDisburse: { control: Input, width: 8 }
    }

    let colorValidateDate = ''
    if (validate.attrs.sendWorkTime) {
      colorValidateDate = '#9f3a38'
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('proposal', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (special) {
        if (content[key].control === Input) {
          if (data.withdrawFormSP && data.withdrawFormSP.length > 0) {
            content[key].value = data.withdrawFormSP[index][key] || ''
          }
        } else if (content[key].control === Checkbox) {
          if (data.withdrawFormSP && data.withdrawFormSP.length > 0) {
            content[key].checked = data.withdrawFormSP[index][key] || false
          }
        } else {
          if (data.withdrawFormSP && data.withdrawFormSP.length > 0) {
            content[key].value = data.withdrawFormSP[index][key] || ''
          }
        }
      } else {
        if (content[key].control === Input) {
          if (data.withdrawForm && data.withdrawForm.length > 0) {
            content[key].value = data.withdrawForm[index][key] || ''
          }
        } else if (content[key].control === Checkbox) {
          if (data.withdrawForm && data.withdrawForm.length > 0) {
            content[key].checked = data.withdrawForm[index][key] || false
          }
        } else {
          if (data.withdrawForm && data.withdrawForm.length > 0) {
            content[key].value = data.withdrawForm[index][key] || ''
          }
        }
      }
    })

    console.log(gettime)

    if (gettime) {
      if (special) {
        // if (!data.withdrawFormSP[index].sendWorkTime) data.withdrawFormSP[index].sendWorkTime = gettime
      } else {
        // if (!data.withdrawForm[index].sendWorkTime) data.withdrawForm[index].sendWorkTime = gettime
      }
    } else {
      // this.getTimeFuction()
    }

    return (
      <Fragment>
        <Form error={validate.error}>
          {/* {role !== 'Contract' && <Form.Group ><Form.Field width={4} disabled={data && data.confirmOFEditTime && role !== 'Admin'}>
            <Header style={{ fontFamily: 'supermarket' }}
              as='h5' content='จำนวนระยะที่ต้องการแบ่งจ่าย' />
            <Form.Group>
              {data.withDrawTime && data.withDrawTime.map((item, idx) => (
                <Form.Checkbox radio
                  key={idx}
                  label={'งวดที่ ' + (idx + 1)}
                  name='indexOfEdit'
                  value={idx}
                  checked={data.indexOfEdit === idx}
                  onChange={this.handleChangeSelected} />
              ))}
            </Form.Group>
          </Form.Field>
            <Form.Field width={4} disabled={data && data.confirmOFEditTime && role !== 'Admin'}>
              <Header style={{ fontFamily: 'supermarket' }}
                as='h5' content='งวดงานงวดเงินที่ต้องการแบ่งจ่ายเงินเป็นระยะ' />
              <Form.Group>
                <Form.Checkbox radio
                  label={'1 ระยะ'}
                  name='manyOfEdit'
                  value={1}
                  checked={data.manyOfEdit === 1}
                  onChange={this.handleChangeSelected} />
                <Form.Checkbox radio
                  label={'2 ระยะ'}
                  name='manyOfEdit'
                  value={2}
                  checked={data.manyOfEdit === 2}
                  onChange={this.handleChangeSelected} />
                <Form.Checkbox radio
                  label={'3 ระยะ'}
                  name='manyOfEdit'
                  value={3}
                  checked={data.manyOfEdit === 3}
                  onChange={this.handleChangeSelected} />
              </Form.Group>
            </Form.Field>
            <Form.Field disabled={data && data.confirmOFEditTime && role !== 'Admin'}>
              <label>{''}</label>
              <Button content='ยืนยัน' color='blue' onClick={this.openModal} />
            </Form.Field>
          </Form.Group>} */}
          <Transition
            visible={validateSP}
            animation='horizontal flip'
            duration={500}
          >
            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                กรอกข้อมูลไม่ครบถ้วน
              </Message.Header>
              <Message.List>
                <Message.Item>
                  {' '}
                  งวดงานงวดเงินและจำนวนระยะที่ต้องการแบ่งจ่าย -
                  โปรดระบุให้ครบถ้วน{' '}
                </Message.Item>
              </Message.List>
            </Message>
          </Transition>
          <Transition
            visible={onSaveSuccessSP}
            animation='horizontal flip'
            duration={500}
          >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle' />บันทึกสำเร็จ
              </Message.Header>
            </Message>
          </Transition>
          {index === 0 && !special && (
            <Message color='yellow'>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                หมายเหตุ*
              </Message.Header>
              <p>
                ผู้ที่จะทำการกรอกข้อมูลเบิกเงินรายงวด งวดที่1 ต้องเป็น User
                ที่เป็นศูนย์สัญญาเท่านั้น
              </p>
            </Message>
          )}
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='จำนวนเบิกเงินงวด'
              as='h5'
              icon='money'
            />
            <Form.Group>
              <Form.Field width={8}>
                <Form.Input
                  label='เลขที่เบิกจ่าย'
                  value={
                    (!special &&
                      data.withdrawForm[index] &&
                      data.withdrawForm[index].noDisburse) ||
                    (special &&
                      data.withdrawFormSP[index] &&
                      data.withdrawFormSP[index].noDisburse) ||
                    'กรุณาทำการบันทึกข้อมูลก่อน'
                  }
                  readOnly
                />
              </Form.Field>
              {/* {this._renderFormField(content.codeDisburse)} */}
              <Form.Field
                disabled={
                  (special &&
                    index !== 0 &&
                    role !== 'Academic' &&
                    role !== 'Admin' &&
                    role !== 'Co-Academic') ||
                  (!special &&
                    index === 0 &&
                    role !== 'Contract' &&
                    role !== 'Admin') ||
                  (currentProposal &&
                    currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                    role !== 'Admin')
                }
                width={8}
              >
                <label style={{ color: colorValidateDate }}>
                  {'วันที่ลงบันทึก *'}
                </label>
                <DayPicker
                  ser={'sendWorkTime'}
                  name={'sendWorkTime'}
                  onDayChange={this.handleDayChangeRow}
                  data={
                    (!special &&
                      data.withdrawForm &&
                      data.withdrawForm[index] &&
                      data.withdrawForm[index].sendWorkTime) ||
                    (special &&
                      data.withdrawFormSP &&
                      data.withdrawFormSP[index] &&
                      data.withdrawFormSP[index].sendWorkTime) ||
                    ''
                  }
                  index={index}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>{'ค่าดำเนินการ *'}</label>
                <Cleave
                  name='withdrawCostOfOperation'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={
                    (!special &&
                      data.withdrawForm[index] &&
                      data.withdrawForm[index].withdrawCostOfOperation) ||
                    (special &&
                      data.withdrawFormSP[index] &&
                      data.withdrawFormSP[index].withdrawCostOfOperation) ||
                    (!special &&
                      data.withDrawTime[index] &&
                      data.withDrawTime[index].manage) ||
                    ''
                  }
                  readOnly={
                    (special &&
                      index !== 0 &&
                      role !== 'Academic' &&
                      role !== 'Admin' &&
                      role !== 'Co-Academic') ||
                    (!special &&
                      index === 0 &&
                      role !== 'Contract' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin')
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>{'ค่าธรรมเนียมบริหาร '}</label>
                <Cleave
                  name='withdrawServiceFee'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={
                    (!special &&
                      data.withdrawForm[index] &&
                      data.withdrawForm[index].withdrawServiceFee) ||
                    (special &&
                      data.withdrawFormSP[index] &&
                      data.withdrawFormSP[index].withdrawServiceFee) ||
                    (!special &&
                      data.withDrawTime[index] &&
                      data.withDrawTime[index].charge) || ''
                  }
                  readOnly={
                    (special &&
                      index !== 0 &&
                      role !== 'Academic' &&
                      role !== 'Admin' &&
                      role !== 'Co-Academic') ||
                    (!special &&
                      index === 0 &&
                      role !== 'Contract' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin')
                  }
                />
              </Form.Field>
              {/* {this._renderFormField(content.withdrawCostOfOperation)}
              {this._renderFormField(content.withdrawServiceFee)} */}
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>{'ค่าตอบแทนผู้จัดการโครงการ *'}</label>
                <Cleave
                  name='withdrawCompensationManager'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={
                    (!special &&
                      data.withdrawForm[index] &&
                      data.withdrawForm[index].withdrawCompensationManager) ||
                    (special &&
                      data.withdrawFormSP[index] &&
                      data.withdrawFormSP[index].withdrawCompensationManager) ||
                    (!special &&
                      data.withDrawTime[index] &&
                      data.withDrawTime[index].mainCom) || ''
                  }
                  readOnly={
                    (special &&
                      index !== 0 &&
                      role !== 'Academic' &&
                      role !== 'Admin' &&
                      role !== 'Co-Academic') ||
                    (!special &&
                      index === 0 &&
                      role !== 'Contract' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin')
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>{'ค่าตอบแทนผู้ร่วมโครงการ'}</label>
                <Cleave
                  name='withdrawCompensationProject'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={
                    (!special &&
                      data.withdrawForm[index] &&
                      data.withdrawForm[index].withdrawCompensationProject) ||
                    (special &&
                      data.withdrawFormSP[index] &&
                      data.withdrawFormSP[index].withdrawCompensationProject) ||
                    (!special &&
                      data.withDrawTime[index] &&
                      data.withDrawTime[index].supCom) || ''
                  }
                  readOnly={
                    (special &&
                      index !== 0 &&
                      role !== 'Academic' &&
                      role !== 'Admin' &&
                      role !== 'Co-Academic') ||
                    (!special &&
                      index === 0 &&
                      role !== 'Contract' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin')
                  }
                />
              </Form.Field>
              {/* {this._renderFormField(content.withdrawCompensationManager)}
              {this._renderFormField(content.withdrawCompensationProject)} */}
            </Form.Group>
          </Segment>
          {/* <Segment>
            <Header style={{ fontFamily: 'supermarket' }} content='การจ่ายเงินงวด' as='h5' icon='clipboard' />
            <Form.Group>
              {this._renderFormField(content.withdrawPayment)}
              {this._renderFormField(content.withdrawPayChequeBy)}
            </Form.Group>
          </Segment> */}
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          {/* ปิดการบันทึก */}
          <Button
            color='blue'
            content='บันทึก'
            icon='save'
            type='submit'
            disabled={
              (special &&
                role !== 'Academic' &&
                role !== 'Admin' &&
                role !== 'Co-Academic') ||
              (!special &&
                index === 0 &&
                role !== 'Contract' &&
                role !== 'Admin') ||
              (!special &&
                index !== 0 &&
                role !== 'Admin' &&
                role !== 'Co-Academic' &&
                role !== 'Academic') ||
              (currentProposal &&
                currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                role !== 'Admin')
            }
            loading={currentState.isSaving}
            onClick={this.handleSubmit}
          />
        </Form>
        <Confirm
          open={this.state.modalEditTime}
          header='คำเตือน'
          content='เมื่อกดยืนยันแล้ว งวดงานงวดเงินและระยะจ่ายเงินที่ท่านเลือกจะไม่สามารถเปลี่ยนแปลงหรือแก้ไขได้ หากต้องการเปลี่ยนแปลงให้ติดต่อผู้ดูระบบเท่านั้น'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
        <Transition
          visible={onSaveSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle' />บันทึกสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentState: state.projects,
  auth: state.auth,
  role: state.role,
  counter: state.counter
})

const mapDispatchToProps = dispatch => ({
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  genId: name => dispatch(generate(name))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawForm)
