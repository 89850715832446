import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Table, Form, Header } from 'semantic-ui-react';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more'
import { actions as projectActions } from 'Redux/reducers/projects';

HighchartsMore(Highcharts)

class TableStatus extends Component {

  componentDidMount = () => {
    this.props.listProposal()
  }

  format(input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1);
    }
  }

  render() {
    const { projects } = this.props
    let dataTable = []
    let data = [
      { name: 'ส่งข้อเสนอโครงการ' },
      { name: 'กลั่นกรอง' },
      { name: 'ทำสัญญาโครงการ' },
      { name: 'ประเมินความก้าวหน้าโครงการ' },
      { name: 'ปิดโครงการ' },
      { name: 'ยุติโครงการ' },
    ]
    data.forEach(row => {
      let check = false
      projects.length > 0 && projects.forEach(item => {
        if (row.name === item._id) {
          dataTable.push({ status: item._id, many: item.project })
          row.y = item.project
          check = true
        }
        // data.push({ name: item._id, y: item.project, format: this.format(item.total.toString()) })
      })
      if (!check) {
        dataTable.push({ status: row.name, many: 0 })
        row.y = 0
      }
    })

    const options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: ': <b>{point.y} โครงการ</b>'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f} %',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          }
        }
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        layout: "vertical",
        x: 20,
        y: 100,
        itemMarginTop: 5,
        itemMarginBottom: 5,
      },
      series: [{
        colorByPoint: true,
        data: data
      }]
    };

    return (
      <Form>
        <Header as='h1' style={{ fontFamily: 'supermarket' }}
          content='จำนวนโครงการแยกตามสถานะโครงการ' textAlign='center'
          subheader='' />
        <Form.Group>
          <Form.Field width={9}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </Form.Field>
          <Form.Field width={7}>
            <br />
            <br />
            <Table celled selectable inverted color='blue'>
              <Table.Header>
                <Table.Row >
                  <Table.HeaderCell content='ลำดับ' textAlign='center' />
                  <Table.HeaderCell content='สถานะโครงการ' textAlign='center' />
                  <Table.HeaderCell content='จำนวนโครงการ' textAlign='center' />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {dataTable.length > 0 && dataTable.map((item, idx) => (
                  <Fragment key={idx}>
                    <Table.Row >
                      <Table.Cell textAlign='center' content={idx + 1} />
                      <Table.Cell textAlign='center' content={item.status} />
                      <Table.Cell textAlign='center' content={this.format(item.many.toString())} />
                    </Table.Row>
                  </Fragment>))}
              </Table.Body>
            </Table>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }

}
const mapStateToProps = state => ({
  projects: state.projects.data,
  borrow: state.scholar.data,
  search: state.search,
  auth: state.auth,
  role: state.role,
  counter: state.counter
})

const mapDispatchToProps = dispatch => ({
  listProposal: () => dispatch(projectActions.fetchList({
    aggregate: [{ $match: { delete: { $ne: true } } },
    { $group: { _id: '$status', project: { $sum: 1 }, total: { $sum: { $toDouble: '$budget' } } } },
    { $sort: { _id: 1 } },
    ]
  })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TableStatus)