import React, { Component, Fragment } from 'react';
import {
  Form,
  Table,
  Label,
  Header,
  Button,
  Transition,
  Message,
  Icon,
  Segment,
  Modal,
  Confirm
} from 'semantic-ui-react'
import { GetServerTime as GetTime } from 'Utils'

import { connect } from 'react-redux'
import Cleave from 'cleave.js/react';
// import { actions as userActions } from 'Redux/reducers/user'

import { actions as professional_burnActions } from 'Redux/reducers/professional_burn'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'
import { actions as professional_bActions } from 'Redux/reducers/professional_b'
import { constraints as ScreenArrayConstraints } from './BurnProjectConstraints'
import { ValidateArray as validateArray } from 'Utils'
import { localTh } from 'Utils'
// import { ModalAddProfesBurn } from 'Utils'
import { ModalListProfes } from 'Utils'
// import { ModalTypeProfes } from 'Utils'

let gettime = ''

const choice = [
  { key: '1', value: 'ให้การสนับสนุนโดยไม่ต้องแก้ไขโครงการ', text: 'ให้การสนับสนุนโดยไม่ต้องแก้ไขโครงการ' },
  { key: '2', value: 'สนับสนุนแต่ต้องแก้ไขโครงการตามประเด็นในเอกสารแนบ', text: 'สนับสนุนแต่ต้องแก้ไขโครงการตามประเด็นในเอกสารแนบ' },
  { key: '3', value: 'ไม่ควรให้การสนับสนุน', text: 'ไม่ควรให้การสนับสนุน' }
]

class BurnScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      onSaveSuccess: false,
      openModalListProfes: false,
      confirmReset: false
    }
  }

  componentDidMount = () => {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

    let queryProfes = {
      choose: true
    }

    this.props.listProfes()
    this.props.listProfesYear(queryProfes)
    if (match.params.id) {
      let query = {
        _id: match.params.id
      }

      this.props.getBurnProject(query)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.burnprojects) {
      let tmp = { ...nextProps.burnprojects }
      let { data } = this.state;

      if (!tmp.screen && !tmp.beforeEditMoney) {
        tmp.screen = []
        let test = []
        let checkeInt = Number.isInteger(tmp.budget)
        let money = 0
        if (!checkeInt) {
          money = parseFloat((tmp.budget).replace(/,/g, ''), 10)
        } else {
          money = tmp.budget
        }

        if (money <= 500000) {
          for (let i = 0; i < 1; i++) {
            test.push({ 'screenProfessional': '', 'screenresult': '' })
          }
        } else if (money <= 1000000) {
          for (let i = 0; i < 3; i++) {
            test.push({ 'screenProfessional': '', 'screenresult': '' })
          }
        } else if (money <= 10000000) {
          for (let i = 0; i < 5; i++) {
            test.push({ 'screenProfessional': '', 'screenresult': '' })
          }
        } else {
          for (let i = 0; i < 7; i++) {
            test.push({ 'screenProfessional': '', 'screenresult': '' })
          }
        }

        tmp.screen = test
      }

      this.setState({ data: { ...tmp, ...data } })
    }
  }

  handleSelectRow = (selectedRow) => {

    if (selectedRow === this.state.selectedRow) selectedRow = null;
    this.setState({ selectedRow, modalAttachment: false })
  }

  handleEditRow = (idx, e, { name, value }) => {
    let { data, validate } = this.state;
    const { professional_b, burnprojects } = this.props

    let _data = []
    if (burnprojects !== null) {
      if (Object.keys(data).length === 0) {
        burnprojects.screen.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      } else {
        data.screen.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      }
    }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    let usersName = professional_b.data.map((item, idx) => ({
      id: item._id,
      name: item.title === '' ? item.titleOther + '  ' + item.firstname + ' ' + item.lastname : item.title + '  ' + item.firstname + ' ' + item.lastname,
    }))

    if (name === 'screenProfessional') {
      usersName.map(r => {
        if (value === r.id) {
          _data[idx][name] = value;
          _data[idx]['nameProfessional'] = r.name
        }
        return r
      })
    } else {
      _data[idx][name] = value;
    }
    let tmp = { ...data, screen: _data }
    this.setState({ data: tmp, validate })

  }

  handleSubmit = () => {
    let { data, validate } = this.state;
    const { match, burnprojects } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    });
    if (match.params.id) {
      data = { ...burnprojects, ...data };
    }

    data.status = 'ขออนุมัติโครงการและจัดทำคำสั่ง'

    validate.attrs = {};

    if (!data.screen) data.screen = []

    if (!validate['validateError']) validate['validateError'] = {}

    let validateArrayError = []
    if (!data.passNoScreen) {
      var valueArr = data.screen.map(function (item) { return item.screenProfessional });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx
      });


      if (isDuplicate) {
        validate.error = true
        validate['validateError']['screenProfessional'] = []
        validate['validateError']['screenProfessional'].push('ซ้ำ')
      }
      validateArrayError = validateArray(data.screen, ScreenArrayConstraints.ScreenArrayConstraints)
      if (Object.keys(validateArrayError).length !== 0 && validateArrayError.constructor === Object) {
        validate.error = true
        if (!validate['validateError']) validate['validateError'] = {}
        Object.keys(validateArrayError).forEach(index => {
          if (validateArrayError[index].screenProfessional) {
            validate['validateError']['screenProfessional'] = validateArrayError[index].screenProfessional
          }
          if (validateArrayError[index].screenresult) {
            validate['validateError']['screenresult'] = validateArrayError[index].screenresult
          }
          validate.attrs['screen' + index] = true;
        })
      }
    }

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      validate['error'] = false
      this.setState({ validate });
      if (this.props.match.params.id) {
        data['timestamp'] = gettime
        this.props.updateBurnPJ(data, data._id)
        this.setState({ onSaveSuccess: true });
        setTimeout(() => {
          this.setState({ onSaveSuccess: false });
        }, 3000)
      }
    }
  }


  handleOpenModalListProfes = () => {
    this.setState({ openModalListProfes: true })
  }

  handleListProfes = (item) => {
    this.setState({ typeProfes: item, ListProfes: true })

  }

  close = () => {
    this.setState({ openModalListProfes: false })
  }

  closeListProfes = () => {
    this.setState({ ListProfes: false })
  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate } = this.state;
    const { burnprojects } = this.props

    data = { ...burnprojects, ...data };

    if (name === 'haveProfesAmount' || name === 'passNoScreen') {
      data[name] = checked;
      data['budgetProfes'] = data.screen.length
    } else {
      data[name] = value;
    }


    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false;
    this.setState({ data, validate });
  }


  onAmountProfesChange = (e) => {
    let { data, validate } = this.state
    const { match, burnprojects } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data };
    }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    let profes = Math.abs(parseInt(e.target.rawValue))

    if (profes) {
      if (profes < data['budgetProfes']) {
        validate.error = true
        validate['validateError']['profesAmount'] = []
        validate['validateError']['profesAmount'].push('น้อยกว่างบประมานกำหนด')
      } else {
        if (profes > 20) {
          validate.error = true
          validate['validateError']['profesAmount'] = []
          validate['validateError']['profesAmount'].push('มากเกินไป')
        } else {
          data.screen = []
          for (let i = 0; i < profes; i++) {
            data.screen.push({ 'screenProfessional': '', 'screenresult': '' })
          }
        }
      }
    }


    data[e.target.name] = profes ? profes.toString() : '0'
    // data[e.target.name] = e.target.rawValue

    this.setState({ data, validate })
  }

  handleResetProfess = () => {
    const { burnprojects } = this.props;
    let { data, validate } = this.state
    data = { ...burnprojects, ...data };

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    data.screen.forEach(x => {
      delete x.OrganizationProfessional
      delete x.nameProfessional
      delete x.typeProfessional
      delete x.explan
      x.screenProfessional = ''
      x.screenresult = ''
    });

    this.setState({ data, validate, confirmReset: false })
    this.props.updateBurnPJ(data, data._id)

  }

  handleConfirmResetProfess = () => {
    this.setState({ confirmReset: true })
  }


  render() {
    let { data, validate, selectedRow, onSaveSuccess } = this.state
    const { role, professional_burn } = this.props

    let usersOptions = professional_burn && professional_burn.list.map((item, idx) => ({
      text: item.title === '' ? item.titleOther + '  ' + item.firstname + ' ' + item.lastname : item.title + '  ' + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    let checkInfo = false

    if (Object.keys(data).length !== 0) {
      if (data.targetGroup.length === 0 || !data.operationPlan.length === 0 || !data.result.length === 0 || !data.objective.length === 0) {
        checkInfo = true
      }
    }

    let checkLock = data.approve === 'done' && role !== 'Admin'

    return (
      <Fragment>
        <div style={{ paddingBottom: 220 }}>
          <Modal
            open={this.state.openModalListProfes}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon>
            <Modal.Content>
              {/* <ModalAddProfesBurn onClose={this.close} /> */}
              {/* <ModalTypeProfes onClose={this.close} onSelectedType={this.handleListProfes}/> */}
            </Modal.Content>
          </Modal>
          <Modal
            open={this.state.ListProfes}
            closeOnDimmerClick={false}
            onClose={this.closeListProfes}
            closeIcon>
            <Modal.Content>
              <ModalListProfes onClose={this.closeListProfes} onSelectedType={this.handleListProfes} />
            </Modal.Content>
          </Modal>
          {/* {!checkInfo &&
            <Button
              content='เพิ่มผู้ทรงคุณวุฒิ' icon='plus'
              color='green' onClick={this.handleYearProfes} />
          } */}
          {!checkInfo &&
            <Segment>
              <Header style={{ fontFamily: 'supermarket' }} icon='address book outline' content='กลั่นกรอง' as='h4' />
              <Form>
                <Form.Checkbox
                  name='haveProfesAmount'
                  onChange={this.handleChange}
                  label={<label>ต้องการเปลี่ยนแปลงจำนวนผู้ทรงคุณวุฒิ</label>}
                  checked={data.haveProfesAmount}
                  disabled={checkLock}
                />
                <Form.Group >
                  <Form.Field width={4} error={validate.validateError['profesAmount'] && validate.validateError['profesAmount'].length !== 0}>
                    <label>{'จำนวนผู้ทรงคุณวุฒิ(ต้องไม่น้อยกว่าจำนวนที่กำหนดมาตามงบประมาณ)'}</label>
                    <Cleave name='profesAmount'
                      maxLength='2'
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand'
                      }}
                      value={parseFloat(data.profesAmount) || 0}
                      onChange={this.onAmountProfesChange.bind(this)}
                      disabled={!data.haveProfesAmount} />
                  </Form.Field>
                </Form.Group>
                <Button type='button'
                  content='ล้างข้อมูลผู้ทรงคุณวุฒิ' icon='sync'
                  onClick={this.handleConfirmResetProfess}
                  disabled={checkLock} />
              </Form>
              {data.screen && data.screen.length !== 0 &&
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign='center' content='คนที่' width={2} />
                      <Table.HeaderCell textAlign='center' content='รายชื่อผู้ทรงคุณวุฒิ' width={8} />
                      {/* <Table.HeaderCell textAlign='center' content='อัพโหลด' width={3} /> */}
                      <Table.HeaderCell textAlign='center' content='ผลการกลั่นกรอง' width={6} />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {/* {formfix} */}
                    {data.screen && data.screen.map((item, idx) => (
                      <Fragment key={idx}>
                        <Table.Row onClick={this.handleSelectRow.bind(this, idx)} error={validate.attrs['screen' + idx]}>
                          {(selectedRow === idx && <Table.Cell width={2}>
                            <Label color="green" ribbon content={'คนที่ ' + (idx + 1)} />
                          </Table.Cell>) || <Table.Cell width={2} textAlign='center' content={idx + 1} />}
                          <Table.Cell width={8}
                            content={item.nameProfessional}
                          // content={<ComponentName id={item.screenProfessional} />}
                          />
                          <Table.Cell content={item.screenresult} />
                        </Table.Row>
                        {selectedRow === idx && (
                          <Table.Row active>
                            <Table.Cell colSpan={3}>
                              <Form>
                                <Form.Group>
                                  <Form.Select placeholder='เลือกผู้ทรงคุณวุฒิ'
                                    label='ผู้ทรงคุณวุฒิ'
                                    search={true}
                                    width={8}
                                    // disabled={!item.OrganizationProfessional}
                                    value={item.screenProfessional}
                                    options={usersOptions}
                                    onChange={this.handleEditRow.bind(this, idx)}
                                    name='screenProfessional'
                                    disabled={checkLock} />
                                  <Form.Select placeholder='กรุณากรอกข้อมูล'
                                    label='ผลการกลั่นกรอง'
                                    width={8}
                                    name={'screenresult'}
                                    options={choice}
                                    onChange={this.handleEditRow.bind(this, idx)}
                                    value={item.screenresult}
                                    disabled={checkLock} />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                  <Form.TextArea
                                    label='คำอธิบาย'
                                    value={item.explan}
                                    onChange={this.handleEditRow.bind(this, idx)}
                                    name='explan'
                                    disabled={checkLock} />
                                </Form.Group>
                              </Form>
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Fragment>
                    ))}
                  </Table.Body>
                </Table>
              }

              {validate.error &&
                <Message error>
                  <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
                  <Message.List>
                    {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                      <Fragment key={idx}>
                        {validate.validateError[key].map((item, idx) => (
                          <Message.Item key={idx}> {localTh('burnInfo', key)} - {item} </Message.Item>
                        ))}
                      </Fragment>
                    ))}
                  </Message.List>
                </Message>
              }
              <Form>
                <Form.Group>
                  <Form.Checkbox label='โครงการนี้ไม่ต้องกลั่นกรอง' name='passNoScreen'
                    onChange={this.handleChange} checked={data.passNoScreen} />
                </Form.Group>
              </Form>

              <Button
                style={{ fontFamily: 'supermarket' }}
                color='blue'
                content='บันทึก'
                icon='save'
                // disabled={validate.error} loading={currentState.isSaving}
                onClick={this.handleSubmit} />
              <Confirm open={this.state.confirmReset}
                content='ต้องการล้างข้อมูลผู้ทรงคุณวุฒิใช่หรือไม่'
                cancelButton='ยกเลิก'
                confirmButton="ยืนยัน"
                onCancel={this.close}
                onConfirm={this.handleResetProfess} />
              <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
                <Message positive>
                  <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
                </Message>
              </Transition>
            </Segment>

          }
          {checkInfo &&
            <Segment className="ui placeholder segment" >
              <div className="ui icon header">
                <i className="dont icon"></i>
                <h2 style={{ fontFamily: 'supermarket' }}>
                  กรุณากรอกข้อมูลพื้นฐานให้ครบก่อนทำการกลั่นกรอง
                  </h2>
              </div>
            </Segment>
          }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  users: state.user,
  professional_b: state.professional_b,
  professional_burn: state.professional_burn.data[0],
  counter: state.counter,
  auth: state.auth,
  burnprojects: state.burnprojects.data[0],
  role: state.role
});

const mapDispatchToProps = dispatch => ({
  listProfes: () => dispatch(professional_bActions.fetchList()),
  listProfesYear: (query) => dispatch(professional_burnActions.fetchList({ query: query })),
  updateBurnPJ: (data, id) => dispatch(burnProjectAction.save(data, id, "POST")),
  getBurnProject: (query) => dispatch(burnProjectAction.fetchList({ query: query })),
})

export default connect(mapStateToProps, mapDispatchToProps)(BurnScreen);
