import React, { Component , Suspense} from 'react'
import './BurnProject.css'
import Paper from 'Components/Paper'
import SidebarBurnProject from "Containers/BurnProject/SidebarBurnProject"
import { SearchBurnProject,SearchBurnProjectResult } from 'Containers/Search'
import { routes } from 'Containers/Router'
import { Route, HashRouter, Switch } from 'react-router-dom'

const LevelBurn = React.lazy(() => import('Containers/LevelBurn'));

const Wrapper = ({ match, component, history, path }) => {
  const children = React.cloneElement(component, { match, history })
  const excludeLevelMenu = ['/torinfo/:id?', '/menu/:id?', '/torform/:id?','/listorganization/:id?','/listindividuals/:id?','/bill/:id?']

  if (excludeLevelMenu.indexOf(path.toLowerCase()) > -1) {
    return (
      <Suspense fallback={<div>Loading</div>}>
        <Paper match={match} history={history}>{children}</Paper>
      </Suspense>
    )
  }
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Paper history={history} match={match}>
        <div className="wrapper-container">
          <div className="left">
            <Suspense fallback={<div>Loading</div>}>
              <LevelBurn history={history} match={match} />
            </Suspense>
          </div>
          <div className="right">
            {children}
          </div>
        </div>
      </Paper>
    </Suspense>
  )
}

class BurnProject extends Component {
  render() {
    const { path } = this.props.match
    return (
      <div >
        <SidebarBurnProject {...this.props}>
          <HashRouter>
            <Switch>
              <Route
                path='/burnproject/searchBurnProject'
                component={() => <div>
                  <SearchBurnProject {...this.props} />
                  <SearchBurnProjectResult {...this.props}/>
                </div>
                } />
              {routes.map((r, i) => <Route key={i}
                exact={r.exact || false}
                path={path + r.path}
                component={() => {
                  return <Wrapper {...this.props} path={r.path} component={r.component} />
                }} />)}
            </Switch>
          </HashRouter>
        </SidebarBurnProject>
      </div>
    )
  }
}

export default BurnProject
