import React, {  Component } from 'react'
import './GraphOffice.css'
import { connect } from 'react-redux'
import { Route, HashRouter, Switch } from 'react-router-dom'
import { SidebarGraph, TableOffice } from 'Containers/GraphMap'
import Paper from 'Components/Paper'

class GraphOffice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTorOpen: false,
      closeOnDimmerClick: false,
      inputText: '',
      filter: {},
    };
  }

  render() {
    const { match, history } = this.props
    return (
      <div>
        <SidebarGraph {...this.props}>
          <HashRouter>
            <Switch>
              <Route
                path='/graph/graphoffice'
                component={() => <div>
                  <Paper history={history} match={match}>
                    <TableOffice {...this.props} />
                  </Paper>
                </div>
                } />
              {/* {routes.map((r, i) => <Route key={i}
                exact={r.exact || false}
                component={() => {
                  return <Wrapper {...this.props} component={r.component} />
                }} />)} */}
            </Switch>
          </HashRouter>
        </SidebarGraph>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  sidebar: state.sidebar,
  borrow: state.scholar.data,
  search: state.search,
  auth: state.auth,
  role: state.role,
  counter: state.counter
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(GraphOffice)