import React, { Component, Fragment } from 'react'
import {
  Table,
  Button,
  Message,
  Confirm,
  Segment,
  Menu,
  Form,
  Input,
  Dropdown,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux'
import { actions as projectActions } from 'Redux/reducers/allprojects'
import { GetServerTime as GetTime } from 'Utils'
import { generate } from 'Redux/reducers/counting'

let gettime

let thisYear = ''
let noProject

const filterOptions = [
  { key: '1', text: 'ทั้งหมด', value: 'ทั้งหมด' },
  { key: '2', text: 'ส่งข้อเสนอโครงการ', value: 'ส่งข้อเสนอโครงการ' },
  { key: '3', text: 'กลั่นกรอง', value: 'กลั่นกรอง' },
  { key: '4', text: 'ทำสัญญาโครงการ', value: 'ทำสัญญาโครงการ' },
  {
    key: '5',
    text: 'ประเมินความก้าวหน้าโครงการ',
    value: 'ประเมินความก้าวหน้าโครงการ'
  },
  { key: '6', text: 'ปิดโครงการ', value: 'ปิดโครงการ' },
  { key: '7', text: 'ยุติโครงการ', value: 'ยุติโครงการ' }
]

class CloneProject extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      openConfirmCopy: false,
      query: {},
      search: {},
      nameSearch: 'name',
      dataSaveing: false
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  componentDidMount = () => {
    const { auth } = this.props

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    noProject = undefined
    let query = {
      sort: { nameTh: 1 },
      limit: 10,
      skip: 0,
      query: {
        academic_id: user._id,
        delete: { $ne: true }
      }
    }

    if (Object.getOwnPropertyNames(this.props.counter.counter).length > 0) {
      Object.keys(this.props.counter.counter).forEach(key => {
        delete this.props.counter.counter[key]
      })
    }

    this.props.fetchProjects(query)
    this.setState({ query: query })
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
      }
    })
  }

  componentWillReceiveProps = nextProps => {
    let { data, dataSaveing } = this.state
    let { counter, isSaving } = nextProps
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        // thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    // if(!gettime && gettime === '' && !thisYear && thisYear === ''&& gettime === undefined && thisYear === undefined) this.getTimeFuction()

    if (data.nameTh) {
      if (
        counter.counter[
          'project-' + thisYear + '-' + data.typeOffice + '-' + data.typeProject
        ]
      ) {
        let code = counter.counter[
          'project-' + thisYear + '-' + data.typeOffice + '-' + data.typeProject
        ]
          .toString()
          .padStart(5, '0')
        noProject =
          parseInt(thisYear) -
          2500 +
          '-' +
          data.typeOffice +
          data.typeProject +
          '-' +
          code
        delete counter.counter[
          'project-' + thisYear + '-' + data.typeOffice + '-' + data.typeProject
        ]
        this.copyProject()
      }
    }

    if (isSaving) {
      this.setState({ dataSaveing: true })
    } else {
      if (dataSaveing) {
        this.setState({ dataSaveing: false })
        this.saveDataSuccess()
      }
    }
  }

  copyProject = () => {
    const { torId } = this.props
    let { data } = this.state

    delete data._id
    delete data.codeProject
    delete data.noAgreement
    delete data.noBookAgreement
    delete data.noBookSendAgreement
    delete data.noBookBankaccount
    delete data.noBookEndProject
    delete data.noBookQuitProject
    delete data.sql
    delete data.sendtoERF

    data.withdrawForm.forEach(x => {
      delete x.noBookTakeMoney
      delete x.noDisburse
    })

    data.tor_id = torId
    data.recDate = gettime
    data.timestamp = gettime
    data.status = 'ส่งข้อเสนอโครงการ'

    if (noProject) {
      data.codeProject = noProject
      noProject = undefined
      this.props.addProposal(data)
      this.setState({ openConfirmCopy: false })
    } else {
      this.props.genId(
        'project-' + thisYear + '-' + data.typeOffice + '-' + data.typeProject
      )
    }
  }

  saveDataSuccess = () => {
    this.props.onCloseModalProject()
  }

  handleOpenConfirmCopy = data => {
    this.setState({ data: data, openConfirmCopy: true })
  }

  paging = skip => {
    let { query } = this.state
    query.skip = query.skip + skip
    this.props.fetchProjects(query)
    this.setState({ query })
  }

  close = () => {
    this.setState({ openConfirmCopy: false })
  }

  onTextChange = (e, { name, value }) => {
    let { query } = this.state

    if (name === 'name') {
      let search = query.query
      if (value.match(/^[0-9]{2}/) !== null) {
        search['codeProject'] = {}
        search['codeProject']['$regex'] = value
        delete search['nameTh']
      } else {
        search['nameTh'] = {}
        search['nameTh']['$regex'] = value
        delete search['codeProject']
      }
      if (value.length >= 3) {
        this.props.searchProjects(search)
        this.setState({ search: search })
      } else if (value.length === 0) {
        search = query.query
        this.props.searchProjects(search)
      }
    } else {
      let search = query.query
      search['managerName'] = {}
      search['managerName']['$regex'] = value
      if (value.length >= 3) {
        this.props.searchProjects(search)
        this.setState({ search: search })
      } else if (value.length === 0) {
        search = query.query
        this.props.searchProjects(search)
      }
    }
  }

  handleFilter = (e, { value }) => {
    const { query } = this.state

    let { search } = this.state
    search = query.query

    if (value === 'ทั้งหมด') {
      if (search.status) {
        delete search.status
      }
    } else {
      search['status'] = value
    }
    this.props.searchProjects(search)
    this.setState({ status: value })
  }

  handleChangeSearch = () => {
    if (this.state.nameSearch === 'name') {
      this.setState({ nameSearch: 'managerName' })
    } else {
      this.setState({ nameSearch: 'name' })
    }
  }

  render () {
    const { projects } = this.props
    let { nameSearch, dataSaveing } = this.state

    return (
      <Fragment>
        <Dimmer active={dataSaveing}>
          <Loader size='large'>กำลังทำการบันทึกข้อมูล</Loader>
        </Dimmer>
        <Message color='yellow'>
          <Message.Header style={{ fontFamily: 'supermarket' }}>
            หมายเหตุ*
          </Message.Header>
          <p>
            * ผู้ใช้งานสามารถ คัดลอกข้อมูลโครงการที่เคยทำไปแล้วภายใต้ข้อเสนอเดิม
          </p>
          <p>* เลข Running Number ต่างๆ ในโครงการ จะเป็นเลขตัวใหม่</p>
          <p>
            * ผู้ใช้งานสามารถ แก้ไขข้อมูลโครงการที่คัดลอกได้ตามขั้นตอนต่างๆ
            เช่นเดียวกับการสร้างโครงการปกติ
          </p>
        </Message>
        <Segment>
          <Menu secondary>
            <Menu.Item fitted>
              <Input
                style={{ fontFamily: 'supermarket' }}
                name={nameSearch}
                onChange={this.onTextChange}
                icon='search'
                placeholder={
                  nameSearch === 'name'
                    ? 'ชื่อหรือรหัสโครงการ..'
                    : 'ผู้รับผิดชอบโครงการ..'
                }
              />
            </Menu.Item>
            <Menu.Item fitted>
              <Button
                style={{ fontFamily: 'supermarket' }}
                content={
                  nameSearch === 'name'
                    ? 'ชื่อหรือรหัสโครงการ'
                    : 'ผู้รับผิดชอบโครงการ'
                }
                fluid
                color='grey'
                icon='retweet'
                onClick={this.handleChangeSearch}
              />
            </Menu.Item>
            <Menu.Item fitted>
              <Form>
                <Dropdown
                  placeholder='กรองข้อมูล'
                  icon='filter'
                  labeled
                  button
                  className='icon search-dropdown'
                  onChange={this.handleFilter}
                  options={filterOptions}
                />
              </Form>
            </Menu.Item>
          </Menu>
        </Segment>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>เลขที่โครงการ</Table.HeaderCell>
              <Table.HeaderCell>ชื่อโครงการ</Table.HeaderCell>
              <Table.HeaderCell>ชื่อผู้รับผิดชอบโครงการ</Table.HeaderCell>
              <Table.HeaderCell>สถานะ</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>คัดลอก</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {projects &&
              projects.data.map((item, idx) => (
                <Table.Row key={idx}>
                  <Table.Cell>{item.codeProject}</Table.Cell>
                  <Table.Cell>{item.nameTh}</Table.Cell>
                  <Table.Cell>{item.managerName}</Table.Cell>
                  <Table.Cell>{item.status}</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Button
                      icon='copy'
                      color='blue'
                      onClick={this.handleOpenConfirmCopy.bind(this, item)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          {projects.data.length !== 0 && (
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan='5' textAlign='right'>
                  {projects.params && projects.params.skip !== 0 && (
                    <Button
                      style={{ fontFamily: 'supermarket' }}
                      content='ย้อนกลับ'
                      onClick={this.paging.bind(null, -10)}
                    />
                  )}
                  {projects &&
                    projects.data.length >= 10 &&
                    projects.data.length !== 0 && (
                    <Button
                      style={{ fontFamily: 'supermarket' }}
                      content='ถัดไป'
                      onClick={this.paging.bind(null, +10)}
                    />
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
        <Confirm
          open={this.state.openConfirmCopy}
          content='ต้องการคัดลอกโครงการใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.copyProject}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  selectedTor: state.tors.currentData,
  projects: state.allprojects,
  role: state.role,
  counter: state.counter,
  isSaving: state.allprojects.isSaving,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  fetchProjects: query => dispatch(projectActions.fetchList({ ...query })),
  searchProjects: query =>
    dispatch(
      projectActions.fetchList({
        query: query,
        sort: { nameTh: 1 },
        limit: 10,
        skip: 0
      })
    ),
  addProposal: data => dispatch(projectActions.save(data)),
  genId: name => dispatch(generate(name))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CloneProject)
