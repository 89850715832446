import React, { Component, Fragment } from 'react'
import { Button, Popup, Input, Modal, Header, Icon, Form, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { login } from 'Redux/reducers/auth'
import './Login.css'
import { actions as userActions } from 'Redux/reducers/user'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
      password: undefined,
      InvalidLogin: false,
      openForgotPass: false,
      data: {},
      sendmailError: false,
      sendmailSuccess: false
    }
  }

  componentDidMount = () => {
    this.props.listUser()
  }

  componentWillReceiveProps = (nextProps) => {
    const { auth } = nextProps
    if (auth.isLoginError) {
      this.setState({ InvalidLogin: true })
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleLoginClick = () => {
    let { user, password } = this.state
    user = (user.toLowerCase()).trim()
    this.props.login(user, password)
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      let { user, password } = this.state
      user = (user.toLowerCase()).trim()
      this.props.login(user, password)
    }
  }

  closeInvalid = () => {
    this.setState({ InvalidLogin: false, openForgotPass: false, sendmailError: false, sendmailSuccess: false })
  }

  handleForgotPass = () => {

    this.setState({ openForgotPass: true })
  }

  handleChangeResetPass = (e, { name, value }) => {
    let { data } = this.state

    data[name] = value.toLowerCase()

    this.setState({ data })
  }

  handleResetPass = () => {
    const { data } = this.state


    fetch('/resetpassword/pm/user/sendmail/' + data.resetPass, {
    }).then(res => {
      res.json().then((response) => {
        if (!response.haveEmail) {
          this.setState({ sendmailError: true })
        } else {
          this.setState({ sendmailError: false, sendmailSuccess: true })
        }
      })
    })

  }

  render() {
    const { user, password, InvalidLogin, sendmailError, sendmailSuccess } = this.state

    return (
      <Fragment>
        <Modal
          open={InvalidLogin}
          onClose={this.closeInvalid}
          size='small'
        >
          <Header icon='browser' style={{ fontFamily: 'supermarket' }} content='ผิดพลาด' />
          <Modal.Content>
            <h3 style={{ fontFamily: 'supermarket' }}>Username หรือ Password ผิด</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color='grey' onClick={this.closeInvalid}>
              <Icon name='checkmark' /> ตกลง
          </Button>
          </Modal.Actions>
        </Modal>

        <Popup
          trigger={
            <Button className="login-button" icon='heart' content='เข้าสู่ระบบ' />
          }
          content={
            <div className="login-modal-container">
              <Input
                onKeyPress={this.handleEnter}
                name='user'
                type='text'
                fluid
                placeholder='ชื่อผู้ใช้งาน...'
                onChange={this.handleChange}
                value={user || ''} />
              <Input
                onKeyPress={this.handleEnter}
                name='password'
                type='password'
                fluid
                placeholder='รหัสผ่าน...'
                onChange={this.handleChange}
                value={password || ''} />
              <div>
                <button className='ui right floated icon button' onClick={this.handleLoginClick}>
                  <i aria-hidden='true' className='heart' />
                  เข้าสู่ระบบ
                </button>
              </div>
            </div>
          }
          on='click'
          position='top right'
        />
        <Button className="login-button" icon='question circle' content='ลืมรหัสผ่าน' onClick={this.handleForgotPass} />
        <Modal open={this.state.openForgotPass} onClose={this.closeInvalid} closeIcon>
          <Header style={{ fontFamily: 'supermarket' }} icon='question circle' content='กรอก Email สำหรับ Reset รหัสผ่าน' />
          <Modal.Content>
            <Form error={sendmailError} >
              <Form.Group widths='equal'>
                <Form.Input name='resetPass' label='Email' placeholder='Email' onChange={this.handleChangeResetPass} />
              </Form.Group>
              <Message error>
                <Message.Header style={{ fontFamily: 'supermarket' }}>ผิดพลาด</Message.Header>
                <Message.List>
                  <Message.Item >ไม่มี Email นี้ในระบบ</Message.Item>
                </Message.List>
              </Message>
              {sendmailSuccess &&
                <Message
                  positive
                  header='Email ถูกต้อง'
                  content='ทางระบบได้ทำการส่ง link สำหรับ reset รหัสผ่านไปทาง Email ของคุณเรียบร้อยแล้ว'
                />
              }
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {!sendmailSuccess &&
              <Button onClick={this.closeInvalid} style={{ fontFamily: 'supermarket' }} color='red'>
                <Icon name='remove' /> ยกเลิก
            </Button>
            }
            {!sendmailSuccess &&
              <Button onClick={this.handleResetPass} style={{ fontFamily: 'supermarket' }} color='green'>
                <Icon name='checkmark' /> ยืนยัน
            </Button>
            }
            {sendmailSuccess &&
              <Button onClick={this.closeInvalid} style={{ fontFamily: 'supermarket' }} color='blue'>
                <Icon name='close' /> ปิด
            </Button>
            }
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  message: state.auth.errorMessage,
  isLoginError: state.auth.isLoginError,
  users: state.user
})

const mapDispatchToProps = dispatch => ({
  login: (user, pass) => dispatch(login({ username: user, password: pass })),
  listUser: () => dispatch(userActions.fetchList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)

