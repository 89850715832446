import React, { Component, Fragment } from 'react'
import {
  Form,
  TextArea,
  Button,
  Input,
  Checkbox,
  Segment,
  Header,
  Select,
  Transition,
  Icon,
  Message
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { connect } from 'react-redux'
import { constraints as ExtendTimeConstraints } from './ProgressConstraints'
import { actions as projectActions } from 'Redux/reducers/projects'
 
import { localTh } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'

let gettime = ''

class ExtendTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validate: { error: false, validateError: null, attrs: {} },
      modalAttachment: false,
      onSaveSuccess: false
    }
  }

  componentDidMount() {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentProposal) {
      let tmp = { ...nextProps.currentProposal }

      if (!tmp.attachmentEndForm) {
        tmp.attachmentEndForm = {}
      }
      let { data } = this.state
      this.setState({ data: { ...tmp, ...data } })
    }
  }

  handleChangeAttachment = (e, { name, checked, value }) => {
    let { data } = this.state
    data.attachmentEndForm[name] = checked || value
    //  data.attachmentEndForm[name] = value
    this.setState({ data })
  }

  _renderFormField = (content) => {
    const { validate } = this.state
    let { currentProposal, role } = this.props
    if ((currentProposal && currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin' && role !== 'Academic')) {
      if (content.control === Select) {
        return (<Form.Field {...content} error={validate.attrs[content.name]} disabled />)
      } else return (<Form.Field {...content} error={validate.attrs[content.name]} readOnly />)
    } else {
      if (content.control === Select) {
        return (<Form.Field {...content} error={validate.attrs[content.name]} />)
      } else return (<Form.Field {...content} error={validate.attrs[content.name]} />)
    }

  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate } = this.state;
    data[name] = checked || value;

    validate['validateError'] = validatejs(data, ExtendTimeConstraints.ExtendTimeConstraints)
    validate.attrs = {};
    validate.error = false;

    this.setState({ data, validate });
  }

  handleSubmit = () => {
    let { data } = this.state;
    const result = validatejs(data, ExtendTimeConstraints.ExtendTimeConstraints)
    if (result) {
      let attrs = {}
      Object.keys(result).forEach((item) => attrs[item] = true)
      this.setState({ validate: { error: true, validateError: result, attrs } })
    } else {
      this.setState({ validate: { error: false, validateError: result, attrs: {} }, onSaveSuccess: true })
      if (this.props.currentProposal) {
        data = { ...this.props.currentProposal, ...data }
        setTimeout(() => {
          this.setState({ onSaveSuccess: false });
        }, 3000)
        data['timestamp'] = gettime
        this.props.updateProposal(data, data._id)
      }
    }
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true })
  }

  handleUploaded = ({ uploaded }) => {
    let { currentProposal } = this.props
    if (!currentProposal[uploaded.name]) currentProposal[uploaded.name] = [];
    currentProposal[uploaded.name].push({ 'fileName': uploaded.typeOfAttach + '_' + uploaded.file.name, 'typeOfAttach': uploaded.typeOfAttach });
    this.props.updateProposal(currentProposal, currentProposal._id);
  }

  handleAttachedFileRemove = (name) => {
    let { currentProposal } = this.props;
    currentProposal['attachEndForm'].splice(name.index, 1);
    this.props.updateProposal(currentProposal, currentProposal._id);
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false })
  }

  handleCloseModal = () => {
    this.setState({ modalSendEmail: false, modalAttachment: false, modalAddGroupFinance: false, modalPrintout: false })
  }

  onMoneyChange = (e) => {
    let { data, validate } = this.state;
    let { currentProposal } = this.props;
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    // let money = Math.abs(parseFloat(e.target.rawValue))
    let money = e.target.rawValue.replace('-', '')
    data[e.target.name] = money? money.toString(): '0'
    // validate array
    if (!validate.attrs) validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    this.setState({ data, validate })
  }

  handleDayChangeRow = (name, index, date) => {
    let { data } = this.state;
    let { currentProposal } = this.props;
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    data[name] = date._d.getTime()

    const result = validatejs(data, ExtendTimeConstraints.ExtendTimeConstraints)
    if (result) {
      let attrs = {}
      Object.keys(result).forEach((item) => attrs[item] = true)
      this.setState({ validate: { error: true, validateError: result, attrs } })
    }

    this.setState({ data })
  }

  render() {
    const { currentProposal, currentState, role } = this.props
    let { data, validate, onSaveSuccess } = this.state
    data = { ...currentProposal, ...data }

    const content = {
      'reasonExtend': { control: TextArea, row: 5 },
      'attachmentExtend': { control: Checkbox },
      'otherattachmentExtend': { control: Checkbox },
      'otherattachmentExtendText': { control: Input },
      'commentExtend': { control: TextArea, row: 5 },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('proposal', key);
      content[key].name = key;
      content[key].onChange = this.handleChange;
      if (content[key].control === Input) {
        content[key].value = data[key] || ''
      } else if (content[key].control === Checkbox) {
        content[key].checked = data[key] || false
      } else {
        content[key].value = data[key] || ''
      }
    })

    return (
      <div>
        <HeaderContent
          subtitle='แบบรายงานขอปิดโครงการ'
          title={data.nameTh} />
        <Form error={validate.error}>
          <Segment>
            <Header style={{ fontFamily: 'supermarket' }} content='ขยายเวลาดำเนินโครงการ' as='h5' icon='clock outline' />
            <Form.Group>
              <Form.Field disabled={(currentProposal && currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin' && role !== 'Academic')} >
                <label>{'ขยายเวลาตั้งแต่วันที่'}</label>
                <DayPicker ser={'startDateExtend'} name={'startDateExtend'}
                  onDayChange={this.handleDayChangeRow}
                  data={data.startDateExtend || ''}
                  max={data.endDateExtend} />
              </Form.Field>
              <Form.Field disabled={(currentProposal && currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin' && role !== 'Academic')} >
                <label>{'ถึงวันที่'}</label>
                <DayPicker ser={'endDateExtend'} name={'endDateExtend'}
                  onDayChange={this.handleDayChangeRow}
                  data={data.endDateExtend || ''}
                  min={data.startDateExtend} />
              </Form.Field>
            </Form.Group>
            {this._renderFormField(content.reasonExtend)}
            {this._renderFormField(content.attachmentExtend)}
            <Form.Group>
              {this._renderFormField(content.otherattachmentExtend)}
              {this._renderFormField(content.otherattachmentExtendText)}
            </Form.Group>
            {this._renderFormField(content.commentExtend)}
          </Segment>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('proposal', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
          {<Button color='blue' content='บันทึก' icon='save'
            type='submit'
            // disabled={validate.error || (role !== 'Academic' && role !== 'Admin') ||
            //   (role !== 'Contract' && role !== 'Admin') ||
            //   (currentProposal && currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin')}
            loading={currentState.isSaving}
            onClick={this.handleSubmit} />}
        </Form>
        <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
          </Message>
        </Transition>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentState: state.projects,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: (id) => dispatch(projectActions.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExtendTime)