import React, { Component, Fragment } from 'react';
import { Table, Button, Menu, Segment, Input, Modal, Header, Icon, Confirm } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { ComponentPerson } from 'Utils'

class ModalListIndividuals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: {}
      },
      openModalOrganizations: false
    };
  }

  componentDidMount = () => {
    const { organizationId } = this.props
    let query = {}
    if (organizationId !== 'Freelance' && organizationId !== 'HaveOrg') {
      query = {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: { organization: organizationId }
      }
    }else if (organizationId === 'HaveOrg') {
      query = {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: { haveOrganization: 'yes' }
      }
    } else {
      query = {
        sort: { firstname: 1 },
        limit: 10,
        skip: 0,
        query: { haveOrganization: 'no' }
      }
    }
    this.props.listIndividuals(query)
    this.setState({ query })
    window.scrollTo(0, 0)
  }

  onSearch = (e, { value }) => {
    const { organizationId } = this.props
    const { query } = this.state

    query['query'] = {}
    query['query']['$or'] = []
    if (organizationId !== 'Freelance' && organizationId !=='HaveOrg') {
      query['query']['$or'].push({ 'firstname': { '$regex': value }, organization: organizationId})
      query['query']['$or'].push({ 'lastname': { '$regex': value }, organization: organizationId })
    }else if (organizationId === 'HaveOrg') {
      query['query']['$or'].push({ 'firstname': { '$regex': value }, haveOrganization: 'yes' })
      query['query']['$or'].push({ 'lastname': { '$regex': value }, haveOrganization: 'yes' })
    } else {
      query['query']['$or'].push({ 'firstname': { '$regex': value }, haveOrganization: 'no' })
      query['query']['$or'].push({ 'lastname': { '$regex': value }, haveOrganization: 'no' })
    }
    // query['query']['$or']['firstname']['$regex'] = value
    if (value.length >= 3) {
      this.props.listIndividuals(query)
      // this.setState({ search: search })
    } else if (value.length === 0) {
      if(organizationId === 'Freelance'){
        query['query'] = { haveOrganization: 'no' }
      }else  if(organizationId === 'HaveOrg'){
        query['query'] = { haveOrganization: 'yes' }
      }else{
        query['query'] = { organization: organizationId}
      }

      this.props.listIndividuals(query)
      // this.setState({ search: search })
    }
  }


  paging = (skip) => {
    let { query } = this.state
    query.skip = query.skip + skip
    this.props.listIndividuals(query)
    this.setState({ query })
  }

  handleEditIndividual = (idx) => {
    let { individuals } = this.props
    if (individuals) {
      this.props.getIndividuals(individuals.data[idx]._id)
    }
    this.setState({ openModalIndividual: true })

  }

  close = () => {
    this.setState({ openModalIndividual: false, checkIndividual: false,confirmDelPerson:false })
  }

  handleAddIndividual = () => {
    this.setState({ openModalIndividual: true, checkIndividual: true })
  }

  handleDeletePerson = ()=>{
    const { idPerson } = this.state
    this.props.delIndividuals(idPerson)
    this.setState({confirmDelPerson:false })

  }
  
  handleConfirmDeletePerson = (item)=>{
    this.setState({confirmDelPerson:true,idPerson:item})
  }



  render() {
    let { openModalIndividual, checkIndividual } = this.state
    const { individuals,role } = this.props

    return (
      <Fragment>
        <Header as='h2'>
          <Icon name='users' />
          <Header.Content style={{ fontFamily: 'supermarket' }}>
            รายชื่อบุคลากร
        </Header.Content>
        </Header>
        <Modal
          open={openModalIndividual}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon>
          <Modal.Header style={{ fontFamily: 'supermarket' }}>เพิ่ม บุคลากร</Modal.Header>
          <Modal.Content>
            <ComponentPerson openModalPerson={checkIndividual} onCloseModalPerson={this.close} />
          </Modal.Content>
        </Modal>
        <Segment>
          <Menu secondary >
            <Menu.Item fitted >
              <Input onChange={this.onSearch} icon='search' placeholder={'องค์กรหรือชื่อหัวหน้า..'} />
            </Menu.Item>
            {/* <Menu.Item fitted>
              <Form>
                <Dropdown
                  placeholder='กรองข้อมูล'
                  icon='filter'
                  labeled
                  button
                  className='icon search-dropdown'
                  onChange={this.handleFilter}
                  options={rolesOptions} />
              </Form>
            </Menu.Item> */}
            <Menu.Item fitted>
            </Menu.Item>
          </Menu>
        </Segment>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width='4'>ชื่อ-นามสกุล</Table.HeaderCell>
              <Table.HeaderCell width='4'>ชื่อองค์กร</Table.HeaderCell>
              <Table.HeaderCell width='4'>ตำแหน่ง</Table.HeaderCell>
              <Table.HeaderCell>ที่อยู่</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width='1'>แก้ไข</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width='1'>ลบ</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {individuals.data.map((item, idx) =>
              <Table.Row key={idx} >
                <Table.Cell>
                {((item.title==='อื่นๆ'? item.otherTitle : item.title ) || 
                (item.title===null? '' : item.title )) 
                + ' ' + item.firstname + ' ' + item.lastname}
                </Table.Cell>
                <Table.Cell>{item.organizationName}</Table.Cell>
                <Table.Cell>{item.role && item.role.map((itm, idx) => itm + ' ')}</Table.Cell>
                <Table.Cell>
                {item.address && (item.addressNo || '') + (item.address.province===null? '':' จ. ' + item.address.province )+ 
                (item.address.city===null? '':' อ. ' + item.address.city) + 
                (item.address.tumbon===null?'':' ต. ' + item.address.tumbon) + ' ' + (item.address.zipcode===null?'' : item.address.zipcode)}
                </Table.Cell>
                <Table.Cell textAlign='center'><Button icon='edit' onClick={this.handleEditIndividual.bind(this, idx)} /></Table.Cell>
                <Table.Cell textAlign='center'><Button disabled={role!=='Admin'} icon='close' color='red' onClick={this.handleConfirmDeletePerson.bind(this,item._id)}></Button></Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='2'><Button style={{ fontFamily: 'supermarket' }} color='green' content='เพิ่มบุคลากร' onClick={this.handleAddIndividual} /></Table.HeaderCell>
              <Table.HeaderCell colSpan='4' textAlign='right'>
                {individuals.params && individuals.params.skip !== 0 &&
                  <Button style={{ fontFamily: 'supermarket' }} content='ย้อนกลับ' onClick={this.paging.bind(null, -10)} />
                }
                {
                  individuals.data && individuals.data.length >= 10 && individuals.data.length !== 0 &&
                  <Button style={{ fontFamily: 'supermarket' }} content='ถัดไป' onClick={this.paging.bind(null, +10)} />
                }
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Confirm
         content='ต้องการลบใช่หรือไม่' 
         open={this.state.confirmDelPerson} 
         onCancel={this.close}
         cancelButton='ยกเลิก'
         confirmButton='ยืนยัน' 
         onConfirm={this.handleDeletePerson} />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  individuals: state.individuals,
  organization:state.organizations,
  role:state.role,
});

const mapDispatchToProps = dispatch => ({
  listIndividuals: (query) => dispatch(individualsActions.fetchList({ ...query })),
  getIndividuals: (id) => dispatch(individualsActions.fetchOne(id)),
  delIndividuals: (id) => dispatch(individualsActions.delete(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalListIndividuals);