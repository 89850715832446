import React, { Component, Fragment } from 'react';
import {
  Form,
  Table,
  Label,
  Button,
  Confirm
} from 'semantic-ui-react';
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux'
import { actions as scholarActions } from 'Redux/reducers/scholar'
import Cleave from 'cleave.js/react';
import { actions as individualsActions } from 'Redux/reducers/individuals'
 

let individualsName

class ComponentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      state: '',
      sumTabel: '0',
      indexDelete: '',
      validate: {},
      isLoad: false
    };
  }

  handleSelectRow = (selectedRow) => {
    let { data } = this.props
    if (selectedRow === this.state.selectedRow) selectedRow = null;
    else {
      if (data[selectedRow].namePerson) {
        let query = {
          '_id': data[selectedRow].namePerson
        }
        this.props.listPersonByOrganization(query)
      }
    }
    this.setState({ selectedRow, modalAttachment: false })
  }

  format = (input) => {
    if (!input) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      // remove leading zero

      num = num.replace(/^0+/, '')
      num = num.replace(/^\./, '0.')

      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      input = input.substring(0, input.length - 1);
    }
  }

  handleChangeMoney = (value) => {
    this.setState({ money: parseFloat(value).toString() })
  }

  handleAddRowTable = (e) => {
    const { nameArray, data } = this.props
    this.props.onAddRow(nameArray)
    this.setState({ state: '', selectedRow: (data.length) })
  }

  handleDeleteRowTable = () => {
    const { nameArray } = this.props
    const { indexDelete } = this.state
    this.props.onDelete(nameArray, indexDelete)
    this.setState({ state: '', openConfirm: false, selectedRow: null })
  }

  openConfirm = (idx) => {
    this.setState({ openConfirm: true, indexDelete: idx })
  }

  close = () => {
    this.setState({ openConfirm: false, selectedRow: null })
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  onTotalMoneyChange = (idx, e) => {
    const { nameArray } = this.props
    this.props.onChange(nameArray, e.target.name, e.target.rawValue, idx)
  }

  onMoneyChange = (idx, e) => {
    const { nameArray } = this.props
    this.props.onChange(nameArray, e.target.name, e.target.rawValue, idx)
  }

  handleChangeRowTable = (e, { name, value, index }) => {
    const { nameArray } = this.props
    this.props.onChange(nameArray, name, value, index)
    if (name === 'namePerson') {
      individualsName.map(r => {
        if (value === r.id) {
          this.props.onChangeName(nameArray, value, r.name, index)
        }
        return r
      })
    }
  }

  handleChangeSearch = (e) => {
    if (e.target.value.length >= 3) {
      let test = e.target.value.split(' ')
      let query = {
        'firstname': { $regex: test[0] },
        'lastname': { $regex: test[1] || '' } 
      }
      this.props.listPersonByOrganization(query)
    }
  }

  openModal = () => {
    if (this.props.openPerson) this.props.openPerson()
  }

  openOrg = () => {
    if (this.props.openOrg) this.props.openOrg()
  }

  render() {
    const { selectedRow } = this.state
    let { data, auth, individuals, nameArray, validate } = this.props

    // console.log(data)

    if (!data) {
      data = []
    }

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let individualsList = individuals.data && individuals.data ?
      individuals.data.map((item, idx) => ({
        key: idx,
        text: item.title === 'อื่นๆ' ? item.otherTitle + '  ' + item.firstname + ' ' + item.lastname : item.title + '  ' + item.firstname + ' ' + item.lastname,
        value: item._id
      })) : []

    individualsName = individuals.data.map((item, idx) => ({
      id: item._id,
      name: item.title === 'อื่นๆ' ? item.otherTitle + '  ' + item.firstname + ' ' + item.lastname : item.title + '  ' + item.firstname + ' ' + item.lastname,
    }))

    const typeList = [
      { key: '0', text: 'บุคคล', value: 'person' },
      { key: '1', text: 'กิจกรรม', value: 'activity' },
    ]

    return (
      <Fragment>
        <Table selectable celled>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell content='ลำดับ' width={1} />
              <Table.HeaderCell content='กิจกรรม' width={7} />
              {/* <Table.HeaderCell content='หน่วย' width={2} /> */}
              <Table.HeaderCell content='จำนวน' width={2} />
              {/* <Table.HeaderCell content='บาท' width={3} /> */}
              <Table.HeaderCell content='เป็นเงิน (บาท)' width={3} />
              <Table.HeaderCell >
                {/* <Button color='green' content='เพิ่ม' type='button'
                  onClick={this.handleAddRowTable} /> */}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data && data.map((item, idx) => (
              <Fragment key={idx}>
                <Table.Row onClick={this.handleSelectRow.bind(this, idx)} textAlign='center' error={validate.attrs[nameArray + idx]}>
                  {(selectedRow === idx && <Table.Cell>
                    <Label color="green" ribbon content={(idx + 1)} />
                  </Table.Cell>) || <Table.Cell content={idx + 1} />}
                  <Table.Cell width={8} content={item.content || ''} />
                  {item.type === 'person' &&
                    <Table.Cell content={item.namePerson ? '1 คน' : ''} />
                  }
                  {item.type !== 'person' &&
                    <Table.Cell content={(item.number || '') + ' ' + (item.unit || '')} />
                  }
                  <Table.Cell content={this.format(item.total) || ''} />
                  <Table.Cell >
                    <Button icon='close' color='red' type='button' index={idx} disabled={data.academicID && data.academicID !== user._id}
                      onClick={this.openConfirm.bind(this, idx)} />
                  </Table.Cell>
                </Table.Row>
                {selectedRow === idx && (
                  <Table.Row active>
                    <Table.Cell colSpan={6}>
                      <Form.Group>
                        <Form.Select name='type' label='ประเภทรายการ *' options={typeList} value={item.type} width={4}
                          onChange={this.handleChangeRowTable} index={idx} readOnly={data.academicID && data.academicID !== user._id} />
                        {item.type === 'activity' && <Form.Input name='content' value={item.content || ''} label='ชื่อรายการ *'
                          onChange={this.handleChangeRowTable} index={idx} readOnly={data.academicID && data.academicID !== user._id} width={8} />}
                        {item.type === 'person' &&
                          <Form.Select name='namePerson' value={item.namePerson || item.content || ''}
                            label='ชื่อบุคคล * (กรอก 3 ตัวอักษรเพื่อค้นหารายชื่อ)'
                            // {...DropdownExtensions}
                            onChange={this.handleChangeRowTable}
                            index={idx}
                            readOnly={data.academicID && data.academicID !== user._id}
                            width={8}
                            loading={this.props.individualsFetching}
                            options={individualsList} search={true}
                            placeholder={'กรุณากรอกอย่างน้อย 3 ตัวอักษร'}
                            onSearchChange={this.handleChangeSearch} />
                        }
                        <Form.Field >
                          <label>เพิ่มบุคคากร</label>
                          <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus' onClick={this.openModal} />
                        </Form.Field>
                        <Form.Field >
                          <label>เพิ่มองค์กร</label>
                          <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus' onClick={this.openOrg} />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths='equal' readOnly={data.academicID && data.academicID !== user._id}>
                        <Form.Field disabled={item.type === 'person'}>
                          <label>{'จำนวน'}</label>
                          <Cleave name='number'
                            maxLength='16'
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand'
                            }}
                            onChange={this.onMoneyChange.bind(this, idx)}
                            value={item.number || ''} />
                        </Form.Field >
                        <Form.Input disabled={item.type === 'person'} maxLength="15" name='unit' label='หน่วยนับ' value={item.unit || ''}
                          onChange={this.handleChangeRowTable} index={idx} readOnly={data.academicID && data.academicID !== user._id} />
                        <Form.Field disabled={item.type === 'person'}>
                          <label>{'ราคาต่อหน่วย (บาท)'}</label>
                          <Cleave name='money'
                            maxLength='16'
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand'
                            }}
                            onChange={this.onMoneyChange.bind(this, idx)}
                            value={item.money || ''} />
                        </Form.Field>
                        <Form.Field disabled={item.type !== 'person'}>
                          <label>{'จำนวนเงิน (บาท)'}</label>
                          <Cleave name='total'
                            maxLength='25'
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand'
                            }}
                            onChange={this.onMoneyChange.bind(this, idx)}
                            value={item.total || ''} />
                        </Form.Field>
                      </Form.Group>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Fragment>
            ))}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan='1' textAlign='center'>
                <Button style={{ fontFamily: 'supermarket' }} type='button' color='green' content='เพิ่ม' disabled={data.academicID && data.academicID !== user._id}
                  onClick={this.handleAddRowTable}
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='4'
                content={data[0] ? 'รวมเป็นเงิน :  ' + this.format(data[0].sum ? data[0].sum : 0) + '  บาท' : 'รวมเป็นเงิน :  0 บาท'}
                textAlign='right' />
            </Table.Row>
          </Table.Footer>
        </Table>
        <Confirm open={this.state.openConfirm}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton="ยืนยัน"
          onCancel={this.close}
          onConfirm={this.handleDeleteRowTable} />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  borrow: state.scholar.currentData,
  auth: state.auth,
  individuals: state.individuals,
  individualsFetching: state.individuals.fetchList
});

const mapDispatchToProps = dispatch => ({
  addBorrow: (data) => dispatch(scholarActions.save(data)),
  updateBorrow: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
  getBorrow: (id) => dispatch(scholarActions.fetchOne(id)),
  listPersonByOrganization: (query) => dispatch(individualsActions.fetchList({ query: query, limit: 10 })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentTable);
