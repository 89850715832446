import React, { Component, Fragment } from 'react'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { connect } from 'react-redux'
import { Step } from 'semantic-ui-react'
import { PageYearBudget, PageGroupBudget, PagePlanBudget, PageActivityBudget } from '../Admin'

class StepBudget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      active: 'year'
    }
  }

  componentDidMount() {
    this.setState({ active: 'year' })
  }

  handleClick = (e, { name }) => {
    this.setState({ active: name })
  }

  handleSelected = (name, value, state) => {
    let { data, active } = this.state
    data[name] = value
    active = state
    this.setState({ data, active })
  }

  render() {
    let { data, active } = this.state
    return (
      <Fragment >
        <Step.Group size='small' widths={4}>
          <Step
            active={active === 'year'}
            icon='calendar alternate outline'
            name='year'
            link
            onClick={this.handleClick}
            title='ปีงบประมาณ'
            description='เลือกรายการที่ต้องการแก้ไข'
          />
          <Step disabled={active === 'year'}
            active={active === 'group'}
            icon='sitemap'
            name='group'
            link
            onClick={this.handleClick}
            title='กลุ่มแผน'
            description='เลือกรายการที่ต้องการแก้ไข'
          />
          <Step disabled={active === 'year' || active === 'group'}
            active={active === 'plan'}
            icon='list'
            name='plan'
            link
            onClick={this.handleClick}
            title='แผนงาน'
            description='เลือกรายการที่ต้องการแก้ไข'
          />
          <Step disabled={active === 'year' || active === 'group' || active === 'plan'}
            active={active === 'activity'}
            icon='tasks'
            name='activity'
            link
            onClick={this.handleClick}
            title='กลุ่มกิจกรรม'
            description='เลือกรายการที่ต้องการแก้ไข'
          />
        </Step.Group>
        {active === 'year' && <PageYearBudget onSelected={this.handleSelected} obj={data} />}
        {active === 'group' && <PageGroupBudget onSelected={this.handleSelected} obj={data} />}
        {active === 'plan' && <PagePlanBudget onSelected={this.handleSelected} obj={data} />}
        {active === 'activity' && <PageActivityBudget onSelected={this.handleSelected} obj={data} />}
      </Fragment>
    )
  }
}

const mapState = state => ({
  fundings: state.fundings.currentData,
})

const mapAction = dispatch => ({
  updateDoc: (data, id) => dispatch(fundingsActions.save(data, id, 'POST')),
  getDoc: (id) => dispatch(fundingsActions.fetchOne(id)),
})

export default connect(mapState, mapAction)(StepBudget)
