import React, { Component } from 'react'
import Level from 'Containers/Level'
import Step from 'Components/Step'
import './Menu.css'
import { connect } from 'react-redux'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as torActions } from 'Redux/reducers/tors'

class Menu extends Component {
  componentDidMount = () => {
    let tor_id = localStorage.getItem('tor_id')
    let project_id = localStorage.getItem('project_id')

    this.props.getTor(tor_id)
    this.props.getProposal(project_id)
    let query = {
      _id: tor_id
    }
    this.props.listTor(query)
  }

  render () {
    const { project, tor, tors } = this.props
    // console.log(project)
    return (
      <div className='pmenu-container'>
        <div className='pmenu-content'>
          <div className='left'>
            <Level type='proporsal' />
          </div>
          <div className='right'>
            <div className='header'>
              <div className='title'>{project && project.nameTh}</div>
              <div className='detail'>
                ข้อกำหนด :{' '}
                {(tor && tor.nameTh) ||
                  (tors && tors.data[0] && tors.data[0].nameTh)}
              </div>
              {project && project.typeProject === 5 && (
                <div className='detail'>
                  ประเภทโครงการ : โครงการทุนต่อเนื่องปีที่{' '}
                  {project.seqConnect}
                </div>
              )}
              <div className='detail'>
                เลขที่โครงการ : {project && project.codeProject}
                <span className='divider'>|</span>
                สถานะ : {project && project.status}
              </div>
            </div>
            <Step {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tor: state.tors.currentData,
  tors: state.tors,
  project: state.projects.currentData
})

const mapDispatchToProps = dispatch => ({
  getProposal: id => dispatch(projectActions.fetchOne(id)),
  getTor: id => dispatch(torActions.fetchOne(id)),
  listTor: query => dispatch(torActions.fetchList({ query: query }))
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
