import React, { Component, Fragment } from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  Header,
  Message,
  Segment,
  TextArea,
  Table,
  Label,
  Transition,
  Confirm,
  Icon
} from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css'
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import 'moment-precise-range-plugin'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
// import { HeaderContent } from 'Components/Header'
import { constraints as Info2Constraints } from './BurnProjectConstraints'
import { constraints as ObjectiveArrayConstraints } from './BurnProjectConstraints'
import { constraints as TargetGroupArrayConstraints } from './BurnProjectConstraints'
import { constraints as OperationPlanArrayConstraints } from './BurnProjectConstraints'
import { constraints as ResultArrayConstraints } from './BurnProjectConstraints'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { actions as userActions } from 'Redux/reducers/user'
import { generate } from 'Redux/reducers/counting'
import { localTh } from 'Utils'
import { FundingFields } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'
import { ValidateArray as validateArray } from 'Utils'
import { ThaiDate } from 'Utils'
import { formatcomma } from 'Utils'
import { actions as typeProject } from 'Redux/reducers/typeproject'

let gettime = ''

let typeOfficeOption = [
  // { key: '0', text: 'สำนักบริหารกลาง', value: '01' },
  // { key: '1', text: 'สำนักพัฒนานโยบาย กลยุทธ์ และงบประมาณ', value: '02' },
  // { key: '2', text: 'สำนักบริหารเงินอุดหนุนผู้ขาดแคลนทุนทรัพย์', value: '03' },
  // { key: '3', text: 'สำนักพัฒนา นวัตกรรมการเรียนรู้เชิงพื้นที่', value: '04' },
  // { key: '4', text: 'สำนักพัฒนาคุณภาพครู นักเรียนครูและสถานศึกษา', value: '05' },
  // { key: '5', text: 'สถาบันวิจัยพัฒนาองค์ความรู้แบะเทคโนโลยีสารสนเทศเพื่อความเสมอภาคทางการศึกษา', value: '06' },
  // { key: '6', text: 'สำนักส่งเสริมการมีส่วนร่มนวัตกรรมและทุนการศึกษา', value: '07' },
  // { key: '7', text: '', value: '' },
]
class BurnPJInformation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      selectedObjectiveRow: null,
      selectedOperationPlanRow: null,
      selectedResultRow: null,
      onSaveSuccess: false,
      nameArray: '',
      onSaveFail: false
    }
  }

  componentDidMount () {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    this.props.listUser()
    this.props.getListOffice()

    if (match.params.id) {
      let query = {
        _id: match.params.id
      }

      this.props.getBurnProject(query)
    }
  }

  componentWillReceiveProps (nextProps) {
    let { data } = this.state
    const { burnprojects, typeProject } = nextProps

    if (typeProject.length > 0) {
      typeOfficeOption = []
      for (let i = 0; i < typeProject[0].typeOffice.length; i++) {
        typeOfficeOption.push({
          key: i,
          text: typeProject[0].typeOffice[i].name,
          value: typeProject[0].typeOffice[i].value
        })
      }
    }

    if (burnprojects) {
      let tmp = burnprojects

      if (!data.operationTime) {
        let sd = moment(burnprojects.startDate).format('YYYY-MM-DD')
        let ed = moment(burnprojects.endDate).format('YYYY-MM-DD')

        // var diff = moment.preciseDiff(sd, ed);
        var diff = moment.preciseDiff(sd + ' 00:00:00', ed + ' 24:00:00')

        let diffyth
        let diffmth
        let diffdth

        let cy = diff.indexOf('years')

        if (cy !== -1) {
          diffyth = diff.replace('years', 'ปี')
        } else {
          diffyth = diff.replace('year', 'ปี')
        }

        let cl = diff.indexOf('months')

        if (cl !== -1) {
          diffmth = diffyth.replace('months', 'เดือน')
        } else {
          diffmth = diffyth.replace('month', 'เดือน')
        }

        let cd = diff.indexOf('days')

        if (cd !== -1) {
          diffdth = diffmth.replace('days', 'วัน')
        } else {
          diffdth = diffmth.replace('day', 'วัน')
        }

        data['operationTime'] = diffdth

        this.setState({ data: { ...tmp, ...data } })
      }
    }
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state
    const { users, match, burnprojects } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data }
    }
    if (!validate.attrs) validate.attrs = {}
    validate.error = false
    validate.attrs = {}

    let usersName = users.data.map((item, idx) => ({
      id: item._id,
      name:
        item.title === 'อื่นๆ'
          ? item.otherTitle + '  ' + item.firstname + ' ' + item.lastname
          : item.title + '  ' + item.firstname + ' ' + item.lastname
    }))

    if (name === 'funding') {
      data['yearBudget'] = value.value.year
      data['groupsPlan'] = value.value.group
      data['plan'] = value.value.plan
      data['activity'] = value.value.activity
      data['valueGroup'] = value.value.valueGroup
      data['valuePlan'] = value.value.valuePlan
      data['valueActivity'] = value.value.valueActivity
    } else if (name === 'academicID') {
      usersName.map(r => {
        if (value === r.id) {
          data[name] = value
          data['nameAcademic'] = r.name
        }
        return r
      })
    } else if (name === 'typeOffice') {
      typeOfficeOption.map(r => {
        if (value === r.value) {
          data[name] = value
          data['typeOfficeName'] = r.text
        }
        return r
      })
    } else {
      data[name] = value
    }

    this.setState({ data, validate })
  }

  handleAddRow = (e, { name }) => {
    let { data, validate, nameArray } = this.state
    const { burnprojects } = this.props
    // let _data = []

    let _objective = []
    let _targetGroup = []
    let _operationPlan = []
    let _result = []
    let tmp = {}
    let indexO,
      indexT,
      indexP,
      indexR = null

    // if (burnprojects !== null) {
    if (name === 'targetGroup') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          _targetGroup = burnprojects.targetGroup.slice()
        } else {
          _targetGroup = data.targetGroup.slice()
        }
      }
      _targetGroup.push({ targetGroup: '' })
      indexT = _targetGroup.length - 1
      tmp = { ...data, targetGroup: _targetGroup }
    } else if (name === 'result') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          _result = burnprojects.result.slice()
        } else {
          _result = data.result.slice()
        }
      }
      _result.push({ result: '' })
      tmp = { ...data, result: _result }
      indexR = _result.length - 1
    } else if (name === 'operationPlan') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          _operationPlan = burnprojects.operationPlan.slice()
        } else {
          _operationPlan = data.operationPlan.slice()
        }
      }
      _operationPlan.push({ operationPlan: '' })
      tmp = { ...data, operationPlan: _operationPlan }
      indexP = _operationPlan.length - 1
    } else if (name === 'objective') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          _objective = burnprojects.objective.slice()
        } else {
          _objective = data.objective.slice()
        }
      }
      _objective.push({ objective: '' })
      tmp = { ...data, objective: _objective }
      indexO = _objective.length - 1
    }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    // if (name === 'objective') {
    //   if (!_data) _data = [];
    //   _data.push({ objective: '' });
    //   indexO = _data.length - 1
    // } else if (name === 'targetGroup') {
    //   if (!_data) _data = [];
    //   _data.push({ targetGroup: '' });
    //   indexT = _data.length - 1
    // } else if (name === 'operationPlan') {
    //   if (!_data) _data = [];
    //   _data.push({ operationPlan: '', budget: '' });
    //   indexP = _data.length - 1
    // } else if (name === 'result') {
    //   if (!_data) data['result'] = [];
    //   _data.push({ result: '' });
    //   indexR = _data.length - 1
    // }
    // let tmp = { ...data, ...tmp }
    this.setState({
      data: tmp,
      nameArray,
      validate,
      selectedObjectiveRow: indexO,
      selectedTargetGroupRow: indexT,
      selectedOperationPlanRow: indexP,
      selectedResultRow: indexR
    })
  }

  handleSelectRow = (selectedRow, name) => {
    let {
      selectedObjectiveRow,
      selectedTargetGroupRow,
      selectedOperationPlanRow,
      selectedResultRow
    } = this.state

    if (name === 'objective') {
      if (selectedRow === this.state.selectedObjectiveRow) {
        selectedObjectiveRow = null
      } else selectedObjectiveRow = selectedRow
    } else if (name === 'targetGroup') {
      if (selectedRow === this.state.selectedTargetGroupRow) {
        selectedTargetGroupRow = null
      } else selectedTargetGroupRow = selectedRow
    } else if (name === 'operationPlan') {
      if (selectedRow === this.state.selectedOperationPlanRow) {
        selectedOperationPlanRow = null
      } else selectedOperationPlanRow = selectedRow
    } else if (name === 'result') {
      if (selectedRow === this.state.selectedResultRow) {
        selectedResultRow = null
      } else selectedResultRow = selectedRow
    }

    this.setState({
      selectedObjectiveRow,
      selectedTargetGroupRow,
      selectedOperationPlanRow,
      selectedResultRow
    })
  }

  handleEditRow = (idx, field, e, { name, value }) => {
    let { data, validate, nameArray } = this.state
    const { burnprojects } = this.props

    let _objective = []
    let _targetGroup = []
    let _operationPlan = []
    let _result = []
    let tmp = {}

    if (field === 'targetGroup') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          burnprojects.targetGroup.forEach(item => {
            var tmp = { ...item }
            _targetGroup.push(tmp)
          })
        } else {
          data.targetGroup.forEach(item => {
            var tmp = { ...item }
            _targetGroup.push(tmp)
          })
        }
      }
      _targetGroup[idx][name] = value
      tmp = { ...data, targetGroup: _targetGroup }
    } else if (field === 'result') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          burnprojects.result.forEach(item => {
            var tmp = { ...item }
            _result.push(tmp)
          })
        } else {
          data.result.forEach(item => {
            var tmp = { ...item }
            _result.push(tmp)
          })
        }
      }
      _result[idx][name] = value
      tmp = { ...data, result: _result }
    } else if (field === 'operationPlan') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          burnprojects.operationPlan.forEach(item => {
            var tmp = { ...item }
            _operationPlan.push(tmp)
          })
        } else {
          data.operationPlan.forEach(item => {
            var tmp = { ...item }
            _operationPlan.push(tmp)
          })
        }
      }
      _operationPlan[idx][name] = value
      tmp = { ...data, operationPlan: _operationPlan }
    } else if (field === 'objective') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          burnprojects.objective.forEach(item => {
            var tmp = { ...item }
            _objective.push(tmp)
          })
        } else {
          data.objective.forEach(item => {
            var tmp = { ...item }
            _objective.push(tmp)
          })
        }
      }
      _objective[idx][name] = value
      tmp = { ...data, objective: _objective }
    }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    this.setState({ data: tmp, validate, nameArray })
  }

  onMoneyTargetsChange = (idx, e) => {
    let { data, validate } = this.state
    const { burnprojects } = this.props

    let _data = []
    if (burnprojects !== null) {
      if (Object.keys(data).length === 0) {
        burnprojects.targetGroup.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      } else {
        data.targetGroup.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      }
    }

    let money = e.target.rawValue.replace('-', '')
    _data[idx][e.target.name] = money ? money.toString() : '0'

    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false

    let tmp = { ...data, budgetDetail: _data }

    this.setState({ data: tmp, validate })
  }

  handleDeleteRow = () => {
    let { data, name, indexDelete, validate, nameArray } = this.state
    const { burnprojects } = this.props

    let _objective = []
    let _targetGroup = []
    let _operationPlan = []
    let _result = []
    let tmp = {}

    if (name === 'targetGroup') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          _targetGroup = burnprojects.targetGroup.slice()
        } else {
          _targetGroup = data.targetGroup.slice()
        }
      }
      _targetGroup.splice(indexDelete, 1)
      // indexT = _targetGroup.length - 1
      tmp = { ...data, targetGroup: _targetGroup }
    } else if (name === 'result') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          _result = burnprojects.result.slice()
        } else {
          _result = data.result.slice()
        }
      }
      _result.splice(indexDelete, 1)
      tmp = { ...data, result: _result }
      // indexR = _result.length - 1
    } else if (name === 'operationPlan') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          _operationPlan = burnprojects.operationPlan.slice()
        } else {
          _operationPlan = data.operationPlan.slice()
        }
      }
      _operationPlan.splice(indexDelete, 1)
      tmp = { ...data, operationPlan: _operationPlan }
      // indexP = _operationPlan.length - 1
    } else if (name === 'objective') {
      if (burnprojects) {
        if (Object.keys(data).length === 0) {
          _objective = burnprojects.objective.slice()
        } else {
          _objective = data.objective.slice()
        }
      }
      _objective.splice(indexDelete, 1)
      tmp = { ...data, objective: _objective }
      // indexO = _objective.length - 1
    }
    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    let sum = 0
    tmp.operationPlan.forEach(x => {
      let money = parseFloat(x.budget)
      sum += money
    })

    tmp.sumOperationPlan = sum

    // if (name === 'objective') {
    //   if (_data) _data.splice(indexDelete, 1)
    // } else if (name === 'targetGroup') {
    //   if (_data) _data.splice(indexDelete, 1)
    // } else if (name === 'operationPlan') {
    //   if (_data) _data.splice(indexDelete, 1)
    // } else if (name === 'result') {
    //   if (_data) _data.splice(indexDelete, 1)
    // }
    // let tmp = { ...data, [nameArray]: _data }
    this.setState({
      data: tmp,
      nameArray,
      selectedObjectiveRow: null,
      selectedOperationPlanRow: null,
      selectedTargetGroupRow: null,
      selectedResultRow: null,
      openConfirm: false
    })
  }

  openConfirm = (idx, name) => {
    this.setState({ openConfirm: true, indexDelete: idx, name: name })
  }

  handleSubmit = () => {
    let { data, validate } = this.state
    const { match, burnprojects } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })
    if (match.params.id) {
      data = { ...burnprojects, ...data }
    }

    data['status'] = 'กลั่นกรอง'

    validate.attrs = {}
    validate['validateError'] = validatejs(
      data,
      Info2Constraints.Info2Constraints
    )

    if (!data.objective) data.objective = []
    if (!data.targetGroup) data.targetGroup = []
    if (!data.operationPlan) data.operationPlan = []
    if (!data.result) data.result = []

    if (!validate['validateError']) validate['validateError'] = {}

    let validateArrayError = validateArray(
      data.objective,
      ObjectiveArrayConstraints.ObjectiveArrayConstraints
    )
    let validateArrayErrorT = validateArray(
      data.targetGroup,
      TargetGroupArrayConstraints.TargetGroupArrayConstraints
    )
    let validateArrayErrorO = validateArray(
      data.operationPlan,
      OperationPlanArrayConstraints.OperationPlanArrayConstraints
    )
    let validateArrayErrorR = validateArray(
      data.result,
      ResultArrayConstraints.ResultArrayConstraints
    )

    if (
      Object.keys(validateArrayError).length !== 0 &&
      validateArrayError.constructor === Object
    ) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayError).forEach(index => {
        if (validateArrayError[index].objective) {
          validate['validateError']['objective'] =
            validateArrayError[index].objective
        }
        validate.attrs['objective' + index] = true
      })
    }

    if (
      Object.keys(validateArrayErrorT).length !== 0 &&
      validateArrayErrorT.constructor === Object
    ) {
      validate.error = true
      if (!validate['validateError']) validate['targetGroup'] = {}
      Object.keys(validateArrayErrorT).forEach(index => {
        if (validateArrayErrorT[index].targetGroup) {
          validate['validateError']['targetGroup'] =
            validateArrayErrorT[index].targetGroup
        }
        if (validateArrayErrorT[index].targetsCount) {
          validate['validateError']['targetsCount'] =
            validateArrayErrorT[index].targetsCount
        }
        if (validateArrayErrorT[index].targetsUnit) {
          validate['validateError']['targetsUnit'] =
            validateArrayErrorT[index].targetsUnit
        }
        validate.attrs['targetGroup' + index] = true
      })
    }

    if (
      Object.keys(validateArrayErrorO).length !== 0 &&
      validateArrayErrorO.constructor === Object
    ) {
      validate.error = true
      if (!validate['validateError']) validate['operationPlan'] = {}
      Object.keys(validateArrayErrorO).forEach(index => {
        if (validateArrayErrorO[index].operationPlan) {
          validate['validateError']['operationPlan'] =
            validateArrayErrorO[index].operationPlan
        }
        if (validateArrayErrorO[index].startDate) {
          validate['validateError']['startDate'] =
            validateArrayErrorO[index].startDate
        }
        if (validateArrayErrorO[index].endDate) {
          validate['validateError']['endDate'] =
            validateArrayErrorO[index].endDate
        }
        if (validateArrayErrorO[index].budget) {
          validate['validateError']['budget'] =
            validateArrayErrorO[index].budget
        }
        validate.attrs['operationPlan' + index] = true
      })
    }

    if (
      Object.keys(validateArrayErrorR).length !== 0 &&
      validateArrayErrorR.constructor === Object
    ) {
      validate.error = true
      if (!validate['validateError']) validate['result'] = {}
      Object.keys(validateArrayErrorR).forEach(index => {
        if (validateArrayErrorR[index].result) {
          validate['validateError']['result'] =
            validateArrayErrorR[index].result
        }
        validate.attrs['result' + index] = true
      })
    }

    if (data.objective.length === 0) {
      validate.error = true
      validate['validateError']['objective'] = []
      validate['validateError']['objective'].push(
        'โปรดระบุวัตถุประสงค์อย่างน้อย 1 ข้อ'
      )
    }

    if (data.targetGroup.length === 0) {
      validate.error = true
      validate['validateError']['targetGroup'] = []
      validate['validateError']['targetGroup'].push(
        'โปรดระบุกลุ่มเป้าหมายอย่างน้อย 1 ข้อ'
      )
    }

    if (data.operationPlan.length === 0) {
      validate.error = true
      validate['validateError']['operationPlan'] = []
      validate['validateError']['operationPlan'].push(
        'โปรดระบุแผนการดำเนินงานอย่างน้อย 1 ข้อ'
      )
    }

    if (data.result.length === 0) {
      validate.error = true
      validate['validateError']['result'] = []
      validate['validateError']['result'].push(
        'โปรดระบุผลคาดว่าจะได้รับอย่างน้อย 1 ข้อ'
      )
    }

    let sum = 0
    data.operationPlan.forEach(x => {
      let money = parseFloat(x.budget)
      sum += money
    })

    // if (sum > data.budget) {
    //   validate.error = true
    //   validate['validateError']['budget'] = []
    //   validate['validateError']['budget'].push('งบประมาณรวมเกินจากงบประมาณโครงการ')
    // }

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      validate['error'] = false
      this.setState({ validate })
      if (sum > data.budget) {
        this.setState({ onSaveFail: true })
        setTimeout(() => {
          this.setState({ onSaveFail: false })
        }, 6000)
      } else {
        if (this.props.match.params.id) {
          data['timestamp'] = gettime

          // if (data.budgetDetail === undefined) {
          data.budgetDetail = []
          data.operationPlan.forEach(item => {
            data.budgetDetail.push({
              budgetContent: item.operationPlan,
              budget: item.budget
            })
          })
          // }
          data['sumOperationPlan'] = sum
          data['codeBudget'] =
            (parseInt(data.yearBudget) - 2500).toString() + data.valueActivity
          this.props.updateBurnPJ(data, data._id)
          this.setState({ onSaveSuccess: true })
          setTimeout(() => {
            this.setState({ onSaveSuccess: false })
          }, 3000)
        }
      }
    }
  }

  getEndTime = (start, endTime) => {
    var day = new Date(start).getDate()
    var month = new Date(start).getMonth() + endTime
    var year = new Date(start).getFullYear()
    var date = new Date(year, month, day).getTime()
    return date
  }

  _renderFormField = content => {
    let { data, validate } = this.state
    const { match, burnprojects, role } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data }
    }

    if (data.approve === 'done' && role !== 'Admin') {
      return (
        <Form.Field
          {...content}
          error={validate.attrs[content.name]}
          disabled
        />
      )
    } else
      return <Form.Field {...content} error={validate.attrs[content.name]} />
  }

  handleComponentPerson = () => {
    this.setState({ openModalPerson: true })
  }

  handleComponentOrganization = () => {
    this.setState({ openModalOrganization: true })
  }

  close = () => {
    this.setState({
      openModalPerson: false,
      openModalOrganization: false,
      openConfirm: false
    })
  }

  onMoneyChange = e => {
    let { data, validate } = this.state
    const { match, burnprojects } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data }
    }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    let money = e.target.rawValue.replace('-', '')
    data[e.target.name] = money ? money.toString() : '0'
    // data[e.target.name] = e.target.rawValue

    this.setState({ data, validate })
  }

  onMoneyPlanChange = (idx, e) => {
    let { data, validate } = this.state
    const { match, burnprojects } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data }
    }

    validate.error = false
    validate.attrs = {}
    validate.validateError = {}

    let money = e.target.rawValue.replace('-', '')
    data.operationPlan[idx][e.target.name] = money ? money.toString() : '0'

    let sum = 0
    data.operationPlan.forEach(x => {
      let money = parseFloat(x.budget)
      sum += money
    })

    data.sumOperationPlan = sum

    this.setState({ data, validate })
  }

  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state
    const { match, burnprojects } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data }
    }
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    data[name] = date._d.getTime()

    if (data.startDate && data.endDate) {
      let sd = moment(data.startDate).format('YYYY-MM-DD')
      let ed = moment(data.endDate).format('YYYY-MM-DD')

      // var diff = moment.preciseDiff(sd, ed);
      var diff = moment.preciseDiff(sd + ' 00:00:00', ed + ' 24:00:00')

      let diffyth
      let diffmth
      let diffdth

      let cy = diff.indexOf('years')

      if (cy !== -1) {
        diffyth = diff.replace('years', 'ปี')
      } else {
        diffyth = diff.replace('year', 'ปี')
      }

      let cl = diff.indexOf('months')

      if (cl !== -1) {
        diffmth = diffyth.replace('months', 'เดือน')
      } else {
        diffmth = diffyth.replace('month', 'เดือน')
      }

      let cd = diff.indexOf('days')

      if (cd !== -1) {
        diffdth = diffmth.replace('days', 'วัน')
      } else {
        diffdth = diffmth.replace('day', 'วัน')
      }

      data['operationTime'] = diffdth
    }

    this.setState({ data, validate })
  }

  handleDayPlanChange = (name, index, date) => {
    let { data, validate } = this.state
    const { match, burnprojects } = this.props
    if (match.params.id) {
      data = { ...burnprojects, ...data }
    }
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    data.operationPlan[index][name] = date._d.getTime()

    this.setState({ data, validate })
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  render () {
    let {
      validate,
      data,
      selectedObjectiveRow,
      onSaveSuccess,
      selectedTargetGroupRow,
      selectedOperationPlanRow,
      selectedResultRow,
      onSaveFail
    } = this.state
    const { auth, users, burnprojects, match, role } = this.props

    if (match.params.id) {
      data = { ...burnprojects, ...data }
    }

    let usersOptions = users.data.map((item, idx) => ({
      text:
        item.title === 'อื่นๆ'
          ? item.otherTitle + '  ' + item.firstname + ' ' + item.lastname
          : item.title + '  ' + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let content = {
      nameTh: { control: Input },
      typeOffice: { control: Select, width: 8, options: typeOfficeOption },
      funding: { control: FundingFields, width: 16 },
      principle: { control: TextArea },
      operationTime: { control: Input },
      budget: { control: Input },
      academicID: { control: Select, width: 8, options: usersOptions }
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('burnInfo', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === Input && content[key].name === 'nameTh') {
        content[key].value = data[key] || ''
      } else if (content[key].control === FundingFields) {
        content[key].value = {
          year: data['yearBudget'],
          group: data['groupsPlan'],
          plan: data['plan'],
          activity: data['activity'],
          valueGroup: data['valueGroup'],
          valuePlan: data['valuePlan'],
          valueActivity: data['valueActivity']
        }
      } else {
        content[key].value = data[key] || ''
      }
    })

    if (data.valueActivity === null) {
      data['codeBudget'] =
        (parseInt(burnprojects.yearBudget) - 2500).toString() +
        burnprojects.valueActivity
    } else {
      data['codeBudget'] =
        (parseInt(data.yearBudget) - 2500).toString() + data.valueActivity
    }

    let checkLock = data.approve === 'done' && role !== 'Admin'

    return (
      <Fragment>
        <Form error={validate.error}>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              icon='hotjar'
              content='โครงการประเภท "เผาหัว"'
              as='h4'
            />
            {this._renderFormField(content.nameTh)}
            <Header
              style={{ fontFamily: 'supermarket' }}
              icon='clock'
              content='ระยะเวลาดำเนินการ'
              as='h4'
            />
            <Form.Group>
              <Form.Field width={4} disabled={checkLock}>
                <label>{'วันที่เริ่มต้น'}</label>
                <DayPicker
                  ser={'startDate'}
                  name={'startDate'}
                  max={data.endDate || null}
                  onDayChange={this.handleDayChange}
                  data={data.startDate || ''}
                />
              </Form.Field>
              <Form.Field width={4} disabled={checkLock}>
                <label>{'วันที่สิ้นสุด'}</label>
                <DayPicker
                  ser={'endDate'}
                  name={'endDate'}
                  min={data.startDate || null}
                  onDayChange={this.handleDayChange}
                  data={data.endDate || ''}
                />
              </Form.Field>
              <Header style={{ fontFamily: 'supermarket', marginLeft: 20 }}>
                ระยะเวลาดำเนินการ :{' '}
                {data['operationTime']
                  ? data['operationTime']
                  : 'ยังไม่ได้ระบุเวลาเริ่มต้น/สิ้นสุด'}
              </Header>
            </Form.Group>
            <Form.Group>
              <Form.Field width='8' disabled={checkLock}>
                <label>{'งบประมาณ'}</label>
                <Cleave
                  name='budget'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.budget || ''}
                />
              </Form.Field>
              {this._renderFormField(content.typeOffice)}
            </Form.Group>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='หมวดงบประมาณโครงการ'
              as='h4'
              icon='money'
            />
            {this._renderFormField(content.funding)}
            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h4'
              content={'รหัสงบประมาณ : ' + (data.codeBudget || '')}
            />
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='หลักการและเหตุผล'
              as='h4'
              icon='pencil alternate'
            />
            {this._renderFormField(content.principle)}
            <Form.Group>{this._renderFormField(content.academicID)}</Form.Group>
            <Form.Group></Form.Group>
            <Header
              as='h4'
              style={{ fontFamily: 'supermarket' }}
              content='วัตถุประสงค์ *'
              icon='list alternate outline'
            />
            <Table celled selectable color='olive'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content='ลำดับ' width={2} />
                  <Table.HeaderCell content='วัตถุประสงค์ *' width={12} />
                  <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.objective &&
                  data.objective.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row
                        onClick={this.handleSelectRow.bind(
                          this,
                          idx,
                          'objective'
                        )}
                        error={validate.attrs['objective' + idx]}
                      >
                        {(selectedObjectiveRow === idx && (
                          <Table.Cell>
                            <Label
                              color='green'
                              ribbon
                              content={'ลำดับที่ ' + (idx + 1)}
                            />
                          </Table.Cell>
                        )) || (
                          <Table.Cell textAlign='center'> {idx + 1}</Table.Cell>
                        )}
                        <Table.Cell content={item.objective} />
                        <Table.Cell textAlign='center'>
                          <Button
                            type='button'
                            name='objective'
                            color='red'
                            icon='close'
                            onClick={this.openConfirm.bind(
                              this,
                              idx,
                              'objective'
                            )}
                            disabled={checkLock}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {selectedObjectiveRow === idx && (
                        <Table.Row active>
                          <Table.Cell colSpan={4}>
                            <Form.TextArea
                              label={'วัตถุประสงค์ที่ ' + (idx + 1)}
                              name='objective'
                              value={item.objective}
                              onChange={this.handleEditRow.bind(
                                this,
                                idx,
                                'objective'
                              )}
                              disabled={checkLock}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='5'>
                    <Button
                      type='button'
                      content='เพิ่ม'
                      color='green'
                      icon='plus'
                      name='objective'
                      onClick={this.handleAddRow}
                      disabled={checkLock}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <Header
              as='h4'
              style={{ fontFamily: 'supermarket' }}
              content='กลุ่มเป้าหมายและผู้ที่เกี่ยวข้อง *'
              icon='list alternate outline'
            />
            <Table celled selectable color='olive'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content='ลำดับ' width={2} />
                  <Table.HeaderCell content='กลุ่มเป้าหมาย *' width={12} />
                  {/* <Table.HeaderCell content='จำนวน *' width={2} /> */}
                  <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.targetGroup &&
                  data.targetGroup.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row
                        onClick={this.handleSelectRow.bind(
                          this,
                          idx,
                          'targetGroup'
                        )}
                        error={validate.attrs['targetGroup' + idx]}
                      >
                        {(selectedTargetGroupRow === idx && (
                          <Table.Cell>
                            <Label
                              color='green'
                              ribbon
                              content={'ลำดับที่ ' + (idx + 1)}
                            />
                          </Table.Cell>
                        )) || (
                          <Table.Cell textAlign='center'> {idx + 1}</Table.Cell>
                        )}
                        <Table.Cell content={item.targetGroup} />
                        {/* <Table.Cell content={(formatcomma(item.targetsCount) ? formatcomma(item.targetsCount) + ' ' + (item.targetsUnit || '') : ' ')} textAlign='center' /> */}
                        <Table.Cell textAlign='center'>
                          <Button
                            type='button'
                            name='targetGroup'
                            color='red'
                            icon='close'
                            onClick={this.openConfirm.bind(
                              this,
                              idx,
                              'targetGroup'
                            )}
                            disabled={checkLock}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {selectedTargetGroupRow === idx && (
                        <Table.Row active>
                          <Table.Cell colSpan={4}>
                            <Form.Group widths='equal'>
                              <Form.TextArea
                                name='targetGroup'
                                value={item.targetGroup}
                                label={'กลุ่มเป้าหมายที่ ' + (idx + 1)}
                                onChange={this.handleEditRow.bind(
                                  this,
                                  idx,
                                  'targetGroup'
                                )}
                                disabled={checkLock}
                              />
                            </Form.Group>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='5'>
                    <Button
                      type='button'
                      content='เพิ่ม'
                      color='green'
                      icon='plus'
                      name='targetGroup'
                      onClick={this.handleAddRow}
                      disabled={checkLock}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>

            <Header
              as='h4'
              style={{ fontFamily: 'supermarket' }}
              content='แผนการดำเนินงาน *'
              icon='list alternate outline'
            />
            <Table celled selectable color='olive'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content='ลำดับ' width={1} />
                  <Table.HeaderCell content='แผนการดำเนินงาน *' width={4} />
                  <Table.HeaderCell content='ระยะเวลา *' width={5} />
                  <Table.HeaderCell content='งบประมาณ *' width={3} />
                  <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.operationPlan &&
                  data.operationPlan.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row
                        onClick={this.handleSelectRow.bind(
                          this,
                          idx,
                          'operationPlan'
                        )}
                        error={validate.attrs['operationPlan' + idx]}
                      >
                        {(selectedOperationPlanRow === idx && (
                          <Table.Cell>
                            <Label
                              color='green'
                              ribbon
                              content={'ลำดับที่ ' + (idx + 1)}
                            />
                          </Table.Cell>
                        )) || (
                          <Table.Cell textAlign='center'> {idx + 1}</Table.Cell>
                        )}
                        <Table.Cell content={item.operationPlan} />
                        <Table.Cell textAlign='center'>
                          {'ลำดับที่ ' + (idx + 1)}
                          <br />
                          {(ThaiDate('month', item.startDate) ||
                            'ยังไม่กำหนด') +
                            ' - ' +
                            (ThaiDate('month', item.endDate) || 'ยังไม่กำหนด')}
                        </Table.Cell>
                        <Table.Cell content={formatcomma(item.budget)} />
                        <Table.Cell textAlign='center'>
                          <Button
                            type='button'
                            name='operationPlan'
                            color='red'
                            icon='close'
                            onClick={this.openConfirm.bind(
                              this,
                              idx,
                              'operationPlan'
                            )}
                            disabled={checkLock}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {selectedOperationPlanRow === idx && (
                        <Table.Row active>
                          <Table.Cell colSpan={6}>
                            <Form.Group widths='equal'>
                              <Form.TextArea
                                label={'แผนการดำเนินงานที่ ' + (idx + 1)}
                                name='operationPlan'
                                value={item.operationPlan}
                                onChange={this.handleEditRow.bind(
                                  this,
                                  idx,
                                  'operationPlan'
                                )}
                                disabled={checkLock}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Field width={4} disabled={checkLock}>
                                <label>{'วันที่เริ่มต้น'}</label>
                                <DayPicker
                                  ser={'startDate'}
                                  name={'startDate'}
                                  min={data.startDate}
                                  max={item.endDate || data.endDate}
                                  index={idx}
                                  onDayChange={this.handleDayPlanChange}
                                  data={item.startDate || ''}
                                />
                              </Form.Field>
                              <Form.Field width={4} disabled={checkLock}>
                                <label>{'วันที่สิ้นสุด'}</label>
                                <DayPicker
                                  ser={'endDate'}
                                  name={'endDate'}
                                  max={data.endDate}
                                  min={item.startDate || data.startDate}
                                  index={idx}
                                  onDayChange={this.handleDayPlanChange}
                                  data={item.endDate || ''}
                                />
                              </Form.Field>
                              <Form.Field width={8} disabled={checkLock}>
                                <label>{'งบประมาณ'}</label>
                                <Cleave
                                  name='budget'
                                  index={idx}
                                  maxLength='16'
                                  options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand'
                                  }}
                                  onChange={this.onMoneyPlanChange.bind(
                                    this,
                                    idx
                                  )}
                                  value={item.budget || ''}
                                />
                              </Form.Field>
                            </Form.Group>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='3'>
                    <Button
                      type='button'
                      content='เพิ่ม'
                      color='green'
                      icon='plus'
                      name='operationPlan'
                      onClick={this.handleAddRow}
                      disabled={checkLock}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    colSpan='2'
                    content={
                      data.sumOperationPlan
                        ? 'จำนวนเงินรวม : ' +
                          formatcomma(data.sumOperationPlan) +
                          ' บาท'
                        : 'จำนวนเงินรวม : 0 บาท'
                    }
                  />
                </Table.Row>
              </Table.Footer>
            </Table>
            <Header
              as='h4'
              style={{ fontFamily: 'supermarket' }}
              content='ผลที่คาดว่าจะได้รับ *'
              icon='list alternate outline'
            />
            <Table celled selectable color='olive'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content='ลำดับ' width={2} />
                  <Table.HeaderCell
                    content='ผลที่คาดว่าจะได้รับ *'
                    width={12}
                  />
                  <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.result &&
                  data.result.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row
                        onClick={this.handleSelectRow.bind(this, idx, 'result')}
                        error={validate.attrs['result' + idx]}
                      >
                        {(selectedResultRow === idx && (
                          <Table.Cell>
                            <Label
                              color='green'
                              ribbon
                              content={'ลำดับที่ ' + (idx + 1)}
                            />
                          </Table.Cell>
                        )) || (
                          <Table.Cell textAlign='center'> {idx + 1}</Table.Cell>
                        )}
                        <Table.Cell content={item.result} />
                        <Table.Cell textAlign='center'>
                          <Button
                            type='button'
                            name='result'
                            color='red'
                            icon='close'
                            onClick={this.openConfirm.bind(this, idx, 'result')}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {selectedResultRow === idx && (
                        <Table.Row active>
                          <Table.Cell colSpan={4}>
                            <Form.TextArea
                              label={'ผลที่คาดว่าจะได้รับที่ ' + (idx + 1)}
                              name='result'
                              value={item.result}
                              onChange={this.handleEditRow.bind(
                                this,
                                idx,
                                'result'
                              )}
                              disabled={checkLock}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='5'>
                    <Button
                      type='button'
                      content='เพิ่ม'
                      color='green'
                      icon='plus'
                      name='result'
                      onClick={this.handleAddRow}
                      disabled={checkLock}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                กรอกข้อมูลไม่ครบถ้วน
              </Message.Header>
              <Message.List>
                {validate.error &&
                  Object.keys(validate.validateError).map((key, idx) => (
                    <Fragment key={idx}>
                      {validate.validateError[key].map((item, idx) => (
                        <Message.Item key={idx}>
                          {' '}
                          {localTh('burnInfo', key)} - {item}{' '}
                        </Message.Item>
                      ))}
                    </Fragment>
                  ))}
              </Message.List>
            </Message>
            <Button
              style={{ fontFamily: 'supermarket' }}
              color='blue'
              content='บันทึก'
              icon='save'
              // disabled={validate.error} loading={currentState.isSaving}
              onClick={this.handleSubmit}
              disabled={checkLock}
            />
          </Segment>
        </Form>
        <Confirm
          open={this.state.openConfirm}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.handleDeleteRow}
        />
        <Transition
          visible={onSaveSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle'></Icon>บันทึกสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
        <Transition
          visible={onSaveFail}
          animation='horizontal flip'
          duration={500}
        >
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='close'></Icon>บันทึกไม่สำเร็จ
              งบประมาณรวมในแผนดำเนินงานเกิน งบประมาณโครงการ
            </Message.Header>
          </Message>
        </Transition>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  burnprojects: state.burnprojects.data[0],
  users: state.user,
  counter: state.counter,
  auth: state.auth,
  role: state.role,
  typeProject: state.typeproject.data
})

const mapDispatchToProps = dispatch => ({
  addBurnProject: data => dispatch(burnProjectAction.save(data)),
  updateBurnPJ: (data, id) =>
    dispatch(burnProjectAction.save(data, id, 'POST')),
  getBurnProject: query =>
    dispatch(burnProjectAction.fetchList({ query: query })),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  listUser: () => dispatch(userActions.fetchList()),
  genId: name => dispatch(generate(name)),
  getListOffice: () =>
    dispatch(typeProject.fetchList({ query: { name: 'สังกัดสำนัก' } }))
})

export default connect(mapStateToProps, mapDispatchToProps)(BurnPJInformation)
