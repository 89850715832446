const QuitProjectConstraints = {
  installmentCount: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  totalSpend: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  spending: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  quitProjectDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // quitProjectApprovement: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}

const EndFormConstraints = {
  refund: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  Takemoney: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  interest: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  ActualUsemoney: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // takeTime: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}

const ResultProgress = {
  askBudget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  isTimeFollowProposal: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  isSpendAskBudgetFollowProposal: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  isSpendOtherBudgetFollowProposal: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  
}

let constraints = {
  QuitProjectConstraints,
  EndFormConstraints,
  ResultProgress
}


module.exports = { constraints }