import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

import Main from 'Containers/Main'

import Header from 'Containers/Header'
// import Footer from 'Containers/Footer'
import Register from 'Containers/Register'
import { Search, SearchBurnProject } from 'Containers/Search'
import { SearchBorrow } from 'Containers/Search'
import { SearchAdmin } from 'Containers/Search'
import { TORForm } from 'Containers/TOR'
import { TORInfo } from 'Containers/TOR'
import { Information } from 'Containers/Project/RegisProject'
import { Objective } from 'Containers/Project/RegisProject'
import { Area } from 'Containers/Project/RegisProject'
import { WithdrawTime } from 'Containers/Project/RegisProject'
import { PageScreen } from 'Containers/Project/Screen'
import { PagePromise, PageSigner } from 'Containers/Project/Promise'
import { TabProgressProject, TabExtendTime } from 'Containers/Project/Progress'
import { TabWithdrawForm } from 'Containers/Project/Progress'
import { ResultProgress } from 'Containers/Project/EndProject'
import { PageQuitProject } from 'Containers/Project/EndProject'
import { EndForm } from 'Containers/Project/EndProject'
import { BorrowMoney } from 'Containers/Borrow'
import { TotalRefund } from 'Containers/Borrow'
import { TapBorrow } from 'Containers/Borrow'
import Project from 'Containers/Project/Project'
import Drashbroad from 'Containers/Project/Drashbroad'
import Menu from 'Containers/Project/Menu'
import { Borrow } from 'Containers/Borrow'
import { Admin } from 'Containers/Admin'
import { ListOrganization } from 'Utils'
import { ListIndividuals } from 'Utils'
import { Bill } from 'Containers/Borrow'
import { ListBill } from 'Containers/Borrow'
import { BurnProject } from 'Containers/BurnProject'
import { TapBurnProject } from 'Containers/BurnProject'
import { StepAddProfesBurn } from 'Utils'
// import { LagalDepartment, ListDepartment, ListNatualPerson } from 'Containers/LagalDepartment'
import { LagalDepartment, ListDepartment, ListNatualPerson } from 'Containers/LagalDepartment'
import {
  TableOffice, TablePersonOffice, ManageDB,
  TableYear, TableStatus, GraphProvince, GraphTargetGroup
} from 'Containers/GraphMap'
// import ClearCache from "react-clear-cache";

// const Information = React.lazy(() => import('Containers/Project/RegisProject/Information'))
//const Main = React.lazy(() => import('Containers/Main'));
//const Register = React.lazy(() => import('Containers/Register'));
//const Project = React.lazy(() => import('Containers/Project/Project'));

export const routes = [
  /* another pages */
  // TOR Page
  { path: '/torform/:id?', component: <TORForm /> },
  { path: '/torinfo/:id?', component: <TORInfo /> },
  { path: '/torflow/:id?', component: <TORForm /> },
  // Project Page
  { path: '/listorganization/:id?', component: <ListOrganization /> },
  { path: '/listindividuals/:id?', component: <ListIndividuals /> },
  { path: '/menu/:id?', component: <Menu /> },
  { path: '/information/:id?', component: <Information /> },
  { path: '/objective/:id?', component: <Objective /> },
  { path: '/area/:id?', component: <Area /> },
  { path: '/withdrawtime/:id?', component: <WithdrawTime /> },
  { path: '/pagescreen/:id?', component: <PageScreen /> },
  { path: '/pagepromise/:id?', component: <PagePromise /> },
  { path: '/pagesigner/:id?', component: <PageSigner /> },
  { path: '/tabProgressproject/:id?', component: <TabProgressProject /> },
  { path: '/tabwithdrawform/:id?', component: <TabWithdrawForm /> },
  { path: '/resultprogress/:id?', component: <ResultProgress /> },
  { path: '/pagequitproject/:id?', component: <PageQuitProject /> },
  { path: '/endform/:id?', component: <EndForm /> },
  // { path: '/ExtendTime/:id?', component: <ExtendTime /> },
  { path: '/ExtendTime/:id?', component: <TabExtendTime /> },

  // Borrow Page
  { path: '/borrowMoney/:id?', component: <BorrowMoney /> },
  { path: '/totalrefund/:id?', component: <TotalRefund /> },
  { path: '/searchborrow', component: <SearchBorrow /> },
  { path: '/tapborrow/:id?', component: <TapBorrow /> },

  //Admin Page
  { path: '/searchadmin', component: <SearchAdmin /> },
  { path: '/register', component: <Register /> },
  { path: '/stepaddprofesburn', component: <StepAddProfesBurn /> },


  { path: '/searchburnproject', component: <SearchBurnProject /> },

  { path: '/search', component: Search },
  { path: '/menu/:id?', component: Menu },

  //Receipt Page
  { path: '/bill/:id?', component: <Bill /> },
  { path: '/listbill/:id?', component: <ListBill /> },

  //BurnProject Page
  { path: '/tapburnproject/:id?', component: <TapBurnProject /> },

  //Graph ner version
  { path: '/graphoffice', component: <TableOffice /> },
  { path: '/graphPersonOffice/:id?', component: <TablePersonOffice /> },
  { path: '/graphYear', component: <TableYear /> },
  { path: '/graphStatus', component: <TableStatus /> },
  { path: '/graphProvince', component: <GraphProvince /> },
  { path: '/graphTargetGroup', component: <GraphTargetGroup /> },
  { path: '/managedb', component: <ManageDB /> },

  //Lagal ListDepartment
  // { path: '/listdepartment', component: <ListDepartment /> },
  { path: '/listdepartment', component: <ListDepartment /> },
  // { path: '/listnatualperson', component: <ListNatualPerson /> }
  { path: '/listnatualperson', component: <ListNatualPerson /> }

];

export class Router extends Component {

  render() {
    return (
      <div>
        {/* <ClearCache>
          {({ isLatestVersion, emptyCacheStorage }) => (
            <div>
              {!isLatestVersion && (window.location.href+'?eraseCache=true')}
              {isLatestVersion && ( */}
                <div>
                  <Header />
                  <HashRouter>
                    <Switch>
                      <Route exact path='/' component={Main} />
                      <Route path='/register' component={Register} />
                      <Route path='/borrow/:sub?/:id?' component={Borrow} />
                      <Route path='/project/:sub?/:id?/:callback?' component={Project} />
                      {/* <Route path='/project/:sub?/:id?/:callback?' component={Drashbroad} /> */}
                      <Route path='/burnproject/:sub?/:id?/:callback?' component={BurnProject} />
                      <Route path='/admin/:sub?/:id?' component={Admin} />
                      <Route path='/graph/:sub?/:id?' component={Drashbroad} />
                      <Route path='/lagaldepartment/:sub?/:id?' component={LagalDepartment} />
                      {/* <Route path='/graph/:sub?/:id?' component={Drashbroad} />  */}
                    </Switch>
                  </HashRouter>
                  {/* <Footer/> */}
                </div>
              {/* )}

            </div>
          )}
        </ClearCache> */}

      </div>
    )
  }
}
