import React, { Fragment, Component } from 'react'
import { Table, Form, Header, Select } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as typeprojectAction } from 'Redux/reducers/typeproject'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'

HighchartsMore(Highcharts)

class TableOffice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      offices: [],
      dataGraph: {},
      data: [],
      iconSortProject: 'angle up',
      iconSortMoney: '',
      sortDownProject: true,
      sortDownMoney: true,
      yearBudgetSelete: '2561'
    }
  }

  componentDidMount = () => {
    let query = {
      name: 'สังกัดสำนัก'
    }
    this.props.listOffice(query)
    this.props.listProposal(this.state.yearBudgetSelete)
  }

  componentWillReceiveProps = nextProps => {
    const { listOffices, projects } = nextProps
    let { data, offices, dataGraph, sortDownProject } = this.state

    if (projects.length > 0 && !projects[0].project) {
      this.props.listProposal(this.state.yearBudgetSelete)
    }
    offices = []
    data = []
    dataGraph = {}
    dataGraph.offices = []
    dataGraph.data = []
    dataGraph.project = []
    listOffices &&
      listOffices.typeOffice.forEach((office, idx) => {
        dataGraph.offices.push(office.name.substring(0, 59))
        let check = false
        projects.length > 0 &&
          projects.forEach(item => {
            if (item._id === office.value) {
              data.push({
                nameOffice: office.name,
                manyhave: item.project,
                money: item.total,
                value: office.value
              })
              dataGraph.project.push(item.project)
              dataGraph.data.push(item.total)
              check = true
            }
          })
        if (!check) {
          dataGraph.data.push(0)
          dataGraph.project.push(0)
          data.splice(idx, 0, {
            nameOffice: office.name,
            manyhave: 0,
            money: 0,
            value: office.value
          })
        }
        offices.push(office.name.substring(0, 59))
      })

    if (sortDownProject) {
      data.sort((a, b) => (a.manyhave > b.manyhave ? -1 : 1))
    } else {
      data.sort((a, b) => (a.manyhave > b.manyhave ? 1 : -1))
    }

    this.setState({ data, offices, dataGraph })

  }

  format(input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '')
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.')
        num[0] = num[0]
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1)
        }
        input = num[0] + '.' + num[1]
        return input
      } else {
        input = num
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        return input
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1)
    }
  }

  handleSelectOffice = value => {
    this.props.history.push('/graph/graphPersonOffice/' + value)
  }

  sortTable = content => {
    let {
      sortDownProject,
      data,
      iconSortProject,
      iconSortMoney,
      sortDownMoney
    } = this.state
    if (content === 'project') {
      iconSortMoney = ''
      sortDownProject = !sortDownProject
      if (sortDownProject) {
        data.sort((a, b) => (a.manyhave > b.manyhave ? -1 : 1))
        iconSortProject = 'angle up'
      } else {
        data.sort((a, b) => (a.manyhave > b.manyhave ? 1 : -1))
        iconSortProject = 'angle down'
      }
      this.setState({ sortDownProject, data, iconSortProject, iconSortMoney })
    } else {
      iconSortProject = ''
      sortDownMoney = !sortDownMoney
      if (sortDownMoney) {
        data.sort((a, b) => (a.money > b.money ? -1 : 1))
        iconSortMoney = 'angle up'
      } else {
        data.sort((a, b) => (a.money > b.money ? 1 : -1))
        iconSortMoney = 'angle down'
      }
      this.setState({ sortDownMoney, data, iconSortProject, iconSortMoney })
    }
  }

  // เด็กและเยาวชนด้อยโอกาส อายุระหว่างแรกเกิด - ๑๘ ปี
  // นักเรียน/นักศึกษา
  // ครู
  // สถานศึกษา
  // องค์กร
  // บุคลากรทางการศึกษาอื่นๆ

  render() {
    let {
      data,
      offices,
      dataGraph,
      iconSortProject,
      iconSortMoney
    } = this.state
    let { listOffices, projects } = this.props

    let dataProject = []
    let dataMoney = []
    listOffices &&
      listOffices.typeOffice.forEach((office, idx) => {
        let check = false
        projects.length > 0 &&
          projects.forEach(item => {
            if (item._id === office.value) {
              dataProject.push({
                name: office.name,
                y: item.project,
                format: this.format((item.project || 0).toString())
              })
              dataMoney.push({
                name: office.name,
                y: item.total,
                format: this.format((item.total || 0).toString())
              })
              check = true
            }
          })
        if (!check) {
          dataProject.splice(idx, 0, {
            name: office.name,
            y: 0,
            format: this.format((0).toString())
          })
          dataMoney.push({
            name: office.name,
            y: 0,
            format: this.format((0).toString())
          })
        }
      })

    // projects.length > 0 && projects.forEach(item => {
    //   dataTable.push({ year: item._id, money: item.total })
    //   data.push({ name: 'ปี ' + item._id, y: item.total, format: this.format((item.total || 0).toString()) })
    // })

    const optionss = {
      chart: {
        renderTo: 'container',
        polar: true,
        type: 'line'
      },

      title: {
        text: ' ',
        x: -80
      },

      pane: {
        size: '90%'
      },

      xAxis: {
        categories: offices,
        tickmarkPlacement: 'on',
        lineWidth: 0
      },
      credits: {
        enabled: false
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      },

      tooltip: {
        shared: true
      },

      series: [
        {
          name: 'งบประมาณโครงการ',
          data: dataGraph.data
        }
      ]
    }

    const optionssss = {
      chart: {
        renderTo: 'container',
        polar: true,
        type: 'line'
      },

      title: {
        text: ' ',
        x: -80
      },

      pane: {
        size: '90%'
      },

      xAxis: {
        categories: offices,
        tickmarkPlacement: 'on',
        lineWidth: 0
      },
      credits: {
        enabled: false
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      },

      tooltip: {
        shared: true
      },

      series: [
        {
          name: 'จำนวนโครงการ',
          data: dataGraph.project
        }
      ]
    }

    const options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '<b>{point.format} โครงการ</b>'
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f} %',
            style: {
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                'black'
            }
          }
        }
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 20,
        y: 100,
        itemMarginTop: 5,
        itemMarginBottom: 5
      },
      credits: {
        enabled: false
      },
      series: [
        {
          colorByPoint: true,
          data: dataProject
        }
      ]
    }

    const optionsss = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '<b>{point.format} บาท</b>'
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f} %',
            style: {
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                'black'
            }
          }
        }
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 20,
        y: 100,
        itemMarginTop: 5,
        itemMarginBottom: 5
      },
      credits: {
        enabled: false
      },
      series: [
        {
          colorByPoint: true,
          data: dataMoney
        }
      ]
    }

    const yearOptions = [
      { key: '2561', value: '2561', text: '2561' },
      { key: '2562', value: '2562', text: '2562' },
      { key: '2563', value: '2563', text: '2563' },
      { key: '2564', value: '2564', text: '2564' },
      { key: '2565', value: '2565', text: '2565' },
      { key: '2566', value: '2566', text: '2566' },
      { key: '2567', value: '2567', text: '2567' },
      { key: '2568', value: '2568', text: '2568' },
      { key: '2569', value: '2569', text: '2569' },
      { key: '2570', value: '2570', text: '2570' }
    ]

    return (
      <Form>
        <Header
          as='h1'
          style={{ fontFamily: 'supermarket' }}
          content='จำนวนโครงการแยกตามสำนัก'
          textAlign='center'
        />
        <Form.Group style={{ textAlign: 'center' }}>
          <Form.Field width={4} />
          <Form.Field width={16}>
            <label>ปีงบประมาณ</label>
            <Select
              style={{ fontFamily: 'supermarket', textAlign: 'center' }}
              options={yearOptions}
              value={this.state.yearBudgetSelete}
              onChange={(e, { value }) => {
                this.setState({ yearBudgetSelete: value })
                this.props.listProposal(value)
              }}
            />
          </Form.Field>
          <Form.Field width={4} />
        </Form.Group>

        <Form.Field width={16}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Form.Field>
        <Form.Group>
          <Form.Field width={16}>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    content='ลำดับ'
                    textAlign='center'
                    onClick={this.sortTable.bind(this, 'index')}
                  />
                  <Table.HeaderCell
                    content='ชื่อสำนัก'
                    textAlign='center'
                    onClick={this.sortTable.bind(this, 'office')}
                  />
                  <Table.HeaderCell
                    content='จำนวนโครงการ'
                    textAlign='center'
                    icon={iconSortProject}
                    onClick={this.sortTable.bind(this, 'project')}
                  />
                  <Table.HeaderCell
                    content='งบประมาณที่ใช้ในโครงการ'
                    textAlign='center'
                    icon={iconSortMoney}
                    onClick={this.sortTable.bind(this, 'money')}
                  />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.length > 0 &&
                  data.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row
                        onClick={this.handleSelectOffice.bind(this, item.value)}
                      >
                        <Table.Cell textAlign='center' content={idx + 1} />
                        <Table.Cell content={item.nameOffice} />
                        <Table.Cell
                          textAlign='center'
                          content={this.format(item.manyhave.toString())}
                        />
                        <Table.Cell
                          textAlign='center'
                          content={this.format(item.money.toString())}
                        />
                      </Table.Row>
                    </Fragment>
                  ))}
              </Table.Body>
            </Table>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={8}>
            <HighchartsReact highcharts={Highcharts} options={optionss} />
          </Form.Field>
          <Form.Field width={8}>
            <HighchartsReact highcharts={Highcharts} options={optionssss} />
          </Form.Field>
        </Form.Group>
        <Header
          as='h1'
          style={{ fontFamily: 'supermarket' }}
          content='จำนวนงบประมาณโครงการแยกตามสำนัก'
          textAlign='center'
        />
        <HighchartsReact highcharts={Highcharts} options={optionsss} />
      </Form>
    )
  }
}
const mapStateToProps = state => ({
  listOffices: state.typeproject.data[0],
  projects: state.projects.data,
  ProjectAll: state.allprojects.data,
  borrow: state.scholar.data,
  search: state.search,
  auth: state.auth,
  role: state.role,
  counter: state.counter,
  loading: state.projects.listLoading
})

const mapDispatchToProps = dispatch => ({
  listProposal: year =>
    dispatch(
      projectActions.fetchList({
        aggregate: [
          { $match: { delete: { $ne: true }, yearBudget: year } },
          {
            $addFields: {
              money_budget: {
                $cond: [
                  { $eq: ["$budget", null] },
                  '$askBudget',
                  '$budget'
                ]
              }
            }
          },
          {
            $group: {
              _id: '$typeOffice',
              project: { $sum: 1 },
              total: { $sum: { $toDouble: '$money_budget' } }
            }
          },
          { $sort: { _id: 1 } }
        ]
      })
    ),
  listOffice: query => dispatch(typeprojectAction.fetchList({ query: query }))
})

export default connect(mapStateToProps, mapDispatchToProps)(TableOffice)
