import React, { Component, Fragment } from 'react';
import { Icon, Table } from 'semantic-ui-react';

class AttachedFile extends Component {
  constructor(props) {
    super(props);

    this.linkRef = null;

    this.setLinkRef = element => {
      this.linkRef = element;
    };

    this.state = {
      isExists: false
    };
  }

  componentDidMount() {
    // this.fetchFile();
    this.setState({ isExists: true })
  }

  // fetchFile = () => {
  //   fetch('/minio/list/' + this.props.bucket, {
  //     headers: {
  //       'Authorization': 'JWT ' + localStorage.getItem('id_token')
  //     }
  //   })
  //     .then(res => res.json())
  //     .then(data => {
  //       // var tmp = data.url.filter((item) => item === this.props.name.fileName)[0];
  //       this.setState({ isExists: true })
  //     })
  // }


  handleOpen = (e) => {    
    var url = '/minio/download/' + this.props.bucket + '/' + this.props.name.fileName;
    var exts = this.props.name.fileName.split('.');
    var typeDict = {
      'pdf': 'application/pdf',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'jpg': 'image/jpg'
    }

    var type = typeDict[exts[exts.length - 1].toLowerCase()] || 'text/plain'


    var request = new XMLHttpRequest();
    request.open("GET", url);
    request.setRequestHeader("Authorization", 'JWT ' + localStorage.getItem('id_token'));
    request.responseType = 'blob';
    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        done(request.response);
      }
    }

    var done = function (blob) {
      var _blob = new Blob([blob], { type });
      var url = URL.createObjectURL(_blob);
      window.open(url, '_blank');
    }

    /*
    request.onload = (e) => { 
      var file = new Blob([this.response],{type:'application/pdf'});
      var fileurl = window.URL.createObjectURL(file);
      window.open(fileurl);
    };
    */
    request.send();
    // console.log(this.props.name.fileName, 'เปิดไฟล์')
  }

  handleRemove = (e) => {    
    console.log(this.props.bucket,this.props.name.fileName)
    fetch('/minio/' + this.props.bucket + '/' + this.props.name.fileName, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + localStorage.getItem('id_token')
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.ok) {
          if (this.props.onRemoved) this.props.onRemoved({ ...this.props });
        }
      })

  }

  render() {
    const { isExists } = this.state;
    const { name, readOnly, checkStatus } = this.props


    let content = <Fragment></Fragment>
    if (isExists) {
      content = (
        <Table.Row >
          <Table.Cell content={name.fileName} />
          <Table.Cell content={name.typeOfAttach} />
          <Table.Cell textAlign='center' content={<Icon name='download' link onClick={this.handleOpen} />} />
          {(readOnly || checkStatus) &&
            <Table.Cell textAlign='center' content={<Icon name='close' link onClick={this.handleRemove} />} />
          }
        </Table.Row>
      )
    }

    return (
      <Fragment>{content}</Fragment>
    )
  }
}

export default AttachedFile;
