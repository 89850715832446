import React, { Component, Fragment } from 'react';
import { Form } from 'semantic-ui-react'
import ThaiAddress from 'react-thai-address';

class FormSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressOptions: []
    };
  }

  handleSearchChange = (e, { searchQuery }) => {
    var queryAttrs = ['tumbon', 'city', 'province', 'zipcode'];
    var resultSize = 20;
    var result = {};
    var searchQueryArr = searchQuery.split(/\s+/g);
    searchQueryArr.forEach((searchStr) => {
      queryAttrs.forEach((attr) => {
        var _result = ThaiAddress.search({ [attr]: searchStr }, resultSize);
        _result.map((item) => ((item.province !== 'กรุงเทพมหานคร' && {
          'text': 'ตำบล' + item.tumbon + ' อำเภอ' + item.city + ' จังหวัด' + item.province + ' ' + item.zipcode,
          // 'value':item.tumbon+' '+item.city+' '+item.province+' '+item.zipcode,
          'value': JSON.stringify(item)
        }) || (item.province === 'กรุงเทพมหานคร' && {
          'text': 'แขวง' + item.tumbon + ' เขต' + item.city + ' ' + item.province + ' ' + item.zipcode,
          // 'value':item.tumbon+' '+item.city+' '+item.province+' '+item.zipcode,
          'value': JSON.stringify(item)
        })
        ))
          .forEach((item) => {
            if (!result[item.value]) result[item.value] = item;
          })
      });
    });

    const addressOptions = Object.keys(result).map((item) => result[item])
    this.setState({ addressOptions });
    /*
    var tmp = results.map((item) => ({
      'text':'ตำบล'+item.tumbon+' อำเภอ'+item.city+' จังหวัด'+item.province+' '+item.zipcode,
      'value':item.tumbon+' '+item.city+' '+item.province+' '+item.zipcode}))
    this.setState({addressOptions:tmp});
    */

  }

  handleOnChange = (e, { name, value, index }) => {
    if (this.props.onChange) this.props.onChange(e, { name, value: JSON.parse(value), index });
  }

  render() {
    let { addressOptions } = this.state
    const { label, readOnly, index } = this.props
    const value = this.props.value ? JSON.stringify(this.props.value) : '{}';
    let valueSt = '';
    if (this.props.value) {
      let item = this.props.value
      addressOptions = addressOptions.filter(item => item.value !== value)
      addressOptions.push({
        'text': 'ตำบล' + item.tumbon + ' อำเภอ' + item.city + ' จังหวัด' + item.province + ' ' + item.zipcode,
        value: value
      });
      valueSt = 'ตำบล' + item.tumbon + ' อำเภอ' + item.city + ' จังหวัด' + item.province + ' ' + item.zipcode;
    }
    return (
      <Fragment>
        {readOnly && (<p>{valueSt}</p>)}
        {!readOnly && (
          <Form.Dropdown placeholder='ที่อยู่' label={label}
            index={index}
            fluid search selection
            name={this.props.name}
            options={addressOptions}
            value={value}
            onChange={this.handleOnChange}
            onSearchChange={this.handleSearchChange}
          />
        )}
      </Fragment>
    )
  }
}

export default FormSearch
