import React, { Component, Fragment } from 'react';
import {
  Form,
  TextArea
} from 'semantic-ui-react'
import Cleave from 'cleave.js/react';

const otherBudgetOption = [
  { key: '1', text: 'งบประมาน', value: 'งบประมาน' },
  { key: '2', text: 'สิ่งของอื่นๆ', value: 'สิ่งของอื่นๆ' },
  { key: '3', text: 'ไม่มี', value: 'ไม่มี' },
]

class ComponentSetBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let { askBudget, otherBudgetDropdown, otherBudget, otherBudgetItem, nameAskBudget, nameotherDropdown,
      nameOtherBudget, nameOtherBudgetItem } = this.props

    return (
      <Fragment>
        <Form.Group widths='equal'>
          <Form.Field >
            <label>{'งบประมาณ'}</label>
            <Cleave name={nameAskBudget}
              maxLength='16'
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand'
              }}
              onChange={this.props.onMoneyToChange.bind(this)}
              value={askBudget || ''} />
          </Form.Field>
          <Form.Select label='การสมทบจากแหล่งอื่น' value={otherBudgetDropdown || ''}
            options={otherBudgetOption} onChange={this.props.onDatatoChange} name={nameotherDropdown} />
        </Form.Group>
        <Form.Group>
          {otherBudgetDropdown === 'งบประมาน' &&
            <Form.Field width='8'>
              <label>{'งบประมาณจากแหล่งอื่น'}</label>
              <Cleave name={nameOtherBudget}
                maxLength='16'
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
                onChange={this.props.onMoneyToChange.bind(this)}
                value={otherBudget || ''}
              />
            </Form.Field>
          }
          {otherBudgetDropdown === 'สิ่งของอื่นๆ' &&
            <Form.Field width='16'>
              <label>{'สิ่งของอื่นๆจากแหล่งอื่น'}</label>
              <TextArea name={nameOtherBudgetItem}
                value={otherBudgetItem}
                onChange={this.props.handleDataToChange}
              />
            </Form.Field>
          }
        </Form.Group>
      </Fragment>
    )
  }
}

export default ComponentSetBudget