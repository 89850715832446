import React, { Component, Fragment } from 'react';
import {
  Tab,
  Menu,
  Modal,
  Header,
  Button,
  Icon
} from 'semantic-ui-react';

import BurnPJInformation from './BurnPJInformation'
import BurnPJApprove from './BurnPJApprove'
import BurnScreen from './BurnScreen'
import { connect } from 'react-redux'
import { actions as scholarActions } from 'Redux/reducers/scholar'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'
import { HeaderContent } from 'Components/Header'
import { PrintoutComponent } from 'Utils'
import { AttachedComponent } from 'Utils'


const receiptOptions = [
  { key: '1', text: 'แบบขออนุมัติโครงการและจัดทำคำสั่ง', value: 'แบบขออนุมัติโครงการและจัดทำคำสั่ง' },
  { key: '2', text: 'แบบแนบท้ายคำสั่งโครงการ', value: 'แบบแนบท้ายคำสั่งโครงการ' },
  { key: '3', text: 'แบบกลั่นกรอง', value: 'แบบกลั่นกรอง' },
  { key: '4', text: 'คำสั่งกองทุนเพื่อความเสมอภาคทางการศึกษา', value: 'คำสั่งกองทุนเพื่อความเสมอภาคทางการศึกษา' },
]

class TapBurnProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPrintout: false,
      data: {}
    };
  }


  componentDidMount = () => {
    const { match } = this.props

    // if (match.params.id) {
    //   let query = {
    //     _id: match.params.id
    //   }

    //   this.props.getBurnProject(query)
    // }

    this.props.getBurnPJ(match.params.id)
  }

    handleUploaded = ({ uploaded }) => {
      let { burnprojects } = this.props

      let findArray = burnprojects[uploaded.name] && burnprojects[uploaded.name].find(x => x.fileName === uploaded.typeOfAttach + '_' + uploaded.file.name)
      if (!findArray) {
        if (!burnprojects[uploaded.name]) burnprojects[uploaded.name] = [];
        burnprojects[uploaded.name].push({ 'fileName': uploaded.typeOfAttach + '_' + uploaded.file.name, 'typeOfAttach': uploaded.typeOfAttach });
      } else {
        this.setState({ alert: true });
      }
      this.props.updateBurnPJ(burnprojects, burnprojects._id);
    }

    _onDownload = () => {
      this.setState({ modalPrintout: true, modalAttachment: false, modalSummary: false })
    }

    _onUpload = () => {
      this.setState({ modalPrintout: false, modalAttachment: true, modalSummary: false })
    }

    handleCloseModal = () => {
      this.setState({ modalSendEmail: false, modalAttachment: false, modalAddGroupFinance: false, modalPrintout: false, modalSummary: false })
    }

    handleAttachedFileRemove = (name) => {
      let { burnprojects } = this.props;
      burnprojects['burnAttach'].splice(name.index, 1);
      this.props.updateBurnPJ(burnprojects, burnprojects._id);
    }


  render() {
    const { match, burnprojects, role } = this.props
    const { modalAttachment, modalPrintout, alert } = this.state
    const panes = [
      { menuItem: <Menu.Item key='1' style={{ fontFamily: 'supermarket' }}>ข้อมูลพื้นฐาน</Menu.Item>, render: () => <BurnPJInformation match={match} /> },
      { menuItem: <Menu.Item key='2' style={{ fontFamily: 'supermarket' }}>กลั่นกรอง</Menu.Item>, render: () => <BurnScreen match={match} /> },
      { menuItem: <Menu.Item key='3' style={{ fontFamily: 'supermarket' }}>ขออนุมัติโครงการจัดทำคำสั่ง</Menu.Item>, render: () => <BurnPJApprove match={match} /> },
    ]

    let fileNamePrint = ['แบบกลั่นกรอง','แบบขออนุมัติโครงการและจัดทำคำสั่ง', 'แบบแนบท้ายคำสั่งโครงการ', 'คำสั่งกองทุนเพื่อความเสมอภาคทางการศึกษา']

    let nameOfFile = []
    // let numberFile =[]
      if(burnprojects && burnprojects.status === 'กลั่นกรอง'){
        fileNamePrint.forEach(x => {
          nameOfFile.push(x+ ' (ข้อมูลยังไม่ครบ)')
        });
      }else
      if(burnprojects && burnprojects.status === 'ขออนุมัติโครงการและจัดทำคำสั่ง'){
        fileNamePrint.forEach(x => {
          if(x!=='แบบกลั่นกรอง'){
            nameOfFile.push(x+ ' (ข้อมูลยังไม่ครบ)')
          }else{
            nameOfFile.push(x)
          }
        });
      }else if(burnprojects && burnprojects.status === 'ขออนุมัติโครงการและจัดทำคำสั่งเสร็จสิ้น'){
        fileNamePrint.forEach(x => {
            nameOfFile.push(x)
        });
      }else if(burnprojects && burnprojects.status === 'ข้อมูลพื้นฐานโครงการ'){
        fileNamePrint.forEach(x => {
          nameOfFile.push(x+ ' (ข้อมูลยังไม่ครบ)')
        });
      }

      let checkLock = burnprojects && burnprojects.approve === 'done'&& role !=='Admin'

    return (
      <Fragment>
        <HeaderContent
          title={burnprojects && burnprojects.nameTh}
          name='burn'
          onDownload={this._onDownload}
          onUpload={this._onUpload}
        />
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        <AttachedComponent
          currentProposal={burnprojects}
          namePage='burnAttach'
          namePageReadOnly='burnAttach'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={receiptOptions}
          onClose={this.handleCloseModal}
          checkStatus={!checkLock}
        />
        <PrintoutComponent
          modalPrintout={modalPrintout}
          // numberOfFile={['แบบขออนุมัติโครงการและจัดทำคำสั่ง', 'แบบแนบท้ายคำสั่งโครงการ', 'แบบกลั่นกรอง', 'คำสั่งกองทุนเพื่อความเสมอภาคทางการศึกษา']}
          numberOfFile={nameOfFile}
          typeFile={[22,21,23,24]}
          // typeFile={numberFile}
          match={match}
          onClose={this.handleCloseModal} 
          doc={burnprojects}/>
        <Modal
          open={alert}
          onClose={this.closeAlert}
          size='small'
        >
          <Header icon='browser' style={{ fontFamily: 'supermarket' }} content='ผิดพลาด' />
          <Modal.Content>
            <h3 style={{ fontFamily: 'supermarket' }}>ไฟล์ซ้ำ</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color='grey' onClick={this.closeAlert}>
              <Icon name='checkmark' /> ตกลง
          </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  borrow: state.scholar.currentData,
  auth: state.auth,
  burnprojects: state.burnprojects.currentData,
  role : state.role
});

const mapDispatchToProps = dispatch => ({
  addBorrow: (data) => dispatch(scholarActions.save(data)),
  updateBorrow: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
  updateBurnPJ: (data, id) => dispatch(burnProjectAction.save(data, id, 'POST')),
  getBurnPJ: (id) => dispatch(burnProjectAction.fetchOne(id)),
  getBurnProject: (query) => dispatch(burnProjectAction.fetchList({ query: query })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TapBurnProject)
