import React, { Component, Fragment } from 'react';
import { Form, Button, Header, Message, Input, Select, TextArea, Portal, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { localTh } from 'Utils'
import validatejs from 'validate.js'

import { constraints as EmailConstraints } from './EmailConstraints'
import { DatePicker as DayPicker } from 'Utils'
import { actions as emailsActions } from 'Redux/reducers/emails'


var typeSendOptions = [
  { text: 'ส่งทันที', value: 'sendNow' },
  { text: 'ส่งแบบมีเงื่อนไข', value: 'sendBefore' },
  { text: 'ส่งกำหนดวันที่', value: 'sendScheduled' },
]

var typeDateOptions = [
  { text: 'ชั่วโมง', value: 'hour' },
  { text: 'วัน', value: 'day' },
  { text: 'เดือน', value: 'month' },
]

class ComponentModalSendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        'from': '"Inforvation System" <admin@moe05.inforvation.systems>'
      },
      validate: { error: false, validateError: null, attrs: {} },
      resEmail: {},
      openSendEmailSuccess: false,
      hideDate: false,
      hideTypeDate: false,
      hideDatetime: false,
    };
  }


  _renderFormField = (content) => {
    const { validate } = this.state;
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else
      return <Form.Field {...content} error={validate.attrs[content.name]} />
  }

  handleSubmit = () => {
    const { data, validate, hideDate, hideDatetime, hideTypeDate } = this.state

    validate['validateError'] = validatejs(data, EmailConstraints.EmailConstraints)
    validate.attrs = {};

    if (!hideDate && !hideDatetime && !hideTypeDate) {
      if (!validate.validateError) {
        validate.error = false;
        fetch('/mail/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('id_token')
          },
          body: JSON.stringify(data)
        })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            this.setState({ resEmail: data })
          });
      } else {
        validate.error = true;
        Object.keys(validate.validateError).forEach((item) => {
          validate.attrs[item] = true;
        })
      }
      this.setState({ validate, openSendEmailSuccess: true })
      setTimeout(
        function () {
          this.setState({ openSendEmailSuccess: false });
        }
          .bind(this),
        3500
      );
    } else {
      console.log(data)
      this.props.addEmail(data)
    }
  }

  handleCloseModal = () => {
    this.props.onCloseModal()
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state;
    if (name === 'typeSend') {
      if (value === 'sendNow') {
        this.setState({ hideDate: false, hideTypeDate: false, hideDatetime: false })
      } else if (value === 'sendScheduled') {
        this.setState({ hideDatetime: true, hideDate: false, hideTypeDate: false, })
      } else {
        this.setState({ hideDate: true, hideTypeDate: true, hideDatetime: false })
      }
    }
    data[name] = value;
    validate['validateError'] = validatejs(data, EmailConstraints.EmailConstraints)
    validate.attrs = {};
    if (!validate.validateError) {
      validate.error = false;
    } else {
      validate.error = true;
      Object.keys(validate.validateError).forEach((item) => {
        validate.attrs[item] = true;
      })
    }
    this.setState({ data, validate });
  }

  handleDayChange = (name, index, date) => {
    let { data } = this.state;
    data[name] = date._d.getTime();
    // let len = data.withDrawTime.length
    // data.withDrawTime[0].startTime = data.startTime
    // data.withDrawTime[len - 1].endTime = data.endTime

    this.setState({ data })
  }

  render() {
    const { data, validate, resEmail, openSendEmailSuccess, hideDate, hideTypeDate, hideDatetime } = this.state
    let content = {
      'from': { control: Input, readOnly: true },
      'to': { control: Input },
      'subject': { control: Input },
      'text': { control: TextArea, autoHeight: true },
      'typeSend': { control: Select, options: typeSendOptions },
      'dateSend': { control: Input },
      'typeDate': { control: Select, options: typeDateOptions },
    }

    console.log(resEmail)

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('sendEmail', key);
      content[key].name = key;
      if (content[key].control === Input) {
        content[key].value = data[key] || '';
      } else {
        content[key].value = data[key];
      }
      content[key].onChange = this.handleChange;
    });

    return (
      <Fragment>
        <Header content='ส่ง Email' />
        <Form error={validate.error}>
          <Form.Group widths='equal'>
            {this._renderFormField(content.from)}
          </Form.Group>
          <Form.Group widths='equal'>
            {this._renderFormField(content.to)}
          </Form.Group>
          <Form.Group widths='equal'>
            {this._renderFormField(content.typeSend)}
            {hideDate && this._renderFormField(content.dateSend)}
            {hideTypeDate && this._renderFormField(content.typeDate)}
            {hideDatetime &&
              <Form.Field width={8}>
                <label>{'วันที่ส่งเมล์'}</label>
                <DayPicker ser={'dateEmail'} name={'dateEmail'}
                  onDayChange={this.handleDayChange}
                  data={data.dateEmail || ''} />
              </Form.Field>}
          </Form.Group>
          <Form.Group widths='equal'>
            {this._renderFormField(content.subject)}
          </Form.Group>
          <Form.Group widths='equal'>
            {this._renderFormField(content.text)}
          </Form.Group>
          <Form.Group inline>
            {Object.keys(resEmail).length === 0 &&
              <Button color='blue' type='submit'>ส่ง</Button>
            }
            {resEmail.accepted && resEmail.accepted.length !== '0' &&
              <Button floated='right' color='blue' type='button' onClick={this.handleCloseModal}>ปิด</Button>
            }
            <Portal
              closeOnTriggerClick
              openOnTriggerClick
              open={openSendEmailSuccess}
            >
              <Segment style={{ left: '40%', position: 'fixed', top: '50%', zIndex: 1000 }}>
                <Header>ส่ง Email สำเร็จ</Header>
              </Segment>
            </Portal>
          </Form.Group>
          <Message error>
            <Message.Header>Validate Error</Message.Header>
            <Message.List>
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('proposal', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
        </Form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentData,
  currentState: state.organizations,
  auth: state.auth,
  emails: state.emails.data,
  currentEmails: state.emails.currentData
});

const mapDispatchToProps = dispatch => ({
  addEmail: (data) => dispatch(emailsActions.save(data)),
  // updateProposal: (data, id) => dispatch(proposalActions.save(data, id, 'POST')),
  // getProposal: (id) => dispatch(proposalActions.fetchOne(id)),
  // updateForm: (data) => dispatch(formActions.update(data, PjmConstraints.PjmConstraints))
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentModalSendEmail);
