import React, { Component, Fragment } from 'react'
import './Register.css'
import passwordHash from 'password-hash'
import validatejs from 'validate.js'
import {
  Form,
  Button,
  Header,
  Input,
  Select,
  Message,
  Segment,
  TextArea,
  Modal
} from 'semantic-ui-react'

import { localTh } from 'Utils'
import { FormSearch } from 'Utils'
import { connect } from 'react-redux'
import { actions as userActions } from 'Redux/reducers/user'
import { actions as user_1Actions } from 'Redux/reducers/user_1'
import { constraints as RegisterConstraints } from './RegisterConstraints'
import { constraints as RegisterConditionConstraints } from './RegisterConstraints'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import ManageOffice from './ManageOffice';

const titleOptions = [
  { value: 'นาย', text: 'นาย' },
  { value: 'นาง', text: 'นาง' },
  { value: 'นางสาว', text: 'นางสาว' },
  { value: 'อื่นๆ', text: 'อื่นๆ(ระบุ)' },
]

const rolesOptions = [
  { value: 'Academic', text: 'นักวิชาการ' },
  { value: 'Contract', text: 'ศูนย์สัญญา' },
  { value: 'Staff', text: 'เจ้าหน้าที่' },
  { value: 'Law', text: 'ฝ่ายกฎหมาย' },
  { value: 'Admin', text: 'ผู้ดูแลระบบ' },
  { value: 'Co-Academic', text:'ผู้ช่วยนักวิชาการ'},
  { value: "auditor", text: "ผู้ตรวจสอบบัญชี" }
]

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      typeProjectModal: false,
      validate: { error: false, validateError: null, attrs: {} },
      onSaveSuccess: false,
      query: {
        sort: { user: 1 },
        limit: 5,
        skip: 0,
      }
    };
  }

  componentDidMount = () => {
    let query = {
      sort: { user: 1 },
      query: {}
    }
    this.props.listUser_1(query)
    this.props.getTypeProject('071f9ae0d12f11e89e8a73d5ec6ec9ef')
    this.setState({ query: query })
  }

  close = () => {
    this.props.getTypeProject('071f9ae0d12f11e89e8a73d5ec6ec9ef')
    this.setState({ typeProjectModal: false })
  }

  _renderFormField = (content) => {
    const { validate } = this.state;
    const { userData } = this.props
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else {
      if (content.label === 'PASSWORD' || content.label === 'USERNAME') {
        if (!userData) {
          return <Form.Field {...content} error={validate.attrs[content.name]} />
        } else {
          return <Form.Field {...content} error={validate.attrs[content.name]} disabled />
        }
      } else {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      }
    }
  }

  handleChange = (e, { name, value }) => {
    const { userData } = this.props
    let { data, validate } = this.state

    if (userData) data = { ...userData, ...data }

    validate.attrs = {};
    validate.error = false

    data[name] = value
    this.setState({ data })

  }

  validateCid = (value) => {
    if (value === null) return '^กรุณากรอกเลขบัตรประชาชน'
    else if (value.length !== 13) return '^ต้องมี 13 หลัก'
    else {
      var num = value.replace(/-/g, '');
      let digits = num.split("")
      // .map((st) => parseInt(st));
      if (digits[0] > 0 || digits[0] < 9) {
        let result = 0;
        for (var i = 0; i < 12; i++) {
          result += digits[i] * (13 - i)
        }
        result = (11 - (result % 11)) % 10;
        if (result !== parseInt(digits[12], 10)) {
          return 'เลขบัตรประชาชนไม่ถูกต้อง'
        }
      } else if (value[0] === 'P') return null
    }
  }

  handleSubmit = () => {
    const { users_1, userData } = this.props
    let { data, validate } = this.state

    if (userData) data = { ...userData, ...data }

    validate.attrs = {};
    if (data.title === 'อื่นๆ') {
      validate['validateError'] = validatejs(data, RegisterConditionConstraints.RegisterConditionConstraints)
    } else {
      validate['validateError'] = validatejs(data, RegisterConstraints.RegisterConstraints)
    }

    if (data.title === 'อื่นๆ') {
      data.title = data.titleOther
    }

    if (!userData) {
      users_1.forEach(x => {
        if (x.user === data.user) {
          validate.error = true
          validate.validateError = {}
          validate['validateError']['user'] = []
          validate['validateError']['user'].push('มีอยู่ในระบบแล้ว')
        }
      });
    }

    if (validate['validateError'] === undefined) {
      validate['validateError'] = {}
    }

    if (!data.cid) {
      validate['validateError'].cid = ['กรุณาใส่เลขบัตรเป็นตัวเลข']
    } else {
      if (data.cid.length !== 13) {
        validate['validateError'].cid = ['กรุณาใส่ให้ครบ 13 หลัก']
      } else {
        var regx = /^[0-9]*$/
        const check = data.cid.match(regx)
        if (check !== null) {
          let validCID = this.validateCid(data.cid)
          if (validCID !== undefined) {
            validate['validateError'].cid = [validCID]
          }
        } else {
          validate['validateError'].cid = ['กรุณาใส่เลขบัตรเป็นตัวเลข']
        }
      }
    }

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      if (data.password && !userData) {
        let Pwd = data.password.trim()
        data['password'] = passwordHash.generate(Pwd);
      }
      data.user = (data.user.toLowerCase()).trim()
      if (userData) {
        this.props.updateUser(data, data._id)
      } else {
        this.props.addUser(data)
      }
      this.setState({ onSaveSuccess: true });
      setTimeout(() => {
        this.setState({ onSaveSuccess: false });
      }, 3000)
      validate['error'] = false
      this.setState({ validate });
      this.props.onCloseModal()
    }
  }

  handleModalTypeProject = () => {
    this.setState({ typeProjectModal: true })
  }

  render() {
    let { data, validate } = this.state
    const { typeProject, userData } = this.props

    if (userData) data = { ...userData, ...data }

    let typeOffice = typeProject ? typeProject.name === 'สังกัดสำนัก' ? typeProject.typeOffice.map((item, idx) => ({
      key: idx,
      text: item.name,
      value: item.value
    })) : [] : []

    let content = {
      'user': { control: Input },
      'password': { control: Input, },
      'title': { control: Select, options: titleOptions },
      'titleOther': { control: Input },
      'firstname': { control: Input },
      'lastname': { control: Input },
      'firstnameEN': { control: Input },
      'lastnameEN': { control: Input },
      'email': { control: Input, width: 8 },
      'tel': { control: Input, width: 4 },
      'fax': { control: Input, width: 4 },
      'roles': { control: Select, options: rolesOptions, multiple: true },
      'workGroup': { control: Input },
      "address": { control: TextArea },
      'typeInstitution': { control: Select, width: 8, options: typeOffice, multiple: true },
      "addressCity": { control: FormSearch },
      "cid": { control: Input, width: 8 }
    }
    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('register', key);
      content[key].name = key;
      if (key === 'roles' || key === 'typeInstitution') content[key].value = data[key] || []
      else content[key].value = data[key] || ''
      content[key].onChange = this.handleChange;
    });

    return (
      <Fragment>
        <Segment>
          <Form error={validate.error}>
            <Header style={{ fontFamily: 'supermarket' }} content='ลงทะเบียน' as='h4' icon='newspaper' />
            <Form.Group widths='equal'>
              {this._renderFormField(content.user)}
              {this._renderFormField(content.password)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.title)}
              {this._renderFormField(content.titleOther)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.firstname)}
              {this._renderFormField(content.lastname)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.firstnameEN)}
              {this._renderFormField(content.lastnameEN)}
            </Form.Group>
            {this._renderFormField(content.cid)}
            {this._renderFormField(content.address)}
            {this._renderFormField(content.addressCity)}
            <Form.Group >
              {this._renderFormField(content.email)}
              {this._renderFormField(content.tel)}
              {this._renderFormField(content.fax)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.roles)}
              {this._renderFormField(content.workGroup)}
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.typeInstitution)}
              <Form.Field width={8}>
                <label>รายการสำนัก</label>
                <Button content='สังกัด' color='green' icon='plus' onClick={this.handleModalTypeProject} />
              </Form.Field>
            </Form.Group>

            <Button style={{ fontFamily: 'supermarket' }} color='green' content={userData ? 'บันทึก' : 'ลงทะเบียน'} onClick={this.handleSubmit} />

            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
              <Message.List>
                {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}> {localTh('register', key)} - {item} </Message.Item>
                    ))}
                  </Fragment>
                ))}
              </Message.List>
            </Message>
          </Form>
        </Segment>
        <Modal style={{ marginTop: 50 }}
          open={this.state.typeProjectModal}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon>
          <Modal.Content>
            <ManageOffice onClose={this.close} />
          </Modal.Content>
        </Modal>
        {/* <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>ลงทะเบียนสำเร็จ</Message.Header>
          </Message>
        </Transition> */}
        {/* </div> */}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  users: state.user.data,
  users_1: state.user_1.data,
  typeProject: state.typeproject.currentData
});

const mapDispatchToProps = dispatch => ({
  addUser: (data) => dispatch(userActions.save(data)),
  listUser: (query) => dispatch(userActions.fetchList(query)),
  listUser_1: (query) => dispatch(user_1Actions.fetchList(query)),
  getTypeProject: (id) => dispatch(typeProject.fetchOne(id)),
  updateUser: (data, id) => dispatch(userActions.save(data, id, 'POST')),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)
