import React, { Component, Fragment } from 'react';
import { Table, Button, Menu, Segment, Input, Modal, Header, Icon, Confirm } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as organizationActions } from 'Redux/reducers/organizations'
import { ComponentOrganization } from 'Utils'


class ModalListOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        sort: { organizationName: 1 },
        limit: 10,
        skip: 0,
        query: {}
      },
      openModalOrganizations: false
    };
  }

  componentDidMount = () => {
    let query = {
      sort: { organizationName: 1 },
      limit: 10,
      skip: 0,
      query: {}
    }
    this.props.listOrganization(query)
    this.setState({ query })
    window.scrollTo(0, 0)
  }

  onSearch = (e, { value }) => {
    const { query } = this.state

    query['query'] = {}
    query['query']['$or'] = []
    query['query']['$or'].push({ 'organizationName': { '$regex': value } })
    query['query']['$or'].push({ 'organizationBossLastname': { '$regex': value } })
    query['query']['$or'].push({ 'organizationBossName': { '$regex': value } })
    // query['query']['$or']['firstname']['$regex'] = value
    if (value.length >= 3) {
      this.props.listOrganization(query)
      // this.setState({ search: search })
    } else if (value.length === 0) {
      query['query'] = {}
      this.props.listOrganization(query)
      // this.setState({ search: search })
    }
  }


  paging = (skip) => {
    let { query } = this.state
    query.skip = query.skip + skip
    this.props.listOrganization(query)
    this.setState({ query })
  }

  handleEditOrganization = (idx) => {
    let { organizations } = this.props
    if (organizations) {
      this.props.getOrganization(organizations.data[idx]._id)
    }
    this.setState({ openModalOrganizations: true,checkOrganization:false })
  }

  handleListIndividual = (idx) => {
    let { organizations } = this.props
    this.props.onListIndividual(organizations.data[idx]._id)
    // this.props.history.push('/project/listindividuals/' + organizations.data[idx]._id);
  }

  handleListIndividualFreelance = () => {
    this.props.onListIndividual('Freelance')
    // this.props.history.push('/project/listindividuals/Freelance' );
  }

  handleListIndividualHaveOrg = () => {
    this.props.onListIndividual('HaveOrg')
  }

  handleAddOrganizations = () => {
    this.setState({ openModalOrganizations: true, checkOrganization: false })
  }

  close = () => {
    this.setState({ openModalOrganizations: false, checkOrganization: true, openModalListIndividual: false,confirmDelOrg:false })
  }

  handleDeleteOrg = ()=>{
    const { idOrg } = this.state
    this.props.delOrganization(idOrg)
    this.setState({confirmDelOrg:false })

  }

  handleConfirmDeleteOrg = (item)=>{
    this.setState({confirmDelOrg:true,idOrg:item})
  }



  render() {
    let { openModalOrganizations, checkOrganization } = this.state
    const { organizations, role } = this.props

    return (
      <Fragment>
        <Header as='h2'>
          <Icon name='university' />
          <Header.Content style={{ fontFamily: 'supermarket' }}>
            รายชื่อองค์กร
        </Header.Content>
        </Header>
        <Modal
          open={openModalOrganizations}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon>
          <Modal.Header style={{ fontFamily: 'supermarket' }}>เพิ่ม องค์กร</Modal.Header>
          <Modal.Content>
            <ComponentOrganization checkOrganization={checkOrganization} onCloseModalPerson={this.close} />
          </Modal.Content>
        </Modal>
        <Segment>
          <Menu secondary >
            <Menu.Item fitted >
              <Input onChange={this.onSearch} icon='search' placeholder={'องค์กรหรือชื่อหัวหน้า..'} />
            </Menu.Item>
            <Menu.Item fitted >
              <Button style={{ fontFamily: 'supermarket' }} content='รายชื่อบุคลากร ที่สังกัดองค์กร' fluid icon='list' onClick={this.handleListIndividualHaveOrg} />
            </Menu.Item>
            <Menu.Item fitted >
              <Button style={{ fontFamily: 'supermarket' }} content='รายชื่อบุคลากร ที่ไม่สังกัดองค์กรใดๆ' fluid icon='list' onClick={this.handleListIndividualFreelance} />
            </Menu.Item>
            {/* <Menu.Item fitted>
              <Form>
                <Dropdown
                  placeholder='กรองข้อมูล'
                  icon='filter'
                  labeled
                  button
                  className='icon search-dropdown'
                  onChange={this.handleFilter}
                  options={rolesOptions} />
              </Form>
            </Menu.Item> */}
            <Menu.Item fitted>
            </Menu.Item>
          </Menu>
        </Segment>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>ชื่อองค์กร</Table.HeaderCell>
              <Table.HeaderCell width={3}>หัวหน้าองค์กร</Table.HeaderCell>
              <Table.HeaderCell width={7}>ที่อยู่</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={2}>แก้ไข</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={2}>ลบ</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {organizations.data.map((item, idx) =>
              <Table.Row key={idx} >
                <Table.Cell onClick={this.handleListIndividual.bind(this, idx)}>{item.organizationName}</Table.Cell>
                <Table.Cell onClick={this.handleListIndividual.bind(this, idx)}>{item.organizationBossTitle === 'อื่นๆ' ?
                  item.organizationBossOtherTitle === '' ? '' : (item.organizationBossOtherTitle || '') + ' ' +
                    (item.organizationBossName || '') + ' ' + (item.organizationBossLastname || '') :
                  (item.organizationBossTitle || '') + ' ' + (item.organizationBossName || '') + ' ' +
                  (item.organizationBossLastname || '')}
                </Table.Cell>
                <Table.Cell onClick={this.handleListIndividual.bind(this, idx)}>
                  {item.organizationAdress && (item.organizationAdressNo || '') +
                    (item.organizationAdress.province === '' ? '' : ' จ. ') + item.organizationAdress.province + (item.organizationAdress.city === '' ? '' : ' อ. ') +
                    item.organizationAdress.city + (item.organizationAdress.tumbon === '' ? '' : ' ต. ') + item.organizationAdress.tumbon +
                    (item.organizationAdress.zipcode === '' || null ? '' : ' ' + item.organizationAdress.zipcode)}</Table.Cell>
                <Table.Cell textAlign='center'><Button icon='edit' onClick={this.handleEditOrganization.bind(this, idx)}></Button></Table.Cell>
                <Table.Cell textAlign='center'><Button disabled={role !== 'Admin'} icon='close' color='red' onClick={this.handleConfirmDeleteOrg.bind(this, item._id)}></Button></Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='2'><Button style={{ fontFamily: 'supermarket' }} color='green' content='เพิ่มองค์กร' onClick={this.handleAddOrganizations} /></Table.HeaderCell>
              <Table.HeaderCell colSpan='4' textAlign='right'>
                {organizations.params && organizations.params.skip !== 0 &&
                  <Button style={{ fontFamily: 'supermarket' }} color='blue' content='ย้อนกลับ' onClick={this.paging.bind(null, -10)} />
                }
                {
                  organizations.data && organizations.data.length >= 10 && organizations.data.length !== 0 &&
                  <Button style={{ fontFamily: 'supermarket' }} color='blue' content='ถัดไป' onClick={this.paging.bind(null, +10)} />
                }
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Confirm
         content='ต้องการลบใช่หรือไม่' 
         open={this.state.confirmDelOrg} 
         onCancel={this.close}
         cancelButton='ยกเลิก'
         confirmButton='ยืนยัน' 
         onConfirm={this.handleDeleteOrg} />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  organizations: state.organizations,
  role: state.role
});

const mapDispatchToProps = dispatch => ({
  listOrganization: (query) => dispatch(organizationActions.fetchList({ ...query })),
  getOrganization: (id) => dispatch(organizationActions.fetchOne(id)),
  delOrganization: (id) => dispatch(organizationActions.delete(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalListOrganization);