import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Table, Form, Header } from 'semantic-ui-react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more'
import { actions as projectActions } from 'Redux/reducers/projects';
import { actions as userAction } from 'Redux/reducers/user'

HighchartsMore(Highcharts)

class TablePersonOffice extends Component {

  componentDidMount = () => {
    const { match } = this.props
    this.props.listProposalWithOffice(match.params.id)
    this.props.listUser(match.params.id)
  }

  format(input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1);
    }
  }

  render() {
    const { projects, user } = this.props
    let dataUserTable = user
    dataUserTable.forEach((item, idx) => {
      if (!item.haveMany) {
        item.haveMany = 0
      }
      projects.forEach(row => {
        if (row.academic_id === item._id) {
          item.haveMany++
        }
      });
    })

    // const options = {
    //   chart: {
    //     plotBackgroundColor: null,
    //     plotBorderWidth: null,
    //     plotShadow: false,
    //     type: 'pie'
    //   },
    //   title: {
    //     text: ''
    //   },
    //   credits: {
    //     enabled: false
    //   },
    //   tooltip: {
    //     pointFormat: 'ปี {series.y}: <b>{point.percentage:.1f}%</b>'
    //   },
    //   plotOptions: {
    //     pie: {
    //       showInLegend: true,
    //       allowPointSelect: true,
    //       cursor: 'pointer',
    //       dataLabels: {
    //         enabled: true,
    //         format: '<b>{point.format} บาท</b> : {point.percentage:.1f} %',
    //         style: {
    //           color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
    //         }
    //       }
    //     }
    //   },
    //   legend: {
    //     align: "left",
    //     verticalAlign: "top",
    //     layout: "vertical",
    //     x: 20,
    //     y: 100,
    //     itemMarginTop: 5,
    //     itemMarginBottom: 5,
    //   },
    //   series: [{
    //     colorByPoint: true,
    //     data: data
    //   }]
    // };

    return (
      <Form>
        <Header as='h1' style={{ fontFamily: 'supermarket' }}
          content='จำนวนโครงการแยกตามสำนัก' textAlign='center'
          subheader='<<< เลือก ปีงบประมาณ [ทั้งหมด, 2562, 2561] >>>' />
        <Form.Group>
          <Form.Field width={8}>
            <Table celled selectable>
              <Table.Header>
                <Table.Row >
                  <Table.HeaderCell content='ลำดับ' textAlign='center' />
                  <Table.HeaderCell content='ชื่อโครงการ' textAlign='center' />
                  <Table.HeaderCell content='จำนวนเงินที่ใช้' textAlign='center' />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {projects.length > 0 && projects.map((item, idx) => (
                  <Fragment key={idx}>
                    <Table.Row >
                      <Table.Cell textAlign='center' content={idx + 1} />
                      <Table.Cell textAlign='center' content={item.nameTh} />
                      <Table.Cell textAlign='center' content={this.format((item.budget || 0).toString())} />
                    </Table.Row>
                  </Fragment>))}
              </Table.Body>
            </Table>
          </Form.Field>
          <Form.Field width={8}>
            <Table celled selectable>
              <Table.Header>
                <Table.Row >
                  <Table.HeaderCell content='ลำดับ' textAlign='center' />
                  <Table.HeaderCell content='ชื่อ - นามสกุล' textAlign='center' />
                  <Table.HeaderCell content='จำนวนโครงการ' textAlign='center' />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {dataUserTable.length > 0 && dataUserTable.map((item, idx) => (
                  <Fragment key={idx}>
                    {/* <ComponentGetCount id={item._id} name={item.title + ' ' + item.firstname + ' ' + item.lastname} /> */}
                    <Table.Row >
                      <Table.Cell textAlign='center' content={idx + 1} />
                      <Table.Cell textAlign='center' content={item.title + ' ' + item.firstname + ' ' + item.lastname} />
                      <Table.Cell textAlign='center' content={item.haveMany || '0'} />
                    </Table.Row>
                  </Fragment>))}
              </Table.Body>
            </Table>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }

}
const mapStateToProps = state => ({
  projects: state.projects.data,
  borrow: state.scholar.data,
  search: state.search,
  auth: state.auth,
  role: state.role,
  user: state.user.data,
  counter: state.counter
})

const mapDispatchToProps = dispatch => ({
  listProposalWithOffice: (id) => dispatch(projectActions.fetchList({
    query: { typeOffice: id, delete: { $ne: true } },
    projection: { nameTh: 1, budget: 1, academic_id: 1, academic: 1 }
  })),
  listUser: (id) => dispatch(userAction.fetchList({
    query: { typeInstitution: id },
    projection: { title: 1, firstname: 1, lastname: 1 }
  })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TablePersonOffice)