import React, { Component } from 'react'
import './Borrow.css'
import Paper from 'Components/Paper'
import SidebarBorrow from "Containers/Borrow/SidebarBorrow"
import { SearchBorrow, SearchBorrowResult } from 'Containers/Search'
import { routes } from 'Containers/Router'
import { Route, HashRouter, Switch } from 'react-router-dom'

const Wrapper = ({ match, component, history }) => {
  const children = React.cloneElement(component, { match, history })
  return (<Paper history={history} match={match}>{children}</Paper >)
}

class Borrow extends Component {
  render() {
    const { path } = this.props.match
    return (
      <div >
        <SidebarBorrow {...this.props}>
          <HashRouter>
            <Switch>
              <Route
                path='/borrow/searchborrow'
                component={() => <div>
                  <SearchBorrow {...this.props} />
                  <SearchBorrowResult {...this.props} />
                </div>
                } />
              {routes.map((r, i) => <Route key={i}
                exact={r.exact || false}
                path={path + r.path}
                component={() => {
                  return <Wrapper {...this.props} component={r.component} />
                }} />)}
            </Switch>
          </HashRouter>
        </SidebarBorrow>
      </div>
    )
  }
}

export default Borrow
