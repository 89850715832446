import React, { Component, Fragment } from 'react';
import { Modal, Table, Segment } from 'semantic-ui-react';
import AttachedFile from './AttachedFile'
import FileUploader from './FileUploader'

class AttachedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExists: true,
      modalOpen: false
    };
  }

  componentDidMount = () => {
    const { modalAttachment } = this.props

    if (modalAttachment || modalAttachment === true) this.setState({ modalOpen: true })
  }

  componentWillReceiveProps = (nextProps) => {
    const { modalAttachment } = nextProps

    if (modalAttachment || modalAttachment === true) this.setState({ modalOpen: true })
  }

  handleUploaded = (uploaded) => {
    if (this.props.onHandleUpload) this.props.onHandleUpload(uploaded)
  }

  closeModal = () => {
    this.props.onClose()
    this.setState({ modalOpen: false })
  }

  handleAttachedFileRemove = (name) => {
    this.props.onHandleRemove(name)
  }

  render() {
    const { isExists, modalOpen } = this.state
    const { modalAttachment, namePage, currentProposal, options, checkStatus } = this.props
    
    let content = <Fragment></Fragment>
    if (isExists) {
      content = (
        <Modal
          open={modalOpen}
          onClose={this.closeModal}
          size='small'
          closeIcon>
          <Modal.Content>
            {currentProposal && (!currentProposal[namePage]) &&
              <Segment className="ui placeholder segment" >
                <div className="ui icon header">
                  <i className="dont icon"></i>
                  <h2 style={{ fontFamily: 'supermarket' }}>
                    ไม่มีไฟล์แนบ
                  </h2>
                </div>
              </Segment>
            }
            {modalAttachment && currentProposal && (currentProposal[namePage]) && 
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell content='ชื่อไฟล์' width={9} />
                    <Table.HeaderCell content='ประเภทของไฟล์' width={5} />
                    <Table.HeaderCell textAlign='center' content='โหลด' width={2} />
                    {(checkStatus) && (
                      <Table.HeaderCell textAlign='center' content='ลบ' width={2} />
                    )}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {currentProposal[namePage] && currentProposal[namePage].map((item, idx) => (
                    <Fragment key={idx}>
                      <AttachedFile
                        {...this.props}
                        namePage={namePage}
                        bucket={currentProposal._id}
                        onRemoved={this.handleAttachedFileRemove}
                        name={item}
                        index={idx}
                        readOnly={checkStatus}
                        checkStatus={checkStatus} />
                    </Fragment>
                  ))}
                </Table.Body>
              </Table>
            }
            {checkStatus && currentProposal &&
              <FileUploader name={namePage} bucket={currentProposal._id}
                onUploaded={this.handleUploaded}
                options={options} currentProposal={currentProposal} />
            }
            {/* <AttachedComponentReadOnly currentProposal={currentProposal} namePageReadOnly={namePageReadOnly}/> */}
          </Modal.Content>
        </Modal>
      )
    }

    return (
      <Fragment>{content}</Fragment>
    )
  }
}




export default AttachedComponent
