import React, { Component, Fragment } from 'react';
import {
  Form,
  Header,
  Message,
  Input,
  Button,
  Select,
  TextArea,
  Table,
  Label,
  Icon,
  Transition,
  Segment,
  Confirm
} from 'semantic-ui-react'
import Cleave from 'cleave.js/react';
import validatejs from 'validate.js'
import jwt from 'jsonwebtoken'
import { localTh } from 'Utils'
import { FormSearch } from 'Utils'
import { formatcomma } from 'Utils'
import { constraints as BillConstraints } from './BillConstraints'
import { constraints as BillArrayConstraints } from './BillConstraints'
import { ValidateArray as validateArray } from 'Utils'
import { actions as receiptsActions } from 'Redux/reducers/receipts'
import { connect } from 'react-redux'

const titleOptions = [
  { value: 'นาย', text: 'นาย' },
  { value: 'นาง', text: 'นาง' },
  { value: 'นางสาว', text: 'นางสาว' },
  { value: 'อื่นๆ', text: 'อื่นๆ(ระบุ)' },
]

class AddBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      selectedRow: null,
      onSaveSuccess: false
    };
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }


  _renderFormField = (content) => {
    const { validate } = this.state;
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else
      return <Form.Field {...content} error={validate.attrs[content.name]} />
  }

  handleAddRow = () => {
    let { data } = this.state
    if(!data.listBill)data['listBill'] = []
    data.listBill.push({ listBill: '', billMoney: '' })

    this.setState({ data })
  }

  handleDeleteRow = () => {
    let { data, indexDelete, validate } = this.state;

    validate.validateError={}
    validate.attrs={}
    validate.error=false

    if (data.listBill) {
      data.listBill.splice(indexDelete, 1)
      this.setState({ data, openConfirmDel: false, selectedRow: null })
    }
  }

  handleConfirmDelete = (idx) =>{
    this.setState({ indexDelete:idx ,openConfirmDel: true })
  }

  handleChange = (e, { name, value }) => {
    let { data,validate } = this.state

    validate.validateError={}
    validate.attrs={}
    validate.error=false

    data[name] = value

    this.setState({ data })
  }

  handleSelectRow = (selectedRow) => {
    if (selectedRow === this.state.selectedRow) selectedRow = null;
    this.setState({ selectedRow })
  }
  
  summary = (array) => {
    let result = 0
    var nf = new Intl.NumberFormat()
    if (array) {
      array.forEach(r => (
        result += r.billMoney
      ));
    }
    return nf.format(result)
  }

  onMoneyChange = (idx, e) => {
    let { data,validate } = this.state
    let result = 0

    validate.validateError={}
    validate.attrs={}
    validate.error=false

    data['listBill'][idx]['billMoney'] = parseFloat(e.target.rawValue)
    if (data['listBill']) {
      data['listBill'].forEach(r => (
        result += r.billMoney
      ));
    }
    data['sumListBill']= result 

    this.setState({ data,validate })
  }



  handleEditRow = (idx, e, { name, value }) => {
    let { data,validate } = this.state

    validate.validateError={}
    validate.attrs={}
    validate.error=false

    data['listBill'][idx][name] = value

    this.setState({ data,validate })
  }

  handleSubmit = () => {
    const { auth,match } =this.props
    let { data, validate } = this.state;

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    validate.validateError={}
    validate.attrs={}
    validate.error=false

    if (!data.listBill) data.listBill = []

    validate.attrs = {};
    validate['validateError'] = validatejs(data, BillConstraints.BillConstraints)
    // validate array
    if (!validate['validateError']) validate['validateError'] = {}

    let validateArrayError = validateArray(data.listBill, BillArrayConstraints.BillArrayConstraints)
    if (Object.keys(validateArrayError).length !== 0 && validateArrayError.constructor === Object) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayError).forEach(index => {
        if (validateArrayError[index].listBill) {
          validate['validateError']['listBill'] = validateArrayError[index].listBill
        }
        if (validateArrayError[index].billMoney) {
          validate['validateError']['billMoney'] = validateArrayError[index].billMoney
        }
        validate.attrs['listBill' + index] = true;
      })
    }
    if (data.listBill.length === 0) {
      validate.error = true
      validate['validateError']['listBill'] = []
      validate['validateError']['listBill'].push('โปรดระบุรายการ')
    }

    // end validate array
    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      validate['error'] = false
      data['academicFirstname'] = user.firstname
      data['academicLastname'] = user.lastname
      data['academicId'] = user._id
      if(match) data['borrow_id'] = match.params.id
      this.setState({ validate });
      this.props.addReceipt(data)
      this.setState({ onSaveSuccess: true });
      setTimeout(() => {
        this.setState({ onSaveSuccess: false });
      }, 3000)
    }
  }

  onCidChange = (e) => {
    let { data, validate } = this.state

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    data['cid'] = e.target.rawValue

    this.setState({ data, validate })
  }

  close = () =>{
    this.setState({ openConfirmDel:false,selectedRow:false })
  }


  render() {
    const { data, validate, selectedRow, onSaveSuccess } = this.state

    if(!data.sumListBill) data.sumListBill = '0'

    let content = {
      'title': { control: Select, options: titleOptions },
      'titleOther': { control: Input ,disabled:data.title!=='อื่นๆ' },
      'firstname': { control: Input },
      'lastname': { control: Input },
      'billAddressNo': { control: TextArea },
      'billAddress': { control: FormSearch },
      'projectName': { control: Input },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('bill', key);
      content[key].name = key;
      content[key].onChange = this.handleChange;
      if (content[key].control === FormSearch) {
        content[key].value = data[key];
      } else {
        content[key].value = data[key] || '';
      }
    });

    return (
      <Fragment>
        <Segment>
          <Header icon='money' style={{ fontFamily: 'supermarket' }} content='ใบสำคัญรับเงิน' />
          <Form error={validate.error}>
          <Form.Group widths='equal'>
              {this._renderFormField(content.title)}
              {this._renderFormField(content.titleOther)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.firstname)}
              {this._renderFormField(content.lastname)}
            </Form.Group>
            <Form.Group >
              <Form.Field width='8'>
                <label>{'เลขประจำตัวประชาชน'}</label>
                <Cleave name='cid'
                  maxLength='17'
                  options={{
                    delimiters: [' '],
                    blocks: [1, 4, 5, 2, 1],
                    uppercase: true
                  }}
                  onChange={this.onCidChange.bind(this)}
                  value={data.cid || ''} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.billAddressNo)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.billAddress)}
              {this._renderFormField(content.projectName)}
            </Form.Group>
            <Header icon='tasks' style={{ fontFamily: 'supermarket' }} content='รายการ' />
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content='ลำดับ' textAlign='center' width={2} />
                  <Table.HeaderCell content='รายการ' textAlign='center' />
                  <Table.HeaderCell content='จำนวนเงิน (บาท)' textAlign='center' width={3} />
                  <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.listBill && data.listBill.map((item, idx) => (
                  <Fragment key={idx}>
                    <Table.Row error={validate.attrs['listBill' + idx]||validate.attrs['billMoney' + idx]} onClick={this.handleSelectRow.bind(this, idx)}>
                      {(selectedRow === idx && <Table.Cell>
                        <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                      </Table.Cell>) || <Table.Cell textAlign='center'>
                          {idx + 1}
                        </Table.Cell>}
                      <Table.Cell textAlign='center' content={item.listBill} />
                      <Table.Cell textAlign='center' content={formatcomma(item.billMoney)} />
                      <Table.Cell textAlign='center'>
                        <Button
                          type='button'
                          name={idx} color='red'
                          icon='close'
                          onClick={this.handleConfirmDelete.bind(this,idx)}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {selectedRow === idx && (
                      <Table.Row active>
                        <Table.Cell colSpan={5}>
                          <Form.TextArea label='รายการ' name='listBill'
                            value={item.listBill} onChange={this.handleEditRow.bind(this, idx)} />
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <label>{'จำนวนเงิน'}</label>
                              <Cleave name='billMoney'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.billMoney || ''} />
                            </Form.Field>
                          </Form.Group>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Fragment>
                ))}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan='1' textAlign='center'>
                    <Button type='button' color='green' content='เพิ่ม'
                      onClick={this.handleAddRow}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan='4' textAlign='center'>
                    {'รวมเป็นเงินทั้งสิ้น(ปิดโครงการ) : '+ formatcomma(data.sumListBill) +'  บาท'}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
              <Message.List>
                {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}> {localTh('bill', key)} - {item} </Message.Item>
                    ))}
                  </Fragment>
                ))}
              </Message.List>
            </Message>
            <Button onClick={this.handleSubmit} style={{ fontFamily: 'supermarket' }} color='blue' disabled={validate.error}>บันทึก</Button>
          </Form>
          <Confirm open={this.state.openConfirmDel}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton="ยืนยัน"
          onCancel={this.close}
          onConfirm={this.handleDeleteRow} />
          <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
            </Message>
          </Transition>
        </Segment>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  receipts: state.receipts,
  auth:state.auth
});

const mapDispatchToProps = dispatch => ({
  addReceipt: (data) => dispatch(receiptsActions.save(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBill);
