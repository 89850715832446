import { UrlConfig } from '../Config'

const GetTime = (callback) =>{
  fetch(UrlConfig.serverTime,{
    method:'get',
    headers: {
      'Content-Type':'application/json',
      'Authorization':'JWT eyJfaWQiOiJkOTJlOWUyMDgzMzAxMWU4OTc0NzI5MTUzYWE0NDEyNSIsImlkIjoiZDkyZTllMjA4MzMwMTFlODk3NDcyOTE1M2FhNDQxMjUiLCJVc2VyIjoidGVzdF8wMiIsIlBhc3NfU2FsdCI6IkxFamllMjJ4R3g4MzFsVWJ5NnBqTzdnREwraz0iLCJQYXNzX0hhc2giOiJBOEZmUUd3OFNFSWI1Zm9SbGE2MmdpVU14Yjg9IiwiaWF0IjoxNTM4NTQwMzQ5fQ'
    },
  })
  .then(response => response.json())
  .then(data => { 
    callback(data)
  })
}

export default GetTime